import React from 'react';
import { Link, useMatch } from 'react-router-dom';

export interface SubmenuProps {
  showSubmenu: boolean;
};

export interface SubmenuItemProps {
  label: string;
  link: string;
};

export const Submenu: React.FC<React.PropsWithChildren<SubmenuProps>> = ({
  children,
  showSubmenu,
}) => {
  if (!children) return null;
  return (
    <ul
      className={`
        flex
        flex-col
        overflow-hidden
        space-y-1
        pl-4
        ${showSubmenu ? 'h-max pb-2.5' : 'h-0'}
      `}
    >
      {children}
    </ul>
  );
};

export const SubmenuItem: React.FC<SubmenuItemProps> = ({
  link,
  label,
}) => {
  const linkMatch = useMatch(link);
  const isSelectedSubmenuClass = linkMatch
    ? 'bg-purple-100 font-medium'
    : '';

  return (
    <li>
      <Link
        to={link}
        aria-current={linkMatch ? 'page' : undefined}
        className={`
          block
          rounded-md
          py-1.5
          px-4
          text-slate-800
          hover:text-slate-900
          ${isSelectedSubmenuClass}
        `}
      >
        {label}
      </Link>
    </li>
  );
};
