import { ApolloError } from '@apollo/client';
import { useCreateCampaignInitialDataSuspenseQuery } from './campaign-initial-data.query.generated';

export type UseCampaignInitialDataHook = {
  vendors: {
    id: string;
    name: string;
  }[];
  error: ApolloError | undefined;
};

export const useCampaignInitialData = (): UseCampaignInitialDataHook => {
  const { data, error } = useCreateCampaignInitialDataSuspenseQuery();

  return {
    vendors: data.vendors.items.map(vendor => ({
      id: vendor.id,
      name: vendor.name,
    })),
    error,
  };
};
