import { useDeleteVendorMutation } from './delete-vendor.query.generated';

type UseDeleteVendorHook = {
  deleteVendor: (id: string) => Promise<void>;
  loading: boolean;
};

export const useDeleteVendor = (): UseDeleteVendorHook => {
  const [vendorMutation, { loading }] = useDeleteVendorMutation();

  const deleteVendor = async (id: string) => {
    const doSubmit = async () => {
      const { data, errors } = await vendorMutation({
        variables: {
          id,
        },
      });

      // Error
      if (errors !== undefined && errors.length > 0) {
        const errorMessage = errors[0].message;
        throw new Error(errorMessage);
      }

      // No error, no data
      if (!data) {
        throw new Error('Server returned empty unexpected response.');
      }

      return data;
    };

    await doSubmit();
  };

  return {
    deleteVendor,
    loading,
  };
};
