import React, { Suspense, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useDialogContext } from '@/app/common';
import { CommonDialog, FilterList, SectionHeading } from '@/app/components';
import { useAdvertisingLibrary } from '../../api';
import {
  AdvertisingToolsBox,
  AdvertisingToolsDialog,
  AdvertisingToolsDialogDetail,
} from '../../components';

export const ToolsBoxes: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { categories, toolsByCategory } = useAdvertisingLibrary();
  const { dialogState, openDialog, closeDialog } = useDialogContext();
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedToolId, setSelectedTool] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleSelectTool = (toolId: string) => {
    navigate({
      pathname: '/campaigns/select',
      search: createSearchParams({ tool: toolId }).toString(),
    });

    closeDialog('advertising-detail');
  };

  const handleFilterChange = (categoryId: string) => {
    setSelectedCategory(categoryId === 'all' ? null : categoryId);
  };

  const handleOpenToolDetail = (toolId: string) => {
    setSelectedTool(toolId);
    openDialog('advertising-detail');
  };

  const handleCloseToolDetail = () => {
    setSelectedTool(null);
    closeDialog('advertising-detail');
  };

  const visibleCategories = selectedCategory
    ? categories.filter(c => c.id === selectedCategory)
    : categories;

  // show alternate content when we dont have any tools
  if (visibleCategories.length === 0) {
    return children;
  }

  return (
    <>
      <FilterList
        active="all"
        items={[
          { id: 'all', name: 'All Tools' },
          ...categories,
        ]}
        onSelect={handleFilterChange}
      />

      <div className="mt-4">
        {visibleCategories.map((category) => (
          <section key={category.id} className="mb-8">
            <header className="flex items-center justify-between mb-4">
              <SectionHeading heading={category.name} />
            </header>

            <div className="mt-4 flex flex-col space-y-2">
              <div className="grid grid-cols-3 gap-4">
                {toolsByCategory[category.id].map(tool => (
                  <AdvertisingToolsBox
                    {...tool}
                    key={tool.id}
                    onOpenDetail={handleOpenToolDetail}
                    onSelect={handleSelectTool}
                  />
                ))}
              </div>
            </div>
          </section>
        ))}

        <AdvertisingToolsDialog
          onSelect={handleSelectTool}
          onClose={() => closeDialog('advertising-detail')}
          isOpened={dialogState['advertising-detail']}
          selectedToolId={selectedToolId ?? undefined}
        />

        <CommonDialog
          isOpen={dialogState['advertising-detail']}
          onClose={() => closeDialog('advertising-detail')}
          size="max"
        >
          <Suspense>
            {selectedToolId && (
              <AdvertisingToolsDialogDetail
                onSelect={handleSelectTool}
                onClose={handleCloseToolDetail}
                toolId={selectedToolId}
              />
            )}
          </Suspense>
        </CommonDialog>
      </div>
    </>
  );
};
