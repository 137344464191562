import { Button } from '@/app/components';

export interface DialogFooterProps {
  onConfirm: () => void;
  onClose: () => void;
};

export const DialogFooter: React.FC<DialogFooterProps> = ({
  onConfirm, onClose,
}) => (
  <div className="flex justify-between mt-8 gap-x-4">
    <Button
      variant="secondary"
      label="Cancel"
      onClick={onClose}
    />
    <Button
      variant="primary"
      label="Confirm"
      onClick={onConfirm}
    />
  </div>
);
