/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiRequestCreativeApprovalMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ApiRequestCreativeApprovalMutation = { __typename?: 'Mutation', requestCreativeApproval: boolean };

export type ApiRejectCreativeMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  note: Types.Scalars['String']['input'];
}>;


export type ApiRejectCreativeMutation = { __typename?: 'Mutation', rejectCreative: boolean };

export type ApiApproveCreativeMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ApiApproveCreativeMutation = { __typename?: 'Mutation', approveCreative: boolean };


export const RequestCreativeApprovalDocument = gql`
    mutation RequestCreativeApproval($id: ID!) {
  requestCreativeApproval(id: $id)
}
    `;
export type ApiRequestCreativeApprovalMutationFn = Apollo.MutationFunction<ApiRequestCreativeApprovalMutation, ApiRequestCreativeApprovalMutationVariables>;

/**
 * __useRequestCreativeApprovalMutation__
 *
 * To run a mutation, you first call `useRequestCreativeApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestCreativeApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestCreativeApprovalMutation, { data, loading, error }] = useRequestCreativeApprovalMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRequestCreativeApprovalMutation(baseOptions?: Apollo.MutationHookOptions<ApiRequestCreativeApprovalMutation, ApiRequestCreativeApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiRequestCreativeApprovalMutation, ApiRequestCreativeApprovalMutationVariables>(RequestCreativeApprovalDocument, options);
      }
export type RequestCreativeApprovalMutationHookResult = ReturnType<typeof useRequestCreativeApprovalMutation>;
export type RequestCreativeApprovalMutationResult = Apollo.MutationResult<ApiRequestCreativeApprovalMutation>;
export type RequestCreativeApprovalMutationOptions = Apollo.BaseMutationOptions<ApiRequestCreativeApprovalMutation, ApiRequestCreativeApprovalMutationVariables>;
export const RejectCreativeDocument = gql`
    mutation RejectCreative($id: ID!, $note: String!) {
  rejectCreative(id: $id, note: $note)
}
    `;
export type ApiRejectCreativeMutationFn = Apollo.MutationFunction<ApiRejectCreativeMutation, ApiRejectCreativeMutationVariables>;

/**
 * __useRejectCreativeMutation__
 *
 * To run a mutation, you first call `useRejectCreativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectCreativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectCreativeMutation, { data, loading, error }] = useRejectCreativeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useRejectCreativeMutation(baseOptions?: Apollo.MutationHookOptions<ApiRejectCreativeMutation, ApiRejectCreativeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiRejectCreativeMutation, ApiRejectCreativeMutationVariables>(RejectCreativeDocument, options);
      }
export type RejectCreativeMutationHookResult = ReturnType<typeof useRejectCreativeMutation>;
export type RejectCreativeMutationResult = Apollo.MutationResult<ApiRejectCreativeMutation>;
export type RejectCreativeMutationOptions = Apollo.BaseMutationOptions<ApiRejectCreativeMutation, ApiRejectCreativeMutationVariables>;
export const ApproveCreativeDocument = gql`
    mutation ApproveCreative($id: ID!) {
  approveCreative(id: $id)
}
    `;
export type ApiApproveCreativeMutationFn = Apollo.MutationFunction<ApiApproveCreativeMutation, ApiApproveCreativeMutationVariables>;

/**
 * __useApproveCreativeMutation__
 *
 * To run a mutation, you first call `useApproveCreativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveCreativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveCreativeMutation, { data, loading, error }] = useApproveCreativeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApproveCreativeMutation(baseOptions?: Apollo.MutationHookOptions<ApiApproveCreativeMutation, ApiApproveCreativeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiApproveCreativeMutation, ApiApproveCreativeMutationVariables>(ApproveCreativeDocument, options);
      }
export type ApproveCreativeMutationHookResult = ReturnType<typeof useApproveCreativeMutation>;
export type ApproveCreativeMutationResult = Apollo.MutationResult<ApiApproveCreativeMutation>;
export type ApproveCreativeMutationOptions = Apollo.BaseMutationOptions<ApiApproveCreativeMutation, ApiApproveCreativeMutationVariables>;