/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiProductDestinationValueFragment = { __typename?: 'CreativeProductDestinationValue', id: string, catalog: string, externalId: string, title: string, url: string, available: boolean };

export type ApiSearchProductDestinationsQueryVariables = Types.Exact<{
  productLimit: Types.InputMaybe<Types.Scalars['Int']['input']>;
  term: Types.Scalars['String']['input'];
}>;


export type ApiSearchProductDestinationsQuery = { __typename?: 'Query', creativeDestinations: { __typename?: 'DestinationResult', time: string, products: { __typename?: 'CreativeProductDestination', type: Types.ApiCreativeDestinationTypes, values: Array<{ __typename?: 'CreativeProductDestinationValue', id: string, catalog: string, externalId: string, title: string, url: string, available: boolean }> } } };

export const ProductDestinationValueFragmentDoc = gql`
    fragment ProductDestinationValue on CreativeProductDestinationValue {
  id
  catalog
  externalId
  title
  url
  available
}
    `;
export const SearchProductDestinationsDocument = gql`
    query SearchProductDestinations($productLimit: Int, $term: String!) {
  creativeDestinations {
    time
    products(limit: $productLimit, term: $term) {
      type
      values {
        ...ProductDestinationValue
      }
    }
  }
}
    ${ProductDestinationValueFragmentDoc}`;

/**
 * __useSearchProductDestinationsQuery__
 *
 * To run a query within a React component, call `useSearchProductDestinationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProductDestinationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProductDestinationsQuery({
 *   variables: {
 *      productLimit: // value for 'productLimit'
 *      term: // value for 'term'
 *   },
 * });
 */
export function useSearchProductDestinationsQuery(baseOptions: Apollo.QueryHookOptions<ApiSearchProductDestinationsQuery, ApiSearchProductDestinationsQueryVariables> & ({ variables: ApiSearchProductDestinationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiSearchProductDestinationsQuery, ApiSearchProductDestinationsQueryVariables>(SearchProductDestinationsDocument, options);
      }
export function useSearchProductDestinationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiSearchProductDestinationsQuery, ApiSearchProductDestinationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiSearchProductDestinationsQuery, ApiSearchProductDestinationsQueryVariables>(SearchProductDestinationsDocument, options);
        }
export function useSearchProductDestinationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApiSearchProductDestinationsQuery, ApiSearchProductDestinationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiSearchProductDestinationsQuery, ApiSearchProductDestinationsQueryVariables>(SearchProductDestinationsDocument, options);
        }
export type SearchProductDestinationsQueryHookResult = ReturnType<typeof useSearchProductDestinationsQuery>;
export type SearchProductDestinationsLazyQueryHookResult = ReturnType<typeof useSearchProductDestinationsLazyQuery>;
export type SearchProductDestinationsSuspenseQueryHookResult = ReturnType<typeof useSearchProductDestinationsSuspenseQuery>;
export type SearchProductDestinationsQueryResult = Apollo.QueryResult<ApiSearchProductDestinationsQuery, ApiSearchProductDestinationsQueryVariables>;