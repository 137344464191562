import React, { InputHTMLAttributes, KeyboardEvent, forwardRef } from 'react';
import { ChangeHandler, Control, Path } from 'react-hook-form';
import { CreativeFieldType, useUploadFile } from '@/app/common';
import { FieldError, FieldWrapper, ImageField, InputField } from '@/app/components';
import { CreativeFormRawData } from './activity-form.interface';

interface CreativeEditorFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  type: CreativeFieldType;
  control: Control<{ creative: CreativeFormRawData }>;
  name: Path<{ creative: CreativeFormRawData }>;
  label: string;
  id: string;
  value?: string;
  required: boolean;
  error?: FieldError;
  onChange: ChangeHandler;
  onBlur: ChangeHandler;
  onEnter?: (event: KeyboardEvent<HTMLInputElement>) => void;
};

export const CreativeEditorField = forwardRef<HTMLInputElement, CreativeEditorFieldProps>(({
  type,
  onChange,
  control,
  ...inputProps
}, ref) => {
  const { uploadFile } = useUploadFile();
  const handleMediaSelect = async (file: File) => {
    return await uploadFile('creative', file);
  };

  // internal fields
  if (type === 'product' || type === 'sku') {
    return null;
  }

  // image field
  if (type === 'image') {
    return (
      <ImageField
        control={control}
        label={inputProps.label}
        id={inputProps.id}
        name={inputProps.name}
        onSelect={handleMediaSelect}
      />
    );
  }

  // text on number field
  return (
    <FieldWrapper>
      <InputField
        {...inputProps}
        onChange={onChange}
        ref={ref}
      />
    </FieldWrapper>
  );
});
