import { useUpdateCampaignMutation } from './update-campaign.query.generated';
import { UpdateCampaignFormData } from '../../interfaces';

type UseUpdateCampaignHook = {
  updateCampaign: (id: string, formData: UpdateCampaignFormData) => Promise<{ id: string }>;
  loading: boolean;
};

export const useUpdateCampaign = (): UseUpdateCampaignHook => {
  const [campaignMutation, { loading }] = useUpdateCampaignMutation();

  const updateCampaign = async (id: string, formData: UpdateCampaignFormData) => {
    const doSubmit = async () => {
      const { data, errors } = await campaignMutation({
        variables: {
          ...formData,
          id,
        },
      });

      // Error
      if (errors !== undefined && errors.length > 0) {
        const errorMessage = errors[0].message;
        throw new Error(errorMessage);
      }

      // No error, no data
      if (!data) {
        throw new Error('Server returned empty unexpected response.');
      }

      return data;
    };

    const result = await doSubmit();
    return {
      id: result.updateCampaign.id,
    };
  };

  return {
    updateCampaign,
    loading,
  };
};
