import React, { forwardRef } from 'react';
import { ChangeHandler } from 'react-hook-form';

export type RadioButtonSizeType = (
  | 'lg'
  | 'md'
  | 'sm'
);

export interface RadioButtonProps {
  checked?: boolean;
  name: string;
  id: string;
  label?: string;
  disabled?: boolean;
  size?: RadioButtonSizeType;
  value?: string;
  onChange?: ChangeHandler;
  onBlur?: ChangeHandler;
};

export const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(({
  label,
  checked = false,
  id,
  name,
  disabled = false,
  size = 'md',
  value,
  onChange,
  onBlur,
  ...rest
}, ref) => {
  return (
    <div className={`nice-radio ${size}`}>
      <input
        {...rest}
        type="radio"
        id={id}
        name={name}
        defaultChecked={checked}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        ref={ref}
      />

      <span className={`like-radio ${size}`} />

      {label !== undefined &&
        <label htmlFor={id} className="ml-2 text-sm">{label}</label>
      }
    </div>
  );
});
