import React from 'react';
import {
  Badge,
  Button,
  InfroStrip,
  PencilIcon,
  RoundedFrame,
  SectionHeading,
} from '@/app/components';
import { useVendorDetail } from '../../api';
import { UsersList } from '../users-list';

interface VendorDetailProps {
  vendorId: string;
};

export const VendorDetail: React.FC<VendorDetailProps> = ({
  vendorId,
}) => {
  const {
    vendor,
    vendorBrandsTotal,
    vendorBrands,
    vendorUsers,
  } = useVendorDetail(vendorId);

  return (
    <>
      <section className="flex justify-between mb-5">
        <header>
          <h1 className="text-3xl font-medium mb-4 flex items-center gap-4">
            {vendor.name}
          </h1>

          <div className="text-sm leading-6">
            <p>{vendor.businessName}</p>
          </div>
        </header>

        <div className="flex space-x-4 items-start">
          <Button
            link="edit"
            label="Edit Advertiser"
            variant="primary"
            icon={PencilIcon}
          />
        </div>
      </section>

      <RoundedFrame margin="mb-5">
        <dl className="flex flex-col gap-y-1 text-sm leading-6 w-1/3 pr-3">
          <div className="flex space-x-2">
            <dt>Company ID:</dt>
            <dd className="font-medium">{vendor.businessId}</dd>
          </div>

          <div className="flex space-x-2">
            <dt>VAT:</dt>
            <dd className="font-medium">{vendor.vatNumber ?? 'none'}</dd>
          </div>
        </dl>

        <dl className="flex flex-col gap-y-1 text-sm leading-6 w-1/3 pr-3">
          <div className="flex space-x-2">
            <dt>Default Sales Person:</dt>
            <dd className="font-medium">{vendor.defaultSalesPerson?.name ?? 'none'}</dd>
          </div>

          <div className="flex space-x-2">
            <dt>Default Trafficker:</dt>
            <dd className="font-medium">{vendor.defaultTrafficker?.name ?? 'none'}</dd>
          </div>
        </dl>
      </RoundedFrame>

      <section className="mb-5">
        <SectionHeading heading="Brands" />
        <div className="flex flex-wrap gap-x-1 gap-y-1.5 mt-4">
          {vendorBrandsTotal > 0 ? (
            vendorBrands.map((brand) => (
              <Badge
                key={brand.id}
                label={brand.name}
                color="black"
              />
            ))
          ) : (
            <div>
              <InfroStrip label="Advertiser does not have any brands assigned yet." type="info" />
            </div>
          )}
        </div>
      </section>

      <UsersList
        headline="Users"
        users={vendorUsers}
      >
        <InfroStrip label="Advertiser does not have any users yet." type="info" />
      </UsersList>
    </>
  );
};
