import { StatusLabel } from '@/app/components';
import { ActivityCreativeStatus } from '../../interfaces';

interface CreativeStatusProps {
  status: ActivityCreativeStatus;
};

export const CreativeStatus: React.FC<CreativeStatusProps> = ({
  status,
}) => {
  const getStatusColor = (status: ActivityCreativeStatus) => {
    switch (status) {
      case 'approved':
        return 'green';
      case 'rejected':
        return 'red';

      default:
        return 'yellow';
    }
  };

  const getStatusLabel = (status: ActivityCreativeStatus) => {
    switch (status) {
      case 'approved':
        return 'Approved';
      case 'pendingApproval':
        return 'Waiting for approval';
      case 'rejected':
        return 'Rejected';

      case 'draft':
      default:
        return 'Draft';
    }
  };

  return (
    <StatusLabel
      color={getStatusColor(status)}
      label={getStatusLabel(status)}
      hasBorder={false}
    />
  );
};
