import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ActivitiesListPage } from './activities-list.page';
import { PopupNoteProvider } from './components';
import { ActivityFiltersProvider } from './hooks';

export const Activities: React.FC = () => (
  <PopupNoteProvider>
    <ActivityFiltersProvider>
      <Routes>
        <Route index element={<ActivitiesListPage />} />
      </Routes>
    </ActivityFiltersProvider>
  </PopupNoteProvider>
);

export default Activities;
