import classNames from 'classnames';
import React from 'react';

interface PageContentBoxProps {
  children: React.ReactNode;
  maxWidth?: 'xl' | '2xl';
  fullHeight?: boolean;
};

export const PageContentBox: React.FC<PageContentBoxProps> = ({
  children,
  maxWidth,
  fullHeight = false,
}) => (
  <div className={classNames(
    {
      'flex flex-col w-full': true,
      'pb-24': !fullHeight,
      'h-full': fullHeight,
    },
    maxWidth !== undefined ? `max-w-${maxWidth} mx-auto` : '',
  )}>
    {children}
  </div>
);
