/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiCreateVendorUserMutationVariables = Types.Exact<{
  vendorId: Types.Scalars['ID']['input'];
  username: Types.Scalars['String']['input'];
  name: Types.Scalars['String']['input'];
  locale: Types.InputMaybe<Types.ApiLocale>;
  timezone: Types.InputMaybe<Types.ApiTimezone>;
  markets: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type ApiCreateVendorUserMutation = { __typename?: 'Mutation', createVendorUser: { __typename?: 'User', id: string } };


export const CreateVendorUserDocument = gql`
    mutation CreateVendorUser($vendorId: ID!, $username: String!, $name: String!, $locale: Locale, $timezone: Timezone, $markets: [String!]!) {
  createVendorUser(
    vendorId: $vendorId
    username: $username
    name: $name
    locale: $locale
    timezone: $timezone
    markets: $markets
  ) {
    id
  }
}
    `;
export type ApiCreateVendorUserMutationFn = Apollo.MutationFunction<ApiCreateVendorUserMutation, ApiCreateVendorUserMutationVariables>;

/**
 * __useCreateVendorUserMutation__
 *
 * To run a mutation, you first call `useCreateVendorUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVendorUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVendorUserMutation, { data, loading, error }] = useCreateVendorUserMutation({
 *   variables: {
 *      vendorId: // value for 'vendorId'
 *      username: // value for 'username'
 *      name: // value for 'name'
 *      locale: // value for 'locale'
 *      timezone: // value for 'timezone'
 *      markets: // value for 'markets'
 *   },
 * });
 */
export function useCreateVendorUserMutation(baseOptions?: Apollo.MutationHookOptions<ApiCreateVendorUserMutation, ApiCreateVendorUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiCreateVendorUserMutation, ApiCreateVendorUserMutationVariables>(CreateVendorUserDocument, options);
      }
export type CreateVendorUserMutationHookResult = ReturnType<typeof useCreateVendorUserMutation>;
export type CreateVendorUserMutationResult = Apollo.MutationResult<ApiCreateVendorUserMutation>;
export type CreateVendorUserMutationOptions = Apollo.BaseMutationOptions<ApiCreateVendorUserMutation, ApiCreateVendorUserMutationVariables>;