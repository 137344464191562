import React from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
  children: React.ReactElement;
  id: string;
};

export const Portal: React.FC<PortalProps> = ({
  children,
  id,
}) => {
  const el = id && document.getElementById(id) || document.body;
  return createPortal(children, el);
};
