import { Cell, RowData, flexRender } from '@tanstack/react-table';

type TableBodyColumnProps<T extends RowData> = (
  & Cell<T, unknown>
  & {
    index: number;
    children?: React.ReactNode;
    as?: React.ElementType;
  }
);

export const TableBodyColumn = <T,>({
  index,
  children,
  as: Element = 'td',
  ...cell
}: TableBodyColumnProps<T>) => {
  const row = cell.getContext().row;
  const childrenContent = children ?? flexRender(cell.column.columnDef.cell, cell.getContext());
  return (
    <Element
      key={cell.id}
      style={{ width: cell.column.getSize() }}
      className={`
        px-4
        py-3
        h-16
        bg-white
        ${index === 0 ? 'rounded-l-sm' : ''}
        ${index === row.getVisibleCells().length - 1 ? 'rounded-r-sm' : ''}
        group-hover:bg-purple-50
      `}
    >
      {childrenContent}
    </Element>
  );
};
