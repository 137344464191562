import React, { useCallback, useState } from 'react';
import { PaneItem, PaneSwitcherContext, PaneSwitcherContextType } from './pane-context';

interface PaneSwitcherProps {
  children: React.ReactNode;
  onSwitch?: (id: string) => void;
};

export const PaneSwitcher: React.FC<PaneSwitcherProps> = ({
  children,
  onSwitch,
}) => {
  const [activePane, setActivePane] = useState<string | null>(null);
  const [panes, setPanes] = useState<PaneSwitcherContextType['panes']>({});

  const switchPane = useCallback((id: string) => {
    setActivePane(id);
    onSwitch?.(id);
  }, []);

  const register = useCallback((pane: PaneItem, active: boolean = false) => {
    active && setActivePane(pane.id);
    setPanes(panes => ({
      ...panes,
      [pane.id]: pane,
    }));
  }, []);

  const unregister = useCallback((pane: PaneItem) => {
    setPanes(panes => {
      const updatedPanes = {...panes};
      delete updatedPanes[pane.id];

      if (activePane === pane.id) {
        setActivePane(null);
      }

      return updatedPanes;
    });
  }, []);

  return (
    <PaneSwitcherContext.Provider value={{
      panes,
      activePane,
      switchPane,
      register,
      unregister,
    }}>
      {children}
    </PaneSwitcherContext.Provider>
  );
};
