import { useAdvertisingTool } from '../../api';
import { SelectedAdvertisingTool } from '../../interfaces';

interface SelectedToolLoaderProps {
  toolId: string;
  children: (tool: SelectedAdvertisingTool) => React.ReactNode;
};

export const SelectedToolLoader = ({
  toolId,
  children,
}: SelectedToolLoaderProps) => {
  const { advertisingTool } = useAdvertisingTool(toolId);
  return children(advertisingTool);
};
