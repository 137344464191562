import React from 'react';
import { Button } from '../button';
import { NoRecordsIcon, PlusIcon } from '../icons';

export interface EmptySlateProps {
  title: React.ReactNode,
  icon?: React.ReactNode | null,
  link?: string;
  linkLabel?: string;
  children: React.ReactNode,
};

export const EmptySlate: React.FC<EmptySlateProps> = ({
  children,
  title,
  icon = NoRecordsIcon,
  link,
  linkLabel,
}) => (
  <div className="bg-white rounded-sm p-8 h-full">
    <div className="flex flex-col items-center justify-center w-full h-full">
      {icon !== null && <div className="mb-6">{icon}</div>}
      <h2 className="font-semibold mb-2">{title}</h2>

      <div className="text-sm max-w-xl text-center mb-6">
        {children}
      </div>

      {linkLabel && (
        <div>
          <Button
            variant="primary"
            label={linkLabel}
            link={link || 'create'}
            icon={PlusIcon}
          />
        </div>
      )}
    </div>
  </div>
);
