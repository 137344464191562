export const appRoutes = {
  CAMPAIGNS: '/campaigns',
  ACTIVITIES: '/line-items',
  DASHBOARDS: '/dashboards',
  MY_ORGANIZATION: '/admin/my-organization',
  MARKETS: '/admin/markets',
  ADVERTISING_CATEGORIES: '/admin/advertising-tools/categories',
  ADVERTISING_TOOLS: '/admin/advertising-tools',
  CREATIVE_FORMATS: '/admin/creative-formats',
  TARGETS: '/admin/targets',
  BRANDS: '/admin/brands',
  VENDORS: '/admin/companies/advertisers',
  AGENCIES: '/admin/companies/agencies',
  USERS: '/admin/users',
  ROOT: '/',
} as const;
