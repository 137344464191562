import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RateType, useAppMeta } from '@/app/common';
import { FieldWrapper, InputField } from '@/app/components';
import { ActivityFormRawData } from './activity-form.interface';
import { SelectedAdvertisingTool } from '../../interfaces';

interface ActivityDetailsQuotesProps {
  selectedTool: SelectedAdvertisingTool;
};

export const ActivityDetailsQuotes: React.FC<ActivityDetailsQuotesProps> = ({
  selectedTool,
}) => {
  const { register, formState: { errors } } = useFormContext<ActivityFormRawData>();
  const { i18n } = useTranslation();
  const { currency } = useAppMeta();
  const { rateType, unitPrice, auction } = selectedTool;
  const priceFormatter = new Intl.NumberFormat(i18n.language, { style: 'currency', currency });

  // not applicable for flat rates
  if (rateType === 'flatRate') {
    return null;
  }

  return (
    <FieldWrapper>
      <div className="flex items-start gap-x-4">
        <div className="grow shrink basis-0">
          <Unit rateType={rateType}>
            {({ label, units }) => (
              <InputField
                type="number"
                {...register('orderedAmount', {
                  required: 'Minimal 1 unit required.',
                  min: {
                    value: 1,
                    message: 'Minimal 1 unit required.',
                  },
                })}
                label={label}
                postfix={units}
                min={1}
                error={errors.orderedAmount}
                required
              />
            )}
          </Unit>
        </div>

        <div className="grow shrink basis-0">
          <InputField
            {...register('customPrice', {
              required: auction ? 'This field is required and cannot be empty' : false,
              min: {
                value: unitPrice,
                message: `Minimal ${unitPrice} required.`,
              },
            })}
            type="number"
            label={auction ? `Auction price ${rateType.toUpperCase()}` : `Price ${rateType.toUpperCase()}`}
            postfix="Kč"
            placeholder="Type a price"
            value={unitPrice.toString()}
            min={unitPrice}
            required={!auction}
            error={errors.customPrice}
          />

          {!errors.customPrice && (
            <span className="text-xs">Min. {priceFormatter.format(unitPrice)}</span>
          )}
        </div>
      </div>
    </FieldWrapper>
  );
};

interface UnitProps {
  rateType: RateType;
  children: (units: {
    label: string;
    units: string;
  }) => React.ReactNode;
};

const Unit: React.FC<UnitProps> = ({
  rateType,
  children,
}) => {
  const getDescriptor = (rateType: RateType) => {
    switch (rateType) {
      case 'cpc':
        return {
          label: 'Number of clicks',
          units: 'clicks',
        };
      case 'cpm':
        return {
          label: 'Number of impressions',
          units: 'impressions',
        };

      default:
        throw new Error(`Unable to display activity details quotes for unknown rate type ${rateType}`);
    }
  };

  return children(getDescriptor(rateType));
};
