export const DashboardIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M3.75 3V14.25C3.75 14.8467 3.98705 15.419 4.40901 15.841C4.83097 16.2629 5.40326 16.5 6 16.5H8.25M3.75 3H2.25M3.75 3H20.25M8.25 16.5H15.75M8.25 16.5L7.25 19.5M20.25 3H21.75M20.25 3V14.25C20.25 14.8467 20.0129 15.419 19.591 15.841C19.169 16.2629 18.5967 16.5 18 16.5H15.75M15.75 16.5L16.75 19.5M7.25 19.5H16.75M7.25 19.5L6.75 21M16.75 19.5L17.25 21M9 11.25V12.75M12 9V12.75M15 6.75V12.75" stroke="#1E293B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const AdToolsIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6H9.375C9.996 6 10.5 6.504 10.5 7.125V10.875C10.5 11.496 9.996 12 9.375 12H3.375C3.07663 12 2.79048 11.8815 2.5795 11.6705C2.36853 11.4595 2.25 11.1734 2.25 10.875V7.125ZM14.25 8.625C14.25 8.004 14.754 7.5 15.375 7.5H20.625C21.246 7.5 21.75 8.004 21.75 8.625V16.875C21.75 17.496 21.246 18 20.625 18H15.375C15.0766 18 14.7905 17.8815 14.5795 17.6705C14.3685 17.4595 14.25 17.1734 14.25 16.875V8.625ZM3.75 16.125C3.75 15.504 4.254 15 4.875 15H10.125C10.746 15 11.25 15.504 11.25 16.125V18.375C11.25 18.996 10.746 19.5 10.125 19.5H4.875C4.57663 19.5 4.29048 19.3815 4.0795 19.1705C3.86853 18.9595 3.75 18.6734 3.75 18.375V16.125Z" stroke="#1E293B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const CampaignsIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M15.59 14.37C15.7982 15.2511 15.8044 16.1678 15.608 17.0516C15.4116 17.9354 15.0177 18.7633 14.4559 19.4732C13.8941 20.1832 13.1789 20.7568 12.364 21.1511C11.549 21.5454 10.6553 21.7501 9.75 21.75V16.95M15.59 14.37C17.4999 12.9788 19.0536 11.1555 20.1242 9.04908C21.1947 6.94267 21.7519 4.61287 21.75 2.25C19.3873 2.2483 17.0577 2.80549 14.9515 3.87607C12.8452 4.94665 11.0221 6.50026 9.631 8.41M15.59 14.37C13.8519 15.6413 11.8603 16.5214 9.75 16.95M9.75 16.95C9.647 16.971 9.543 16.991 9.439 17.01C8.53179 16.2906 7.71038 15.4692 6.991 14.562C7.00989 14.4578 7.02889 14.3538 7.05 14.25M9.631 8.41C8.74986 8.2016 7.83298 8.19532 6.94907 8.39164C6.06516 8.58797 5.23714 8.98179 4.52707 9.54362C3.817 10.1054 3.2433 10.8207 2.84895 11.6357C2.4546 12.4508 2.24984 13.3446 2.25 14.25H7.05M9.631 8.41C8.35988 10.1478 7.47875 12.14 7.05 14.25M4.811 16.64C4.15318 17.1292 3.64173 17.789 3.33202 18.548C3.02232 19.307 2.92618 20.1363 3.054 20.946C3.86383 21.0737 4.69316 20.9774 5.45218 20.6675C6.21119 20.3577 6.87095 19.846 7.36 19.188M16.5 9C16.5 9.39783 16.342 9.77936 16.0607 10.0607C15.7794 10.342 15.3978 10.5 15 10.5C14.6022 10.5 14.2206 10.342 13.9393 10.0607C13.658 9.77936 13.5 9.39783 13.5 9C13.5 8.60218 13.658 8.22065 13.9393 7.93934C14.2206 7.65804 14.6022 7.5 15 7.5C15.3978 7.5 15.7794 7.65804 16.0607 7.93934C16.342 8.22065 16.5 8.60218 16.5 9Z" stroke="#1E293B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const LineItemsIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M8.25 6.75H20.25M8.25 12H20.25M8.25 17.25H20.25M3.75 6.75H3.757V6.758H3.75V6.75ZM4.125 6.75C4.125 6.84946 4.08549 6.94484 4.01516 7.01516C3.94484 7.08549 3.84946 7.125 3.75 7.125C3.65054 7.125 3.55516 7.08549 3.48483 7.01516C3.41451 6.94484 3.375 6.84946 3.375 6.75C3.375 6.65054 3.41451 6.55516 3.48483 6.48484C3.55516 6.41451 3.65054 6.375 3.75 6.375C3.84946 6.375 3.94484 6.41451 4.01516 6.48484C4.08549 6.55516 4.125 6.65054 4.125 6.75ZM3.75 12H3.757V12.008H3.75V12ZM4.125 12C4.125 12.0995 4.08549 12.1948 4.01516 12.2652C3.94484 12.3355 3.84946 12.375 3.75 12.375C3.65054 12.375 3.55516 12.3355 3.48483 12.2652C3.41451 12.1948 3.375 12.0995 3.375 12C3.375 11.9005 3.41451 11.8052 3.48483 11.7348C3.55516 11.6645 3.65054 11.625 3.75 11.625C3.84946 11.625 3.94484 11.6645 4.01516 11.7348C4.08549 11.8052 4.125 11.9005 4.125 12ZM3.75 17.25H3.757V17.258H3.75V17.25ZM4.125 17.25C4.125 17.3495 4.08549 17.4448 4.01516 17.5152C3.94484 17.5855 3.84946 17.625 3.75 17.625C3.65054 17.625 3.55516 17.5855 3.48483 17.5152C3.41451 17.4448 3.375 17.3495 3.375 17.25C3.375 17.1505 3.41451 17.0552 3.48483 16.9848C3.55516 16.9145 3.65054 16.875 3.75 16.875C3.84946 16.875 3.94484 16.9145 4.01516 16.9848C4.08549 17.0552 4.125 17.1505 4.125 17.25Z" stroke="#1E293B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const CreativesIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M12 7.5H13.5M12 10.5H13.5M6 13.5H13.5M6 16.5H13.5M16.5 7.5H19.875C20.496 7.5 21 8.004 21 8.625V18C21 18.5967 20.7629 19.169 20.341 19.591C19.919 20.0129 19.3467 20.25 18.75 20.25M16.5 7.5V18C16.5 18.5967 16.7371 19.169 17.159 19.591C17.581 20.0129 18.1533 20.25 18.75 20.25M16.5 7.5V4.875C16.5 4.254 15.996 3.75 15.375 3.75H4.125C3.504 3.75 3 4.254 3 4.875V18C3 18.5967 3.23705 19.169 3.65901 19.591C4.08097 20.0129 4.65326 20.25 5.25 20.25H18.75M6 7.5H9V10.5H6V7.5Z" stroke="#1E293B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const HelpIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M9.879 7.519C11.05 6.494 12.95 6.494 14.121 7.519C15.293 8.544 15.293 10.206 14.121 11.231C13.918 11.41 13.691 11.557 13.451 11.673C12.706 12.034 12.001 12.672 12.001 13.5V14.25M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12ZM12 17.25H12.008V17.258H12V17.25Z" stroke="#1E293B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const AdminIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M12 2.71411C9.73574 4.86435 6.72026 6.04369 3.598 6.00011C3.20084 7.21009 2.99898 8.47562 3 9.74911C3 15.3411 6.824 20.0391 12 21.3721C17.176 20.0401 21 15.3421 21 9.75011C21 8.44011 20.79 7.17911 20.402 5.99911H20.25C17.054 5.99911 14.15 4.75111 12 2.71411Z" stroke="#1E293B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.0003 8.79958C14.0003 9.33009 13.7895 9.83886 13.4144 10.214C13.0393 10.5891 12.5305 10.7998 12 10.7998C11.4695 10.7998 10.9607 10.5891 10.5856 10.214C10.2105 9.83886 9.99973 9.33009 9.99973 8.79958C9.99973 8.26908 10.2105 7.7603 10.5856 7.38518C10.9607 7.01006 11.4695 6.79932 12 6.79932C12.5305 6.79932 13.0393 7.01006 13.4144 7.38518C13.7895 7.7603 14.0003 8.26908 14.0003 8.79958ZM8 16.3302C8.01714 15.2806 8.44611 14.2799 9.19439 13.5437C9.94267 12.8075 10.9503 12.395 12 12.395C13.0497 12.395 14.0573 12.8075 14.8056 13.5437C15.5539 14.2799 15.9829 15.2806 16 16.3302C14.7451 16.9056 13.3805 17.2026 12 17.2007C10.5726 17.2007 9.21776 16.8892 8 16.3302Z" stroke="#1E293B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const OrganizationsIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M2.25 21H21.75M3.75 3V21M14.25 3V21M20.25 7.5V21M6.75 6.75H7.5M6.75 9.75H7.5M6.75 12.75H7.5M10.5 6.75H11.25M10.5 9.75H11.25M10.5 12.75H11.25M6.75 21V17.625C6.75 17.004 7.254 16.5 7.875 16.5H10.125C10.746 16.5 11.25 17.004 11.25 17.625V21M3 3H15M14.25 7.5H21M17.25 11.25H17.258V11.258H17.25V11.25ZM17.25 14.25H17.258V14.258H17.25V14.25ZM17.25 17.25H17.258V17.258H17.25V17.25Z" stroke="#1E293B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const MarketsIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M13.5001 21V13.5C13.5001 13.3011 13.5791 13.1103 13.7198 12.9697C13.8604 12.829 14.0512 12.75 14.2501 12.75H17.2501C17.449 12.75 17.6398 12.829 17.7804 12.9697C17.9211 13.1103 18.0001 13.3011 18.0001 13.5V21M13.5001 21H2.36011M13.5001 21H18.0001M18.0001 21H21.6401M20.2501 21V9.349M20.2501 9.349C19.6502 9.6951 18.949 9.82304 18.2656 9.71113C17.5821 9.59922 16.9583 9.25435 16.5001 8.735C15.9501 9.357 15.1461 9.75 14.2501 9.75C13.8244 9.75041 13.4035 9.66 13.0155 9.48479C12.6275 9.30959 12.2813 9.05363 12.0001 8.734C11.4501 9.357 10.6461 9.75 9.75011 9.75C9.32439 9.75041 8.90348 9.66 8.51548 9.48479C8.12748 9.30959 7.78132 9.05363 7.50011 8.734C7.04203 9.25351 6.41835 9.59858 5.73486 9.71067C5.05136 9.82276 4.35013 9.69498 3.75011 9.349M20.2501 9.349C20.6487 9.11891 20.989 8.80019 21.2447 8.41751C21.5004 8.03484 21.6646 7.59846 21.7247 7.14215C21.7847 6.68584 21.7389 6.22184 21.5909 5.78605C21.4429 5.35026 21.1966 4.95436 20.8711 4.629L19.6811 3.44C19.4001 3.15862 19.0188 3.00035 18.6211 3H5.37811C4.98057 3.00008 4.59931 3.15798 4.31811 3.439L3.12911 4.629C2.80435 4.95474 2.55873 5.3507 2.41118 5.78637C2.26364 6.22204 2.21811 6.68577 2.27811 7.14182C2.33811 7.59786 2.50204 8.03403 2.75725 8.41671C3.01246 8.79939 3.35215 9.11835 3.75011 9.349M3.75011 21V9.349M6.75011 18H10.5001C10.699 18 10.8898 17.921 11.0304 17.7803C11.1711 17.6397 11.2501 17.4489 11.2501 17.25V13.5C11.2501 13.3011 11.1711 13.1103 11.0304 12.9697C10.8898 12.829 10.699 12.75 10.5001 12.75H6.75011C6.5512 12.75 6.36043 12.829 6.21978 12.9697C6.07913 13.1103 6.00011 13.3011 6.00011 13.5V17.25C6.00011 17.664 6.33611 18 6.75011 18Z" stroke="#1E293B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const TargetingIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M17.5 12C17.5 12.7223 17.3577 13.4375 17.0813 14.1048C16.8049 14.772 16.3998 15.3784 15.8891 15.8891C15.3784 16.3998 14.772 16.8049 14.1048 17.0813C13.4375 17.3577 12.7223 17.5 12 17.5C11.2777 17.5 10.5625 17.3577 9.89524 17.0813C9.22795 16.8049 8.62163 16.3998 8.11091 15.8891C7.60019 15.3784 7.19506 14.772 6.91866 14.1048C6.64226 13.4375 6.5 12.7223 6.5 12C6.5 10.5413 7.07946 9.14236 8.11091 8.11091C9.14236 7.07946 10.5413 6.5 12 6.5C13.4587 6.5 14.8576 7.07946 15.8891 8.11091C16.9205 9.14236 17.5 10.5413 17.5 12Z" stroke="#1E293B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14 12C14 12.2626 13.9483 12.5227 13.8478 12.7654C13.7472 13.008 13.5999 13.2285 13.4142 13.4142C13.2285 13.5999 13.008 13.7472 12.7654 13.8478C12.5227 13.9483 12.2626 14 12 14C11.7374 14 11.4773 13.9483 11.2346 13.8478C10.992 13.7472 10.7715 13.5999 10.5858 13.4142C10.4001 13.2285 10.2528 13.008 10.1522 12.7654C10.0517 12.5227 10 12.2626 10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12Z" stroke="#1E293B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z" stroke="#1E293B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const BrandsIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M9.568 3H5.25C4.65326 3 4.08097 3.23705 3.65901 3.65901C3.23705 4.08097 3 4.65326 3 5.25V9.568C3 10.165 3.237 10.738 3.659 11.159L13.24 20.74C13.939 21.439 15.02 21.612 15.847 21.07C17.9286 19.7066 19.7066 17.9286 21.07 15.847C21.612 15.02 21.439 13.939 20.74 13.24L11.16 3.66C10.951 3.45077 10.7029 3.28478 10.4297 3.17154C10.1565 3.05829 9.86371 3 9.568 3Z" stroke="#1E293B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6 6H6.008V6.008H6V6Z" stroke="#1E293B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const CompaniesIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M20.2501 14.15V18.4C20.2501 19.494 19.4631 20.436 18.3781 20.58C16.2911 20.857 14.1621 21 12.0001 21C9.83806 21 7.70906 20.857 5.62206 20.58C4.53706 20.436 3.75006 19.494 3.75006 18.4V14.15M20.2501 14.15C20.4875 13.9436 20.6775 13.6883 20.807 13.4016C20.9364 13.1149 21.0023 12.8036 21.0001 12.489V8.706C21.0001 7.625 20.2321 6.691 19.1631 6.531C18.0304 6.36142 16.892 6.23234 15.7501 6.144M20.2501 14.15C20.0561 14.315 19.8301 14.445 19.5771 14.53C17.1333 15.3408 14.5748 15.7528 12.0001 15.75C9.35206 15.75 6.80506 15.321 4.42306 14.53C4.17631 14.4479 3.94778 14.3189 3.75006 14.15M3.75006 14.15C3.5126 13.9436 3.32262 13.6883 3.19315 13.4016C3.06369 13.1149 2.99782 12.8036 3.00006 12.489V8.706C3.00006 7.625 3.76806 6.691 4.83706 6.531C5.96975 6.36142 7.10814 6.23233 8.25006 6.144M15.7501 6.144V5.25C15.7501 4.65326 15.513 4.08097 15.091 3.65901C14.6691 3.23705 14.0968 3 13.5001 3H10.5001C9.90332 3 9.33102 3.23705 8.90907 3.65901C8.48711 4.08097 8.25006 4.65326 8.25006 5.25V6.144M15.7501 6.144C13.2538 5.95108 10.7463 5.95108 8.25006 6.144M12.0001 12.75H12.0081V12.758H12.0001V12.75Z" stroke="#1E293B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const UsersIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M15.75 6C15.75 6.99456 15.3549 7.94839 14.6516 8.65165C13.9484 9.35491 12.9945 9.75 12 9.75C11.0054 9.75 10.0516 9.35491 9.34833 8.65165C8.64506 7.94839 8.24998 6.99456 8.24998 6C8.24998 5.00544 8.64506 4.05161 9.34833 3.34835C10.0516 2.64509 11.0054 2.25 12 2.25C12.9945 2.25 13.9484 2.64509 14.6516 3.34835C15.3549 4.05161 15.75 5.00544 15.75 6ZM4.50098 20.118C4.53311 18.1504 5.33731 16.2742 6.74015 14.894C8.14299 13.5139 10.0321 12.7405 12 12.7405C13.9679 12.7405 15.857 13.5139 17.2598 14.894C18.6626 16.2742 19.4668 18.1504 19.499 20.118C17.1464 21.1968 14.5881 21.7535 12 21.75C9.32398 21.75 6.78398 21.166 4.50098 20.118Z" stroke="#1E293B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const SettingsIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M11.42 15.17L17.25 21C17.75 21.484 18.4202 21.7521 19.1161 21.7465C19.812 21.7408 20.4777 21.4619 20.9698 20.9698C21.4619 20.4777 21.7408 19.812 21.7465 19.1161C21.7521 18.4202 21.484 17.75 21 17.25L15.123 11.373M11.42 15.17L13.916 12.14C14.233 11.756 14.656 11.514 15.124 11.374C15.674 11.21 16.287 11.186 16.867 11.234C17.6488 11.3011 18.4346 11.1627 19.1464 10.8323C19.8582 10.502 20.4712 9.99124 20.9246 9.35081C21.3781 8.71037 21.6562 7.9625 21.7313 7.1814C21.8065 6.4003 21.676 5.61313 21.353 4.898L18.077 8.175C17.5289 8.04826 17.0274 7.77016 16.6296 7.37238C16.2318 6.97459 15.9537 6.4731 15.827 5.925L19.103 2.649C18.3879 2.32596 17.6007 2.19554 16.8196 2.27068C16.0385 2.34582 15.2906 2.62391 14.6502 3.07735C14.0098 3.53079 13.499 4.14381 13.1687 4.8556C12.8383 5.56738 12.6999 6.35317 12.767 7.135C12.858 8.211 12.696 9.399 11.863 10.085L11.761 10.17M11.42 15.17L6.765 20.823C6.53939 21.098 6.2587 21.3227 5.94099 21.4827C5.62329 21.6427 5.27561 21.7344 4.92033 21.7518C4.56505 21.7693 4.21005 21.7122 3.87817 21.5842C3.54629 21.4562 3.24489 21.2602 2.99336 21.0086C2.74184 20.7571 2.54577 20.4557 2.41776 20.1238C2.28976 19.792 2.23266 19.4369 2.25015 19.0817C2.26764 18.7264 2.35932 18.3787 2.51929 18.061C2.67927 17.7433 2.90399 17.4626 3.179 17.237L10.016 11.607L5.909 7.5H4.5L2.25 3.75L3.75 2.25L7.5 4.5V5.909L11.76 10.169L10.015 11.606M18.375 18.375L15.75 15.75M4.867 19.125H4.875V19.133H4.867V19.125Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const LandingPagesIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M9 17.25V18.257C9.00005 18.6511 8.92244 19.0414 8.77162 19.4055C8.6208 19.7696 8.39971 20.1004 8.121 20.379L7.5 21H16.5L15.879 20.379C15.6003 20.1004 15.3792 19.7696 15.2284 19.4055C15.0776 19.0414 15 18.6511 15 18.257V17.25M21 5.25V15C21 15.5967 20.7629 16.169 20.341 16.591C19.919 17.0129 19.3467 17.25 18.75 17.25H5.25C4.65326 17.25 4.08097 17.0129 3.65901 16.591C3.23705 16.169 3 15.5967 3 15V5.25M21 5.25C21 4.65326 20.7629 4.08097 20.341 3.65901C19.919 3.23705 19.3467 3 18.75 3H5.25C4.65326 3 4.08097 3.23705 3.65901 3.65901C3.23705 4.08097 3 4.65326 3 5.25M21 5.25V12C21 12.5967 20.7629 13.169 20.341 13.591C19.919 14.0129 19.3467 14.25 18.75 14.25H5.25C4.65326 14.25 4.08097 14.0129 3.65901 13.591C3.23705 13.169 3 12.5967 3 12V5.25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const AvailabilityIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M11.35 3.836C11.285 4.046 11.25 4.269 11.25 4.5C11.25 4.914 11.586 5.25 12 5.25H16.5C16.6989 5.25 16.8897 5.17098 17.0303 5.03033C17.171 4.88968 17.25 4.69891 17.25 4.5C17.2501 4.27491 17.2164 4.05109 17.15 3.836M11.35 3.836C11.492 3.3767 11.7774 2.97493 12.1643 2.68954C12.5511 2.40414 13.0192 2.25011 13.5 2.25H15C16.012 2.25 16.867 2.918 17.15 3.836M11.35 3.836C10.974 3.859 10.6 3.886 10.226 3.916C9.095 4.01 8.25 4.973 8.25 6.108V8.25M17.15 3.836C17.526 3.859 17.9 3.886 18.274 3.916C19.405 4.01 20.25 4.973 20.25 6.108V16.5C20.25 17.0967 20.0129 17.669 19.591 18.091C19.169 18.5129 18.5967 18.75 18 18.75H15.75M8.25 8.25H4.875C4.254 8.25 3.75 8.754 3.75 9.375V20.625C3.75 21.246 4.254 21.75 4.875 21.75H14.625C15.246 21.75 15.75 21.246 15.75 20.625V18.75M8.25 8.25H14.625C15.246 8.25 15.75 8.754 15.75 9.375V18.75M7.5 15.75L9 17.25L12 13.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const ActivitiesIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M9 12H12.75M9 15H12.75M9 18H12.75M15.75 18.75H18C18.5967 18.75 19.169 18.5129 19.591 18.091C20.0129 17.669 20.25 17.0967 20.25 16.5V6.108C20.25 4.973 19.405 4.01 18.274 3.916C17.9 3.88498 17.5256 3.85831 17.151 3.836M17.151 3.836C17.2174 4.05109 17.2501 4.27491 17.25 4.5C17.25 4.69891 17.171 4.88968 17.0303 5.03033C16.8897 5.17098 16.6989 5.25 16.5 5.25H12C11.586 5.25 11.25 4.914 11.25 4.5C11.25 4.269 11.285 4.046 11.35 3.836M17.151 3.836C16.868 2.918 16.012 2.25 15 2.25H13.5C13.0192 2.25011 12.5511 2.40414 12.1643 2.68954C11.7774 2.97493 11.492 3.3767 11.35 3.836M11.35 3.836C10.974 3.859 10.6 3.886 10.226 3.916C9.095 4.01 8.25 4.973 8.25 6.108V8.25M8.25 8.25H4.875C4.254 8.25 3.75 8.754 3.75 9.375V20.625C3.75 21.246 4.254 21.75 4.875 21.75H14.625C15.246 21.75 15.75 21.246 15.75 20.625V9.375C15.75 8.754 15.246 8.25 14.625 8.25H8.25ZM6.75 12H6.758V12.008H6.75V12ZM6.75 15H6.758V15.008H6.75V15ZM6.75 18H6.758V18.008H6.75V18Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const MediaIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M14.7519 11.1679L11.5547 9.03647C10.8901 8.59343 10 9.06982 10 9.86852V14.1315C10 14.9302 10.8901 15.4066 11.5547 14.9635L14.7519 12.8321C15.3457 12.4362 15.3457 11.5638 14.7519 11.1679Z" stroke="#1E293B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#1E293B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);
