import { ApiCampaignStatus } from '@/app/common';
import { ApiCampaignItemFragment } from './get-campaigns.query.generated';
import { CampaignItem, CampaignStatus } from '../../interfaces';

export const mapCampaign = (
  campaign: ApiCampaignItemFragment,
): CampaignItem => ({
  id: campaign.id,
  name: campaign.name,
  status: mapStatus(campaign.status),
  price: campaign.price,
  startDate: campaign.startDate && new Date(campaign.startDate) || null,
  endDate: campaign.endDate && new Date(campaign.endDate) || null,
  salesPerson: campaign.salesPerson.name,
  vendor: campaign.vendor?.name ?? null,
  agency: campaign.agency?.name ?? null,
  metrics: {
    impressions: campaign.metrics.impressions,
    views: campaign.metrics.views,
    clicks: campaign.metrics.clicks,
    visibility: campaign.metrics.visibility,
    ctr: campaign.metrics.ctr,
    osp: campaign.metrics.osp,
  },
});

const mapStatus = (status: ApiCampaignStatus): CampaignStatus => {
  switch (status) {
    case ApiCampaignStatus.Draft:
      return 'draft';
    case ApiCampaignStatus.Incomplete:
      return 'incomplete';
    case ApiCampaignStatus.Ready:
      return 'ready';
    case ApiCampaignStatus.Online:
      return 'online';
    case ApiCampaignStatus.Closed:
      return 'closed';

    default:
      throw new Error(`Unable to map campaign status for unknown value ${status}`);
  }
};
