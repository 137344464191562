export const ACTIVITY_STATUS = {
  draft: 'draft',
  pendingApproval: 'pendingApproval',
  approved: 'approved',
  rejected: 'rejected',
  incomplete: 'incomplete',
  ready: 'ready',
  online: 'online',
  paused: 'paused',
  closed: 'closed',
  cancelled: 'cancelled',
} as const;

export type ActivityStatus = keyof typeof ACTIVITY_STATUS;

export type ActivityStatusTransitions = (
  | 'REQUEST'
  | 'APPROVE'
  | 'REJECT'
  | 'CANCEL'
  | 'PAUSE'
  | 'RESUME'
);
