import { mapContext } from './context.mapper';
import { useContextsQuery } from './get-contexts.query.generated';
import { AuthContext } from '../../interfaces';

type UseContextsHook = {
  contexts: AuthContext[];
  loading: boolean;
  refreshContexts: () => Promise<void>;
};

export const useContexts = (): UseContextsHook => {
  const { data, error, loading, refetch } = useContextsQuery();

  // Error
  if (error) {
    throw new Error(error.message, { cause: error });
  }

  const refreshContexts = async () => {
    const { error } = await refetch();
    if (error) {
      throw new Error('Unable to refresh contexts.');
    }
  };

  return {
    loading,
    contexts: (data?.contexts ?? []).map(context => mapContext(context)),
    refreshContexts,
  };
};
