import React from 'react';
import { FieldValues, Path } from 'react-hook-form';
import { SelectableItem, SelectableItemType } from './selectable-item';
import { FieldError } from '../forms';
import { InfroStrip } from '../notificatons';

export interface SelectableListProps<T extends FieldValues> {
  name: Path<T>;
  label?: string;
  items: SelectableItemType[];
  selectedItems: SelectableItemType[];
  onToggleItem: (item: SelectableItemType) => void;
  error?: FieldError;
};

export const SelectableList: React.FC<SelectableListProps<FieldValues>> = ({
  items,
  label = 'items',
  selectedItems,
  onToggleItem,
  error,
}) => {
  const isItemSelected = (item: SelectableItemType) => {
    return selectedItems.some(selectedItem => selectedItem.id === item.id);
  };

  const notSelectedItems = items.filter(item => !isItemSelected(item));

  return (
    <>
      {selectedItems.length > 0 ? (
        <div className="flex flex-col space-y-1 mb-4">
          {selectedItems.map(item => (
            <SelectableItem
              key={`selected-${item.id}`}
              item={item}
              isSelected={true}
              onToggle={() => onToggleItem(item)}
            />
          ))}
        </div>
      ) : (
        <>
          <div className="mb-4">
            <InfroStrip label={`Currently there are no added ${label}`} type="info" />
            {error?.message && (
              <p className="mt-1 text-xs text-red-700 leading-4">{error.message}</p>
            )}
          </div>
        </>
      )}

      {notSelectedItems.length > 0 && (
        <>
          <h3 className="mb-4">
            <span className="text-sm">Available {label}</span>
          </h3>

          {notSelectedItems.map(item => (
            <SelectableItem
              key={`unselected-${item.id}`}
              item={item}
              isSelected={false}
              onToggle={() => onToggleItem(item)}
            />
          ))}
        </>
      )}
    </>
  );
};
