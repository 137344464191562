import React from 'react';
import { CloseIcon } from '@/app/components';

export type BadgeSizeType = (
  | 'lg'
  | 'md'
  | 'sm'
);

export type BadgeColorType = (
  | 'blue'
  | 'green'
  | 'yellow'
  | 'red'
  | 'grey'
  | 'black'
);

interface BadgeProps {
  color: BadgeColorType;
  label: string;
  size?: BadgeSizeType;
  onClick?: () => void;
};

export const Badge: React.FC<BadgeProps> = ({
  label,
  color,
  size = 'md',
  onClick,
}) => {

  const handleClick = () => {
    if (onClick !== undefined) {
      onClick();
    }
  };

  const getSizeClassName = () => {
    switch (size) {
      case 'lg':
        return 'px-3 py-2 text-sm';
      case 'md':
        return 'px-3 py-1.5 text-sm';
      case 'sm':
        return 'px-3 py-1 text-xs';
      default:
        throw new Error(`Unknown size: ${size}`);
    }
  };

  const getColorClassName = () => {
    switch (color) {
      case 'grey':
        return 'text-slate-800 bg-slate-300';
      case 'blue':
        return 'text-slate-800 bg-purple-300';
      case 'green':
        return 'text-slate-800 bg-green-300';
      case 'yellow':
        return 'text-slate-800 bg-yellow-300';
      case 'red':
        return 'text-slate-800 bg-red-300';
      case 'black':
        return 'text-white bg-slate-600';
      default:
        throw new Error(`Unknown color: ${color}`);
    }
  };

  return (
    <span
      className={`
        inline-flex
        items-center
        rounded-md
        px-2
        py-1
        leading-4
        self-center
        text-sm
        ${getSizeClassName()}
        ${getColorClassName()}
      `}
    >
      {label}

      {onClick !== undefined && (
        <button
          className="
            ml-1
            -mr-1
            hover:text-slate-300
          "
          type="button"
          onClick={handleClick}
        >
          {CloseIcon}
        </button>
      )}
    </span>
  );
};
