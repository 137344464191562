import React from 'react';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { useDialogContext } from '@/app/common';
import { PageContentBox } from '@/app/components';
import { useActivityDetail, useCampaignDetail, useUpdateActivity } from './api';
import {
  ActivityForm,
  AdvertisingToolsDialog,
  ChangeToolDialog,
} from './components';
import { ActivityFormData } from './interfaces';

export const ActivitiesEditPage: React.FC = () => {
  const { id, activityId } = useParams<{ id: string, activityId: string }>();
  if (!id || !activityId) {
    throw new Error('Unknown campaign ID or activityId. Unable to open page with unknown ID.');
  }

  const navigate = useNavigate();
  const { campaign } = useCampaignDetail(id);
  const { activity, advertisingToolId, refreshActivity } = useActivityDetail(activityId);
  const { updateActivity } = useUpdateActivity();
  const { dialogState, openDialog, closeDialog } = useDialogContext();

  const handleSubmit = async (data: ActivityFormData) => {
    await updateActivity(activity.id, data);
    await refreshActivity();
    navigate('..');
  };

  const handleSelectTool = (toolId: string) => {
    navigate({ search: createSearchParams({
      tool: toolId,
    }).toString() });

    closeDialog('advertising-detail');
  };

  const confirmToolChange = () => {
    closeDialog('changeTool');
    openDialog('advertising-detail');
  };

  const handleCancel = () => {
    navigate('..');
  };

  return (
    <PageContentBox maxWidth="2xl">
      <header className="mb-4">
        <h1 className="text-3xl font-medium">Edit Line Item</h1>
      </header>

      <ActivityForm
        campaign={campaign}
        activity={activity}
        selectedTool={advertisingToolId}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />

      <AdvertisingToolsDialog
        onSelect={handleSelectTool}
        onClose={() => closeDialog('advertising-detail')}
        isOpened={dialogState['advertising-detail']}
      />

      <ChangeToolDialog
        onConfirm={confirmToolChange}
        onClose={() => closeDialog('changeTool')}
        isOpened={dialogState['changeTool']}
      />
    </PageContentBox>
  );
};
