import { createContextualCan } from '@casl/react';
import { createContext, useContext, useEffect, useState } from 'react';
import { ability, definePermissionsFor } from './ability';
import { PermissionsContextType } from './permissions.interface';
import { useAuth } from '../auth';

const initialAbilityRule = ability.build();

export const PermissionsContext = createContext<PermissionsContextType>(initialAbilityRule);

export const PermissionsProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [permissions, setPermissions] = useState(initialAbilityRule);
  const { state: { currentUser }} = useAuth();

  useEffect(() => {
    setPermissions(definePermissionsFor(currentUser ?? undefined));
  }, [currentUser?.role]);

  return (
    <PermissionsContext.Provider value={permissions}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = (): PermissionsContextType => {
  const context = useContext(PermissionsContext);
  if (!context) {
    throw new Error('usePermissions must be used within a PermissionsProvider');
  }

  return context;
};

export const Can = createContextualCan(PermissionsContext.Consumer);
