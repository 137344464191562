import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Timezone, useAppMeta, useLocalDate } from '@/app/common';
import {
  Button,
  ChevronRightIcon,
  ToggleSection,
} from '@/app/components';
import { ActivityBuilder } from './activity-builder';
import { ActivityBuilderSkeleton } from './activity-builder-skeleton';
import { ActivityDetails } from './activity-details';
import { ActivityFormRawData } from './activity-form.interface';
import { CreativeDetails } from './creative-details';
import { ActivityDetail, ActivityFormData, ActivityTargetValueFormData, CampaignDetail } from '../../interfaces';

interface ActivityFormProps {
  children?: React.ReactNode;
  campaign: CampaignDetail;
  activity?: ActivityDetail;
  selectedTool: string | null;
  onSubmit: (formData: ActivityFormData) => Promise<void>;
  onCancel: () => void;
};

export const ActivityForm: React.FC<ActivityFormProps> = ({
  children,
  campaign,
  activity,
  selectedTool,
  onSubmit,
  onCancel,
}) => {
  const { timezone } = useAppMeta();
  const formMethods = useForm<ActivityFormRawData>({
    mode: 'onChange',
    defaultValues: {
      toolId: activity?.toolId ?? null,
      startDate: activity?.startDate ? dayjs(activity.startDate).format('YYYY-MM-DD') : '',
      endDate: activity?.endDate ? dayjs(activity.endDate).format('YYYY-MM-DD') : '',
      orderedAmount: activity?.orderedAmount.toString() ?? '0',
      customPrice: activity?.unitPrice.toString() ?? undefined,
      targets: (activity?.targets ?? []).reduce((stack, current) => {
        const targetValues = current.values.map(value => ({
          name: value.value,
          value: value.id,
        }));

        return {
          ...stack,
          [current.targetId]: current.multiple ? targetValues : targetValues[0] ?? null,
        };
      }, {}),
      creatives: (activity?.creatives ?? []).map(creative => creative.id),
      note: null,
    },
  });
  const { formState: { isSubmitting, isValidating } } = formMethods;

  const doSubmit = async (formData: ActivityFormRawData) => {
    const toolId = formData.toolId;
    if (!toolId) {
      throw new Error('Cannot submit activity form without selected advertising tool.');
    }

    await onSubmit({
      ...formData,
      toolId,
      startDate: useLocalDate(timezone, formData.startDate).toISOString(),
      endDate: formData.endDate
        ? useLocalDate(timezone, formData.endDate).add(1, 'day').startOf('day').toISOString()
        : null,
      orderedAmount: Number(formData.orderedAmount),
      customPrice: Number(formData.customPrice),
      targets: Object.keys(formData.targets ?? {}).map(targetId => {
        const selectedValues = formData.targets?.[targetId];
        let values: ActivityTargetValueFormData[] = [];
        if (selectedValues && Array.isArray(selectedValues)) {
          values = selectedValues.map(v => ({
            id: v.value,
            value: v.name,
          }));
        } else if (selectedValues) {
          values = [{
            id: selectedValues.value,
            value: selectedValues.name,
          }];
        }

        return {
          targetId,
          values,
        };
      }),
      note: formData.note && formData.note.length > 0 ? formData.note : null,
    });
  };

  useEffect(() => {
    formMethods.setValue('toolId', selectedTool, { shouldDirty: false });
  }, [selectedTool]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(doSubmit)} noValidate>
        {children}

        <ActivityBuilder
          selectedTool={selectedTool}
          fallback={
            <Controller
              control={formMethods.control}
              name="toolId"
              rules={{
                required: 'You have to select advertising tool first.',
              }}
              render={({ fieldState: { error } }) => (
                <ActivityBuilderSkeleton error={error} />
              )}
            />
          }
        >
          {(tool) => (
            <>
              {activity === undefined ? (
                <ToggleSection title="Line item" margin="mb-5">
                  <ActivityDetails selectedTool={tool} />
                </ToggleSection>
              ) : (
                <div className="mb-5">
                  <ActivityDetails selectedTool={tool} activity={activity} />
                </div>
              )}

              <ToggleSection title="Creative" margin="mb-5">
                <CreativeDetails campaign={campaign} activity={activity} selectedTool={tool} />
              </ToggleSection>
            </>
          )}
        </ActivityBuilder>

        <div className="flex justify-between mt-8">
          <div>
            <Button label="Cancel" variant="secondary" onClick={onCancel} />
          </div>

          <div>
            <Button
              type="submit"
              label="Proceed"
              variant="primary"
              icon={ChevronRightIcon}
              iconPosition="right"
              disabled={isSubmitting || isValidating}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
