type UseAuthTokenHook = {
  token: string | null;
  refreshToken: string | null;
  hasCredentials: boolean;

  setToken: (token: string) => void;
  setRefreshToken: (refreshToken: string) => void;
  clearTokens: () => void;
};

export const AUTH_TOKEN_LOCAL_STORAGE_KEY = 'auth.token';
export const REFRESH_TOKEN_LOCAL_STORAGE_KEY = 'auth.refreshToken';

export const useAuthToken = (): UseAuthTokenHook => {
  const token = localStorage.getItem(AUTH_TOKEN_LOCAL_STORAGE_KEY);
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY);
  const setToken = (token: string) => localStorage.setItem(AUTH_TOKEN_LOCAL_STORAGE_KEY, token);
  const setRefreshToken = (token: string) => localStorage.setItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY, token);
  const clearTokens = () => {
    localStorage.removeItem(AUTH_TOKEN_LOCAL_STORAGE_KEY);
    localStorage.removeItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY);
  };

  return {
    token,
    refreshToken,
    hasCredentials: token !== null && refreshToken !== null,
    setToken,
    setRefreshToken,
    clearTokens,
  };
};
