import { ApolloError } from '@apollo/client';
import { useVendorSuspenseQuery } from './get-vendor-detail.query.generated';
import { mapVendorBrand, mapVendorDetail, mapVendorUser } from './vendor.mapper';
import { VendorBrandItem, VendorDetail, VendorUserItem } from '../../interfaces';

type UseVendorDetailHook = {
  vendor: VendorDetail;
  vendorUsersTotal: number;
  vendorUsers: VendorUserItem[];
  vendorBrandsTotal: number;
  vendorBrands: VendorBrandItem[];
  refreshVendor: () => Promise<VendorDetail>;
  error?: ApolloError;
};

export const useVendorDetail = (id: string): UseVendorDetailHook => {
  const { data, error, refetch } = useVendorSuspenseQuery({
    variables: {
      id,
    },
  });

  const refreshVendor = async () => {
    const { data, error } = await refetch();
    if (error) {
      throw new Error('Unable to refresh vendor detail.');
    }

    return mapVendorDetail(data.vendor);
  };

  return {
    vendor: mapVendorDetail(data.vendor),
    vendorUsersTotal: data.vendor.users.total,
    vendorUsers: data.vendor.users.items.map(mapVendorUser),
    vendorBrandsTotal: data.vendor.brands.total,
    vendorBrands: data.vendor.brands.items.map(mapVendorBrand),
    refreshVendor,
    error,
  };
};
