import React, { Suspense } from 'react';
import { useFormContext } from 'react-hook-form';
import { ComboboxField, ComboboxSearch } from '@/app/components';
import { AgencyFormRawData } from './agency-form.interface';
import { useSearchVendors } from '../../api';

export const VendorsSearchField = () => {
  return (
    <Suspense fallback={<VendorsSearchFieldSkeleton />}>
      <VendorsLoader />
    </Suspense>
  );
};

export const VendorsLoader: React.FC = () => {
  const { control, watch } = useFormContext<AgencyFormRawData>();
  const { vendors, searchVendors } = useSearchVendors();
  const handleSearch = (term: string) => {
    searchVendors(term);
  };

  const selectedVendorsIds = watch('vendors').map(v => v.value);
  const notSelectedVendors = vendors.filter(v => !selectedVendorsIds.includes(v.id));

  return (
    <ComboboxField
      name="vendors"
      control={control}
      options={notSelectedVendors.map(vendor => ({
        value: vendor.id,
        name: vendor.name,
      }))}
      placeholder="Search for advertiser..."
      onSearch={handleSearch}
      displayValue={false}
      multiple
    />
  );
};

export const VendorsSearchFieldSkeleton = () => (
  <ComboboxSearch
    id="vendorForm.vendors"
    options={[]}
    placeholder="Search for advertiser..."
    multiple
    disabled
  />
);
