import React from 'react';
import {
  Button,
  Paginator,
} from '@/app/components';

export type TableFooterProps = {
  pagination: {
    currentPage: number;
    postsPerPage: number;
    totalPosts: number;
    showPrevNext?: boolean;
  };
  label?: string;
};

export const TableFooter: React.FC<TableFooterProps> = ({ pagination, label }) => {
  const { currentPage, postsPerPage, totalPosts } = pagination;
  const pagesCount = Math.ceil(totalPosts / postsPerPage);

  const startRecord = (currentPage - 1) * postsPerPage + 1;
  const endRecord = Math.min(currentPage * postsPerPage, totalPosts);

  if (pagesCount === 0) {
    return null;
  }

  return (
    <div className="flex items-center space-x-4 justify-between">
      <div className="text-sm font-semibold">
        {`${startRecord}-${endRecord} of ${totalPosts} ${
          label !== undefined ? label : 'items'
        }`}
      </div>
      <div className="flex space-x-14 items-center">
        <Paginator
          itemsCount={totalPosts}
          currentPage={currentPage}
          itemsPerPage={postsPerPage}
        />
      </div>
      <div>
        {/* TODO: Implement load more posts */}
        <Button
          label={`Show ${postsPerPage} more`}
          variant="secondary"
          link={{ search: `?more=${postsPerPage}` }}
        />
      </div>
    </div>
  );
};
