import { isEmail } from 'class-validator';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Button, InputField, Notification } from '@/app/components';
import { useRequestResetPassword } from '../../api';

interface PasswordRequestFormRawData {
  username: string;
};

export interface PasswordRequestFormData {
  username: string;
};

interface PasswordRequestFormProps {
  children?: React.ReactNode;
};

export const PasswordRequestForm: React.FC<PasswordRequestFormProps> = ({
  children,
}) => {
  const { requestPasswordReset, loading } = useRequestResetPassword();
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const { register, reset, handleSubmit, formState: { errors } } = useForm<PasswordRequestFormRawData>({
    mode: 'onChange',
  });

  const doSubmit = async (formData: PasswordRequestFormRawData) => {
    try {
      setError(null);
      setSuccess(false);

      await requestPasswordReset(formData.username);
      reset();
      setSuccess(true);
    } catch (error) {
      setError('Error occurred, please try again');
    }
  };

  return (
    <>
      <header className="mb-6">
        <h1 className="text-3xl font-medium">Zadání emailu</h1>
      </header>

      <form onSubmit={handleSubmit(doSubmit)} noValidate>
        {children && !success && (
          <div className="mb-4">
            {children}
          </div>
        )}

        <div className="mb-4">
          <InputField
            {...register('username', {
              required: 'This field is required and cannot be empty',
              validate: (username: string) => {
                return isEmail(username) ? true : 'Field has to be a valid email address.';
              },
            })}
            type="email"
            label="Your email address"
            error={errors.username}
            required
          />

          {error !== null && (
            <div className="mt-3">
              <Notification
                color="red"
                label={error}
                type="error"
              />
            </div>
          )}

          {success && (
            <div className="mt-3">
              <Notification
                color="blue"
                label="Pokud Váš email v systému existuje, zašleme Vám odkaz na vytvoření nového hesla"
                type="info"
              />
            </div>
          )}
        </div>

        <div className="flex flex-col mt-8">
          <Button
            type="submit"
            label="Odeslat"
            disabled={loading}
          />

          <div className="text-sm mt-8 text-center text-slate-500">
            <span className="mr-1">Znáte svoje heslo?</span>
            <Link to={'/login'} className="underline">
            Přihlásit se
            </Link>
          </div>
        </div>
      </form>
    </>
  );
};
