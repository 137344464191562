/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiUpdatedCreativeFragment = { __typename?: 'Creative', id: string, name: string, status: Types.ApiCreativeStatus, statusNote: string | null, destinationType: Types.ApiCreativeDestinationTypes, destinationUrl: string, destinationId: string | null, format: { __typename?: 'IdentifiedCreativeFormat', id: string, name: string }, contents: Array<{ __typename?: 'CreativeField', fieldId: string, type: Types.ApiCreativeFieldType, slug: string, name: string, position: number, value: string | null, mediaValue: { __typename?: 'CreativeFieldMediaValue', id: string, name: string, size: number, mimeType: string, path: string, url: string } | null }> };

export type ApiUpdatedCreativeContentFragment = { __typename?: 'CreativeField', fieldId: string, type: Types.ApiCreativeFieldType, slug: string, name: string, position: number, value: string | null, mediaValue: { __typename?: 'CreativeFieldMediaValue', id: string, name: string, size: number, mimeType: string, path: string, url: string } | null };

export type ApiUpdateCreativeMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  name: Types.Scalars['String']['input'];
  destination: Types.ApiCreativeDestinationDetails;
  contents: Types.InputMaybe<Array<Types.ApiCreateCreativeField> | Types.ApiCreateCreativeField>;
}>;


export type ApiUpdateCreativeMutation = { __typename?: 'Mutation', updateCreative: { __typename?: 'Creative', id: string, name: string, status: Types.ApiCreativeStatus, statusNote: string | null, destinationType: Types.ApiCreativeDestinationTypes, destinationUrl: string, destinationId: string | null, format: { __typename?: 'IdentifiedCreativeFormat', id: string, name: string }, contents: Array<{ __typename?: 'CreativeField', fieldId: string, type: Types.ApiCreativeFieldType, slug: string, name: string, position: number, value: string | null, mediaValue: { __typename?: 'CreativeFieldMediaValue', id: string, name: string, size: number, mimeType: string, path: string, url: string } | null }> } };

export const UpdatedCreativeContentFragmentDoc = gql`
    fragment UpdatedCreativeContent on CreativeField {
  fieldId
  type
  slug
  name
  position
  value
  mediaValue {
    id
    name
    size
    mimeType
    path
    url
  }
}
    `;
export const UpdatedCreativeFragmentDoc = gql`
    fragment UpdatedCreative on Creative {
  id
  name
  status
  statusNote
  format {
    id
    name
  }
  contents {
    ...UpdatedCreativeContent
  }
  destinationType
  destinationUrl
  destinationId
}
    ${UpdatedCreativeContentFragmentDoc}`;
export const UpdateCreativeDocument = gql`
    mutation UpdateCreative($id: ID!, $name: String!, $destination: CreativeDestinationDetails!, $contents: [CreateCreativeField!]) {
  updateCreative(
    id: $id
    name: $name
    destination: $destination
    contents: $contents
  ) {
    ...UpdatedCreative
  }
}
    ${UpdatedCreativeFragmentDoc}`;
export type ApiUpdateCreativeMutationFn = Apollo.MutationFunction<ApiUpdateCreativeMutation, ApiUpdateCreativeMutationVariables>;

/**
 * __useUpdateCreativeMutation__
 *
 * To run a mutation, you first call `useUpdateCreativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreativeMutation, { data, loading, error }] = useUpdateCreativeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      destination: // value for 'destination'
 *      contents: // value for 'contents'
 *   },
 * });
 */
export function useUpdateCreativeMutation(baseOptions?: Apollo.MutationHookOptions<ApiUpdateCreativeMutation, ApiUpdateCreativeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiUpdateCreativeMutation, ApiUpdateCreativeMutationVariables>(UpdateCreativeDocument, options);
      }
export type UpdateCreativeMutationHookResult = ReturnType<typeof useUpdateCreativeMutation>;
export type UpdateCreativeMutationResult = Apollo.MutationResult<ApiUpdateCreativeMutation>;
export type UpdateCreativeMutationOptions = Apollo.BaseMutationOptions<ApiUpdateCreativeMutation, ApiUpdateCreativeMutationVariables>;