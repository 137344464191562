import React, { useEffect, useRef, useState } from 'react';
import { LanguageDropdown } from '@/app/components';
import { LanguageSwitcherItem } from './language.interface';

export const mockedLanguageItems = [
  {
    id: 'cs',
    code: 'CS',
    name: 'Czech',
  },
  {
    id: 'en',
    code: 'EN',
    name: 'English',
  },
];

export const LanguageSwitcher: React.FC = () => {
  const [isDropdownActive, setIsDropdownActive] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownActive(false);
    }
  };

  const handleSwitchLanguage = async (language: LanguageSwitcherItem): Promise<void> => {
    setIsDropdownActive(false);
    // TODO: switch language
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const languages: LanguageSwitcherItem[] = mockedLanguageItems.map(context => ({
    id: context.code,
    name: context.name,
  }));

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        className="
          flex
          items-center
          space-x-2
          text-slate-900
          hover:text-purple-800
          border
          border-slate-300
          rounded-xl
          p-1
          h-8
          box-content
          text-left
        "
        type="button"
        onClick={ () => setIsDropdownActive(!isDropdownActive) }
      >
        <div>
          <div className="flex items-center justify-center rounded-full bg-purple-200 w-8 h-8 overflow-hidden text-sm">
            EN
          </div>
        </div>
      </button>

      {isDropdownActive && (
        <LanguageDropdown
          languages={languages}
          onSelect={handleSwitchLanguage}
        />
      )}
    </div>
  );
};
