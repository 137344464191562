import React from 'react';
import { Route, Routes } from 'react-router-dom';
//import { CreativeLibraryCreatePage } from './creative-library-create.page';
import { PopupNoteProvider } from './components';
import { CreativeLibraryDetailPage } from './creative-library-detail.page';
//import { CreativeLibraryEditPage } from './creative-library-edit.page';
import { CreativeLibraryListPage } from './creative-library-list.page';

export const CreativeLibrary: React.FC = () => (
  <PopupNoteProvider>
    <Routes>
      <Route index element={<CreativeLibraryListPage />} />
      {/*<Route path="create" element={<CreativeLibraryCreatePage />} />*/}
      <Route path=":id">
        <Route index element={<CreativeLibraryDetailPage />} />
        {/*<Route path="edit" element={<CreativeLibraryEditPage />} />*/}
      </Route>
    </Routes>
  </PopupNoteProvider>
);

export default CreativeLibrary;
