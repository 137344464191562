import React from 'react';
import { useTranslation } from 'react-i18next';
import { RateType, useAppMeta } from '@/app/common';
import { RoundedFrame } from '@/app/components';

interface ActivitySummaryProps {
  rateType: RateType;
  unitPrice: number;
  orderedAmount: number;
};

export const ActivitySummary: React.FC<ActivitySummaryProps> = ({
  rateType,
  unitPrice,
  orderedAmount,
}) => {
  const { i18n } = useTranslation();
  const { currency } = useAppMeta();
  const priceFormatter = new Intl.NumberFormat(i18n.language, { style: 'currency', currency });
  const normalizedAmount = Math.floor(orderedAmount);
  const orderedAmountPerUnit = rateType === 'cpm' ? normalizedAmount / 1000 : normalizedAmount;
  const totalPrice = unitPrice * orderedAmountPerUnit;

  return (
    <RoundedFrame margin="mb-5">
      <dl className="flex flex-col gap-y-1 text-sm leading-6">
        <div className="flex space-x-2">
          <dt>Cost per unit:</dt>
          <dd className="font-medium">
            {priceFormatter.format(unitPrice)}
          </dd>
        </div>
        <div className="flex space-x-2">
          <dt>Total cost:</dt>
          <dd className="font-medium text-base">
            {priceFormatter.format(totalPrice)}
          </dd>
        </div>
      </dl>
    </RoundedFrame>
  );
};
