/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiSearchAgenciesQueryVariables = Types.Exact<{
  term: Types.InputMaybe<Types.Scalars['String']['input']>;
  vendor: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ApiSearchAgenciesQuery = { __typename?: 'Query', agencies: { __typename?: 'AgencyResult', total: number, items: Array<{ __typename?: 'LeanAgency', id: string, name: string }> } };


export const SearchAgenciesDocument = gql`
    query SearchAgencies($term: String, $vendor: String) {
  agencies(page: 1, limit: 10, search: $term, vendor: $vendor) {
    total
    items {
      id
      name
    }
  }
}
    `;

/**
 * __useSearchAgenciesQuery__
 *
 * To run a query within a React component, call `useSearchAgenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAgenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAgenciesQuery({
 *   variables: {
 *      term: // value for 'term'
 *      vendor: // value for 'vendor'
 *   },
 * });
 */
export function useSearchAgenciesQuery(baseOptions?: Apollo.QueryHookOptions<ApiSearchAgenciesQuery, ApiSearchAgenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiSearchAgenciesQuery, ApiSearchAgenciesQueryVariables>(SearchAgenciesDocument, options);
      }
export function useSearchAgenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiSearchAgenciesQuery, ApiSearchAgenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiSearchAgenciesQuery, ApiSearchAgenciesQueryVariables>(SearchAgenciesDocument, options);
        }
export function useSearchAgenciesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApiSearchAgenciesQuery, ApiSearchAgenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiSearchAgenciesQuery, ApiSearchAgenciesQueryVariables>(SearchAgenciesDocument, options);
        }
export type SearchAgenciesQueryHookResult = ReturnType<typeof useSearchAgenciesQuery>;
export type SearchAgenciesLazyQueryHookResult = ReturnType<typeof useSearchAgenciesLazyQuery>;
export type SearchAgenciesSuspenseQueryHookResult = ReturnType<typeof useSearchAgenciesSuspenseQuery>;
export type SearchAgenciesQueryResult = Apollo.QueryResult<ApiSearchAgenciesQuery, ApiSearchAgenciesQueryVariables>;