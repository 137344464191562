/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiActivityItemFragment = { __typename?: 'LeanActivity', id: string, campaignId: string, name: string, icon: Types.ApiAdvertisingToolIcon | null, status: Types.ApiActivityStatus, statusNote: string | null, startDate: string, endDate: string | null, rateType: Types.ApiAdvertisingToolRate, orderedAmount: number, unitPrice: number, totalPrice: number, metrics: { __typename?: 'Metrics', impressions: number, views: number, clicks: number, visibility: number, ctr: number, osp: number }, creatives: { __typename?: 'CreativeResult', total: number, items: Array<{ __typename?: 'Creative', id: string, name: string, status: Types.ApiCreativeStatus, statusNote: string | null, destinationType: Types.ApiCreativeDestinationTypes, destinationUrl: string, destinationId: string | null, format: { __typename?: 'IdentifiedCreativeFormat', id: string, name: string }, contents: Array<{ __typename?: 'CreativeField', fieldId: string, type: Types.ApiCreativeFieldType, slug: string, name: string, position: number, value: string | null, mediaValue: { __typename?: 'CreativeFieldMediaValue', id: string, name: string, size: number, mimeType: string, path: string, url: string } | null }> }> } };

export type ApiActivityItemCreativeFragment = { __typename?: 'Creative', id: string, name: string, status: Types.ApiCreativeStatus, statusNote: string | null, destinationType: Types.ApiCreativeDestinationTypes, destinationUrl: string, destinationId: string | null, format: { __typename?: 'IdentifiedCreativeFormat', id: string, name: string }, contents: Array<{ __typename?: 'CreativeField', fieldId: string, type: Types.ApiCreativeFieldType, slug: string, name: string, position: number, value: string | null, mediaValue: { __typename?: 'CreativeFieldMediaValue', id: string, name: string, size: number, mimeType: string, path: string, url: string } | null }> };

export type ApiActivityItemCreativeContentFragment = { __typename?: 'CreativeField', fieldId: string, type: Types.ApiCreativeFieldType, slug: string, name: string, position: number, value: string | null, mediaValue: { __typename?: 'CreativeFieldMediaValue', id: string, name: string, size: number, mimeType: string, path: string, url: string } | null };

export type ApiActivitiesQueryVariables = Types.Exact<{
  page: Types.Scalars['Int']['input'];
  limit: Types.Scalars['Int']['input'];
  search: Types.InputMaybe<Types.Scalars['String']['input']>;
  vendor: Types.InputMaybe<Types.Scalars['ID']['input']>;
  status: Types.InputMaybe<Types.ApiActivityStatus>;
  salesPerson: Types.InputMaybe<Types.Scalars['ID']['input']>;
  startDate: Types.InputMaybe<Types.Scalars['String']['input']>;
  endDate: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ApiActivitiesQuery = { __typename?: 'Query', activities: { __typename?: 'ActivityResult', total: number, items: Array<{ __typename?: 'LeanActivity', id: string, campaignId: string, name: string, icon: Types.ApiAdvertisingToolIcon | null, status: Types.ApiActivityStatus, statusNote: string | null, startDate: string, endDate: string | null, rateType: Types.ApiAdvertisingToolRate, orderedAmount: number, unitPrice: number, totalPrice: number, metrics: { __typename?: 'Metrics', impressions: number, views: number, clicks: number, visibility: number, ctr: number, osp: number }, creatives: { __typename?: 'CreativeResult', total: number, items: Array<{ __typename?: 'Creative', id: string, name: string, status: Types.ApiCreativeStatus, statusNote: string | null, destinationType: Types.ApiCreativeDestinationTypes, destinationUrl: string, destinationId: string | null, format: { __typename?: 'IdentifiedCreativeFormat', id: string, name: string }, contents: Array<{ __typename?: 'CreativeField', fieldId: string, type: Types.ApiCreativeFieldType, slug: string, name: string, position: number, value: string | null, mediaValue: { __typename?: 'CreativeFieldMediaValue', id: string, name: string, size: number, mimeType: string, path: string, url: string } | null }> }> } }> } };

export const ActivityItemCreativeContentFragmentDoc = gql`
    fragment ActivityItemCreativeContent on CreativeField {
  fieldId
  type
  slug
  name
  position
  value
  mediaValue {
    id
    name
    size
    mimeType
    path
    url
  }
}
    `;
export const ActivityItemCreativeFragmentDoc = gql`
    fragment ActivityItemCreative on Creative {
  id
  name
  status
  statusNote
  format {
    id
    name
  }
  contents {
    ...ActivityItemCreativeContent
  }
  destinationType
  destinationUrl
  destinationId
}
    ${ActivityItemCreativeContentFragmentDoc}`;
export const ActivityItemFragmentDoc = gql`
    fragment ActivityItem on LeanActivity {
  id
  campaignId
  name
  icon
  status
  statusNote
  startDate
  endDate
  rateType
  orderedAmount
  unitPrice
  totalPrice
  metrics {
    impressions
    views
    clicks
    visibility
    ctr
    osp
  }
  creatives {
    total
    items {
      ...ActivityItemCreative
    }
  }
}
    ${ActivityItemCreativeFragmentDoc}`;
export const ActivitiesDocument = gql`
    query Activities($page: Int!, $limit: Int!, $search: String, $vendor: ID, $status: ActivityStatus, $salesPerson: ID, $startDate: String, $endDate: String) {
  activities(
    page: $page
    limit: $limit
    search: $search
    vendor: $vendor
    status: $status
    salesPerson: $salesPerson
    startDate: $startDate
    endDate: $endDate
  ) {
    total
    items {
      ...ActivityItem
    }
  }
}
    ${ActivityItemFragmentDoc}`;

/**
 * __useActivitiesQuery__
 *
 * To run a query within a React component, call `useActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivitiesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      vendor: // value for 'vendor'
 *      status: // value for 'status'
 *      salesPerson: // value for 'salesPerson'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useActivitiesQuery(baseOptions: Apollo.QueryHookOptions<ApiActivitiesQuery, ApiActivitiesQueryVariables> & ({ variables: ApiActivitiesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiActivitiesQuery, ApiActivitiesQueryVariables>(ActivitiesDocument, options);
      }
export function useActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiActivitiesQuery, ApiActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiActivitiesQuery, ApiActivitiesQueryVariables>(ActivitiesDocument, options);
        }
export function useActivitiesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApiActivitiesQuery, ApiActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiActivitiesQuery, ApiActivitiesQueryVariables>(ActivitiesDocument, options);
        }
export type ActivitiesQueryHookResult = ReturnType<typeof useActivitiesQuery>;
export type ActivitiesLazyQueryHookResult = ReturnType<typeof useActivitiesLazyQuery>;
export type ActivitiesSuspenseQueryHookResult = ReturnType<typeof useActivitiesSuspenseQuery>;
export type ActivitiesQueryResult = Apollo.QueryResult<ApiActivitiesQuery, ApiActivitiesQueryVariables>;