import React, { useCallback, useEffect, useState } from 'react';
import { Loader } from '@/app/components';
import { useVerifyToken } from '../../api';

interface PasswordTokenCheckerProps {
  token: string;
  children: (result: boolean) => React.ReactNode;
};

export const PasswordTokenChecker: React.FC<PasswordTokenCheckerProps> = ({
  token,
  children,
}) => {
  const { verifyToken } = useVerifyToken();
  const [verified, setVerified] = useState<boolean | null>(null);

  const doVerifyToken = useCallback(async (token: string) => {
    try {
      setVerified(null);
      const result = await verifyToken(token);
      setVerified(result);
    } catch {
      setVerified(false);
    }
  }, []);

  useEffect(() => {
    doVerifyToken(token);

    return () => {
      setVerified(false);
    };
  }, []);

  if (verified === null) {
    return <Loader />;
  }

  return children(verified);
};
