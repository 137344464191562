/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiUpdateActivityMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  startDate: Types.Scalars['String']['input'];
  endDate: Types.InputMaybe<Types.Scalars['String']['input']>;
  orderedAmount: Types.Scalars['Int']['input'];
  customPrice: Types.Scalars['Float']['input'];
  targets: Types.InputMaybe<Array<Types.ApiCreateActivityTarget> | Types.ApiCreateActivityTarget>;
  creatives: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  note: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ApiUpdateActivityMutation = { __typename?: 'Mutation', updateActivity: { __typename?: 'Activity', id: string } };


export const UpdateActivityDocument = gql`
    mutation UpdateActivity($id: ID!, $startDate: String!, $endDate: String, $orderedAmount: Int!, $customPrice: Float!, $targets: [CreateActivityTarget!], $creatives: [String!], $note: String) {
  updateActivity(
    id: $id
    startDate: $startDate
    endDate: $endDate
    orderedAmount: $orderedAmount
    customPrice: $customPrice
    targets: $targets
    creatives: $creatives
    note: $note
  ) {
    id
  }
}
    `;
export type ApiUpdateActivityMutationFn = Apollo.MutationFunction<ApiUpdateActivityMutation, ApiUpdateActivityMutationVariables>;

/**
 * __useUpdateActivityMutation__
 *
 * To run a mutation, you first call `useUpdateActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActivityMutation, { data, loading, error }] = useUpdateActivityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      orderedAmount: // value for 'orderedAmount'
 *      customPrice: // value for 'customPrice'
 *      targets: // value for 'targets'
 *      creatives: // value for 'creatives'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useUpdateActivityMutation(baseOptions?: Apollo.MutationHookOptions<ApiUpdateActivityMutation, ApiUpdateActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiUpdateActivityMutation, ApiUpdateActivityMutationVariables>(UpdateActivityDocument, options);
      }
export type UpdateActivityMutationHookResult = ReturnType<typeof useUpdateActivityMutation>;
export type UpdateActivityMutationResult = Apollo.MutationResult<ApiUpdateActivityMutation>;
export type UpdateActivityMutationOptions = Apollo.BaseMutationOptions<ApiUpdateActivityMutation, ApiUpdateActivityMutationVariables>;