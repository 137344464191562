import React, { useRef } from 'react';
import {
  BinIcon,
  Button,
  CloseIcon,
  EditIcon,
  RoundedFrame,
  TicksIcon,
  WhiteCard,
} from '@/app/components';
import { useCreativeWorkflow } from '../../api';
import { useCreativeStatus } from '../../hooks';
import { ActivityCreativeItem } from '../../interfaces';
import { CreativeDestinationInfo } from '../creative-destination-info';
import { CreativeStatus } from '../creative-status';
import { PopupNote, usePopupNote } from '../popup-note';

interface CreativeSummaryProps {
  creative: ActivityCreativeItem;
  onEdit: (creativeId: string) => void;
  onDelete: (creativeId: string) => void;
};

export const CreativeSummary: React.FC<CreativeSummaryProps> = ({
  creative,
  onEdit,
  onDelete,
}) => {
  const rejectButtonRef = useRef<HTMLDivElement>(null);
  const { popupNoteId, openPopupNote, closePopupNote } = usePopupNote();

  const { approve, reject, request } = useCreativeWorkflow();
  const [creativeState, trigger] = useCreativeStatus(creative.status, {
    APPROVE: () => approve(creative.id),
    REJECT: (note: string) => reject(creative.id, note),
    REQUEST: () => request(creative.id),
  });

  const handleSubmitRejection = async (note: string) => {
    await trigger('REJECT', note);
    closePopupNote();
  };

  const handleEdit = (ev: React.SyntheticEvent) => {
    ev.preventDefault();
    onEdit(creative.id);
  };

  const handleDelete = (ev: React.SyntheticEvent) => {
    ev.preventDefault();
    onDelete(creative.id);
  };

  return (
    <WhiteCard padding="mt-4">
      <div className="flex items-center justify-between mb-4">
        <div>
          <h3 className="text-lg font-medium flex items-center">{creative.name}</h3>
        </div>
        <div className="flex items-center gap-x-4">
          {creativeState.can('APPROVE') && (
            <div>
              <Button
                size="sm"
                label="Approve"
                variant="confirm"
                icon={TicksIcon}
                onClick={() => trigger('APPROVE')}
              />
            </div>
          )}

          {creativeState.can('REJECT') && (
            <div ref={rejectButtonRef}>
              <Button
                size="sm"
                label="Disapprove"
                variant="decline"
                icon={CloseIcon}
                onClick={() => openPopupNote(creative.id)}
              />
            </div>
          )}

          {creativeState.can('REQUEST') && (
            <div>
              <Button
                size="sm"
                label="Send for approval"
                variant="secondary"
                onClick={() => trigger('REQUEST')}
              />
            </div>
          )}

          <button className="text-purple-600" type="button" onClick={handleEdit}>{EditIcon}</button>
          <button className="text-purple-600" type="button" onClick={handleDelete}>{BinIcon}</button>
        </div>
      </div>

      <div>
        <CreativeStatus status={creativeState.currentState} />
      </div>

      <RoundedFrame margin="my-5">
        <dl className="text-sm leading-6 pr-3 w-full" >
          {creative.previewContents.map((content, index) => (
            <div key={`${content.term}_${index}`} className="flex space-x-2">
              <dt>{content.term}:</dt>
              <dd className="font-medium">{content.description}</dd>
            </div>
          ))}
          <div className="flex space-x-2">
            <dt>Click destination:</dt>
            <dd>
              <CreativeDestinationInfo creative={creative} />
            </dd>
          </div>
        </dl>
      </RoundedFrame>

      {creative.previewImage && (
        <div className="rounded-sm overflow-hidden">
          <img
            src={creative.previewImage.url}
            height={230}
            alt={creative.previewImage.name}
            className="w-full h-auto"
          />
        </div>
      )}

      {popupNoteId === creative.id && (
        <PopupNote
          actionRef={rejectButtonRef}
          position="bottom"
          onClose={closePopupNote}
          onSubmit={handleSubmitRejection}
        />
      )}
    </WhiteCard>
  );
};
