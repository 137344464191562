/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiSearchVendorItemFragment = { __typename?: 'LeanVendor', id: string, name: string };

export type ApiAgencySearchVendorsQueryVariables = Types.Exact<{
  limit: Types.InputMaybe<Types.Scalars['Int']['input']>;
  search: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ApiAgencySearchVendorsQuery = { __typename?: 'Query', vendors: { __typename?: 'VendorResult', items: Array<{ __typename?: 'LeanVendor', id: string, name: string }> } };

export const SearchVendorItemFragmentDoc = gql`
    fragment SearchVendorItem on LeanVendor {
  id
  name
}
    `;
export const AgencySearchVendorsDocument = gql`
    query AgencySearchVendors($limit: Int, $search: String) {
  vendors(limit: $limit, search: $search) {
    items {
      ...SearchVendorItem
    }
  }
}
    ${SearchVendorItemFragmentDoc}`;

/**
 * __useAgencySearchVendorsQuery__
 *
 * To run a query within a React component, call `useAgencySearchVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgencySearchVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgencySearchVendorsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useAgencySearchVendorsQuery(baseOptions?: Apollo.QueryHookOptions<ApiAgencySearchVendorsQuery, ApiAgencySearchVendorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiAgencySearchVendorsQuery, ApiAgencySearchVendorsQueryVariables>(AgencySearchVendorsDocument, options);
      }
export function useAgencySearchVendorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiAgencySearchVendorsQuery, ApiAgencySearchVendorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiAgencySearchVendorsQuery, ApiAgencySearchVendorsQueryVariables>(AgencySearchVendorsDocument, options);
        }
export function useAgencySearchVendorsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApiAgencySearchVendorsQuery, ApiAgencySearchVendorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiAgencySearchVendorsQuery, ApiAgencySearchVendorsQueryVariables>(AgencySearchVendorsDocument, options);
        }
export type AgencySearchVendorsQueryHookResult = ReturnType<typeof useAgencySearchVendorsQuery>;
export type AgencySearchVendorsLazyQueryHookResult = ReturnType<typeof useAgencySearchVendorsLazyQuery>;
export type AgencySearchVendorsSuspenseQueryHookResult = ReturnType<typeof useAgencySearchVendorsSuspenseQuery>;
export type AgencySearchVendorsQueryResult = Apollo.QueryResult<ApiAgencySearchVendorsQuery, ApiAgencySearchVendorsQueryVariables>;