import React from 'react';

export interface DefinitionListItem {
  term: string;
  description: string | JSX.Element;
};

export interface DefinitionListProps {
  data: DefinitionListItem[];
  columns?: 1 | 2 | 3 | 4 | 5;
};

export const DefinitionList: React.FC<DefinitionListProps> = ({ data, columns = 2 }) => {

  const getColumnsClassName = (columns: number) => {
    switch (columns) {
      case 1:
        return '';
      case 2:
        return 'md:grid-cols-2';
      case 3:
        return 'md:grid-cols-3';
      case 4:
        return 'md:grid-cols-4';
      case 5:
        return 'md:grid-cols-5';
      default:
        return 'md:grid-cols-2';
    }
  };

  const columnsClassName = getColumnsClassName(columns);
  return (
    <dl
      className={`
        grid
        grid-cols-1
        text-sm
        leading-6
        w-fit
        gap-x-2
        gap-y-1
        ${columnsClassName}
      `}
    >
      {data.map((item, index) => (
        <div key={`def-item-${index}`} className="flex space-x-2">
          <dt>{item.term}:</dt>
          <dd className="font-medium">{item.description}</dd>
        </div>
      ))}
    </dl>
  );
};
