/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiCreateVendorMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
  billingInfo: Types.ApiCreateCompanyBillingInput;
  defaultTrafficker: Types.InputMaybe<Types.Scalars['ID']['input']>;
  defaultSalesPerson: Types.InputMaybe<Types.Scalars['ID']['input']>;
  brands: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
}>;


export type ApiCreateVendorMutation = { __typename?: 'Mutation', createVendor: { __typename?: 'Vendor', id: string } };


export const CreateVendorDocument = gql`
    mutation CreateVendor($name: String!, $billingInfo: CreateCompanyBillingInput!, $defaultTrafficker: ID, $defaultSalesPerson: ID, $brands: [String!]) {
  createVendor(
    name: $name
    billingInfo: $billingInfo
    defaultTrafficker: $defaultTrafficker
    defaultSalesPerson: $defaultSalesPerson
    brands: $brands
  ) {
    id
  }
}
    `;
export type ApiCreateVendorMutationFn = Apollo.MutationFunction<ApiCreateVendorMutation, ApiCreateVendorMutationVariables>;

/**
 * __useCreateVendorMutation__
 *
 * To run a mutation, you first call `useCreateVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVendorMutation, { data, loading, error }] = useCreateVendorMutation({
 *   variables: {
 *      name: // value for 'name'
 *      billingInfo: // value for 'billingInfo'
 *      defaultTrafficker: // value for 'defaultTrafficker'
 *      defaultSalesPerson: // value for 'defaultSalesPerson'
 *      brands: // value for 'brands'
 *   },
 * });
 */
export function useCreateVendorMutation(baseOptions?: Apollo.MutationHookOptions<ApiCreateVendorMutation, ApiCreateVendorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiCreateVendorMutation, ApiCreateVendorMutationVariables>(CreateVendorDocument, options);
      }
export type CreateVendorMutationHookResult = ReturnType<typeof useCreateVendorMutation>;
export type CreateVendorMutationResult = Apollo.MutationResult<ApiCreateVendorMutation>;
export type CreateVendorMutationOptions = Apollo.BaseMutationOptions<ApiCreateVendorMutation, ApiCreateVendorMutationVariables>;