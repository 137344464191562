import { Description, DialogTitle } from '@headlessui/react';

export interface DialogHeaderProps {
  title: string;
  titleSize?: string;
  titleIcon?: React.ReactNode;
  description?: string | React.ReactNode;
};

export const DialogHeader: React.FC<DialogHeaderProps> = ({
  title,
  titleIcon,
  titleSize = 'text-xl',
  description,
}) => (
  <div className="mb-4">
    <DialogTitle
      className={`
        flex
        items-center
        gap-x-4
        text-3xl
        font-medium
        mb-4
        ${titleSize}
      `}
    >
      {titleIcon ?? titleIcon}
      {title}
    </DialogTitle>
    {description && (
      <Description className="text-sm leading-6">
        {description}
      </Description>
    )}
  </div>
);
