import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageContentBox } from '@/app/components';
import { useCampaignDetail, useUpdateCampaign } from './api';
import { UpdateCampaignForm } from './components';
import { UpdateCampaignFormData } from './interfaces';

export const CampaignsEditPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  if (!id) {
    throw new Error('Unknown campaign ID. Cannot access campaign edit page without valid ID.');
  }

  const { campaign, refreshCampaign } = useCampaignDetail(id);
  const navigate = useNavigate();
  const { updateCampaign } = useUpdateCampaign();

  const handleSubmit = async (data: UpdateCampaignFormData) => {
    await updateCampaign(id, data);
    await refreshCampaign();
    navigate('..');
  };

  const handleCancel = () => {
    navigate('..');
  };

  return (
    <PageContentBox maxWidth="2xl">
      <header className="mb-4">
        <h1 className="text-3xl font-medium">Edit campaign</h1>
      </header>

      <UpdateCampaignForm
        campaign={campaign}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </PageContentBox>
  );
};
