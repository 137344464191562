import { ApiCurrency } from '../api/types.generated';
import { Currency } from '../interfaces';

export const mapStringToCurrencyEnum = (value: string): ApiCurrency => {
  switch (value) {
    case ApiCurrency.Czk:
      return ApiCurrency.Czk;
    case ApiCurrency.Eur:
      return ApiCurrency.Eur;

    default:
      throw new Error(`Unable to convert Currency object from string for unknown value ${value}`);
  }
};

export const mapApiCurrencyToCurrency = (currency: ApiCurrency): Currency => {
  switch (currency) {
    case ApiCurrency.Czk:
      return Currency.Czk;
    case ApiCurrency.Eur:
      return Currency.Eur;

    default:
      throw new Error(`Unable to convert Currency from API constant for unknown value ${currency}`);
  }
};
