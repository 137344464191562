import { ApolloError } from '@apollo/client';
import { useLazySearch } from '@/app/common';
import {
  ApiProductDestinationValueFragment,
  useCreativeDestinationsSuspenseQuery,
} from './get-destinations.query.generated';
import { useSearchProductDestinationsLazyQuery } from './search-products.query.generated';
import { ProductDestinationValue } from '../../interfaces';

type UseDestinationsHook = {
  products: ProductDestinationValue[];
  searchProducts: (term: string) => void;
  error: ApolloError | undefined;
};

export const useDestinations = (
  selectedDestinationType?: string,
  selectedDestinationId?: string | null,
): UseDestinationsHook => {
  const selectedProductIds = selectedDestinationId && [selectedDestinationId] || [];
  const { data: initialDestinations, error } = useCreativeDestinationsSuspenseQuery({
    variables: {
      productIds: selectedProductIds,
      productLimit: 10,
    },
  });
  const [searchProductsQuery, { error: searchProductsError }] = useSearchProductDestinationsLazyQuery();

  const initialProducts = initialDestinations.creativeDestinations.initialProducts.values.map(mapDestinationValue);
  const selectedProducts = initialDestinations.creativeDestinations.selectedProducts.values.map(mapDestinationValue);
  const uniqueProducts = [
    ...selectedProducts,
    ...initialProducts.filter(p => !selectedProductIds.includes(p.id)),
  ];
  const mergedProducts = Object.values(uniqueProducts).slice(0, initialProducts.length);

  const { search: searchProducts, results: searchedProducts } = useLazySearch<ProductDestinationValue>({
    initialValues: mergedProducts,
    onSearch: async (term: string) => {
      const result = await searchProductsQuery({
        variables: {
          productLimit: 10,
          term,
        },
      });

      if (result.error) {
        throw result.error;
      }

      if (!result.data) {
        throw new Error('Empty product destination search response returned while data was expected');
      }

      return result.data.creativeDestinations.products.values.map(mapDestinationValue);
    },
  });

  return {
    products: searchedProducts,
    searchProducts,
    error: error ?? searchProductsError ?? undefined,
  };
};

const mapDestinationValue = ({
  id,
  catalog,
  externalId,
  title,
  url,
  available,
}: ApiProductDestinationValueFragment): ProductDestinationValue => ({
  id,
  catalog,
  externalId,
  name: title,
  title,
  url,
  available,
  value: id,
});
