import { useVerifyPasswordResetTokenMutation } from './reset-password.mutations.generated';

type UseVerifyTokenHook = {
  verifyToken: (token: string) => Promise<boolean>;
  loading: boolean;
};

export const useVerifyToken = (): UseVerifyTokenHook => {
  const [verifyTokenMutation, { loading }] = useVerifyPasswordResetTokenMutation();

  const verifyToken = async (
    token: string,
  ) => {
    const doSubmit = async () => {
      const { data, errors } = await verifyTokenMutation({
        variables: {
          token,
        },
      });

      // Error
      if (errors !== undefined && errors.length > 0) {
        const errorMessage = errors[0].message;
        throw new Error(errorMessage);
      }

      // No error, no data
      if (!data) {
        throw new Error('Server returned empty unexpected response.');
      }

      return data.verifyPasswordResetToken;
    };

    return await doSubmit();
  };

  return {
    verifyToken,
    loading,
  };
};
