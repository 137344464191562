import { Control, Controller, FieldValues, Path, PathValue } from 'react-hook-form';
import { Textarea, TextareaProps } from './textarea';

interface TextareaFieldProps<T extends FieldValues> extends
  Omit<TextareaProps, 'onBlur' | 'value' | 'id'> {
    control: Control<T>;
    defaultValue?: PathValue<T, Path<T>>;
    name: Path<T>;
  };

export const TextareaField = <T extends FieldValues>({
  name,
  label,
  defaultValue,
  error,
  disabled = false,
  required = false,
  placeholder = '',
  control,
  ...rest
}: TextareaFieldProps<T>) => (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{ required }}
        render={({ field }) => (
          <Textarea
            {...field}
            {...rest}
            label={label}
            error={error}
            disabled={disabled}
            required={required}
            placeholder={placeholder}

          />
        )}
      />
    </>
  );
