/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiUploadRequestFragment = { __typename?: 'MediaUploadRequest', requestId: string, url: string };

export type ApiCreateUploadRequestMutationVariables = Types.Exact<{
  type: Types.ApiMediaType;
  name: Types.Scalars['String']['input'];
  size: Types.Scalars['Int']['input'];
  mimeType: Types.Scalars['String']['input'];
  imageWidth: Types.InputMaybe<Types.Scalars['Int']['input']>;
  imageHeight: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type ApiCreateUploadRequestMutation = { __typename?: 'Mutation', createMediaUploadRequest: { __typename?: 'MediaUploadRequest', requestId: string, url: string } };

export const UploadRequestFragmentDoc = gql`
    fragment UploadRequest on MediaUploadRequest {
  requestId
  url
}
    `;
export const CreateUploadRequestDocument = gql`
    mutation CreateUploadRequest($type: MediaType!, $name: String!, $size: Int!, $mimeType: String!, $imageWidth: Int, $imageHeight: Int) {
  createMediaUploadRequest(
    type: $type
    name: $name
    size: $size
    mimeType: $mimeType
    imageWidth: $imageWidth
    imageHeight: $imageHeight
  ) {
    ...UploadRequest
  }
}
    ${UploadRequestFragmentDoc}`;
export type ApiCreateUploadRequestMutationFn = Apollo.MutationFunction<ApiCreateUploadRequestMutation, ApiCreateUploadRequestMutationVariables>;

/**
 * __useCreateUploadRequestMutation__
 *
 * To run a mutation, you first call `useCreateUploadRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUploadRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUploadRequestMutation, { data, loading, error }] = useCreateUploadRequestMutation({
 *   variables: {
 *      type: // value for 'type'
 *      name: // value for 'name'
 *      size: // value for 'size'
 *      mimeType: // value for 'mimeType'
 *      imageWidth: // value for 'imageWidth'
 *      imageHeight: // value for 'imageHeight'
 *   },
 * });
 */
export function useCreateUploadRequestMutation(baseOptions?: Apollo.MutationHookOptions<ApiCreateUploadRequestMutation, ApiCreateUploadRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiCreateUploadRequestMutation, ApiCreateUploadRequestMutationVariables>(CreateUploadRequestDocument, options);
      }
export type CreateUploadRequestMutationHookResult = ReturnType<typeof useCreateUploadRequestMutation>;
export type CreateUploadRequestMutationResult = Apollo.MutationResult<ApiCreateUploadRequestMutation>;
export type CreateUploadRequestMutationOptions = Apollo.BaseMutationOptions<ApiCreateUploadRequestMutation, ApiCreateUploadRequestMutationVariables>;