import { useTranslation } from 'react-i18next';
import { useAppMeta } from '@/app/common';
import {
  Button,
  ChevronRightIcon,
  DefinitionList,
  RoundedFrame,
  ToolIcon,
  WhiteCard,
} from '@/app/components';
import { AdvertisingTool } from '../../interfaces';

interface AdvertisingToolsBoxProps extends AdvertisingTool {
  onOpenDetail: (toolId: string) => void;
  onSelect: (toolId: string) => void;
};

export const AdvertisingToolsBox: React.FC<AdvertisingToolsBoxProps> = ({
  id,
  name,
  description,
  icon,
  targets,
  rateType,
  unitPrice,
  onOpenDetail,
  onSelect,
}) => {
  const { i18n } = useTranslation();
  const { currency } = useAppMeta();
  const priceFormatter = Intl.NumberFormat(i18n.language, { style: 'currency', currency });

  return (
    <WhiteCard padding="p-6">
      <div className="flex flex-col gap-y-4">
        <div className="flex items-center gap-x-3">
          <ToolIcon type={icon} />
          <h3 className="text-lg font-medium">{name}</h3>
        </div>

        <p className="text-sm">
          {description}
        </p>

        <RoundedFrame>
          <DefinitionList data={[
            ...(targets ? [{ term: 'Targeting', description: targets }] : []),
            { term: 'Rate type', description: rateType.toUpperCase() },
            { term: 'Cost per unit', description: priceFormatter.format(unitPrice) },
          ]} columns={1} />
        </RoundedFrame>

        <div className="flex justify-between mt-1">
          <button
            type="button"
            className="text-sm text-purple-600 hover:text-purple-950"
            onClick={() => onOpenDetail(id)}
          >
            Show detail
          </button>

          <div>
            <Button
              label="Select tool"
              variant="primary"
              icon={ChevronRightIcon}
              iconPosition="right"
              onClick={() => onSelect(id)}
            />
          </div>
        </div>
      </div>
    </WhiteCard>
  );
};
