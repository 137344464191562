import { FetchResult } from '@apollo/client';
import {
  useApproveActivityMutation,
  useCancelActivityMutation,
  usePauseActivityMutation,
  useRejectActivityMutation,
  useRequestActivityApprovalMutation,
  useResumeActivityMutation,
} from './activity-workflow.query.generated';

type UseActivityWorkflowHook = {
  request: (id: string) => Promise<void>;
  approve: (id: string) => Promise<void>;
  reject: (id: string, note: string) => Promise<void>;
  cancel: (id: string) => Promise<void>;
  pause: (id: string) => Promise<void>;
  resume: (id: string) => Promise<void>;
};

export const useActivityWorkflow = (): UseActivityWorkflowHook => {
  const [approveMutation] = useApproveActivityMutation();
  const [rejectMutation] = useRejectActivityMutation();
  const [requestMutation] = useRequestActivityApprovalMutation();
  const [cancelMutation] = useCancelActivityMutation();
  const [pauseMutation] = usePauseActivityMutation();
  const [resumeMutation] = useResumeActivityMutation();

  const submitWorkflowRequest = async <TData>(mutationPromise: Promise<FetchResult<TData>>) => {
    const { data, errors } = await mutationPromise;

    // Error
    if (errors !== undefined && errors.length > 0) {
      const errorMessage = errors[0].message;
      throw new Error(errorMessage);
    }

    // No error, no data
    if (!data) {
      throw new Error('Server returned empty unexpected response.');
    }

    return data;
  };

  const request = async (id: string) => {
    await submitWorkflowRequest(requestMutation({
      variables: {
        id,
      },
    }));
  };

  const reject = async (id: string, note: string) => {
    await submitWorkflowRequest(rejectMutation({
      variables: {
        id,
        note,
      },
    }));
  };

  const approve = async (id: string) => {
    await submitWorkflowRequest(approveMutation({
      variables: {
        id,
      },
    }));
  };

  const cancel = async (id: string) => {
    await submitWorkflowRequest(cancelMutation({
      variables: {
        id,
      },
    }));
  };

  const pause = async (id: string) => {
    await submitWorkflowRequest(pauseMutation({
      variables: {
        id,
      },
    }));
  };

  const resume = async (id: string) => {
    await submitWorkflowRequest(resumeMutation({
      variables: {
        id,
      },
    }));
  };

  return {
    request,
    approve,
    reject,
    cancel,
    pause,
    resume,
  };
};
