import React, { MouseEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppMeta } from '@/app/common';
import {
  AutoDisabledButton,
  AutoDisabledButtonChildrenProps,
  Button,
  ButtonCircle,
  CloseIcon,
  DefinitionList,
  DefinitionListItem,
  EmptySlate,
  Notification,
  PauseIcon,
  PencilIcon,
  PlayIcon,
  PlusIcon,
  RoundedFrame,
  SectionHeading,
  StatisticsList,
  SwitchButton,
  TicksIcon,
} from '@/app/components';
import { useActivityDetail, useActivityWorkflow } from './api';
import { ActivityStatus, CreativeList, PopupNote } from './components';
import { useActivityStatus } from './hooks';

export const ActivitiesDetailPage: React.FC = () => {
  const { activityId } = useParams();
  if (activityId === undefined) {
    throw new Error('Unable to load activity detail. ID parameter is missing.');
  }

  const rejectButtonRef = useRef<HTMLDivElement>(null);
  const [rejectionPopupVisible, setRejectionPopupVisible] = useState<boolean>(false);
  const { activity, refreshActivity } = useActivityDetail(activityId);
  const { approve, reject, request, cancel, pause, resume } = useActivityWorkflow();
  const [activityStatus, triggerWorkflow] = useActivityStatus(activity.status, {
    APPROVE: async () => {
      await approve(activity.id);
      await refreshActivity();
    },
    REJECT: (note: string) => reject(activity.id, note),
    REQUEST: () => request(activity.id),
    CANCEL: () => cancel(activity.id),
    PAUSE: () => pause(activity.id),
    RESUME: () => resume(activity.id),
  });
  const [isCreativesExpanded, expandAllCreatives] = useState<boolean>(false);
  const { i18n } = useTranslation();
  const { currency } = useAppMeta();
  const dateFormatter = new Intl.DateTimeFormat(i18n.language, { dateStyle: 'long' });
  const priceFormatter = new Intl.NumberFormat(i18n.language, { style: 'currency', currency });

  const handleToggleCreatives = () => {
    expandAllCreatives(expanded => !expanded);
  };

  const handleSubmitRejection = async (note: string) => {
    await triggerWorkflow('REJECT', note);
    await refreshActivity();
    setRejectionPopupVisible(false);
  };

  const mapDetailsDefinition = (): DefinitionListItem[] => {
    const mapRateTypeToUnit = (): string | null => {
      switch (activity.rateType) {
        case 'cpc': return 'Clicks';
        case 'cpm': return 'Impressions';
        case 'cpw': return 'Views';
        case 'flatRate': return 'Flat Rate';

        default: return null;
      }
    };

    const items: DefinitionListItem[] = [
      {
        term: 'Period',
        description: [
          dateFormatter.format(activity.startDate),
          activity.endDate ? dateFormatter.format(activity.endDate) : 'None',
        ].join(' - '),
      },
    ];

    const unit = mapRateTypeToUnit();
    if (unit) {
      items.push({ term: unit, description: activity.orderedAmount.toString() });
    }

    items.push({
      term: activity.rateType.toUpperCase(),
      description: priceFormatter.format(activity.unitPrice),
    });

    items.push({
      term: 'Price',
      description: priceFormatter.format(activity.totalPrice),
    });

    return items;
  };

  const TogglePauseButton = ({
    disabled,
    onClick,
  }: AutoDisabledButtonChildrenProps) => {
    const handlePause = (
      event: MouseEvent<HTMLButtonElement>,
      onClickCallback: (event: MouseEvent<HTMLButtonElement>) => void,
    ) => {
      event.stopPropagation();
      onClickCallback(event);
      triggerWorkflow('PAUSE');
    };

    const handleResume = (
      event: MouseEvent<HTMLButtonElement>,
      onClickCallback: (event: MouseEvent<HTMLButtonElement>) => void,
    ) => {
      event.stopPropagation();
      onClickCallback(event);
      triggerWorkflow('RESUME');
    };

    if (activityStatus.can('PAUSE')) {
      return (
        <ButtonCircle
          variant="tertiary"
          label="Pause"
          icon={PauseIcon}
          onClick={event => handlePause(event, onClick)}
          disabled={disabled}
          loading={disabled}
        />
      );
    } else if (activityStatus.can('RESUME')) {
      return (
        <ButtonCircle
          variant="tertiary"
          label="Resume"
          icon={PlayIcon}
          onClick={event => handleResume(event, onClick)}
          disabled={disabled}
          loading={disabled}
        />
      );
    }

    return null;
  };

  return (
    <div className="pb-36">
      <div className="flex justify-between mb-4">
        <h1 className="text-3xl">{activity.name}</h1>

        <div className="flex space-x-2 items-center">
          {activityStatus.can('REQUEST') && (
            <div>
              <Button
                label="Send for approval"
                variant="secondary"
                onClick={() => triggerWorkflow('REQUEST')}
              />
            </div>
          )}

          {activityStatus.can('APPROVE') && (
            <div>
              <Button
                label="Approve"
                variant="confirm"
                icon={TicksIcon}
                onClick={() => triggerWorkflow('APPROVE')}
              />
            </div>
          )}

          {activityStatus.can('REJECT') && (
            <div ref={rejectButtonRef}>
              <Button
                label="Disapprove"
                variant="decline"
                icon={CloseIcon}
                onClick={() => setRejectionPopupVisible(true)}
              />
            </div>
          )}

          <AutoDisabledButton duration={5}>
            {buttonProps => <TogglePauseButton {...buttonProps} />}
          </AutoDisabledButton>

          {activityStatus.can('CANCEL') && (
            <div>
              <Button
                label="Cancel"
                variant="quinary"
                onClick={() => triggerWorkflow('CANCEL')}
              />
            </div>
          )}

          <div>
            <Button
              label="Edit Line Item"
              icon={PencilIcon}
              variant="primary"
              link="edit"
            />
          </div>
        </div>
      </div>

      <div className="mb-5">
        <ActivityStatus status={activityStatus.currentState} />
        {activity.statusNote && (
          <div className="mt-5">
            <Notification
              type="warning"
              color="yellow"
              label={
                <p>
                  <strong>Activity had been disapproved with following reason:</strong>
                  {' '}
                  {activity.statusNote}
                </p>
              }
            />
          </div>
        )}
      </div>

      <RoundedFrame margin="mb-5">
        <DefinitionList data={mapDetailsDefinition()} columns={3} />
      </RoundedFrame>

      <section className="mb-8">
        <header className="mb-4">
          <SectionHeading heading="Statistics" />
        </header>

        <RoundedFrame>
          <StatisticsList {...activity.metrics} />
        </RoundedFrame>
      </section>

      <section className="mb-8">
        <header className="flex items-center justify-between mb-4">
          <SectionHeading heading="Creatives" />

          <div className="flex items-center gap-x-8">
            {activity.creatives.length > 0 && (
              <SwitchButton
                onChange={handleToggleCreatives}
                value={isCreativesExpanded}
                label="Expand all creatives"
                labelPosition="left"
                name="expand-creatives"
              />
            )}

            <div className="ml-8">
              <Button
                label="Add Creative"
                variant="primary"
                link="edit"
                icon={PlusIcon}
              />
            </div>
          </div>
        </header>

        <CreativeList
          creatives={activity.creatives}
          expanded={isCreativesExpanded}
        >
          <EmptySlate title="Creatives" linkLabel="Add Creative" link="edit">
            <p>
              There are no creatives under this line item yet&hellip;
            </p>
          </EmptySlate>
        </CreativeList>
      </section>

      {rejectionPopupVisible && (
        <PopupNote
          actionRef={rejectButtonRef}
          position="bottom"
          onClose={() => setRejectionPopupVisible(false)}
          onSubmit={handleSubmitRejection}
        />
      )}
    </div>
  );
};
