import { Cell, RowData, flexRender } from '@tanstack/react-table';

type TablePlaceholderColumnProps<T extends RowData> = (
  & Cell<T, unknown>
  & {
    children?: React.ReactNode;
  }
);

export const TablePlaceholderColumn = <T,>({
  children,
  ...cell
}: TablePlaceholderColumnProps<T>) => {
  const childrenContent = children ?? flexRender(cell.column.columnDef.cell, cell.getContext());
  return (
    <div
      key={cell.id}
      className={`
        px-4
        py-3
        h-16
        bg-white
        rounded-l-sm
        rounded-r-sm
        group-hover:bg-purple-50
      `}
    >
      <div className="flex justify-between gap-x-2">
        {childrenContent}
      </div>
    </div>
  );
};
