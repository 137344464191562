import React from 'react';
import {
  CloseCircleIcon,
  CloseIcon,
  ExclamationCircleIcon,
  InfoCircleIcon,
  TickCircleIcon,
} from '@/app/components';

export type NotificationColorType = (
  | 'blue'
  | 'green'
  | 'yellow'
  | 'red'
  | 'grey'
);

export type NotificationType = (
  | 'info'
  | 'success'
  | 'warning'
  | 'error'
);

interface NotificationProps {
  type: NotificationType;
  color: NotificationColorType;
  label: string | React.ReactElement;
  onClick?: () => void;
};

export const Notification: React.FC<NotificationProps> = ({
  label,
  type,
  color,
  onClick,
}) => {

  const handleClick = () => {
    if (onClick !== undefined) {
      onClick();
    }
  };

  const getTypeIcon = () => {
    switch (type) {
      case 'info':
        return InfoCircleIcon;
      case 'success':
        return TickCircleIcon;
      case 'warning':
        return ExclamationCircleIcon;
      case 'error':
        return CloseCircleIcon;
      default:
        throw new Error(`Unknown icon type: ${type}`);
    }
  };

  const getColorClassName = () => {
    switch (color) {
      case 'grey':
        return 'bg-slate-100 text-slate-950';
      case 'blue':
        return 'bg-purple-100 text-purple-950';
      case 'green':
        return 'bg-green-100 text-green-950';
      case 'yellow':
        return 'bg-yellow-100 text-yellow-950';
      case 'red':
        return 'bg-red-100 text-red-950';
      default:
        throw new Error(`Unknown color: ${color}`);
    }
  };

  const getIconClassName = () => {
    switch (color) {
      case 'grey':
        return 'text-slate-950';
      case 'blue':
        return 'text-purple-950';
      case 'green':
        return 'text-green-950';
      case 'yellow':
        return 'text-yellow-950';
      case 'red':
        return 'text-red-950';
      default:
        throw new Error(`Unknown close color: ${color}`);
    }
  };

  return (
    <span
      className={`
        flex
        items-center
        rounded-xs
        px-4
        py-3
        text-sm
        leading-5
        self-center
        ${getColorClassName()}
      `}
    >
      <span className={`mr-2 ${getIconClassName()}`}>{getTypeIcon()}</span>

      {label}

      {onClick !== undefined && (
        <button
          className={`
            ml-2
            hover:text-slate-900
          `}
          type="button"
          onClick={handleClick}
        >
          {CloseIcon}
        </button>
      )}
    </span>
  );
};
