import { ApolloError } from '@apollo/client';
import { useLazySearch } from '@/app/common';
import {
  useSearchAgencyContactsLazyQuery,
  useSearchAgencyContactsSuspenseQuery,
} from './search-agency-contacts.query.generated';

export interface AgencyContactSearchItem {
  id: string;
  name: string;
};

export type UseSearchAgencyContactsHook = {
  searchAgencyContacts: (term: string) => void;
  agencyContacts: AgencyContactSearchItem[];
  error: ApolloError | null;
};

export const useSearchAgencyContacts = (agency: string): UseSearchAgencyContactsHook => {
  const { data } = useSearchAgencyContactsSuspenseQuery({
    variables: {
      agency,
      term: null,
    },
  });
  const initialContacts = data.agency.users.items.map(user => ({
    id: user.id,
    name: user.name,
  }));

  const [searchContacts] = useSearchAgencyContactsLazyQuery();
  const { search, results, error } = useLazySearch<AgencyContactSearchItem>({
    initialValues: initialContacts,
    onSearch: async (term: string) => {
      const result = await searchContacts({
        variables: {
          agency,
          term,
        },
      });

      if (result.error) {
        throw result.error;
      }

      if (!result.data) {
        throw new Error('Empty contacts search response returned while data was expected');
      }

      return result.data.agency.users.items.map(contact => ({
        id: contact.id,
        name: contact.name,
      }));
    },
  });

  return {
    searchAgencyContacts: search,
    agencyContacts: results,
    error: error,
  };
};
