import { useMemo } from 'react';
import { Control, Controller, FieldValues, Path, PathValue, RegisterOptions } from 'react-hook-form';
import { ComboboxProps, ComboboxSearch } from './combobox';

interface ComboboxFieldProps<T extends FieldValues> extends
  Omit<ComboboxProps, 'onBlur' | 'value' | 'id'> {
    control: Control<T>;
    defaultValue?: PathValue<T, Path<T>>;
    name: Path<T>;
    rules?: Omit<RegisterOptions<T>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  };

export const ComboboxField = <T extends FieldValues>({
  name,
  label,
  options,
  defaultValue,
  inline = false,
  disabled = undefined,
  required = undefined,
  placeholder = '',
  control,
  rules,
  multiple = false,
  onChange: customOnChange,
  ...rest
}: ComboboxFieldProps<T>) => {
  const memoizedOptions = useMemo(() => options, [options]);
  return(
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules ? rules : { required }}
        render={({ field: { onChange, value, ...fieldProps }, fieldState: { error } }) => (
          <ComboboxSearch
            {...fieldProps}
            {...rest}
            value={value}
            options={memoizedOptions}
            label={label}
            error={error}
            inline={inline}
            disabled={disabled}
            required={required}
            placeholder={placeholder}
            multiple={multiple}
            id={name}
            onChange={(e) => {
              onChange(e);
              if (customOnChange) {
                customOnChange(e);
              }
            }}
          />
        )}
      />
    </>
  );};
