import React from 'react';
import { Link, To } from 'react-router-dom';
import { SpinnerIcon } from '../icons';

export type ButtonType = (
  | 'button'
  | 'submit'
  | 'reset'
);

export type ButtonIconPosition = (
  | 'left'
  | 'right'
);

export type ButtonVariant = (
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'quaternary'
  | 'quinary'
  | 'confirm'
  | 'decline'
);

export type ButtonSizeType = (
  | 'lg'
  | 'md'
  | 'sm'
);

// Common properties for all buttons
interface ButtonBaseProps {
  label: string;
  icon?: React.ReactNode;
  iconPosition?: ButtonIconPosition;
  disabled?: boolean;
  variant?: ButtonVariant;
  type?: ButtonType;
  loading?: boolean;
  size?: ButtonSizeType;
};

// Properties for a button with an onClick callback
interface ButtonWithOnClick extends ButtonBaseProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  link?: never;
  type?: never;
};

// Properties for a button with an href link
interface ButtonWithHref extends ButtonBaseProps {
  onClick?: never;
  link: To;
  type?: never;
};

interface ButtonWithoutLinkOrOnClick extends ButtonBaseProps {
  onClick?: never;
  link?: never;
  type: ButtonType;
};

export type ButtonProps = ButtonWithOnClick | ButtonWithHref | ButtonWithoutLinkOrOnClick;

export const Button: React.FC<ButtonProps> = ({
  label,
  icon,
  link,
  variant = 'primary',
  type = 'button',
  iconPosition = 'left',
  loading = false,
  disabled = loading || false,
  size = 'md',
  onClick,
  ...rest
}) => {
  const getSizeClassName = () => {
    switch (size) {
      case 'lg':
        return 'py-2';
      case 'md':
        return 'py-1.5 h-9';
      case 'sm':
        return 'py-1 h-8';
      default:
        throw new Error(`Unknown size: ${size}`);
    }
  };

  const buttonTypeClass = {
    primary: 'btn-primary',
    secondary: 'btn-secondary',
    tertiary: 'btn-tertiary',
    quaternary: 'btn-quaternary',
    quinary: 'btn-quinary',
    confirm: 'btn-confirm',
    decline: 'btn-decline',
  }[variant];

  const buttonJustifyClass = icon !== undefined
    ? 'justify-between'
    : 'justify-center';

  const buttonClass = `
    inline-flex
    items-center
    text-sm
    font-medium
    rounded-lg
    leading-6
    px-4
    w-full
    focus:outline-none
    transition-colors
    duration-500
    relative
    z-10
    ${getSizeClassName()}
    ${buttonJustifyClass}
    ${buttonTypeClass}
    ${loading ? 'isLoading' : ''}
    ${disabled ? 'pointer-events-none' : ''}
  `;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick !== undefined) {
      onClick(e);
    }
  };

  const renderContent = () => (
    <>
      {icon !== undefined && iconPosition === 'left' && (
        <span className="mr-2">{icon}</span>
      )}
      <span className="whitespace-nowrap overflow-x-hidden">{label}</span>
      {icon !== undefined && iconPosition === 'right' && (
        <span className="ml-2">{icon}</span>
      )}

      {loading && SpinnerIcon}
    </>
  );

  if (link) {
    return (
      <Link {...rest} to={link} className={buttonClass} data-disabled={disabled}>
        {renderContent()}
      </Link>
    );
  }

  return (
    <button
      {...rest}
      type={type}
      className={buttonClass}
      disabled={disabled}
      onClick={type === 'button' ? handleClick : undefined}
    >
      {renderContent()}
    </button>
  );
};
