import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Badge,
  FieldWrapper,
  InputField,
  Selectbox,
  SidebarFilters,
  SwitchButton,
  TableHeader,
} from '@/app/components';
import { SalesPersonField } from '@/app/modules/companies/components';
import { ActivityFilterFormValues } from './activity-filter.interface';
import { StatusField } from './status-field';
import { ActivityFilters, useActivityFilters } from '../../hooks';
import { VendorField } from '../vendor-field';

export const ActivityFilter: React.FC = () => {
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const { filters, filterBadges, setFilters } = useActivityFilters();
  const formMethods = useForm<ActivityFilterFormValues>({
    defaultValues: {
      vendor: filters.vendor,
      status: filters.status,
      salesPerson: filters.salesPerson,
      startDate: filters.startDate ?? '',
      endDate: filters.endDate ?? '',
      search: filters.search ?? '',
    },
  });
  const viewModes = ['List', 'Performance'];

  const handleSearch = async (term: string) => {
    setFilters({
      search: term,
    });
  };

  const handleViewModeSwitch = (viewModeIndex: number) => {
    setFilters({
      viewMode: viewModeIndex === 0 ? 'list' : 'performance',
    }, false);
  };

  const handleToggleCreatives = () => {
    setFilters({
      toggleCreatives: !filters.toggleCreatives,
    }, false);
  };

  const toggleFilters = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleRemoveFilter = (key: string) => {
    setFilters({
      [key]: null,
    });
  };

  const handleResetFilters = () => {
    setFilters({
      search: null,
      vendor: null,
      status: null,
      salesPerson: null,
      startDate: null,
      endDate: null,
    });
    setIsFilterOpen(false);
  };

  const handleSubmitFilters = (newFilters: ActivityFilters) => {
    setFilters({
      ...filters,
      ...newFilters,
    });

    setIsFilterOpen(false);
  };

  useEffect(() => {
    formMethods.setValue('search', filters.search ?? '');
    formMethods.setValue('salesPerson', filters.salesPerson);
    formMethods.setValue('status', filters.status);
    formMethods.setValue('vendor', filters.vendor);
  }, [filters]);

  return (
    <>
      <TableHeader
        searchValue={filters.search}
        onSearch={handleSearch}
        handleViewSwitch={handleViewModeSwitch}
        viewTypes={viewModes}
        selectedViewType={filters.viewMode === 'performance' ? 1 : 0}
        onToggleFilters={toggleFilters}
      >
        <SwitchButton
          onChange={handleToggleCreatives}
          value={filters.toggleCreatives}
          label="Creatives"
          labelPosition="left"
          name="expand-creatives"
        />
      </TableHeader>

      {filterBadges.length > 0 && (
        <div className="flex mt-3 gap-x-3">
          <div className="flex flex-wrap items-center gap-x-1 gap-y-1.5">
            {filterBadges.map(filter => (
              <Badge
                key={filter.key}
                label={`${filter.key}: ${filter.displayValue}`}
                color="black"
                onClick={() => handleRemoveFilter(filter.key)}
              />
            ))}
          </div>

          <button className="text-purple-600 hover:text-purple-900 text-sm" onClick={handleResetFilters}>
            Cancel all
          </button>
        </div>
      )}

      <FormProvider {...formMethods}>
        <SidebarFilters
          isActive={isFilterOpen}
          onClose={toggleFilters}
          onSubmit={handleSubmitFilters}
          onReset={handleResetFilters}
        >
          <div>
            <FieldWrapper>
              <InputField
                {...formMethods.register('search')}
                label="Search"
                placeholder="Search..."
              />
            </FieldWrapper>

            <FieldWrapper>
              {isFilterOpen ? (
                <VendorField
                  control={formMethods.control}
                  name="vendor"
                  label="Advertiser"
                  placeholder="Select from the list..."
                />
              ) : (
                <Selectbox
                  id="activityFilters.vendor"
                  label="Advertiser"
                  placeholder="Select from the list..."
                  options={[]}
                />
              )}
            </FieldWrapper>

            <FieldWrapper>
              <StatusField />
            </FieldWrapper>

            <FieldWrapper>
              {isFilterOpen ? (
                <SalesPersonField
                  control={formMethods.control}
                  name="salesPerson"
                  label="Sales Person"
                  placeholder="Select from the list..."
                />
              ) : (
                <Selectbox
                  id="activityFilters.salesPerson"
                  label="Sales Person"
                  placeholder="Select from the list..."
                  options={[]}
                />
              )}
            </FieldWrapper>
          </div>

          <hr className="mt-6 mb-4 border-slate-200 border-t " />
          <h3 className="text-lg font-medium mb-3">Duration</h3>
          <FieldWrapper>
            <div className="flex gap-x-4">
              <InputField
                {...formMethods.register('startDate')}
                type="date"
                label="From"
              />
              <InputField
                {...formMethods.register('endDate')}
                type="date"
                label="Until"
              />
            </div>
          </FieldWrapper>
        </SidebarFilters>
      </FormProvider>
    </>
  );
};
