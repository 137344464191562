import React, { Suspense } from 'react';
import { CreativeEditor } from './creative-editor';
import { CreativeSummary } from './creative-summary';
import { ActivityCreativeItem, CampaignDetail, CreativeFormat } from '../../interfaces';

interface CreativeBuilderProps {
  campaign: CampaignDetail;
  format: CreativeFormat;
  creative?: ActivityCreativeItem;
  editMode?: boolean;
  onSubmit: (creative: ActivityCreativeItem) => void;
  onCancel: () => void;
  onEdit: (creativeId: string) => void;
  onDelete: (creativeId: string) => void;
};

export const CreativeBuilder: React.FC<CreativeBuilderProps> = ({
  campaign,
  format,
  creative,
  editMode = false,
  onSubmit,
  onCancel,
  onEdit,
  onDelete,
}) => {

  if (editMode) {
    return (
      <Suspense>
        <CreativeEditor
          campaign={campaign}
          format={format}
          creative={creative}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </Suspense>
    );
  } else if (!creative) {
    return null;
  }

  return (
    <CreativeSummary
      creative={creative}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  );
};
