import { AuthState, LoadUserPayload, LoginPayload } from './auth.interface';

export type AuthAction = (
  | { type: 'LOGIN'; payload: LoginPayload }
  | { type: 'LOGIN_FAILED' }
  | { type: 'LOAD_USER'; payload: LoadUserPayload }
  | { type: 'SWITCH_CONTEXT'; payload: LoginPayload }
  | { type: 'LOGOUT' }
);

export const authReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case 'LOGIN':
    case 'SWITCH_CONTEXT':
      return {
        ...action.payload,
        loading: false,
        error: null,
      };
    case 'LOGIN_FAILED':
      return {
        ...state,
        error: 'Some error occured',
      };
    case 'LOAD_USER':
      return {
        ...state,
        currentUser: action.payload.currentUser,
        currentWorkspace: action.payload.currentWorkspace,
      };
    case 'LOGOUT':
      return {
        token: null,
        refreshToken: null,
        currentUser: null,
        currentWorkspace: null,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
