import React, { Suspense } from 'react';
import {
  EmptySlate,
  Loader,
  PageContentBox,
} from '@/app/components';
import { ToolsBoxes } from './components';

export const ToolsLibraryPage: React.FC = () => {
  return (
    <PageContentBox fullHeight>
      <div className="flex justify-between mb-4">
        <h1 className="text-3xl">Advertising Tools</h1>
      </div>

      <Suspense fallback={<Loader />}>
        <ToolsBoxes>
          <EmptySlate title="Advertising tools">
            <p>
              There are no tools available for current market&hellip;
            </p>
          </EmptySlate>
        </ToolsBoxes>
      </Suspense>
    </PageContentBox>
  );
};
