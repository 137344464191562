import React from 'react';
import { TableFooter } from '@/app/components';
import { ActivityList, ActivityListProps } from './activity-list';

interface PaginatedActivityListProps extends ActivityListProps {
  children: React.ReactNode;
  page: number;
  total: number;
  limit?: number;
};

export const PaginatedActivityList: React.FC<PaginatedActivityListProps> = ({
  children,
  page,
  total,
  limit = 10,
  ...props
}) => {
  if (total === 0) {
    return children;
  }

  return (
    <ActivityList {...props}>
      <TableFooter
        label="line items"
        pagination={{
          currentPage: page,
          totalPosts: total,
          postsPerPage: limit,
        }}
      />
    </ActivityList>
  );
};
