import { ApiRole } from '../api/types.generated';

export const mapStringToRoleEnum = (value: string): ApiRole => {
  switch (value.toUpperCase()) {
    case ApiRole.Agency:
      return ApiRole.Agency;
    case ApiRole.Vendor:
      return ApiRole.Vendor;
    case ApiRole.Retailer:
      return ApiRole.Retailer;
    case ApiRole.MarketAdmin:
      return ApiRole.MarketAdmin;
    case ApiRole.OrganizationAdmin:
      return ApiRole.OrganizationAdmin;
    case ApiRole.SuperAdmin:
      return ApiRole.SuperAdmin;

    default:
      throw new Error(`Unable to convert Role object from string for unknown value ${value}`);
  }
};
