import { useFormContext } from 'react-hook-form';
import { Timezone, useAppMeta, useDialogContext, useLocalDate } from '@/app/common';
import { Button, FieldWrapper, InputField, WhiteCard } from '@/app/components';
import { ActivityDetailsQuotes } from './activity-details-quotes';
import { ActivityFormRawData } from './activity-form.interface';
import { ActivitySummary } from './activity-summary';
import { TargetSelectField } from './target-select-field';
import { ActivityDetail, SelectedAdvertisingTool } from '../../interfaces';
import { SummaryBox } from '../summary-box';

interface ActivityDetailsProps {
  selectedTool: SelectedAdvertisingTool;
  activity?: ActivityDetail;
};

export const ActivityDetails: React.FC<ActivityDetailsProps> = ({
  selectedTool,
  activity,
}) => {
  const { openDialog } = useDialogContext();
  const { register, watch, formState: { errors } } = useFormContext<ActivityFormRawData>();
  const { timezone } = useAppMeta();

  const currentStartDate = watch('startDate');
  const minimalStartDate = useLocalDate(timezone)
    .add(selectedTool.minimalDaysBeforeStart, 'days');
  const minimalEndDateString = currentStartDate;
  const availabilityEnabled = false;

  return (
    <WhiteCard>
      <div className="mb-4">
        <SummaryBox
          title={selectedTool.name}
          icon={selectedTool.icon}
          definitionList={[
            { term: 'Category', description: selectedTool.categories.join(', ')},
            { term: 'Creative format', description: selectedTool.formats.map(format => format.name).join(', ') },
            { term: 'Advertising tool type', description: selectedTool.toolType },
          ]}
        >
          {activity === undefined && (
            <Button
              label="Change tool"
              variant="secondary"
              onClick={() => openDialog('changeTool')}
            />
          )}
        </SummaryBox>
      </div>

      {selectedTool.targets.map(target => (
        <TargetSelectField
          key={target.id}
          target={target}
          activityTarget={activity?.targetsById[target.id]}
        />
      ))}

      <FieldWrapper>
        <div className="flex items-start gap-x-4">
          <div className="grow shrink basis-0">
            <InputField
              {...register('startDate', {
                required: 'This field is required and cannot be empty',
                pattern: new RegExp('[0-9]{4}-[0-9]{2}-[0-9]{2}'),
                min: 'Minimal allowed date exceeded',
              })}
              type="date"
              min={minimalStartDate.format('YYYY-MM-DD')}
              label="From"
              error={errors.startDate}
              required
            />
          </div>

          <div className="grow shrink basis-0">
            <InputField
              {...register('endDate', {
                pattern: new RegExp('[0-9]{4}-[0-9]{2}-[0-9]{2}'),
                min: 'Minimal allowed date exceeded',
              })}
              type="date"
              min={minimalEndDateString}
              label="Until"
              disabled={currentStartDate === ''}
              error={errors.endDate}
            />
          </div>

          <div className="pt-9 shrink">
            <button
              type="button"
              disabled={!availabilityEnabled}
              className={`text-sm ${availabilityEnabled ? 'text-purple-600' : 'text-slate-600'}`}
            >
              Check availability
            </button>
          </div>
        </div>
      </FieldWrapper>

      <ActivityDetailsQuotes selectedTool={selectedTool} />

      <ActivitySummary
        orderedAmount={Number(watch('orderedAmount', activity?.orderedAmount.toString() ?? '0'))}
        unitPrice={
          selectedTool.auction
            ? Number(watch('customPrice', activity?.unitPrice.toString() ?? '0'))
            : selectedTool.unitPrice
        }
        rateType={selectedTool.rateType}
      />
    </WhiteCard>
  );
};
