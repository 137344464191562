import classNames from 'classnames';
import React from 'react';
import {
  CloseCircleIcon,
  ExclamationCircleIcon,
  InfoCircleIcon,
  TickCircleIcon,
} from '@/app/components';

export type InfroStripType = (
  | 'info'
  | 'success'
  | 'warning'
  | 'error'
);

export type InfroStripColorType = (
  | 'grey'
  | 'red'
);

interface InfroStripProps {
  type: InfroStripType;
  label: string | React.ReactNode;
  color?: InfroStripColorType;
  onClick?: () => void;
};

export const InfroStrip: React.FC<InfroStripProps> = ({
  label,
  type,
  color = 'grey',
}) => {
  const getIconType = () => {
    switch (type) {
      case 'info':
        return InfoCircleIcon;
      case 'success':
        return TickCircleIcon;
      case 'warning':
        return ExclamationCircleIcon;
      case 'error':
        return CloseCircleIcon;
      default:
        throw new Error(`Unknown icon type: ${type}`);
    }
  };

  const getIconColorClassName = () => {
    switch (color) {
      case 'grey':
        return 'text-slate-600';
      case 'red':
        return 'text-red-700';
      default:
        throw new Error(`Unknown close color: ${color}`);
    }
  };

  const getColorClassName = () => {
    switch (color) {
      case 'grey':
        return 'text-slate-600';
      case 'red':
        return 'text-red-700';
      default:
        throw new Error(`Unknown color: ${color}`);
    }
  };

  return (
    <div
      className={classNames([
        'flex',
        'items-center',
        'rounded-xs',
        'pr-4',
        'py-2',
        'text-sm',
        'leading-6',
        'self-center',
      ], getColorClassName())}
    >
      <span className={classNames('mr-2', getIconColorClassName())}>{getIconType()}</span>
      {label}
    </div>
  );
};
