import { Dialog, DialogPanel } from '@headlessui/react';
import { CloseIcon } from '@/app/components';

export type DialogSizeList = (
  | 'sm'
  | 'md'
  | 'max'
);

export interface CommonDialogProps {
  isOpen: boolean;
  size?: DialogSizeList;
  onClose: () => void;
  children: React.ReactNode;
};

export const CommonDialog: React.FC<CommonDialogProps> = ({
  isOpen,
  children,
  size = 'sm',
  onClose,
}) => {
  if (!isOpen) return null;

  const getDialogSize = () => {
    switch (size) {
      case 'sm':
        return 'w-[480px]';
      case 'md':
        return 'w-[640px]';
      case 'max':
        return 'w-10/12';
      default:
        throw new Error(`Unknown size: ${size}`);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" />

      <div className="fixed inset-0 flex w-screen items-center justify-center">
        <DialogPanel
          className={`
            mx-auto
            max-w-7xl
            p-8
            rounded-sm
            relative
            bg-white
            ${getDialogSize()}
          `}
        >
          <button
            type="button"
            className="absolute top-4 right-4 p-2"
            title="Close"
            onClick={onClose}
          >
            {CloseIcon}
          </button>

          {children}
        </DialogPanel>
      </div>
    </Dialog>
  );
};
