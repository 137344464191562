import classNames from 'classnames';
import React, { RefObject, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Portal, Textarea } from '@/app/components';

interface NoteFormRawValues {
  note: string;
};

interface PopupNoteProps {
  position?: 'top' | 'bottom';
  actionRef: RefObject<HTMLDivElement>;
  onSubmit: (note: string) => void;
  onClose: () => void;
};

export const PopupNote: React.FC<PopupNoteProps> = ({
  position = 'top',
  actionRef,
  onSubmit,
  onClose,
}) => {
  const popupRef = useRef<HTMLDialogElement>(null);
  const [popupPosition, setPopupPosition] = useState<{ top: number, left: number } | undefined>(undefined);
  const { register, handleSubmit, formState: { errors } } = useForm<NoteFormRawValues>({
    mode: 'onChange',
    defaultValues: {
      note: '',
    },
  });

  const doSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    return handleSubmit(async (values) => {
      await onSubmit(values.note);
    })(event);
  };


  const handleClickOutside = (event: MouseEvent) => {
    if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      setPopupPosition(undefined);
    };
  }, []);

  useLayoutEffect(() => {
    const rect = actionRef.current?.getBoundingClientRect() ?? { left: 0, right: 0, width: 0, top: 0, bottom: 0 };
    const top = position === 'top'
      ? rect.top + window.scrollY - 20
      : rect.bottom + window.scrollY + 20;
    let left = rect.left + rect.width / 2;
    if (window.innerWidth - rect.right < 80) {
      left = window.innerWidth - 80 - rect.width;
    }

    setPopupPosition({ top, left });
  }, []);

  return (
    <Portal id="popup-note">
      <dialog
        ref={popupRef}
        open
        className={classNames({
          'popup-bottom': position === 'top',
          '-translate-y-full': position === 'top',
          'popup-top': position === 'bottom',
        }, [
          'absolute',
          'flex',
          'items-center',
          'justify-center',
          'z-20',
          '-translate-x-1/2',
          'bg-transparent',
          'm-0',
        ])}
        style={popupPosition}
      >
        <div className="bg-white p-6 rounded-sm shadow-bottom w-[320px]">
          <form onSubmit={doSubmit} className="space-y-6" noValidate>
            <Textarea
              {...register('note', {
                required: 'This field is required and cannot be empty',
              })}
              label="Comment to disapproval"
              error={errors.note}
              required
            />

            <div className="flex justify-between gap-x-4">
              <Button onClick={onClose} label="Cancel" variant="secondary" />
              <Button type="submit" label="Confirm" variant="primary" />
            </div>
          </form>
        </div>
      </dialog>
    </Portal>
  );
};
