import React, { useEffect } from 'react';
import { usePaneSwitcher } from './pane-context';

interface PaneSwitcherContentProps {
  id: string;
  title: string;
  active?: boolean;
  children: React.ReactNode;
};

export const PaneSwitcherContent: React.FC<PaneSwitcherContentProps> = ({
  id,
  title,
  active = false,
  children,
}) => {
  const context = usePaneSwitcher();

  useEffect(() => {
    context.register({ id, title }, active);
    return () => {
      context.unregister({ id, title });
    };
  }, []);

  if (context.activePane === id) {
    return children;
  }

  return null;
};
