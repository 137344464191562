import { CurrentUser } from '../../interfaces';
import { ApiCurrentUser } from '../types.generated';

export const mapCurrentUser = ({
  currentRole,
  ...userData
}: ApiCurrentUser): CurrentUser => ({
  ...userData,
  role: currentRole,
});
