import { mapCurrentUser } from './current-user.mapper';
import { mapCurrentWorkspace } from './current-workspace.mapper';
import { useCurrentUserLazyQuery } from './get-current-user.query.generated';
import { CurrentUser, CurrentWorkspace } from '../../interfaces';

type UseLazyCurrentUserHook = [
  getCurrentUser: (token?: string) => Promise<{
    currentUser: CurrentUser;
    currentWorkspace: CurrentWorkspace;
  }>,
];

export const useLazyCurrentUser = (): UseLazyCurrentUserHook => {
  const [currentUserQuery] = useCurrentUserLazyQuery();
  const getCurrentUser = async (token?: string) => {
    const { data, error } = await currentUserQuery({
      context: token && {
        headers: {
          authorization: `Bearer ${token}`,
        },
      } || undefined,
    });

    // Error
    if (error) {
      throw new Error(error.message, { cause: error });
    }

    // No error, no data
    if (!data || !data.me || !data.workspace) {
      throw new Error('Current user returned empty unexpected response.');
    }

    return {
      currentUser: mapCurrentUser(data.me),
      currentWorkspace: mapCurrentWorkspace(data.workspace),
    };
  };

  return [
    getCurrentUser,
  ];
};
