import { ApolloError } from '@apollo/client';
import { useLazySearch } from '@/app/common';
import { useSearchTargetLazyQuery } from './search-target.query.generated';
import { ActivityTarget, AdvertisingTarget, AdvertisingTargetValue } from '../../interfaces';

export type UseSearchTargetHook = {
  searchTarget: (term: string) => void;
  values: AdvertisingTargetValue[];
  error: ApolloError | null;
};

export const useSearchTarget = (
  target: AdvertisingTarget,
  activityTarget?: ActivityTarget,
): UseSearchTargetHook => {
  const [searchTarget] = useSearchTargetLazyQuery();
  const initialValues = !target.searchable ? target.initialValues : [
    ...(activityTarget?.values ?? []),
    ...target.initialValues,
  ].slice(0, target.initialValues.length);

  const { search, results, error } = useLazySearch<AdvertisingTargetValue>({
    initialValues,
    onSearch: async (term: string) => {
      if (!target.searchable) {
        return target.initialValues.filter(value => value.value.match(new RegExp(term, 'i')));
      }

      const result = await searchTarget({
        variables: {
          targetId: target.id,
          term,
        },
      });

      if (result.error) {
        throw result.error;
      }

      if (!result.data) {
        throw new Error('Empty contacts search response returned while data was expected');
      }

      return result.data.adTarget.searchValues.map(({ id, value }) => ({
        id,
        value,
      }));
    },
  });

  return {
    searchTarget: search,
    values: results,
    error: error,
  };
};
