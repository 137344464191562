import { useCreateActivityMutation } from './create-activity.query.generated';
import { ActivityFormData } from '../../interfaces';

type UseCreateActivityHook = {
  createActivity: (
    campaignId: string,
    formData: ActivityFormData,
  ) => Promise<{ id: string }>;
  loading: boolean;
};

export const useCreateActivity = (): UseCreateActivityHook => {
  const [activityMutation, { loading }] = useCreateActivityMutation();

  const createActivity = async (
    campaignId: string,
    formData: ActivityFormData,
  ) => {
    const doSubmit = async () => {
      const { data, errors } = await activityMutation({
        variables: {
          ...formData,
          campaignId,
        },
      });

      // Error
      if (errors !== undefined && errors.length > 0) {
        const errorMessage = errors[0].message;
        throw new Error(errorMessage);
      }

      // No error, no data
      if (!data) {
        throw new Error('Server returned empty unexpected response.');
      }

      return data;
    };

    const result = await doSubmit();
    return {
      id: result.createActivity.id,
    };
  };

  return {
    createActivity,
    loading,
  };
};
