import { useMemo } from 'react';
import { ActionArgType, StateMachineInterface, createStateMachine, useStateMachine, useUserRole } from '@/app/common';
import {
  ActivityCreativeStatus,
  CreativeStatusTransitions,
} from '../../interfaces';

type UseCreativeStatusHook = [
  StateMachineInterface<ActivityCreativeStatus, CreativeStatusTransitions>,
  updateState: (transition: CreativeStatusTransitions, ...data: ActionArgType[]) => void,
];

export const useCreativeStatus = (
  initialStatus: ActivityCreativeStatus,
  actions?: Record<CreativeStatusTransitions, (...data: ActionArgType[]) => Promise<void>>,
): UseCreativeStatusHook => {
  const { isRetailer } = useUserRole();
  const stateMachine = useMemo(() => createStateMachine<ActivityCreativeStatus, CreativeStatusTransitions>({
    initial: initialStatus,
    transitions: isRetailer()
      ? [
        { name: 'APPROVE', from: 'draft', to: 'approved' },
        { name: 'REJECT', from: 'draft', to: 'rejected' },
        { name: 'APPROVE', from: 'pendingApproval', to: 'approved' },
        { name: 'REJECT', from: 'pendingApproval', to: 'rejected' },
        { name: 'APPROVE', from: 'rejected', to: 'approved' },
        { name: 'REJECT', from: 'rejected', to: 'rejected' },
      ] : [
        { name: 'REQUEST', from: 'draft', to: 'pendingApproval' },
        { name: 'REQUEST', from: 'rejected', to: 'pendingApproval' },
      ],
  }), [initialStatus]);

  return useStateMachine<ActivityCreativeStatus, CreativeStatusTransitions>(
    stateMachine,
    actions ? { actions } : undefined,
  );
};
