import React, { forwardRef } from 'react';
import { ChangeHandler } from 'react-hook-form';

export type CheckboxSizeType = (
  | 'lg'
  | 'md'
  | 'sm'
);

export interface CheckboxProps {
  name: string;
  value?: string | boolean;
  checked?: boolean;
  label?: string;
  disabled?: boolean;
  size?: CheckboxSizeType;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: ChangeHandler;
};

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(({
  name,
  label,
  checked = false,
  value,
  size = 'md',
  ...rest
}, ref) => {
  return (
    <div className={`nice-checkbox ${size}`}>
      <input
        {...rest}
        type="checkbox"
        id={name}
        name={name}
        value={typeof value === 'boolean' ? `${value}` : value}
        defaultChecked={checked}
        ref={ref}
      />

      <span className={`like-checkbox ${size}`} />

      {label !== undefined && (
        <label htmlFor={name} className="ml-2 text-sm">{label}</label>
      )}
    </div>
  );
});
