import { Control, Controller, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import { SelectableItemType } from './selectable-item';
import { SelectableList, SelectableListProps } from './selectable-list';

type SelectableListFieldProps<T extends FieldValues, N extends FieldPath<T>> = (
  & Omit<SelectableListProps<T>, 'selectedItems' | 'onToggleItem' | 'name'>
  & {
    name: N;
    control: Control<T>;
    defaultValue?: UseControllerProps<T, N>['defaultValue'];
    rules?: UseControllerProps<T, N>['rules'];
  }
  & {
    required?: string;
  }
);

export const SelectableListField = <T extends FieldValues, N extends FieldPath<T>>({
  items,
  name,
  label,
  control,
  defaultValue,
  rules,
}: SelectableListFieldProps<T, N>) => {
  const handleToggleItem = (value: string[], item: SelectableItemType) => {
    const isSelected = value.includes(item.id);
    if (isSelected) {
      return value.filter(id => id !== item.id);
    } else {
      return [...value, item.id];
    }
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <SelectableList
          items={items}
          name={name}
          label={label}
          selectedItems={items.filter(item => value.includes(item.id))}
          onToggleItem={(item) => onChange(handleToggleItem(value, item))}
          error={error}
        />
      )}
    />
  );
};
