import { ApiAgencyItemFragment } from './get-agencies.query.generated';
import { AgencyItem } from '../../interfaces';

export const mapAgencyItem = (
  agency: ApiAgencyItemFragment,
): AgencyItem => ({
  id: agency.id,
  name: agency.name,
  businessId: agency.billingInfo.businessId,
  businessName: agency.billingInfo.businessName,
  vatNumber: agency.billingInfo.vatNumber || null,
});
