import { ApiTimezone } from '../api/types.generated';
import { Timezone } from '../interfaces';

export const mapStringToTimezoneEnum = (value: string): ApiTimezone => {
  switch (value) {
    case ApiTimezone.EuropePrague:
      return ApiTimezone.EuropePrague;

    default:
      throw new Error(`Unable to convert Timezone object from string for unknown value ${value}`);
  }
};

export const mapApiTimezoneToTimezone = (timezone: ApiTimezone): Timezone => {
  switch (timezone) {
    case ApiTimezone.EuropePrague:
      return Timezone.EuropePrague;

    default:
      throw new Error(`Unable to convert Timezone from API constant for unknown value ${timezone}`);
  }
};

export const mapTimezoneToApiTimezone = (timezone: Timezone): ApiTimezone => {
  switch (timezone) {
    case Timezone.EuropePrague:
      return ApiTimezone.EuropePrague;

    default:
      throw new Error(`Unable to convert Timezone to API constant for unknown value ${timezone}`);
  }
};
