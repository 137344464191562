import { isEmail } from 'class-validator';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { locales, timezones } from '@/app/common';
import {
  Button,
  ChevronRightIcon,
  ComboboxField,
  FieldWrapper,
  InputField,
  ToggleSection,
  WhiteCard,
} from '@/app/components';
import { UserFormRawData } from './user-form.interface';
import { UserMarketField } from './user-market-field';
import { AgencyUserFormData, VendorUserDetail, VendorUserFormData } from '../../interfaces';

interface UserFormProps {
  user?: VendorUserDetail;
  onSubmit: (formData: AgencyUserFormData | VendorUserFormData) => Promise<void>;
  onCancel: () => void;
};

export const UserForm: React.FC<UserFormProps> = ({
  user,
  onCancel,
  onSubmit,
}) => {
  const formMethods = useForm<UserFormRawData>({
    mode: 'onChange',
    defaultValues: {
      username: user?.username ?? '',
      name: user?.name ?? '',
      locale: user?.locale && {
        value: user.locale,
        name: user.locale,
      } || null,
      timezone: user?.timezone && {
        value: user.timezone,
        name: user.timezone,
      } || null,
      markets: user?.markets ?? [],
    },
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = formMethods;

  const doSubmit = async (formData: UserFormRawData) => {
    await onSubmit({
      ...formData,
      locale: formData.locale?.value ?? null,
      timezone: formData.timezone?.value ?? null,
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(doSubmit)} noValidate>
        <ToggleSection title="General Info" margin="mb-5">
          <WhiteCard>
            <FieldWrapper>
              <InputField
                {...register('username', {
                  required: 'This field is required and cannot be empty',
                  maxLength: {
                    value: 100,
                    message: 'Field value is too long',
                  },
                  validate: (value: string) => {
                    return isEmail(value) ? true : 'Field has to be a valid email address.';
                  },
                })}
                label="Email"
                placeholder="someuser@gmail.com"
                type="email"
                error={errors.username}
                required
              />
            </FieldWrapper>

            <FieldWrapper>
              <InputField
                {...register('name', {
                  required: 'This field is required and cannot be empty',
                  maxLength: {
                    value: 100,
                    message: 'Field value is too long',
                  },
                })}
                label="Full name"
                placeholder="František Nedbal"
                error={errors.name}
                required
              />
            </FieldWrapper>

            <FieldWrapper>
              <ComboboxField
                control={control}
                name="locale"
                options={locales}
                label="Locale"
                placeholder="Select locale"
              />
            </FieldWrapper>

            <FieldWrapper>
              <ComboboxField
                control={control}
                name="timezone"
                options={timezones}
                label="Timezone"
                placeholder="Select timezone"
              />
            </FieldWrapper>
          </WhiteCard>
        </ToggleSection>

        <ToggleSection title="Selected Markets">
          <WhiteCard>
            <UserMarketField />
          </WhiteCard>
        </ToggleSection>

        <div className="flex justify-between mt-8">
          <div>
            <Button label="Cancel" variant="secondary" onClick={onCancel} />
          </div>

          <div>
            <Button
              type="submit"
              label="Create User"
              variant="primary"
              icon={ChevronRightIcon}
              iconPosition="right"
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
