import { useState } from 'react';
import {
  DialogContent,
  DialogHeader,
  EmptySlate,
  FilterList,
  SectionHeading,
} from '@/app/components';
import { AdvertisingToolsBox } from './advertising-tools-box';
import { useAdvertisingLibrary } from '../../api';

interface AdvertisingToolsDialogListProps {
  onOpenDetail: (toolId: string) => void;
  onSelect: (toolId: string) => void;
};

export const AdvertisingToolsDialogList: React.FC<AdvertisingToolsDialogListProps> = ({
  onOpenDetail,
  onSelect,
}) => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const { categories, toolsByCategory } = useAdvertisingLibrary();

  const handleFilterChange = (categoryId: string) => {
    setSelectedCategory(categoryId === 'all' ? null : categoryId);
  };

  const visibleCategories = selectedCategory
    ? categories.filter(c => c.id === selectedCategory)
    : categories;

  return (
    <>
      <DialogHeader
        title="Advertising Tools"
        titleSize="text-3xl"
      />

      <DialogContent canScroll fixedHeight={75}>
        {visibleCategories.length > 0 ? (
          <>
            <FilterList
              active="all"
              items={[
                { id: 'all', name: 'All Tools' },
                ...categories,
              ]}
              onSelect={handleFilterChange}
            />

            <div className="mt-4">
              {visibleCategories.map(category => (
                <section key={category.id} className="mb-8">
                  <header className="flex items-center justify-between mb-4">
                    <SectionHeading heading={category.name} />
                  </header>

                  <div className="mt-4 flex flex-col space-y-2">
                    <div className="grid grid-cols-3 gap-4">
                      {toolsByCategory[category.id].map(tool => (
                        <AdvertisingToolsBox
                          {...tool}
                          key={tool.id}
                          onOpenDetail={onOpenDetail}
                          onSelect={onSelect}
                        />
                      ))}
                    </div>
                  </div>
                </section>
              ))}
            </div>
          </>
        ) : (
          <EmptySlate title="Advertising tools">
            <p>
              There are no tools available for current market&hellip;
            </p>
          </EmptySlate>
        )}
      </DialogContent>
    </>
  );
};
