import { ApolloError } from '@apollo/client';
import { usePagination } from '@/app/common';
import { mapAgencyItem } from './agency.mapper';
import { useAgenciesSuspenseQuery } from './get-agencies.query.generated';
import { useAgencyFilters } from '../../hooks';
import { AgencyItem } from '../../interfaces';

type UseAgenciesHook = {
  agencies: AgencyItem[];
  total: number;
  page: number;
  limit: number;
  error: ApolloError | undefined;
};
export const useAgencies = (): UseAgenciesHook => {
  const limit = 10;
  const [page] = usePagination();
  const [filters] = useAgencyFilters();
  const { data, error } = useAgenciesSuspenseQuery({
    variables: {
      ...filters,
      page,
      limit,
    },
  });

  return {
    total: data.agencies.total,
    agencies: data.agencies.items.map(mapAgencyItem),
    page,
    limit,
    error,
  };
};
