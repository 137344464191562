/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiProductDestinationValueFragment = { __typename?: 'CreativeProductDestinationValue', id: string, catalog: string, externalId: string, title: string, url: string, available: boolean };

export type ApiCreativeDestinationsQueryVariables = Types.Exact<{
  productLimit: Types.InputMaybe<Types.Scalars['Int']['input']>;
  productIds: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
}>;


export type ApiCreativeDestinationsQuery = { __typename?: 'Query', creativeDestinations: { __typename?: 'DestinationResult', time: string, initialProducts: { __typename?: 'CreativeProductDestination', type: Types.ApiCreativeDestinationTypes, values: Array<{ __typename?: 'CreativeProductDestinationValue', id: string, catalog: string, externalId: string, title: string, url: string, available: boolean }> }, selectedProducts: { __typename?: 'CreativeProductDestination', type: Types.ApiCreativeDestinationTypes, values: Array<{ __typename?: 'CreativeProductDestinationValue', id: string, catalog: string, externalId: string, title: string, url: string, available: boolean }> } } };

export const ProductDestinationValueFragmentDoc = gql`
    fragment ProductDestinationValue on CreativeProductDestinationValue {
  id
  catalog
  externalId
  title
  url
  available
}
    `;
export const CreativeDestinationsDocument = gql`
    query CreativeDestinations($productLimit: Int, $productIds: [String!]) {
  creativeDestinations {
    time
    initialProducts: products(limit: $productLimit) {
      type
      values {
        ...ProductDestinationValue
      }
    }
    selectedProducts: products(ids: $productIds) {
      type
      values {
        ...ProductDestinationValue
      }
    }
  }
}
    ${ProductDestinationValueFragmentDoc}`;

/**
 * __useCreativeDestinationsQuery__
 *
 * To run a query within a React component, call `useCreativeDestinationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreativeDestinationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreativeDestinationsQuery({
 *   variables: {
 *      productLimit: // value for 'productLimit'
 *      productIds: // value for 'productIds'
 *   },
 * });
 */
export function useCreativeDestinationsQuery(baseOptions?: Apollo.QueryHookOptions<ApiCreativeDestinationsQuery, ApiCreativeDestinationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiCreativeDestinationsQuery, ApiCreativeDestinationsQueryVariables>(CreativeDestinationsDocument, options);
      }
export function useCreativeDestinationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiCreativeDestinationsQuery, ApiCreativeDestinationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiCreativeDestinationsQuery, ApiCreativeDestinationsQueryVariables>(CreativeDestinationsDocument, options);
        }
export function useCreativeDestinationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApiCreativeDestinationsQuery, ApiCreativeDestinationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiCreativeDestinationsQuery, ApiCreativeDestinationsQueryVariables>(CreativeDestinationsDocument, options);
        }
export type CreativeDestinationsQueryHookResult = ReturnType<typeof useCreativeDestinationsQuery>;
export type CreativeDestinationsLazyQueryHookResult = ReturnType<typeof useCreativeDestinationsLazyQuery>;
export type CreativeDestinationsSuspenseQueryHookResult = ReturnType<typeof useCreativeDestinationsSuspenseQuery>;
export type CreativeDestinationsQueryResult = Apollo.QueryResult<ApiCreativeDestinationsQuery, ApiCreativeDestinationsQueryVariables>;