import { Suspense } from 'react';
import { useFormContext } from 'react-hook-form';
import { ComboboxField } from '@/app/components';
import { CampaignFormRawData } from './create-campaign-form.interface';
import { useSearchAgencyContacts } from '../../api';

interface SecondaryContactSelectProps {
  selectedAgency: string | null;
};

export const SecondaryContactSelect = ({
  selectedAgency,
}: SecondaryContactSelectProps) => {
  const { control } = useFormContext<CampaignFormRawData>();

  if (!selectedAgency) {
    return (
      <ComboboxField
        control={control}
        name="secondaryContact"
        label="Agency contact"
        placeholder="Select agency contact"
        options={[]}
        disabled
      />
    );
  }

  return (
    <Suspense>
      <SearchableAgencyContacts
        selectedAgency={selectedAgency}
      />
    </Suspense>
  );
};

interface SearchableAgencyContactsProps extends SecondaryContactSelectProps {
  selectedAgency: string;
};

export const SearchableAgencyContacts: React.FC<SearchableAgencyContactsProps> = ({
  selectedAgency,
}) => {
  const { control, formState: { errors } } = useFormContext<CampaignFormRawData>();
  const { searchAgencyContacts, agencyContacts } = useSearchAgencyContacts(selectedAgency);

  const handleSearch = async (term: string) => {
    await searchAgencyContacts(term);
  };

  return (
    <ComboboxField
      control={control}
      name="secondaryContact"
      label="Agency contact"
      placeholder="Select agency contact"
      onSearch={handleSearch}
      options={agencyContacts.map(contact => ({
        name: contact.name,
        value: contact.id,
      }))}
      error={errors.secondaryContact}
    />
  );
};
