/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiRefreshTokenMutationVariables = Types.Exact<{
  refreshToken: Types.Scalars['String']['input'];
}>;


export type ApiRefreshTokenMutation = { __typename?: 'Mutation', refreshToken: { __typename?: 'AuthToken', token: string, refreshToken: string, currentContext: { __typename?: 'AuthContext', id: string, marketId: string, marketName: string, orgId: string, role: Types.ApiRole, userId: string } } };


export const RefreshTokenDocument = gql`
    mutation RefreshToken($refreshToken: String!) {
  refreshToken(refreshToken: $refreshToken) {
    token
    refreshToken
    currentContext {
      id
      marketId
      marketName
      orgId
      role
      userId
    }
  }
}
    `;
export type ApiRefreshTokenMutationFn = Apollo.MutationFunction<ApiRefreshTokenMutation, ApiRefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<ApiRefreshTokenMutation, ApiRefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiRefreshTokenMutation, ApiRefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<ApiRefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<ApiRefreshTokenMutation, ApiRefreshTokenMutationVariables>;