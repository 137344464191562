import { DefinitionList, DefinitionListItem, RoundedFrame } from '@/app/components';

export const mockedToolsList = [
  {
    id: 'idWiasdOASdkfh',
    icon: 'icon1',
    name: 'Sponsored product in category (Position 1)',
    from: '',
    to: '',
    budget: '',
    total: '',
    impressions: '263',
    views: '54',
    visibility: '39 %',
    clicks: '5',
    ctr: '1,9 %',
    state: 'Draft',
    definitionData: [
      { term: 'From', description: '24/07/2024' },
      { term: 'To', description: '24/07/2024' },
      { term: 'Budget', description: '5 000 Kč / CPM' },
      { term: 'Total', description: '5 000 Kč' },
      { term: 'Impressions:', description: '263' },
      { term: 'Views', description: '25' },
      { term: 'Visibility', description: '39 %' },
      { term: 'Clicks', description: '5' },
      { term: 'CTR', description: '1,9 %' },
    ],
    subRows: [
      {
        id: 'item1',
        name: 'Creative 1',
        from: '',
        to: '',
        budget: '',
        total: '',
        impressions: '263',
        views: '54',
        visibility: '39 %',
        clicks: '5',
        ctr: '1,9 %',
        state: 'Draft',
        definitionData: [
          { term: 'Name', description: 'Some text here' },
          { term: 'Something', description: 'Something else' },
        ],
      },
      {
        id: 'item2',
        name: 'Creative 3',
        from: '',
        to: '',
        budget: '',
        total: '',
        impressions: '263',
        views: '54',
        visibility: '39 %',
        clicks: '5',
        ctr: '1,9 %',
        state: 'Approved',
        definitionData: [
          { term: 'Name', description: 'Some text here' },
          { term: 'Something', description: 'Something else' },
        ],
      },
    ],
  },
  {
    id: 'asdasdasdd',
    icon: 'icon2',
    name: 'Banner under carousel on HP',
    from: '',
    to: '',
    budget: '',
    total: '',
    impressions: '263',
    views: '54',
    visibility: '39 %',
    clicks: '5',
    ctr: '1,9 %',
    state: 'Draft',
    definitionData: [
      { term: 'From', description: '24/07/2024' },
      { term: 'To', description: '24/07/2024' },
      { term: 'Budget', description: '5 000 Kč / CPM' },
      { term: 'Total', description: '5 000 Kč' },
      { term: 'Impressions:', description: '263' },
      { term: 'Views', description: '25' },
      { term: 'Visibility', description: '39 %' },
      { term: 'Clicks', description: '5' },
      { term: 'CTR', description: '1,9 %' },
    ],
    subRows: [
      {
        id: 'tsrasdWjdfs',
        name: 'Creative 2',
        from: '',
        to: '',
        budget: '',
        total: '',
        impressions: '263',
        views: '54',
        visibility: '39 %',
        clicks: '5',
        ctr: '1,9 %',
        state: 'Draft',
        definitionData: [
          { term: 'Name', description: 'Some text here' },
          { term: 'Something', description: 'Something else' },
        ],
      },
    ],
  },
  {
    id: 'gadjyjdjfg',
    icon: 'icon2',
    name: 'Banner in carousel on HP',
    from: '',
    to: '',
    budget: '',
    total: '',
    impressions: '263',
    views: '54',
    visibility: '39 %',
    clicks: '5',
    ctr: '1,9 %',
    state: 'Approved',
    subRows: [
    ],
    definitionData: [
      { term: 'From', description: '24/07/2024' },
      { term: 'To', description: '24/07/2024' },
      { term: 'Budget', description: '5 000 Kč / CPM' },
      { term: 'Total', description: '5 000 Kč' },
      { term: 'Impressions:', description: '263' },
      { term: 'Views', description: '25' },
      { term: 'Visibility', description: '39 %' },
      { term: 'Clicks', description: '5' },
      { term: 'CTR', description: '1,9 %' },
    ],
  },
];

export const mockStatisticsData = [
  { term: 'Impressions', description: '263' },
  { term: 'Views', description: '54' },
  { term: 'Visibility', description: '39 %' },
  { term: 'Clicks', description: '5' },
  { term: 'CTR', description: '1,9 %' },
];

export const mockedMoreContent = (definitionData: DefinitionListItem[]) => (
  <RoundedFrame>
    <DefinitionList
      data={definitionData}
      columns={3}
    />
  </RoundedFrame>
);

export const mockedCreativeMoreContent = (definitionData: DefinitionListItem[]) => (
  <div className="flex items-start space-x-4">
    <div className="rounded-xs overflow-hidden">
      <img src="https://via.placeholder.com/180x120" width={180} alt="placeholder" />
    </div>
    <div className="w-full">
      <DefinitionList
        data={definitionData}
        columns={3}
      />
    </div>
  </div>
);

export const mockedAdvertisingBox = {
  id: 'toolId',
  name: 'Sponsored product on HP',
  description: 'Some descriptive description of the tool lorem ipsum dolor sit amet',
  rateType: 'cpc' as const,
  unitPrice: 1000,
  targets: null,
  icon: '',
};

export const mockedCreativeFormatOptions = [
  { name: 'Creative Format 1', value: 'Creative Format 1', description: 'Some description of creative format...' },
  { name: 'Creative Format 2', value: 'Creative Format 2', description: 'Some description of creative format...' },
  { name: 'Creative Format 3', value: 'Creative Format 3', description: 'Some description of creative format...' },
  { name: 'Creative Format 4', value: 'Creative Format 4', description: 'Some description of creative format...' },
  { name: 'Creative Format 5', value: 'Creative Format 5', description: 'Some description of creative format...' },
];

export const mockedProductOptions = [
  { name: 'Product 1', value: 'https://example.com/product-1' },
  { name: 'Product 2', value: 'https://example.com/product-2' },
  { name: 'Product 3', value: 'https://example.com/product-3' },
  { name: 'Product 4', value: 'https://example.com/product-4' },
  { name: 'Product 5', value: 'https://example.com/product-5' },
];

export const mockedLandingPageOptions = [
  { name: 'Landing Page 1', value: 'https://example.com/landing-page-1' },
  { name: 'Landing Page 2', value: 'https://example.com/landing-page-2' },
  { name: 'Landing Page 3', value: 'https://example.com/landing-page-3' },
  { name: 'Landing Page 4', value: 'https://example.com/landing-page-4' },
  { name: 'Landing Page 5', value: 'https://example.com/landing-page-5' },
];

export const mockedCreativeFormData = [
  {
    creativeText: '',
    product: [''],
    landingPage: [''],
    customUrl: '',
    banner: '',
    id: '',
  },
];
