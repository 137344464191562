import React, { cloneElement, useEffect, useRef, useState } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { Button, CloseIcon } from '@/app/components';

export interface SidebarFiltersProps<V extends FieldValues> {
  isActive: boolean;
  children: React.ReactNode;
  onClose: () => void;
  onSubmit: (data: V) => void;
  onReset: () => void;
};

export const SidebarFilters = <V extends FieldValues>({
  isActive,
  children,
  onClose,
  onSubmit,
  onReset,
}: SidebarFiltersProps<V>) => {
  const { handleSubmit } = useFormContext<V>();

  const [hasScrollbar, setHasScrollbar] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkScrollbar = () => {
      if (contentRef.current) {
        setHasScrollbar(contentRef.current.scrollHeight > contentRef.current.clientHeight);
      }
    };

    checkScrollbar();
    window.addEventListener('resize', checkScrollbar);

    return () => {
      window.removeEventListener('resize', checkScrollbar);
    };
  }, []);

  useEffect(() => {
    if (isActive) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [isActive]);

  const handleClose = () => {
    onClose();
  };

  const backdropClass = `
    before:fixed
    before:w-screen
    before:h-screen
    before:z-10
    before:content-normal
    before:bg-white
    before:opacity-50
    before:top-0
    before:left-0
  `;

  const wrapperClass = `
    maw-w-[1920px]
    w-screen
    fixed
    w-screen
    h-screen
    left-0
    hd:left-[calc((100vw-1920px)/2)]
    top-0
    overflow-hidden
    ${isActive ? 'z-20' : '-z-10'}
  `;

  return (
    <aside className={`${wrapperClass} ${isActive ? backdropClass : ''}`}>
      <div
        className={`
          w-96
          flex
          flex-col
          bg-white
          shadow-md
          absolute
          z-20
          left-0
          top-0
          h-screen
          transition-transform
          ${isActive ? 'translate-x-0' : '-translate-x-full'}
        `}
      >
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className={`flex items-center justify-between p-6 ${hasScrollbar ? 'shadow-bottom' : ''}`}>
            <h2 className="text-2xl font-medium">Filters</h2>
            <button type="button" onClick={handleClose}>
              <span>{cloneElement(CloseIcon, { className: 'w-6 h-6' })}</span>
            </button>
          </div>

          <div className="overflow-y-auto p-6" ref={contentRef}>
            {children}
          </div>

          <div className={`flex justify-between gap-x-4 mt-auto p-6 ${hasScrollbar ? 'shadow-top' : ''}`}>
            <Button
              onClick={onReset}
              label="Cancel"
              variant="secondary"
            />
            <Button
              type="submit"
              label="Filter"
            />
          </div>
        </form>
      </div>
    </aside>
  );
};
