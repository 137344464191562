import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SwitchButton } from '@/app/components';
import { ActivityFormRawData } from './activity-form.interface';
import { CreativeBuilder } from './creative-builder';
import { CreativesFormatSelector } from './creatives-format-selector';
import { CreativesNoteField } from './creatives-note-field';
import { ActivityCreativeItem, ActivityDetail, CampaignDetail, SelectedAdvertisingTool } from '../../interfaces';

interface CreativeDetailsProps {
  campaign: CampaignDetail;
  selectedTool: SelectedAdvertisingTool;
  activity?: ActivityDetail;
};

export const CreativeDetails: React.FC<CreativeDetailsProps> = ({
  campaign,
  selectedTool,
  activity = undefined,
}) => {
  const { control, setValue, watch } = useFormContext<ActivityFormRawData>();
  const [selectedFormat, setSelectedFormat] = useState<string | null>(null);
  const [selectedCreative, setSelectedCreative] = useState<string | null>(null);
  const [creatives, setCreatives] = useState<ActivityCreativeItem[]>(activity?.creatives ?? []);
  const [creativeRequested, setCreativeRequested] = useState<boolean>(true);

  const toggleRequestCreative = () => setCreativeRequested(requested => !requested);

  const handleSelectFormat = (formatId: string) => {
    const selectedFormat = selectedTool.formatsById[formatId];
    if (!selectedFormat) {
      throw new Error(`Unknown format selected ${formatId}`);
    }

    setSelectedFormat(formatId);
  };

  const handleCreativeEditClick = (creativeId: string) => {
    setSelectedCreative(creativeId);
  };

  const handleCreativeRemoveClick = (creativeId: string) => {
    setCreatives(currentCreatives => currentCreatives.filter(creative => creative.id !== creativeId));
  };

  const handleCreativeSubmit = (creative: ActivityCreativeItem) => {
    setCreatives(currentCreatives => {
      const editMode = currentCreatives.filter(c => c.id === creative.id).length > 0;
      if (editMode) {
        return currentCreatives.map(
          currentCreative => currentCreative.id === creative.id ? creative : currentCreative,
        );
      }

      return [
        ...currentCreatives,
        creative,
      ];
    });

    setSelectedFormat(null);
    setSelectedCreative(null);
  };

  const handleCancelCreative = () => {
    setSelectedFormat(null);
    setSelectedCreative(null);
  };

  const handleSelectCreative = (creative: ActivityCreativeItem) => {
    setCreatives(currentCreatives => [
      ...currentCreatives,
      creative,
    ]);
  };

  // on each creatives update, update form value
  useEffect(() => {
    const newCreatives = creatives.map(creative => creative.id);
    setValue('creatives', newCreatives, {
      shouldDirty: true,
    });

    return () => {
      setValue('creatives', []);
    };
  }, [creatives]);

  return (
    <Controller
      control={control}
      name="creatives"
      rules={{
        required: watch('note') === null && watch('creatives').length === 0
          ? 'At least 1 creative is required.'
          : false,
      }}
      render={({ fieldState: { error } }) => (
        <>
          {creatives.length === 0 ? (
            <>
              <div className="mt-4">
                <SwitchButton
                  label="I don't want to add creative now"
                  onChange={toggleRequestCreative}
                  value={!creativeRequested}
                  name="activityForm.noCreatives"
                />
              </div>

              {!creativeRequested && <CreativesNoteField />}
            </>
          ) : (
            creatives.map(creative => (
              <CreativeBuilder
                key={creative.id}
                campaign={campaign}
                format={selectedTool.formatsById[creative.formatId]}
                creative={creative}
                editMode={creative.id === selectedCreative}
                onCancel={handleCancelCreative}
                onSubmit={handleCreativeSubmit}
                onEdit={handleCreativeEditClick}
                onDelete={handleCreativeRemoveClick}
              />
            ))
          )}

          {selectedFormat !== null && (
            <CreativeBuilder
              campaign={campaign}
              format={selectedTool.formatsById[selectedFormat]}
              editMode={true}
              onCancel={handleCancelCreative}
              onSubmit={handleCreativeSubmit}
              onEdit={handleCreativeEditClick}
              onDelete={handleCreativeRemoveClick}
            />
          )}

          {creativeRequested && (
            <CreativesFormatSelector
              formats={selectedTool.formats}
              vendorId={campaign.vendorId}
              onSelectFormat={handleSelectFormat}
              onSelectCreative={handleSelectCreative}
              error={error}
            />
          )}
        </>
      )} />
  );
};
