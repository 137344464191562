/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiCreativeItemFragment = { __typename?: 'Creative', id: string, name: string, status: Types.ApiCreativeStatus, statusNote: string | null, destinationType: Types.ApiCreativeDestinationTypes, destinationUrl: string, destinationId: string | null, format: { __typename?: 'IdentifiedCreativeFormat', id: string, name: string }, contents: Array<{ __typename?: 'CreativeField', fieldId: string, type: Types.ApiCreativeFieldType, slug: string, name: string, position: number, value: string | null, mediaValue: { __typename?: 'CreativeFieldMediaValue', id: string, name: string, size: number, mimeType: string, path: string, url: string } | null }> };

export type ApiCreativeItemContentFragment = { __typename?: 'CreativeField', fieldId: string, type: Types.ApiCreativeFieldType, slug: string, name: string, position: number, value: string | null, mediaValue: { __typename?: 'CreativeFieldMediaValue', id: string, name: string, size: number, mimeType: string, path: string, url: string } | null };

export type ApiGetCreativesQueryVariables = Types.Exact<{
  page: Types.Scalars['Int']['input'];
  limit: Types.Scalars['Int']['input'];
  vendorId: Types.InputMaybe<Types.Scalars['String']['input']>;
  formats: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  search: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ApiGetCreativesQuery = { __typename?: 'Query', creatives: { __typename?: 'CreativeResult', total: number, items: Array<{ __typename?: 'Creative', id: string, name: string, status: Types.ApiCreativeStatus, statusNote: string | null, destinationType: Types.ApiCreativeDestinationTypes, destinationUrl: string, destinationId: string | null, format: { __typename?: 'IdentifiedCreativeFormat', id: string, name: string }, contents: Array<{ __typename?: 'CreativeField', fieldId: string, type: Types.ApiCreativeFieldType, slug: string, name: string, position: number, value: string | null, mediaValue: { __typename?: 'CreativeFieldMediaValue', id: string, name: string, size: number, mimeType: string, path: string, url: string } | null }> }> } };

export const CreativeItemContentFragmentDoc = gql`
    fragment CreativeItemContent on CreativeField {
  fieldId
  type
  slug
  name
  position
  value
  mediaValue {
    id
    name
    size
    mimeType
    path
    url
  }
}
    `;
export const CreativeItemFragmentDoc = gql`
    fragment CreativeItem on Creative {
  id
  name
  status
  statusNote
  format {
    id
    name
  }
  contents {
    ...CreativeItemContent
  }
  destinationType
  destinationUrl
  destinationId
}
    ${CreativeItemContentFragmentDoc}`;
export const GetCreativesDocument = gql`
    query GetCreatives($page: Int!, $limit: Int!, $vendorId: String, $formats: [String!], $search: String) {
  creatives(
    page: $page
    limit: $limit
    vendorId: $vendorId
    formats: $formats
    search: $search
  ) {
    total
    items {
      ...CreativeItem
    }
  }
}
    ${CreativeItemFragmentDoc}`;

/**
 * __useGetCreativesQuery__
 *
 * To run a query within a React component, call `useGetCreativesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreativesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreativesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      vendorId: // value for 'vendorId'
 *      formats: // value for 'formats'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetCreativesQuery(baseOptions: Apollo.QueryHookOptions<ApiGetCreativesQuery, ApiGetCreativesQueryVariables> & ({ variables: ApiGetCreativesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiGetCreativesQuery, ApiGetCreativesQueryVariables>(GetCreativesDocument, options);
      }
export function useGetCreativesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiGetCreativesQuery, ApiGetCreativesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiGetCreativesQuery, ApiGetCreativesQueryVariables>(GetCreativesDocument, options);
        }
export function useGetCreativesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApiGetCreativesQuery, ApiGetCreativesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiGetCreativesQuery, ApiGetCreativesQueryVariables>(GetCreativesDocument, options);
        }
export type GetCreativesQueryHookResult = ReturnType<typeof useGetCreativesQuery>;
export type GetCreativesLazyQueryHookResult = ReturnType<typeof useGetCreativesLazyQuery>;
export type GetCreativesSuspenseQueryHookResult = ReturnType<typeof useGetCreativesSuspenseQuery>;
export type GetCreativesQueryResult = Apollo.QueryResult<ApiGetCreativesQuery, ApiGetCreativesQueryVariables>;