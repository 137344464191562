import { Suspense, useEffect, useState } from 'react';
import { CommonDialog, Loader } from '@/app/components';
import { AdvertisingToolsDialogDetail } from './advertising-tools-dialog-detail';
import { AdvertisingToolsDialogList } from './advertising-tools-dialog-list';

interface AdvertisingToolsDialogProps {
  isOpened: boolean;
  selectedToolId?: string;
  onClose: () => void;
  onSelect: (selectedTool: string) => void;
};

export const AdvertisingToolsDialog: React.FC<AdvertisingToolsDialogProps> = ({
  isOpened,
  selectedToolId,
  onClose,
  onSelect,
}) => {
  const [toolDetail, setToolDetail] = useState<string | null>(selectedToolId ?? null);

  const handleDetailClose = () => {
    setToolDetail(null);
  };


  // Reset state
  useEffect(() => {
    setToolDetail(selectedToolId ?? null);

    return () => {
      setToolDetail(null);
    };
  }, [selectedToolId]);

  return (
    <CommonDialog
      isOpen={isOpened}
      onClose={onClose}
      size="max"
    >
      <Suspense fallback={<DialogLoader />}>
        {toolDetail ? (
          <AdvertisingToolsDialogDetail onSelect={onSelect} onClose={handleDetailClose} toolId={toolDetail} />
        ) : (
          <AdvertisingToolsDialogList onSelect={onSelect} onOpenDetail={toolId => setToolDetail(toolId)} />
        )}
      </Suspense>
    </CommonDialog>
  );
};

export const DialogLoader = () => (
  <div className="w-full h-full flex justify-center items-center bg-black/20 absolute inset-0">
    <Loader />
  </div>
);
