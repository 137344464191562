import { ApiCurrentWorkspaceFragment } from './get-current-user.query.generated';
import { CurrentWorkspace } from '../../interfaces';

export const mapCurrentWorkspace = ({
  id,
  name,
  currency,
}: ApiCurrentWorkspaceFragment): CurrentWorkspace => ({
  id,
  name,
  currency,
});
