import { MediaType } from './upload-file.interface';
import { ApiMediaType } from '../types.generated';

export const mapRequestType = (type: MediaType): ApiMediaType => {
  switch (type) {
    case 'advertisingTool':
      return ApiMediaType.AdvertisingTool;
    case 'creative':
      return ApiMediaType.Creative;
    case 'creativeFormat':
      return ApiMediaType.CreativeFormat;

    default:
      throw new Error(`Unable to convert upload request type for unknown value ${type}.`);
  }
};
