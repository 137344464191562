import {
  DndContext,
  type DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  type UniqueIdentifier,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  ColumnDef,
  Row,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { Ref, forwardRef, useImperativeHandle, useMemo } from 'react';
import { TableHeadColumn } from './table-head-column';

export interface TableRowData<U = unknown> {
  subRows?: U[];
  moreContent?: React.ReactNode;
};

type TableProps<T extends TableRowData<U>, U = unknown> = {
  columns: ColumnDef<T>[];
  data: T[];
  getRowCanExpand?: (row: Row<T>) => boolean;
  getRowLink?: (row: T) => string;
  renderSubComponent?: (props: { row: Row<T> }) => React.ReactElement;
  children?: (props: { row: Row<T> }) => React.ReactNode | React.ReactNode[];
};

const TableInternal = <T extends TableRowData<U>, U = unknown>({
  columns,
  data,
  getRowCanExpand,
  children = () => null,
}: TableProps<T, U>, ref: Ref<unknown>) => {
  const table = useReactTable<T>({
    columns,
    data,
    getRowCanExpand,
    getSubRows: (row) => row.subRows ? (row.subRows as []) : [],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    defaultColumn: {
      size: 300,
    },
  });

  useImperativeHandle(ref, () => table, [table]);

  return (
    <div className="text-sm">
      <table className="border-spacing-y-1 border-separate w-full ">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={`tr-${headerGroup.id}`}>
              {headerGroup.headers.map((header) => <TableHeadColumn {...header} key={header.id} />)}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => children({ row }))}
        </tbody>
      </table>
    </div>
  );
};

type DraggableTablesProps<T extends TableRowData<U>, U = unknown> = (
  & TableProps<T, U>
  & {
    getRowId: (row: T) => string;
    onSort: (activeId: string, nextId: string) => void;
  }
);

const DraggableTableInternal = <T extends TableRowData<U>, U = unknown>({
  columns,
  data,
  getRowCanExpand,
  getRowId,
  onSort,
  children = () => null,
}: DraggableTablesProps<T, U>, ref: Ref<unknown>) => {
  const dataIds = useMemo<UniqueIdentifier[]>(
    () => data?.map(getRowId),
    [data],
  );

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  );

  const table = useReactTable<T>({
    columns,
    data,
    getRowCanExpand,
    getSubRows: (row) => row.subRows ? (row.subRows as []) : [],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowId,
    defaultColumn: {
      size: 300,
    },
  });

  useImperativeHandle(ref, () => table, [table]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      onSort(active.id.toString(), over.id.toString());
    }
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <div className="text-sm">
        <table className="border-spacing-y-1 border-separate w-full ">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={`tr-${headerGroup.id}`}>
                {headerGroup.headers.map((header) => <TableHeadColumn {...header} key={header.id} />)}
              </tr>
            ))}
          </thead>
          <tbody>
            <SortableContext
              items={dataIds}
              strategy={verticalListSortingStrategy}
            >
              {table.getRowModel().rows.map(row => children({ row }))}
            </SortableContext>
          </tbody>
        </table>
      </div>
    </DndContext>
  );
};

export const Table = forwardRef(TableInternal);
export const DraggableTable = forwardRef(DraggableTableInternal);
