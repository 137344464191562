import { useCallback } from 'react';
import { useSwitchContextMutation } from './switch-workspace.query.generated';
import { LoginPayload } from '../../hooks/auth/auth.interface';
import { useLazyCurrentUser } from '../get-current-user';

type UseSwitchWorkspaceHook = [
  (workspaceId: string) => Promise<LoginPayload>,
];

export const useSwitchWorkspace = (): UseSwitchWorkspaceHook => {
  const [switchContextMutation] = useSwitchContextMutation();
  const [fetchCurrentUser] = useLazyCurrentUser();

  const switchWorkspace = useCallback(async (
    workspaceId: string,
  ) => {
    const doswitchWorkspace = async () => {
      const { data, errors } = await switchContextMutation({
        variables: {
          contextId: workspaceId,
        },
      });

      // Error
      if (errors !== undefined && errors.length > 0) {
        const errorMessage = errors[0].message;
        throw new Error(errorMessage);
      }

      // No error, no data
      if (!data) {
        throw new Error('Switch workspace returned empty unexpected response.');
      }

      return data.switchContext;
    };

    const switchWorkspaceData = await doswitchWorkspace();
    const { currentUser, currentWorkspace } = await fetchCurrentUser(switchWorkspaceData.token);
    return {
      ...switchWorkspaceData,
      currentUser,
      currentWorkspace,
    };
  }, []);

  return [
    switchWorkspace,
  ];
};
