import React from 'react';

interface ContainerProps {
  children: React.ReactNode;
};

export const Container: React.FC<ContainerProps> = ({
  children,
}) => (
  <div className="flex items-center px-4 py-3">
    <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-center">
      <div>
        <nav className="isolate inline-flex" aria-label="Pagination">
          <ul className="flex space-x-1 text-slate-800 text-sm">
            {children}
          </ul>
        </nav>
      </div>
    </div>
  </div>
);
