import { useUpdateAgencyMutation } from './update-agency.query.generated';
import { AgencyFormData } from '../../interfaces';

type UseUpdateAgencyHook = {
  updateAgency: (id: string, formData: AgencyFormData) => Promise<void>;
  loading: boolean;
};

export const useUpdateAgency = (): UseUpdateAgencyHook => {
  const [agencyMutation, { loading }] = useUpdateAgencyMutation();

  const updateAgency = async (
    id: string,
    formData: AgencyFormData,
  ) => {
    const doSubmit = async () => {
      const { data, errors } = await agencyMutation({
        variables: {
          ...formData,
          id,
          billingInfo: {
            businessId: formData.businessId,
            businessName: formData.businessName,
            vatNumber: formData.vatNumber,
          },
        },
      });

      // Error
      if (errors !== undefined && errors.length > 0) {
        const errorMessage = errors[0].message;
        throw new Error(errorMessage);
      }

      // No error, no data
      if (!data) {
        throw new Error('Server returned empty unexpected response.');
      }

      return data;
    };

    await doSubmit();
  };

  return {
    updateAgency,
    loading,
  };
};
