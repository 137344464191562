import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PageContentBox } from '@/app/components';
import { useCreateCampaign } from './api';
import { CreateCampaignForm } from './components';
import { CreateCampaignFormData } from './interfaces';

export const CampaignsCreate: React.FC = () => {
  const navigate = useNavigate();
  const { createCampaign } = useCreateCampaign();

  const handleSubmit = async (data: CreateCampaignFormData) => {
    const campaign = await createCampaign(data);
    navigate(`../${campaign.id}/line-item`);
  };

  const handleCancel = () => {
    navigate('..');
  };

  return (
    <PageContentBox maxWidth="2xl">
      <header className="mb-4">
        <h1 className="text-3xl font-medium">New campaign</h1>
      </header>

      <CreateCampaignForm
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </PageContentBox>
  );
};
