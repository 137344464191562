/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiMetaQueryVariables = Types.Exact<{
  slug: Types.Scalars['String']['input'];
}>;


export type ApiMetaQuery = { __typename?: 'Query', meta: { __typename?: 'AppMetadata', id: string, locale: Types.ApiLocale, timezone: Types.ApiTimezone, currency: Types.ApiCurrency }, workspace: { __typename?: 'Workspace', locale: Types.ApiLocale, timezone: Types.ApiTimezone, currency: Types.ApiCurrency } | null, me: { __typename?: 'CurrentUser', locale: Types.ApiLocale | null, timezone: Types.ApiTimezone | null } | null };


export const MetaDocument = gql`
    query meta($slug: String!) {
  meta(slug: $slug) {
    id
    locale
    timezone
    currency
  }
  workspace {
    locale
    timezone
    currency
  }
  me {
    locale
    timezone
  }
}
    `;

/**
 * __useMetaQuery__
 *
 * To run a query within a React component, call `useMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetaQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useMetaQuery(baseOptions: Apollo.QueryHookOptions<ApiMetaQuery, ApiMetaQueryVariables> & ({ variables: ApiMetaQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiMetaQuery, ApiMetaQueryVariables>(MetaDocument, options);
      }
export function useMetaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiMetaQuery, ApiMetaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiMetaQuery, ApiMetaQueryVariables>(MetaDocument, options);
        }
export function useMetaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApiMetaQuery, ApiMetaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiMetaQuery, ApiMetaQueryVariables>(MetaDocument, options);
        }
export type MetaQueryHookResult = ReturnType<typeof useMetaQuery>;
export type MetaLazyQueryHookResult = ReturnType<typeof useMetaLazyQuery>;
export type MetaSuspenseQueryHookResult = ReturnType<typeof useMetaSuspenseQuery>;
export type MetaQueryResult = Apollo.QueryResult<ApiMetaQuery, ApiMetaQueryVariables>;