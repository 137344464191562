import { ApiCreativeFieldType } from '../api';
import { CreativeFieldType } from '../interfaces';

export const mapCreativeFieldType = (type: ApiCreativeFieldType): CreativeFieldType => {
  switch (type) {
    case ApiCreativeFieldType.Image:
      return 'image';
    case ApiCreativeFieldType.ImageConversion:
      return 'imageConversion';
    case ApiCreativeFieldType.Number:
      return 'number';
    case ApiCreativeFieldType.Product:
      return 'product';
    case ApiCreativeFieldType.Sku:
      return 'sku';
    case ApiCreativeFieldType.Text:
      return 'text';

    default:
      throw new Error(`Unable to convert creative field type for unknown value ${type}`);
  }
};

export const mapStringToCreativeFieldType = (type: string): CreativeFieldType => {
  switch (type) {
    case 'image': return 'image';
    case 'imageConversion': return 'imageConversion';
    case 'number': return 'number';
    case 'product': return 'product';
    case 'sku': return 'sku';
    case 'text': return 'text';

    default:
      throw new Error(`Unable to convert string to field type for unknown value ${type}`);
  }
};
