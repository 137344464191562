import {
  ApiCreativeStatus,
  mapCreativeFieldType,
} from '@/app/common';
import {
  ApiCreativeDetailContentFragment,
  ApiCreativeDetailFragment,
} from './get-creative-detail.query.generated';
import {
  ActivityCreativeStatus,
  CreativeContent,
  CreativeContentMedia,
  CreativeDetail,
  CreativePreviewContent,
  CreativePreviewImage,
} from '../../interfaces';

export const mapCreative = (
  creative: ApiCreativeDetailFragment,
): CreativeDetail => {
  const contents = creative.contents.map(content => mapCreativeContent(content));
  return {
    id: creative.id,
    name: creative.name,
    status: mapCreativeStatus(creative.status),
    statusNote: creative.statusNote,
    previewImages: mapPreviewImages(contents),
    previewContents: mapPreviewContents(contents),
    contents,
    destinationType: creative.destinationType.toString().toLowerCase(),
    destinationUrl: creative.destinationUrl,
    destinationId: creative.destinationId,
    formatId: creative.format.id,
  };
};

interface HasMedia {
  mediaValue: CreativeContentMedia;
};

const hasMediaPopulated = (
  content: CreativeContent,
): content is CreativeContent & HasMedia => content.mediaValue !== null;

const mapPreviewImages = (
  contents: CreativeContent[],
): CreativePreviewImage[] => {
  // filter contents of type image with populated value
  const imageContents = contents.filter(content => content.type === 'image' && content.mediaValue !== null);
  const populatedImageContents = imageContents.filter(hasMediaPopulated);

  return populatedImageContents.map(content => ({
    name: content.mediaValue.name,
    url: content.mediaValue.url,
  }));
};

const mapPreviewContents = (
  contents: CreativeContent[],
): CreativePreviewContent[] => {
  // filter contents of type text with populated value
  const textContents = contents.filter(content => content.type === 'text' && content.value !== null);
  return textContents.map(content => ({
    term: content.name,
    description: content.value ?? '',
  }));
};

const mapCreativeContent = (
  content: ApiCreativeDetailContentFragment,
): CreativeContent => ({
  fieldId: content.fieldId,
  type: mapCreativeFieldType(content.type),
  slug: content.slug,
  name: content.name,
  position: content.position,
  required: false,
  value: content.value,
  mediaValue: content.mediaValue ? {
    id: content.mediaValue.id,
    name: content.mediaValue.name,
    size: content.mediaValue.size,
    mimeType: content.mediaValue.mimeType,
    path: content.mediaValue.path,
    url: content.mediaValue.url,
  } : null,
});

const mapCreativeStatus = (status: ApiCreativeStatus): ActivityCreativeStatus => {
  switch (status) {
    case ApiCreativeStatus.Draft:
      return 'draft';
    case ApiCreativeStatus.PendingApproval:
      return 'pendingApproval';
    case ApiCreativeStatus.Approved:
      return 'approved';
    case ApiCreativeStatus.Rejected:
      return 'rejected';

    default:
      throw new Error(`Unable to map activity creative status for unknown value ${status}`);
  }
};
