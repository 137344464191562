import React from 'react';

export type TooltipPositionType = (
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
);

interface TooltipProps {
  text: string;
  position?: TooltipPositionType;
  children: React.ReactNode;
};

export const Tooltip: React.FC<TooltipProps> = ({ text, children, position = 'top' }) => {

  const getTooltipPositionClassName = () => {
    switch (position) {
      case 'bottom':
        return 'tooltip-bottom top-full left-1/2 -translate-x-1/2 mt-2';
      case 'top':
        return 'tooltip-top bottom-full left-1/2 -translate-x-1/2 mb-3';
      case 'left':
        return 'tooltip-left right-full top-1/2 -translate-y-1/2 mr-2';
      case 'right':
        return 'tooltip-right left-full top-1/2 -translate-y-1/2 ml-2';
      default:
        throw new Error(`Unknown position: ${position}`);
    }
  };

  return (
    <div className="tooltip-group group relative inline-flex">
      <div className="inline-flex">{children}</div>
      <div
        className={`
          tooltip-text
          absolute
          z-10
          px-3
          py-2
          w-max
          max-w-80
          text-sm
          text-white
          bg-gray-700
          rounded-xs
          invisible
          opacity-0
          tooltip
          group-hover:opacity-100
          group-hover:visible
          ${getTooltipPositionClassName()}
        `}
      >
        {text}
      </div>
    </div>
  );
};
