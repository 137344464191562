import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppMeta } from '@/app/common';
import { Table, TableFooter, TableRow } from '@/app/components';
import { createListColumns } from './campaign-view-list';
import { createPerformanceColumns } from './campaign-view-performance';
import { useCampaigns } from '../../api';
import { useCampaignFilters } from '../../hooks';
import { CampaignItem } from '../../interfaces';

export type CampaignsViewMode = (
  | 'list'
  | 'performance'
);

interface CampaignsListProps {
  children: React.ReactNode;
};

export const CampaignsList: React.FC<CampaignsListProps> = ({
  children,
}) => {
  const { i18n } = useTranslation();
  const { currency } = useAppMeta();
  const navigate = useNavigate();
  const { filters } = useCampaignFilters();
  const { campaigns, total, page } = useCampaigns();
  const columns = useMemo<ColumnDef<CampaignItem>[]>(
    () => filters.viewMode === 'list'
      ? createListColumns({ i18n, currency })
      : createPerformanceColumns({ i18n, currency }),
    [filters.viewMode],
  );

  const handleRowClick = (row: CampaignItem) => {
    navigate(row.id);
  };

  if (total === 0) {
    return children;
  }

  return (
    <div className="mt-4 flex flex-col space-y-2">
      <Table<CampaignItem> data={campaigns} columns={columns}>
        {({ row }) => <TableRow key={row.id} row={row} onClick={handleRowClick} />}
      </Table>
      <TableFooter
        label="campaigns"
        pagination={{
          currentPage: page,
          totalPosts: total,
          postsPerPage: 10,
        }}
      />
    </div>
  );
};
