import { useState } from 'react';
import { ApiUploadRequestFragment, useCreateUploadRequestMutation } from './create-upload-request.query.generated';
import { MediaType } from './upload-file.interface';
import { mapRequestType } from './upload-file.mapper';

export type UploadResult = {
  id: string;
  name: string;
  url: string;
};

type UseUploadFileHook = {
  uploadFile: (type: MediaType, file: File) => Promise<UploadResult>;
  loading: boolean;
  error?: string;
};

export const useUploadFile = (): UseUploadFileHook => {
  const [uploading, setUploading] = useState<boolean>(true);
  const [uploadMutation, { loading }] = useCreateUploadRequestMutation();

  const createUploadRequest = async (type: MediaType, file: File): Promise<ApiUploadRequestFragment> => {
    const { data, errors } = await uploadMutation({
      variables: {
        type: mapRequestType(type),
        name: file.name,
        size: file.size,
        mimeType: file.type,
        imageWidth: null,
        imageHeight: null,
      },
    });

    // Error
    if (errors !== undefined && errors.length > 0) {
      const errorMessage = errors[0].message;
      throw new Error(errorMessage);
    }

    // No error, no data
    if (!data) {
      throw new Error('Switch workspace returned empty unexpected response.');
    }

    return data.createMediaUploadRequest;
  };

  const uploadFileToStorage = async (file: File, signedUrl: string) => {
    await fetch(signedUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type ?? 'multipart/form-data',
      },
      body: file,
    });
  };

  const uploadFile = async (type: MediaType, file: File): Promise<UploadResult> => {
    try {
      const { requestId, url } = await createUploadRequest(type, file);

      setUploading(true);
      await uploadFileToStorage(file, url);
      return {
        id: requestId,
        name: file.name,
        url,
      };
    } catch (err: unknown) {
      if (err instanceof Error) {
        throw new Error(`Unable to upload file: ${err.message}`, {
          cause: err,
        });
      }

      throw err;
    } finally {
      setUploading(false);
    }
  };

  return {
    uploadFile,
    loading: loading || uploading || false,
  };
};
