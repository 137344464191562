import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Row } from '@tanstack/react-table';
import { CSSProperties } from 'react';
import { TableBodyColumn } from './table-body-column';
import { TableRowProps } from './table-row';
import { DragIcon } from '../icons';

export type TableDraggableRowProps<T> = (
  & TableRowProps<T>
  & {
    getRowId: (row: T) => string;
  }
);

export const DraggableTableRow = <T,>({
  row,
  onClick,
  getRowId,
  children = (row: Row<T>) => (
    <>
      {row.getVisibleCells().map((cell, index) => (
        <TableBodyColumn {...cell} index={index} key={index} />
      ))}
    </>
  ),
}: TableDraggableRowProps<T>) => {
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: getRowId(row.original),
  });

  const handleRowClick = () => {
    onClick?.(row.original);
  };

  const style: CSSProperties = {
    position: 'relative',
    zIndex: isDragging ? 1 : 0,
    transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
  };

  return (
    <tr
      className={`
        shadow-sm
        rounded-sm
        ${onClick ? 'cursor-pointer group' : ''}
      `}
      style={style}
      onClick={handleRowClick}
      ref={setNodeRef}
    >
      {children(row)}
    </tr>
  );
};

export const DraggableTableRowHandle = ({ rowId }: { rowId: string }) => {
  const { attributes, listeners } = useSortable({
    id: rowId,
  });

  return (
    // Alternatively, you could set these attributes on the rows themselves
    <button type="button" {...attributes} {...listeners}>
      {DragIcon}
    </button>
  );
};
