import { Suspense } from 'react';
import { Control, FieldValues, Path, RegisterOptions } from 'react-hook-form';
import { ComboboxField, ComboboxSearch } from '@/app/components';
import { useSearchSalesUsers } from '../../api';

interface SalesPersonFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  id?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  rules?: Omit<RegisterOptions<T>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
};

interface SalesPersonFieldInternalProps<T extends FieldValues> extends SalesPersonFieldProps<T> {
  id: string;
  label: string;
  placeholder: string;
};

export const SalesPersonField = <T extends FieldValues>({
  id = 'vendorForm.salesPerson',
  label = 'Default Sales Person',
  placeholder = 'Select sales person...',
  ...props
}: SalesPersonFieldProps<T>,
) => {
  const allProps: SalesPersonFieldInternalProps<T> = {
    ...props,
    id,
    label,
    placeholder,
  };

  return (
    <Suspense fallback={<SalesPersonFieldSkeleton {...allProps} />}>
      <SalesPersonsLoader {...allProps} />
    </Suspense>
  );
};

export const SalesPersonsLoader =
  <T extends FieldValues>(props: SalesPersonFieldInternalProps<T>) => {
    const { users, searchUsers } = useSearchSalesUsers();
    const handleSearch = (term: string) => {
      searchUsers(term);
    };

    return (
      <ComboboxField
        {...props}
        options={users.map(user => ({
          value: user.id,
          name: user.name,
        }))}
        onSearch={handleSearch}
      />
    );
  };

export const SalesPersonFieldSkeleton =
  <T extends FieldValues>(props: SalesPersonFieldInternalProps<T>) => (
    <ComboboxSearch
      {...props}
      options={[]}
      disabled
    />
  );
