import { ColumnDef } from '@tanstack/react-table';
import { i18n } from 'i18next';
import { Currency } from '@/app/common';
import { CampaignItem } from '../../interfaces';
import { CampaignStatus } from '../campaign-status';

export const createListColumns = ({
  i18n,
  currency,
}: {
  i18n: i18n;
  currency: Currency;
}): ColumnDef<CampaignItem>[] => [
  {
    accessorKey: 'name',
    header: () => <span>Name</span>,
    size: 400,
  },
  {
    accessorKey: 'salesPerson',
    header: () => <span>Sales person</span>,
    size: 200,
  },
  {
    accessorKey: 'startDate',
    header: () => <span>From</span>,
    cell: (info) => {
      const date = info.getValue<Date | null>();
      if (!date) return null;

      return new Intl.DateTimeFormat(i18n.language, {
        dateStyle: 'medium',
      }).format(date);
    },
    size: 200,
  },
  {
    accessorKey: 'endDate',
    header: () => <span>Until</span>,
    cell: (info) => {
      const date = info.getValue<Date | null>();
      if (!date) return null;

      return new Intl.DateTimeFormat(i18n.language, {
        dateStyle: 'medium',
      }).format(date);
    },
    size: 200,
  },
  {
    accessorKey: 'status',
    header: () => <span>State</span>,
    cell: ({ row }) => {
      return (
        <CampaignStatus status={row.original.status} />
      );
    },
    size: 150,
  },
  {
    accessorKey: 'price',
    header: () => <span>Price</span>,
    cell: (info) => {
      return new Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency,
      }).format(info.getValue<number>());
    },
    size: 150,
  },
];
