/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiUpdateCampaignMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  name: Types.InputMaybe<Types.Scalars['String']['input']>;
  salesPerson: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type ApiUpdateCampaignMutation = { __typename?: 'Mutation', updateCampaign: { __typename?: 'Campaign', id: string } };


export const UpdateCampaignDocument = gql`
    mutation UpdateCampaign($id: ID!, $name: String, $salesPerson: ID) {
  updateCampaign(id: $id, name: $name, salesPerson: $salesPerson) {
    id
  }
}
    `;
export type ApiUpdateCampaignMutationFn = Apollo.MutationFunction<ApiUpdateCampaignMutation, ApiUpdateCampaignMutationVariables>;

/**
 * __useUpdateCampaignMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignMutation, { data, loading, error }] = useUpdateCampaignMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      salesPerson: // value for 'salesPerson'
 *   },
 * });
 */
export function useUpdateCampaignMutation(baseOptions?: Apollo.MutationHookOptions<ApiUpdateCampaignMutation, ApiUpdateCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiUpdateCampaignMutation, ApiUpdateCampaignMutationVariables>(UpdateCampaignDocument, options);
      }
export type UpdateCampaignMutationHookResult = ReturnType<typeof useUpdateCampaignMutation>;
export type UpdateCampaignMutationResult = Apollo.MutationResult<ApiUpdateCampaignMutation>;
export type UpdateCampaignMutationOptions = Apollo.BaseMutationOptions<ApiUpdateCampaignMutation, ApiUpdateCampaignMutationVariables>;