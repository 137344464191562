import { Tool3Icon } from '@/app/components';

export const mockedCampaignsDetail = {
  name: 'My new campaign',
  advertiser: 'Lékárna.cz',
  advertiserContact: 'Václav Nejedlý',
  agency: 'Sunshine s.r.o.',
  agencyContact: 'Milena Veverková',
};

export const mockedCampaignsDetailList = [
  { term: 'Advertiser', description: mockedCampaignsDetail.advertiser },
  { term: 'Advertiser contact', description: mockedCampaignsDetail.advertiserContact },
  { term: 'Agency', description: mockedCampaignsDetail.agency },
  { term: 'Agency contact', description: mockedCampaignsDetail.agencyContact },
];

export const TargetingList = [
  { id: 'target1', name: 'Position 1' },
  { id: 'target2', name: 'Position 2' },
  { id: 'target3', name: 'Position 3' },
];

export const mockedLineItem = {
  name: 'Banner under carousel on HP',
  icon: Tool3Icon,
  category: 'Some category',
  creativeFormat: 'Some creative format',
  advertisingToolType: 'Display',
};

export const mockedLineItemDefinitionList = [
  { term: 'Category', description: mockedLineItem.category },
  { term: 'Creative format', description: mockedLineItem.creativeFormat },
  { term: 'Advertising tool type', description: mockedLineItem.advertisingToolType },
];

export const mockedCampaignsList = [
  {
    id: 'campaing-1',
    name: 'My campaign 1',
    salesPerson: 'Matyáš Nejedlý',
    from: '03.06.2024',
    until: '16.06.2024',
    state: 'Online',
    price: '5 100 Kč',
  },
  {
    id: 'campaing-2',
    name: 'My campaign 2',
    salesPerson: 'Matyáš Nejedlý',
    from: '03.06.2024',
    until: '16.06.2024',
    state: 'Ready',
    price: '5 100 Kč',
  },
  {
    id: 'campaing-3',
    name: 'My campaign 3',
    salesPerson: 'Matyáš Nejedlý',
    from: '03.06.2024',
    until: '16.06.2024',
    state: 'Online',
    price: '5 100 Kč',
  },
  {
    id: 'campaing-4',
    name: 'My campaign 4',
    salesPerson: 'Matyáš Nejedlý',
    from: '03.06.2024',
    until: '16.06.2024',
    state: 'Draft',
    price: '5 100 Kč',
  },
  {
    id: 'campaing-5',
    name: 'My campaign 5',
    salesPerson: 'Matyáš Nejedlý',
    from: '03.06.2024',
    until: '16.06.2024',
    state: 'Online',
    price: '5 100 Kč',
  },
  {
    id: 'campaing-6',
    name: 'My campaign 6',
    salesPerson: 'Matyáš Nejedlý',
    from: '03.06.2024',
    until: '16.06.2024',
    state: 'Ready',
    price: '5 100 Kč',
  },
  {
    id: 'campaing-7',
    name: 'My campaign 7',
    salesPerson: 'Matyáš Nejedlý',
    from: '03.06.2024',
    until: '16.06.2024',
    state: 'Draft',
    price: '5 100 Kč',
  },
  {
    id: 'campaing-8',
    name: 'My campaign 8',
    salesPerson: 'Matyáš Nejedlý',
    from: '03.06.2024',
    until: '16.06.2024',
    state: 'Online',
    price: '5 100 Kč',
  },
  {
    id: 'campaing-9',
    name: 'My campaign 9',
    salesPerson: 'Matyáš Nejedlý',
    from: '03.06.2024',
    until: '16.06.2024',
    state: 'Cancelled',
    price: '5 100 Kč',
  },
  {
    id: 'campaing-10',
    name: 'My campaign 10',
    salesPerson: 'Matyáš Nejedlý',
    from: '03.06.2024',
    until: '16.06.2024',
    state: 'Online',
    price: '5 100 Kč',
  },
];
