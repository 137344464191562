import { ApolloError } from '@apollo/client';
import { mapCreative } from './creative-detail.mapper';
import { useGetCreativeDetailSuspenseQuery } from './get-creative-detail.query.generated';
import { CreativeDetail } from '../../interfaces';

type UseCreativeDetailHook = {
  creative: CreativeDetail;
  refreshCreative: () => Promise<CreativeDetail>;
  error: ApolloError | undefined;
};

export const useCreativeDetail = (id: string): UseCreativeDetailHook => {
  const { data, error, refetch } = useGetCreativeDetailSuspenseQuery({
    variables: {
      id,
    },
  });

  const refreshCreative = async () => {
    const { data, error } = await refetch();
    if (error) {
      throw new Error('Unable to refresh creative detail.');
    }

    return mapCreative(data.creative);
  };

  return {
    creative: mapCreative(data.creative),
    refreshCreative,
    error,
  };
};
