/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiUpdateVendorMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  name: Types.Scalars['String']['input'];
  billingInfo: Types.ApiUpdateCompanyBillingInput;
  defaultTrafficker: Types.InputMaybe<Types.Scalars['ID']['input']>;
  defaultSalesPerson: Types.InputMaybe<Types.Scalars['ID']['input']>;
  brands: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
}>;


export type ApiUpdateVendorMutation = { __typename?: 'Mutation', updateVendor: { __typename?: 'Vendor', id: string } };


export const UpdateVendorDocument = gql`
    mutation UpdateVendor($id: ID!, $name: String!, $billingInfo: UpdateCompanyBillingInput!, $defaultTrafficker: ID, $defaultSalesPerson: ID, $brands: [String!]) {
  updateVendor(
    id: $id
    name: $name
    billingInfo: $billingInfo
    defaultTrafficker: $defaultTrafficker
    defaultSalesPerson: $defaultSalesPerson
    brands: $brands
  ) {
    id
  }
}
    `;
export type ApiUpdateVendorMutationFn = Apollo.MutationFunction<ApiUpdateVendorMutation, ApiUpdateVendorMutationVariables>;

/**
 * __useUpdateVendorMutation__
 *
 * To run a mutation, you first call `useUpdateVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVendorMutation, { data, loading, error }] = useUpdateVendorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      billingInfo: // value for 'billingInfo'
 *      defaultTrafficker: // value for 'defaultTrafficker'
 *      defaultSalesPerson: // value for 'defaultSalesPerson'
 *      brands: // value for 'brands'
 *   },
 * });
 */
export function useUpdateVendorMutation(baseOptions?: Apollo.MutationHookOptions<ApiUpdateVendorMutation, ApiUpdateVendorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiUpdateVendorMutation, ApiUpdateVendorMutationVariables>(UpdateVendorDocument, options);
      }
export type UpdateVendorMutationHookResult = ReturnType<typeof useUpdateVendorMutation>;
export type UpdateVendorMutationResult = Apollo.MutationResult<ApiUpdateVendorMutation>;
export type UpdateVendorMutationOptions = Apollo.BaseMutationOptions<ApiUpdateVendorMutation, ApiUpdateVendorMutationVariables>;