import { useFormContext } from 'react-hook-form';
import { SelectboxField } from '@/app/components';
import { CampaignFilterFormValues } from './campaign-filter.interface';
import { CAMPAIGN_STATUS } from '../../interfaces';

export const StatusField = () => {
  const { control } = useFormContext<CampaignFilterFormValues>();
  const options = [
    CAMPAIGN_STATUS.draft,
    CAMPAIGN_STATUS.incomplete,
    CAMPAIGN_STATUS.ready,
    CAMPAIGN_STATUS.online,
    CAMPAIGN_STATUS.closed,
  ].map(status => ({ name: status, value: status }));

  return (
    <SelectboxField
      control={control}
      name="status"
      options={options}
      label="Status"
      placeholder="Select from the list..."
    />
  );
};
