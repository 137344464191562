import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToolsLibraryPage } from './tools-library-list.page';

export const ToolsLibrary: React.FC = () => (
  <Routes>
    <Route index element={<ToolsLibraryPage />} />
  </Routes>
);

export default ToolsLibrary;
