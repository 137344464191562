import { createFiltersContext } from './context.factory';
import type {
  Filters,
  NormalizedFilters,
} from './filters.interface';
import {
  UseFiltersHookFn,
  createUseFilters,
} from './hook.factory';
import { FiltersProviderOptions, createFiltersProvider } from './provider.factory';

type CreateFiltersResult<
  F extends Filters,
  N extends NormalizedFilters
> = {
  FiltersProvider: React.FC<React.PropsWithChildren>;
  useFilters: UseFiltersHookFn<F, N>;
};

export type { SetFilters } from './filters.interface';

export const createFilters = <F extends Filters, N extends NormalizedFilters = F>(
  options: FiltersProviderOptions<F, N>,
): CreateFiltersResult<F, N> => {
  const context = createFiltersContext<F, N>();
  const provider = createFiltersProvider<F, N>(context, options);
  const useFilters = createUseFilters<F, N>(context);

  return {
    FiltersProvider: provider,
    useFilters,
  };
};
