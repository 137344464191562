import { ApolloError } from '@apollo/client';
import { TOOL_ICONS, mapCreativeFieldType, mapRateType } from '@/app/common';
import { useSelectAdvertisingToolSuspenseQuery } from './get-advertising-tool.query.generated';
import { CreativeFormat, CreativeFormatField, SelectedAdvertisingTool } from '../../interfaces';

type UseAdvertisingToolHook = {
  advertisingTool: SelectedAdvertisingTool;
  error: ApolloError | undefined;
};

export const useAdvertisingTool = (toolId: string): UseAdvertisingToolHook => {
  const {
    error,
    data: {
      advertisingTool,
    },
  } = useSelectAdvertisingToolSuspenseQuery({
    variables: {
      id: toolId,
    },
  });

  const formats: CreativeFormat[] = advertisingTool.creativeFormats.map(format => {
    const fields: CreativeFormatField[] = format.fields.map(field => ({
      id: field.id,
      type: mapCreativeFieldType(field.type),
      position: field.position,
      slug: field.slug,
      name: field.name,
      required: field.required,

      minLength: field.options.minLength,
      maxLength: field.options.maxLength,
      numberFormat: field.options.numberFormat,
      min: field.options.min,
      max: field.options.max,
      maxFileSize: field.options.maxFileSize,
    }));

    const fieldsById = fields.reduce<Record<string, CreativeFormatField>>((stack, current) => ({
      ...stack,
      [current.id]: current,
    }), {});

    return {
      id: format.id,
      name: format.name,
      description: format.description,
      fields,
      fieldsById,
    };
  });

  const formatsById = formats.reduce<Record<string, CreativeFormat>>((stack, current) => ({
    ...stack,
    [current.id]: current,
  }), {});

  return {
    advertisingTool: {
      id: advertisingTool.id,
      name: advertisingTool.name,
      description: advertisingTool.description,
      icon: advertisingTool.icon ? TOOL_ICONS[advertisingTool.icon] : null,
      toolType: advertisingTool.type,
      rateType: mapRateType(advertisingTool.config.rateType),
      unitPrice: advertisingTool.config.price,
      auction: advertisingTool.config.auction,
      minimalBudget: advertisingTool.config.minimalBudget,
      autoPauseFlights: advertisingTool.features.autoPauseFlights ?? false,
      checkTargettingAvailability: advertisingTool.features.checkTargettingAvailability ?? false,
      useDefaultPositionValue: advertisingTool.features.useDefaultPositionValue ?? false,
      minimalDaysBeforeStart: advertisingTool.features.minimalDaysBeforeStart ?? 0,
      categories: advertisingTool.categories.map(category => category.name),
      targets: advertisingTool.targets.map(target => ({
        id: target.id,
        name: target.name,
        searchable: target.searchable,
        valueLimit: target.valueLimit,
        multiple: false,
        initialValues: target.initialValues.map(value => ({
          id: value.id,
          value: value.value,
        })),
        values: target.values.map(value => ({
          id: value.id,
          value: value.value,
        })),
      })),
      formats,
      formatsById,
      previewImage: advertisingTool.previewImage && {
        id: advertisingTool.previewImage.id,
        name: advertisingTool.previewImage.name,
        url: advertisingTool.previewImage.url,
        width: advertisingTool.previewImage.meta.imageWidth,
        height: advertisingTool.previewImage.meta.imageHeight,
      } || null,
    },
    error,
  };
};
