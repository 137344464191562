import React, { createContext, useContext } from 'react';
import { useContexts } from '../../api';
import { AuthContext } from '../../interfaces';

type AuthContextsContextType = {
  contexts: AuthContext[];
  refreshContexts: () => Promise<void>;
};

const AuthContextsContext = createContext<AuthContextsContextType | null>(null);

export const AuthContextsProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { contexts, refreshContexts } = useContexts();

  return (
    <AuthContextsContext.Provider value={{ contexts, refreshContexts }}>
      {children}
    </AuthContextsContext.Provider>
  );
};

export const useAuthContexts = () => {
  const context = useContext(AuthContextsContext);
  if (!context) {
    throw new Error('useAuthContexts must be used within a AuthContextsProvider');
  }

  return context;
};
