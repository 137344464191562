import React from 'react';
import { Can } from '@/app/common';
import {
  AdminIcon,
  Logo,
  Navigation,
  SidebarIcon,
  SwitchButton,
} from '@/app/components';

export interface SidebarProps {
  isNavCollapsed: boolean;
  setIsNavCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  adminModeEnabled: boolean;
  switchAdminMode: () => void;
};

export const Sidebar: React.FC<SidebarProps> = ({
  isNavCollapsed,
  setIsNavCollapsed,
  adminModeEnabled,
  switchAdminMode,
}) => (
  <div
    className={`
      pt-2
      pb-4
      flex
      flex-col
      justify-between
      h-full
      pl-2
    `}
  >
    <div>
      <div className="flex items-center justify-between mb-4 pr-4">
        <Logo isNavCollapsed={isNavCollapsed} />
        {!isNavCollapsed && (
          <button
            type="button"
            className="p-2"
            aria-controls="main-sidebar"
            title="Collapse sidebar"
            onClick={() => setIsNavCollapsed(isNavCollapsed => !isNavCollapsed)}
          >
            {SidebarIcon}
          </button>
        )}
      </div>

      <Can I="manage" an="organization">
        <div className={`flex items-center py-2.5 justify-between mb-2 ${isNavCollapsed ? 'px-0' : 'px-4'}`}>
          {!isNavCollapsed && (
            <span className="inline-flex gap-x-2">{AdminIcon} Admin</span>
          )}
          <SwitchButton
            name="adminModeEnabled"
            value={adminModeEnabled}
            backgroundColor="bg-white"
            onChange={switchAdminMode}
          />
        </div>
      </Can>

      <Navigation
        isNavCollapsed={isNavCollapsed}
        adminModeEnabled={adminModeEnabled}
      />
    </div>

    <div>
      {isNavCollapsed && (
        <button
          type="button"
          className="rotate-180 p-2"
          aria-controls="main-sidebar"
          title="Expand sidebar"
          onClick={() => setIsNavCollapsed(isNavCollapsed => !isNavCollapsed)}
        >
          {SidebarIcon}
        </button>
      )}
    </div>
  </div>
);
