import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Can, useAuth } from '@/app/common';
import { ChevronRightIcon } from '@/app/components';

type UserDropdownProps = {
  adminModeEnabled?: boolean;
  switchAdminMode: () => void;
};

export const UserDropdown: React.FC<UserDropdownProps> = ({
  adminModeEnabled = false,
  switchAdminMode,
}) => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const buttonClass = `
    w-full
    text-left
    leading-6
    text-sm
    hover:bg-purple-200
    py-2
    px-3
    flex
    items-center
    justify-between
  `;

  return(
    <div
      className="
        max-w-64
        min-w-52
        top-[calc(100%+0.25rem)]
        py-2
        absolute
        right-0
        z-20
        rounded-sm
        border
        border-slate-300
        bg-white
      "
    >
      <ul>
        <Can I="manage" an="organization">
          <li>
            <button className={buttonClass} onClick={switchAdminMode} type="button">
              <span className="tracking-wide text-nowrap pl-1">
                Switch to {adminModeEnabled === true ? 'User' : 'Admin'} view
              </span>
              <span className="stroke-slate-600 w-4 ml-3 inline-flex justify-center">{ChevronRightIcon}</span>
            </button>
          </li>
        </Can>
        <li>
          <button className={buttonClass} onClick={handleLogout} type="button">
            <span className="tracking-wide text-nowrap pl-1">Logout</span>
          </button>
        </li>
      </ul>
    </div>
  );
};
