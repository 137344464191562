import { useSearchParams } from 'react-router-dom';

export type UsePaginationHook = () => [number];

export const usePagination: UsePaginationHook = () => {
  const [searchParams] = useSearchParams();
  const pageNumber = Number(searchParams.get('page') ?? 1);

  return [pageNumber];
};
