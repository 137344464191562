/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiSelectAdvertisingToolQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ApiSelectAdvertisingToolQuery = { __typename?: 'Query', advertisingTool: { __typename?: 'AdvertisingTool', id: string, name: string, description: string | null, icon: Types.ApiAdvertisingToolIcon | null, type: Types.ApiAdvertisingToolType, categories: Array<{ __typename?: 'AdvertisingToolCategory', id: string, name: string }>, config: { __typename?: 'ToolConfigDto', rateType: Types.ApiAdvertisingToolRate, price: number, minimalBudget: number, auction: boolean }, features: { __typename?: 'ToolFeaturesDto', autoPauseFlights: boolean | null, minimalDaysBeforeStart: number | null, checkTargettingAvailability: boolean | null, useDefaultPositionValue: boolean | null }, creativeFormats: Array<{ __typename?: 'AdvertisingToolCreativeFormat', id: string, name: string, description: string | null, fields: Array<{ __typename?: 'CreativeFormatFieldDto', id: string, type: Types.ApiCreativeFieldType, position: number, slug: string, name: string, description: string | null, required: boolean, options: { __typename?: 'FormatFieldOptionsDto', minLength: number | null, maxLength: number | null, numberFormat: Types.ApiCreativeFormatFieldNumberFormat | null, min: number | null, max: number | null, maxFileSize: number | null } }> }>, targets: Array<{ __typename?: 'AdvertisingToolTarget', id: string, name: string, searchable: boolean, valueLimit: number, values: Array<{ __typename?: 'AdTargetValue', id: string, value: string }>, initialValues: Array<{ __typename?: 'AdTargetValue', id: string, value: string }> }>, previewImage: { __typename?: 'MediaFileDto', id: string, name: string, url: string, meta: { __typename?: 'MediaMetaDto', imageWidth: number | null, imageHeight: number | null } } | null } };


export const SelectAdvertisingToolDocument = gql`
    query SelectAdvertisingTool($id: ID!) {
  advertisingTool(id: $id) {
    id
    name
    description
    icon
    type
    categories {
      id
      name
    }
    config {
      rateType
      price
      minimalBudget
      auction
    }
    features {
      autoPauseFlights
      minimalDaysBeforeStart
      checkTargettingAvailability
      useDefaultPositionValue
    }
    creativeFormats {
      id
      name
      description
      fields {
        id
        type
        position
        slug
        name
        description
        required
        options {
          minLength
          maxLength
          numberFormat
          min
          max
          maxFileSize
        }
      }
    }
    targets {
      id
      name
      searchable
      valueLimit
      values {
        id
        value
      }
      initialValues {
        id
        value
      }
    }
    previewImage {
      id
      name
      url
      meta {
        imageWidth
        imageHeight
      }
    }
  }
}
    `;

/**
 * __useSelectAdvertisingToolQuery__
 *
 * To run a query within a React component, call `useSelectAdvertisingToolQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectAdvertisingToolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectAdvertisingToolQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSelectAdvertisingToolQuery(baseOptions: Apollo.QueryHookOptions<ApiSelectAdvertisingToolQuery, ApiSelectAdvertisingToolQueryVariables> & ({ variables: ApiSelectAdvertisingToolQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiSelectAdvertisingToolQuery, ApiSelectAdvertisingToolQueryVariables>(SelectAdvertisingToolDocument, options);
      }
export function useSelectAdvertisingToolLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiSelectAdvertisingToolQuery, ApiSelectAdvertisingToolQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiSelectAdvertisingToolQuery, ApiSelectAdvertisingToolQueryVariables>(SelectAdvertisingToolDocument, options);
        }
export function useSelectAdvertisingToolSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApiSelectAdvertisingToolQuery, ApiSelectAdvertisingToolQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiSelectAdvertisingToolQuery, ApiSelectAdvertisingToolQueryVariables>(SelectAdvertisingToolDocument, options);
        }
export type SelectAdvertisingToolQueryHookResult = ReturnType<typeof useSelectAdvertisingToolQuery>;
export type SelectAdvertisingToolLazyQueryHookResult = ReturnType<typeof useSelectAdvertisingToolLazyQuery>;
export type SelectAdvertisingToolSuspenseQueryHookResult = ReturnType<typeof useSelectAdvertisingToolSuspenseQuery>;
export type SelectAdvertisingToolQueryResult = Apollo.QueryResult<ApiSelectAdvertisingToolQuery, ApiSelectAdvertisingToolQueryVariables>;