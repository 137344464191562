export const PlusIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <path
      d="M6 1V11M11 6H1"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MinusIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="2" viewBox="0 0 12 2" fill="none">
    <path d="M11 1H1" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const ChevronRightIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="transparent"
  >
    <path
      d="M1.5 1L6.5 6L1.5 11"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ChevronDownIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
    <path d="M13.25 0.875L7 7.125L0.75 0.875" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const ChevronLeftIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M10.5 13L5.5 8L10.5 3"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const FiltersIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    {/* TODO: fix max allowed length */}
    <path
      d="M10.5 6H20.25M10.5 6C10.5 6.39782 10.342 6.77936 10.0607 7.06066C9.77936 7.34196 9.39782 7.5 9 7.5C8.60218 7.5 8.22064 7.34196 7.93934 7.06066C7.65804 6.77936 7.5 6.39782 7.5 6M10.5 6C10.5 5.60218 10.342 5.22064 10.0607 4.93934C9.77936 4.65804 9.39782 4.5 9 4.5C8.60218 4.5 8.22064 4.65804 7.93934 4.93934C7.65804 5.22064 7.5 5.60218 7.5 6M7.5 6H3.75M10.5 18H20.25M10.5 18C10.5 18.3978 10.342 18.7794 10.0607 19.0607C9.77936 19.342 9.39782 19.5 9 19.5C8.60218 19.5 8.22064 19.342 7.93934 19.0607C7.65804 18.7794 7.5 18.3978 7.5 18M10.5 18C10.5 17.6022 10.342 17.2206 10.0607 16.9393C9.77936 16.658 9.39782 16.5 9 16.5C8.60218 16.5 8.22064 16.658 7.93934 16.9393C7.65804 17.2206 7.5 17.6022 7.5 18M7.5 18H3.75M16.5 12H20.25M16.5 12C16.5 12.3978 16.342 12.7794 16.0607 13.0607C15.7794 13.342 15.3978 13.5 15 13.5C14.6022 13.5 14.2206 13.342 13.9393 13.0607C13.658 12.7794 13.5 12.3978 13.5 12M16.5 12C16.5 11.6022 16.342 11.2206 16.0607 10.9393C15.7794 10.658 15.3978 10.5 15 10.5C14.6022 10.5 14.2206 10.658 13.9393 10.9393C13.658 11.2206 13.5 11.6022 13.5 12M13.5 12H3.75"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ChevronUpDownIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M5.5 10L8 12.5L10.5 10M5.5 6L8 3.5L10.5 6"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const TicksIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4265 0.547967C14.5713 0.644513 14.6718 0.794622 14.7059 0.965277C14.7401 1.13593 14.705 1.31316 14.6085 1.45797L6.73349 13.2705C6.6796 13.3512 6.60849 13.4189 6.52526 13.4689C6.44204 13.5188 6.34878 13.5496 6.25221 13.5592C6.15563 13.5687 6.05814 13.5567 5.96675 13.524C5.87537 13.4914 5.79237 13.4388 5.72374 13.3702L0.473738 8.12022C0.357818 7.99581 0.29471 7.83127 0.29771 7.66126C0.300709 7.49125 0.369582 7.32904 0.489819 7.2088C0.610055 7.08856 0.772267 7.01969 0.94228 7.01669C1.11229 7.01369 1.27683 7.0768 1.40124 7.19272L6.08511 11.8766L13.5165 0.729092C13.6132 0.584435 13.7633 0.484093 13.934 0.450128C14.1046 0.416163 14.2818 0.451355 14.4265 0.547967Z"
      fill="currentColor"
    />
  </svg>
);

export const CalendarIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M4.5 2V3.5M11.5 2V3.5M2 12.5V5C2 4.60218 2.15804 4.22064 2.43934 3.93934C2.72064 3.65804 3.10218 3.5 3.5 3.5H12.5C12.8978 3.5 13.2794 3.65804 13.5607 3.93934C13.842 4.22064 14 4.60218 14 5V12.5M2 12.5C2 12.8978 2.15804 13.2794 2.43934 13.5607C2.72064 13.842 3.10218 14 3.5 14H12.5C12.8978 14 13.2794 13.842 13.5607 13.5607C13.842 13.2794 14 12.8978 14 12.5M2 12.5V7.5C2 7.10218 2.15804 6.72064 2.43934 6.43934C2.72064 6.15804 3.10218 6 3.5 6H12.5C12.8978 6 13.2794 6.15804 13.5607 6.43934C13.842 6.72064 14 7.10218 14 7.5V12.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const VerticalArrowsIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M5.5 10L8 12.5L10.5 10M5.5 6L8 3.5L10.5 6" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const UpArrowsIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 24 24" fill="none">
    <path d="M5 10L12 3M12 3L19 10M12 3V21" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const DownArrowsIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 24 24" fill="none">
    <path d="M19 14L12 21M12 21L5 14M12 21L12 3" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const CloseIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M4 12L12 4M4 4L12 12" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const InfoCircleIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="">
    <path fillRule="evenodd" clipRule="evenodd" d="M2.25 12C2.25 6.615 6.615 2.25 12 2.25C17.385 2.25 21.75 6.615 21.75 12C21.75 17.385 17.385 21.75 12 21.75C6.615 21.75 2.25 17.385 2.25 12ZM10.956 10.558C12.102 9.985 13.393 11.021 13.082 12.264L12.373 15.1L12.415 15.08C12.5912 15.0025 12.7905 14.9958 12.9715 15.0612C13.1526 15.1265 13.3016 15.259 13.3877 15.4312C13.4737 15.6033 13.4903 15.802 13.434 15.9861C13.3777 16.1702 13.2527 16.3255 13.085 16.42L13.045 16.442C11.898 17.015 10.607 15.979 10.918 14.736L11.628 11.9L11.586 11.92C11.4975 11.9692 11.4 11.9999 11.2994 12.0104C11.1987 12.0209 11.097 12.0109 11.0003 11.981C10.9036 11.9511 10.8139 11.902 10.7367 11.8366C10.6595 11.7711 10.5964 11.6907 10.551 11.6002C10.5057 11.5098 10.4792 11.411 10.4731 11.31C10.4669 11.209 10.4813 11.1078 10.5153 11.0124C10.5493 10.9171 10.6022 10.8297 10.6709 10.7553C10.7396 10.681 10.8226 10.6214 10.915 10.58L10.956 10.558ZM12 9C12.1989 9 12.3897 8.92098 12.5303 8.78033C12.671 8.63968 12.75 8.44891 12.75 8.25C12.75 8.05109 12.671 7.86032 12.5303 7.71967C12.3897 7.57902 12.1989 7.5 12 7.5C11.8011 7.5 11.6103 7.57902 11.4697 7.71967C11.329 7.86032 11.25 8.05109 11.25 8.25C11.25 8.44891 11.329 8.63968 11.4697 8.78033C11.6103 8.92098 11.8011 9 12 9Z" fill="currentColor"/>
  </svg>
);

export const TickCircleIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="">
    <path fillRule="evenodd" clipRule="evenodd" d="M2.25 12C2.25 6.615 6.615 2.25 12 2.25C17.385 2.25 21.75 6.615 21.75 12C21.75 17.385 17.385 21.75 12 21.75C6.615 21.75 2.25 17.385 2.25 12ZM15.61 10.186C15.67 10.1061 15.7134 10.0149 15.7377 9.91795C15.762 9.82098 15.7666 9.72014 15.7514 9.62135C15.7361 9.52257 15.7012 9.42782 15.6489 9.3427C15.5965 9.25757 15.5276 9.18378 15.4463 9.12565C15.3649 9.06753 15.2728 9.02624 15.1753 9.00423C15.0778 8.98221 14.9769 8.97991 14.8785 8.99746C14.7801 9.01501 14.6862 9.05205 14.6023 9.10641C14.5184 9.16077 14.4462 9.23135 14.39 9.314L11.154 13.844L9.53 12.22C9.38783 12.0875 9.19978 12.0154 9.00548 12.0188C8.81118 12.0223 8.62579 12.101 8.48838 12.2384C8.35097 12.3758 8.27225 12.5612 8.26882 12.7555C8.2654 12.9498 8.33752 13.1378 8.47 13.28L10.72 15.53C10.797 15.6069 10.8898 15.6662 10.992 15.7036C11.0942 15.7411 11.2033 15.7559 11.3118 15.7469C11.4202 15.738 11.5255 15.7055 11.6201 15.6519C11.7148 15.5982 11.7967 15.5245 11.86 15.436L15.61 10.186Z" fill="currentColor"/>
  </svg>
);

export const ExclamationCircleIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="">
    <path fillRule="evenodd" clipRule="evenodd" d="M2.25 12C2.25 6.615 6.615 2.25 12 2.25C17.385 2.25 21.75 6.615 21.75 12C21.75 17.385 17.385 21.75 12 21.75C6.615 21.75 2.25 17.385 2.25 12ZM12 8.25C12.1989 8.25 12.3897 8.32902 12.5303 8.46967C12.671 8.61032 12.75 8.80109 12.75 9V12.75C12.75 12.9489 12.671 13.1397 12.5303 13.2803C12.3897 13.421 12.1989 13.5 12 13.5C11.8011 13.5 11.6103 13.421 11.4697 13.2803C11.329 13.1397 11.25 12.9489 11.25 12.75V9C11.25 8.80109 11.329 8.61032 11.4697 8.46967C11.6103 8.32902 11.8011 8.25 12 8.25ZM12 16.5C12.1989 16.5 12.3897 16.421 12.5303 16.2803C12.671 16.1397 12.75 15.9489 12.75 15.75C12.75 15.5511 12.671 15.3603 12.5303 15.2197C12.3897 15.079 12.1989 15 12 15C11.8011 15 11.6103 15.079 11.4697 15.2197C11.329 15.3603 11.25 15.5511 11.25 15.75C11.25 15.9489 11.329 16.1397 11.4697 16.2803C11.6103 16.421 11.8011 16.5 12 16.5Z" fill="currentColor"/>
  </svg>
);

export const CloseCircleIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="">
    <path fillRule="evenodd" clipRule="evenodd" d="M12 2.25C6.615 2.25 2.25 6.615 2.25 12C2.25 17.385 6.615 21.75 12 21.75C17.385 21.75 21.75 17.385 21.75 12C21.75 6.615 17.385 2.25 12 2.25ZM10.28 9.22C10.2113 9.14631 10.1285 9.08721 10.0365 9.04622C9.94454 9.00523 9.84522 8.98318 9.74452 8.98141C9.64382 8.97963 9.54379 8.99816 9.4504 9.03588C9.35701 9.0736 9.27218 9.12974 9.20096 9.20096C9.12974 9.27218 9.0736 9.35701 9.03588 9.4504C8.99816 9.54379 8.97963 9.64382 8.98141 9.74452C8.98318 9.84522 9.00523 9.94454 9.04622 10.0365C9.08721 10.1285 9.14631 10.2113 9.22 10.28L10.94 12L9.22 13.72C9.14631 13.7887 9.08721 13.8715 9.04622 13.9635C9.00523 14.0555 8.98318 14.1548 8.98141 14.2555C8.97963 14.3562 8.99816 14.4562 9.03588 14.5496C9.0736 14.643 9.12974 14.7278 9.20096 14.799C9.27218 14.8703 9.35701 14.9264 9.4504 14.9641C9.54379 15.0018 9.64382 15.0204 9.74452 15.0186C9.84522 15.0168 9.94454 14.9948 10.0365 14.9538C10.1285 14.9128 10.2113 14.8537 10.28 14.78L12 13.06L13.72 14.78C13.7887 14.8537 13.8715 14.9128 13.9635 14.9538C14.0555 14.9948 14.1548 15.0168 14.2555 15.0186C14.3562 15.0204 14.4562 15.0018 14.5496 14.9641C14.643 14.9264 14.7278 14.8703 14.799 14.799C14.8703 14.7278 14.9264 14.643 14.9641 14.5496C15.0018 14.4562 15.0204 14.3562 15.0186 14.2555C15.0168 14.1548 14.9948 14.0555 14.9538 13.9635C14.9128 13.8715 14.8537 13.7887 14.78 13.72L13.06 12L14.78 10.28C14.8537 10.2113 14.9128 10.1285 14.9538 10.0365C14.9948 9.94454 15.0168 9.84522 15.0186 9.74452C15.0204 9.64382 15.0018 9.54379 14.9641 9.4504C14.9264 9.35701 14.8703 9.27218 14.799 9.20096C14.7278 9.12974 14.643 9.0736 14.5496 9.03588C14.4562 8.99816 14.3562 8.97963 14.2555 8.98141C14.1548 8.98318 14.0555 9.00523 13.9635 9.04622C13.8715 9.08721 13.7887 9.14631 13.72 9.22L12 10.94L10.28 9.22Z" fill="currentColor"/>
  </svg>
);

export const PencilIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
    <path d="M20.2678 3.73223L20.7981 3.2019V3.2019L20.2678 3.73223ZM6.50006 21.0355V21.7855C6.69897 21.7855 6.88974 21.7065 7.03039 21.5658L6.50006 21.0355ZM3.00006 21.0355H2.25006C2.25006 21.4497 2.58585 21.7855 3.00006 21.7855V21.0355ZM3.00006 17.4644L2.46973 16.9341C2.32908 17.0747 2.25006 17.2655 2.25006 17.4644H3.00006ZM17.2626 4.26256C17.946 3.57915 19.054 3.57915 19.7374 4.26256L20.7981 3.2019C19.5289 1.9327 17.4711 1.9327 16.2019 3.2019L17.2626 4.26256ZM19.7374 4.26256C20.4209 4.94598 20.4209 6.05402 19.7374 6.73744L20.7981 7.7981C22.0673 6.52889 22.0673 4.47111 20.7981 3.2019L19.7374 4.26256ZM19.7374 6.73744L5.96973 20.5051L7.03039 21.5658L20.7981 7.7981L19.7374 6.73744ZM6.50006 20.2855H3.00006V21.7855H6.50006V20.2855ZM16.2019 3.2019L2.46973 16.9341L3.53039 17.9947L17.2626 4.26256L16.2019 3.2019ZM2.25006 17.4644V21.0355H3.75006V17.4644H2.25006ZM14.7019 5.76256L18.2374 9.2981L19.2981 8.23744L15.7626 4.7019L14.7019 5.76256Z" fill="currentColor" stroke="none"/>
  </svg>
);

export const BinIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M19 7L18.1327 19.1425C18.0579 20.1891 17.187 21 16.1378 21H7.86224C6.81296 21 5.94208 20.1891 5.86732 19.1425L5 7M10 11V17M14 11V17M15 7V4C15 3.44772 14.5523 3 14 3H10C9.44772 3 9 3.44772 9 4V7M4 7H20" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" stroke="currentColor"/>
  </svg>
);

export const EditIcon = (
  <svg  xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
    <path d="M18.2678 1.73223L18.7981 1.2019V1.2019L18.2678 1.73223ZM4.50006 19.0355V19.7855C4.69897 19.7855 4.88974 19.7065 5.03039 19.5658L4.50006 19.0355ZM1.00006 19.0355H0.250061C0.250061 19.4497 0.585847 19.7855 1.00006 19.7855L1.00006 19.0355ZM1.00006 15.4644L0.469731 14.9341C0.329079 15.0747 0.250061 15.2655 0.250061 15.4644H1.00006ZM15.2626 2.26256C15.946 1.57915 17.054 1.57915 17.7374 2.26256L18.7981 1.2019C17.5289 -0.067301 15.4711 -0.067301 14.2019 1.2019L15.2626 2.26256ZM17.7374 2.26256C18.4209 2.94598 18.4209 4.05402 17.7374 4.73744L18.7981 5.7981C20.0673 4.52889 20.0673 2.47111 18.7981 1.2019L17.7374 2.26256ZM17.7374 4.73744L3.96973 18.5051L5.03039 19.5658L18.7981 5.7981L17.7374 4.73744ZM4.50006 18.2855H1.00006V19.7855H4.50006V18.2855ZM14.2019 1.2019L0.469731 14.9341L1.53039 15.9947L15.2626 2.26256L14.2019 1.2019ZM0.250061 15.4644V19.0355H1.75006V15.4644H0.250061ZM12.7019 3.76256L16.2374 7.2981L17.2981 6.23744L13.7626 2.7019L12.7019 3.76256Z"/>
  </svg>
);

export const DragIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="5.80078" y="3" width="2.80037" height="2.80023" rx="1.40012" fill="currentColor"/>
    <rect x="11.4023" y="3" width="2.80037" height="2.80023" rx="1.40012" fill="currentColor"/>
    <rect x="5.80078" y="8.60156" width="2.80037" height="2.80023" rx="1.40012" fill="currentColor"/>
    <rect x="11.4023" y="8.60156" width="2.80037" height="2.80023" rx="1.40012" fill="currentColor"/>
    <rect x="5.80078" y="14.2002" width="2.80037" height="2.80023" rx="1.40012" fill="currentColor"/>
    <rect x="11.4023" y="14.2002" width="2.80037" height="2.80023" rx="1.40012" fill="currentColor"/>
  </svg>
);

export const MagnifyingGlassIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const LocationIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M15 10.5C15 11.2956 14.6839 12.0587 14.1213 12.6213C13.5587 13.1839 12.7956 13.5 12 13.5C11.2044 13.5 10.4413 13.1839 9.87868 12.6213C9.31607 12.0587 9 11.2956 9 10.5C9 9.70435 9.31607 8.94129 9.87868 8.37868C10.4413 7.81607 11.2044 7.5 12 7.5C12.7956 7.5 13.5587 7.81607 14.1213 8.37868C14.6839 8.94129 15 9.70435 15 10.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M19.5 10.5C19.5 17.642 12 21.75 12 21.75C12 21.75 4.5 17.642 4.5 10.5C4.5 8.51088 5.29018 6.60322 6.6967 5.1967C8.10322 3.79018 10.0109 3 12 3C13.9891 3 15.8968 3.79018 17.3033 5.1967C18.7098 6.60322 19.5 8.51088 19.5 10.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const PauseIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path d="M19.5 11V21M12.5 11V21" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const PlayIcon = (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1.98044C1 1.23557 1.79795 0.763932 2.45058 1.12245L12.4924 6.64545C12.6461 6.72989 12.7743 6.85407 12.8636 7.005C12.9529 7.15593 13 7.32808 13 7.50345C13 7.67881 12.9529 7.85096 12.8636 8.00189C12.7743 8.15283 12.6461 8.277 12.4924 8.36144L2.45058 13.8845C2.30151 13.9664 2.13365 14.0081 1.96355 14.0054C1.79346 14.0028 1.62699 13.9558 1.48057 13.8692C1.33415 13.7826 1.21282 13.6593 1.12855 13.5115C1.04428 13.3638 0.999974 13.1966 1 13.0265V1.98044Z" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

export const SpinnerIcon = (
  <svg className="spinner" viewBox="0 0 50 50">
    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
  </svg>
);

export const NoRecordsIcon = (
  <svg width="156" height="173" viewBox="0 0 156 173" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_i_4077_69732)">
      <mask id="mask0_4077_69732" maskUnits="userSpaceOnUse" x="0" y="15" width="140" height="141">
        <circle cx="70" cy="85.5" r="70" fill="white"/>
      </mask>
      <g mask="url(#mask0_4077_69732)">
        <circle cx="70" cy="85.5" r="69.5" fill="#F8FAFC" stroke="#CBD5E1"/>
        <g filter="url(#filter1_d_4077_69732)">
          <rect x="22.4444" y="54.5841" width="95.1119" height="14.4711" rx="7.23556" fill="#E4E2FF"/>
          <rect x="22.4444" y="54.5841" width="95.1119" height="14.4711" rx="7.23556" stroke="#9463F8" strokeWidth="0.888889"/>
          <rect x="22.4444" y="78.6583" width="95.1114" height="14.4711" rx="7.23556" fill="#E4E2FF"/>
          <rect x="22.4444" y="78.6583" width="95.1114" height="14.4711" rx="7.23556" stroke="#9463F8" strokeWidth="0.888889"/>
          <rect x="22.4444" y="102.735" width="95.1119" height="14.4711" rx="7.23556" fill="#E4E2FF"/>
          <rect x="22.4444" y="102.735" width="95.1119" height="14.4711" rx="7.23556" stroke="#9463F8" strokeWidth="0.888889"/>
          <rect x="22.4444" y="126.201" width="95.1119" height="13.5111" rx="6.75556" fill="#E4E2FF"/>
          <rect x="22.4444" y="126.201" width="95.1119" height="13.5111" rx="6.75556" stroke="#9463F8" strokeWidth="0.888889"/>
          <rect x="22.4444" y="54.0841" width="95.1119" height="14.4711" rx="7.23556" fill="#E4E2FF"/>
          <rect x="22.4444" y="54.0841" width="95.1119" height="14.4711" rx="7.23556" stroke="#9463F8" strokeWidth="0.888889"/>
          <rect x="22.4444" y="78.1583" width="95.1114" height="14.4711" rx="7.23556" fill="#E4E2FF"/>
          <rect x="22.4444" y="78.1583" width="95.1114" height="14.4711" rx="7.23556" stroke="#9463F8" strokeWidth="0.888889"/>
          <rect x="22.4444" y="102.235" width="95.1119" height="14.4711" rx="7.23556" fill="#E4E2FF"/>
          <rect x="22.4444" y="102.235" width="95.1119" height="14.4711" rx="7.23556" stroke="#9463F8" strokeWidth="0.888889"/>
          <rect x="22.4444" y="125.701" width="95.1119" height="13.5111" rx="6.75556" fill="#E4E2FF"/>
          <rect x="22.4444" y="125.701" width="95.1119" height="13.5111" rx="6.75556" stroke="#9463F8" strokeWidth="0.888889"/>
          <rect x="22.4444" y="53.5841" width="95.1119" height="14.4711" rx="7.23556" fill="#E4E2FF"/>
          <rect x="22.4444" y="53.5841" width="95.1119" height="14.4711" rx="7.23556" stroke="#9463F8" strokeWidth="0.888889"/>
          <rect x="22.4444" y="77.6583" width="95.1114" height="14.4711" rx="7.23556" fill="#E4E2FF"/>
          <rect x="22.4444" y="77.6583" width="95.1114" height="14.4711" rx="7.23556" stroke="#9463F8" strokeWidth="0.888889"/>
          <rect x="22.4444" y="101.735" width="95.1119" height="14.4711" rx="7.23556" fill="#E4E2FF"/>
          <rect x="22.4444" y="101.735" width="95.1119" height="14.4711" rx="7.23556" stroke="#9463F8" strokeWidth="0.888889"/>
          <rect x="22.4444" y="125.201" width="95.1119" height="13.5111" rx="6.75556" fill="#E4E2FF"/>
          <rect x="22.4444" y="125.201" width="95.1119" height="13.5111" rx="6.75556" stroke="#9463F8" strokeWidth="0.888889"/>
        </g>
        <rect width="148" height="106" transform="translate(-4 49)" fill="url(#paint0_linear_4077_69732)"/>
        <circle cx="70" cy="85.5" r="69.5" stroke="#CBD5E1"/>
      </g>
      <rect x="50" y="132.5" width="40" height="40" rx="20" fill="#B095FC"/>
      <path d="M70 147.5V157.5M75 152.5H65" stroke="#1E293B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M114.404 16.7991C120 36 120 36 101.279 41.4568C120 36.0001 120 36 125.597 55.201C120 36 120 36 138.721 30.5433C120 36 120 36 114.404 16.7991Z" fill="#F2F1FF" stroke="#9463F8" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <filter id="filter0_i_4077_69732" x="0" y="0.69043" width="155.243" height="173.81" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="3.5"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_4077_69732"/>
      </filter>
      <filter id="filter1_d_4077_69732" x="18.16" y="51.2196" width="103.681" height="106.98" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1.92"/>
        <feGaussianBlur stdDeviation="1.92"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4077_69732"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4077_69732" result="shape"/>
      </filter>
      <linearGradient id="paint0_linear_4077_69732" x1="74" y1="47.5" x2="74" y2="100.5" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0"/>
        <stop offset="1" stopColor="#F8FAFC"/>
      </linearGradient>
    </defs>
  </svg>
);

export const SidebarIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
    <path d="M1 5.59091V14.4545C1 14.997 1.23705 15.5173 1.65901 15.9009C2.08097 16.2845 2.65326 16.5 3.25 16.5H16.75C17.3467 16.5 17.919 16.2845 18.341 15.9009C18.7629 15.5173 19 14.997 19 14.4545V5.59091M1 5.59091V3.54545C1 3.00297 1.23705 2.4827 1.65901 2.0991C2.08097 1.7155 2.65326 1.5 3.25 1.5H16.75C17.3467 1.5 17.919 1.7155 18.341 2.0991C18.7629 2.4827 19 3.00297 19 3.54545V5.59091" stroke="#1E293B" strokeWidth="1.08571" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.499 5.5L8.5 8.96705M8.5 8.96705L11.499 12.4341M8.5 8.96705H15.9974" stroke="#1E293B" strokeWidth="1.08571" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5 1.5V16.5" stroke="#1E293B" strokeWidth="1.08571" strokeLinecap="round"/>
  </svg>
);
