import { Suspense, useState } from 'react';
import {
  CommonDialog,
  DialogContent,
  DialogHeader,
  SwitchButton,
  TableFooter,
} from '@/app/components';
import { CreativeRow } from './creative-row';
import { useCreatives } from '../../../api';
import { CreativeFiltersProvider } from '../../../hooks';
import { ActivityCreativeItem } from '../../../interfaces';
import { CreativeFilter } from '../../creative-filter';

interface SelectCreativesDialogProps {
  isOpened: boolean,
  allowedFormats: string[];
  vendorId: string;
  onClose: () => void;
  onSelect: (creative: ActivityCreativeItem) => void;
};

export const SelectCreativesDialog: React.FC<SelectCreativesDialogProps> = ({
  isOpened,
  allowedFormats,
  vendorId,
  onClose,
  onSelect,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandAll = () => {
    setExpanded(expanded => !expanded);
  };

  return (
    <CommonDialog isOpen={isOpened} onClose={onClose} size="max">
      <DialogHeader title="Creatives" titleSize="text-3xl" />
      <DialogContent canScroll>
        <CreativeFiltersProvider>
          <div className="flex gap-x-3 mb-4 items-center">
            <CreativeFilter />

            <SwitchButton
              label="Expand all creatives"
              name="expandAll"
              id="creatives.expandAll"
              value={expanded}
              onChange={handleExpandAll}
            />
          </div>

          <div className="flex flex-col gap-y-1">
            <Suspense>
              <CreativesLoader allowedFormats={allowedFormats} vendorId={vendorId}>
                {({ creatives, total, page, limit }) => (
                  <>
                    {creatives.map(creative => (
                      <CreativeRow
                        key={creative.id}
                        creative={creative}
                        onSelect={onSelect}
                        isExpanded={expanded}
                      />
                    ))}

                    <TableFooter
                      pagination={{
                        currentPage: page,
                        postsPerPage: limit,
                        totalPosts: total,
                      }}
                      label="creatives"
                    />
                  </>
                )}
              </CreativesLoader>
            </Suspense>
          </div>
        </CreativeFiltersProvider>
      </DialogContent>
    </CommonDialog>
  );
};

interface CreativesLoaderProps {
  allowedFormats: string[];
  vendorId: string;
  children: (data: {
    creatives: ActivityCreativeItem[];
    total: number;
    page: number;
    limit: number;
  }) => React.ReactNode;
};

const CreativesLoader: React.FC<CreativesLoaderProps> = ({
  children,
  allowedFormats,
  vendorId,
}) => {
  const result = useCreatives({
    allowedFormats,
    vendorId,
  });

  return children(result);
};
