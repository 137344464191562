import React, { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  EmptySlate,
  ListContentBox,
  PageContentBox,
} from '@/app/components';
import { useActivities } from './api';
import { ActivityFilter, PaginatedActivityList } from './components';
import { useActivityFilters } from './hooks';
import { ActivityItem } from './interfaces';

export const ActivitiesListPage: React.FC = () => {
  const navigate = useNavigate();
  const { activities, total, page, limit } = useActivities();
  const { filters } = useActivityFilters();

  const handleRowClick = (row: ActivityItem) => {
    const rowLink = `/campaigns/${row.campaignId}/line-item/${row.id}`;
    navigate(rowLink);
  };

  return (
    <PageContentBox fullHeight>
      <header className="flex justify-between mb-4">
        <h1 className="text-3xl">Line Items</h1>
      </header>

      <ActivityFilter />

      <ListContentBox fullHeight>
        <Suspense>
          <PaginatedActivityList
            viewMode={filters.viewMode}
            activities={activities}
            page={page}
            total={total}
            limit={limit}
            onRowClick={handleRowClick}
            showCreatives={filters.toggleCreatives}
          >
            <EmptySlate title="Line Items" linkLabel="Create Campaign" link="/campaigns/create">
              <p>
                It seems like you haven't created any campaigns and activity yet. <br />
                Go ahead, and create one&hellip;
              </p>
            </EmptySlate>
          </PaginatedActivityList>
        </Suspense>
      </ListContentBox>
    </PageContentBox>
  );
};
