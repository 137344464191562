import React from 'react';
import { TableHeader } from '@/app/components';
import { useAgencyFilters } from '../../hooks';

export const AgencyFilter: React.FC = () => {
  const [filters, setFilters] = useAgencyFilters();

  const handleSearch = async (term: string) => {
    setFilters({
      search: term,
    });
  };

  return (
    <TableHeader
      searchValue={filters.search}
      onSearch={handleSearch}
    />
  );
};
