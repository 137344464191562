import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { MenuIcon, MenuIconsType, Submenu } from '@/app/components';
import styles from './menu-item.module.css';

export interface MenuItemProps {
  label: string;
  link: string;
  iconName?: MenuIconsType;
  isNavCollapsed?: boolean;
};

export interface MenuItemIconProps {
  iconName?: MenuIconsType;
  label: string;
};

export const MenuItem: React.FC<React.PropsWithChildren<MenuItemProps>> = ({
  children: submenu,
  label,
  link,
  iconName,
  isNavCollapsed,
}) => {
  const [showSubmenu, setShowSubmenu] = useState(true);
  const location = useLocation();
  const isItemActive = link === '/' ? location.pathname === '/' : location.pathname.startsWith(link);
  const isSelectedItemClass = isItemActive === true
    ? `bg-slate-50 rounded-s-md shadow ${styles.menuItemRounded}`
    : '';

  // Delay for CSS transition
  useEffect(() => {
    if (!isNavCollapsed && submenu !== undefined) {
      const timer = setTimeout(() => {
        setShowSubmenu(true);
      }, 300);

      return () => clearTimeout(timer);
    } else {
      setShowSubmenu(false);
    }
  }, [isNavCollapsed, submenu]);

  return (
    <li
      className={`
        text-md
        relative
        ${isSelectedItemClass}
      `}
    >
      <NavLink
        to={link}
        title={label}
        className={({ isActive }) => {
          return `
            py-2.5
            flex
            gap-x-2
            text-slate-800
            hover:text-slate-900
            whitespace-nowrap
            ${isNavCollapsed ? 'pl-2.5' : 'pl-4'}
            ${isActive ? 'font-medium' : ''}
          `;
        }}
        aria-current={isItemActive ? 'page' : undefined}
      >
        {
          isNavCollapsed
            ? <MenuItemIcon iconName={iconName} label={label} />
            : (
              <>
                <MenuItemIcon iconName={iconName} label={label} />
                {label}
              </>
            )
        }
      </NavLink>

      {submenu !== undefined && isItemActive && (
        <Submenu showSubmenu={showSubmenu}>
          {submenu}
        </Submenu>
      )}
    </li>
  );
};

export const MenuItemIcon: React.FC<MenuItemIconProps> = ({ iconName, label }) => (
  iconName !== undefined
    ? <MenuIcon name={iconName} label={label} />
    : (
      <span
        className="
          inline-flex
          items-center
          justify-center
          w-6
          h-6
          rounded-full
          text-xs
          bg-slate-300
        "
      >
        {label.substring(0, 2).toUpperCase()}
      </span>
    )
);
