/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiAgencyItemFragment = { __typename?: 'LeanAgency', id: string, name: string, billingInfo: { __typename?: 'CompanyBilling', businessName: string, businessId: string, vatNumber: string | null } };

export type ApiAgenciesQueryVariables = Types.Exact<{
  page: Types.Scalars['Int']['input'];
  limit: Types.Scalars['Int']['input'];
  search: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ApiAgenciesQuery = { __typename?: 'Query', agencies: { __typename?: 'AgencyResult', total: number, items: Array<{ __typename?: 'LeanAgency', id: string, name: string, billingInfo: { __typename?: 'CompanyBilling', businessName: string, businessId: string, vatNumber: string | null } }> } };

export const AgencyItemFragmentDoc = gql`
    fragment AgencyItem on LeanAgency {
  id
  name
  billingInfo {
    businessName
    businessId
    vatNumber
  }
}
    `;
export const AgenciesDocument = gql`
    query Agencies($page: Int!, $limit: Int!, $search: String) {
  agencies(page: $page, limit: $limit, search: $search) {
    total
    items {
      ...AgencyItem
    }
  }
}
    ${AgencyItemFragmentDoc}`;

/**
 * __useAgenciesQuery__
 *
 * To run a query within a React component, call `useAgenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgenciesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useAgenciesQuery(baseOptions: Apollo.QueryHookOptions<ApiAgenciesQuery, ApiAgenciesQueryVariables> & ({ variables: ApiAgenciesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiAgenciesQuery, ApiAgenciesQueryVariables>(AgenciesDocument, options);
      }
export function useAgenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiAgenciesQuery, ApiAgenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiAgenciesQuery, ApiAgenciesQueryVariables>(AgenciesDocument, options);
        }
export function useAgenciesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApiAgenciesQuery, ApiAgenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiAgenciesQuery, ApiAgenciesQueryVariables>(AgenciesDocument, options);
        }
export type AgenciesQueryHookResult = ReturnType<typeof useAgenciesQuery>;
export type AgenciesLazyQueryHookResult = ReturnType<typeof useAgenciesLazyQuery>;
export type AgenciesSuspenseQueryHookResult = ReturnType<typeof useAgenciesSuspenseQuery>;
export type AgenciesQueryResult = Apollo.QueryResult<ApiAgenciesQuery, ApiAgenciesQueryVariables>;