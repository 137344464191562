import { useNavigate } from 'react-router-dom';
import { appRoutes } from '@/app/app.routes';

export const useWorkspaceRedirects = () => {
  const navigate = useNavigate();
  const getUrl = (currentPath: string): string => {
    const firstMatchPath = Object.values(appRoutes).reduce<string | null>((url, routePath) => {
      const matchRoute = (path: string) => currentPath.includes(path);

      return url === null && matchRoute(routePath) ? routePath : url;
    }, null);

    return firstMatchPath ?? appRoutes.ROOT;
  };

  return () => navigate(getUrl(window.location.pathname));
};
