import { useCallback, useEffect, useState } from 'react';
import { ActionArgType, StateMachineInterface, StateType, TransitionType } from './state-machine.interface';

type UseStateMachineHook<S extends StateType, T extends TransitionType> = [
  state: StateMachineInterface<S, T>,
  updateState: (transition: T, ...data: ActionArgType[]) => void,
];

type UseStateMachineOptions<
  T extends TransitionType,
> = {
  actions: Record<T, (...data: ActionArgType[]) => Promise<void>>;
};

export const useStateMachine = <
  S extends StateType,
  T extends TransitionType,
>(
    stateMachine: StateMachineInterface<S, T>,
    options?: UseStateMachineOptions<T>,
  ): UseStateMachineHook<S, T> => {
  const [state, setState] = useState<StateMachineInterface<S, T>>(stateMachine);

  const updateState = useCallback(async (event: T, ...data: ActionArgType[]) => {
    if (options?.actions[event]) {
      await options.actions[event](...data);
    }

    const instance = state.move(event);
    setState(instance);
  }, []);

  useEffect(() => setState(stateMachine), [stateMachine.currentState]);

  return [
    state,
    updateState,
  ];
};
