import { ColumnDef } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, TableFooter, TableRow} from '@/app/components';
import { useAgencies } from '../../api';
import { AgencyItem } from '../../interfaces';

interface AgencyListProps {
  children: React.ReactNode;
};

export const AgencyList: React.FC<AgencyListProps> = ({
  children,
}) => {
  const {
    agencies,
    total,
    page,
    limit,
  } = useAgencies();
  const navigate = useNavigate();
  const columns = useMemo<ColumnDef<AgencyItem>[]>(
    () => [
      {
        accessorKey: 'name',
        cell: (info) => info.getValue(),
        header: () => <span>Name</span>,
        size: 300,
      },
      {
        accessorKey: 'id',
        cell: (info) => info.getValue(),
        header: () => <span>Id</span>,
        size: 300,
      },
      {
        accessorKey: 'businessName',
        cell: (info) => info.getValue(),
        header: () => <span>Business name</span>,
      },
      {
        accessorKey: 'vatNumber',
        header: () => <span>CID / VAT</span>,
      },
    ],
    [],
  );

  const handleRowClick = (row: AgencyItem) => navigate(row.id);

  if (total === 0) {
    return children;
  }

  return (
    <>
      <Table<AgencyItem> data={agencies} columns={columns}>
        {({ row }) => <TableRow key={row.id} row={row} onClick={handleRowClick} />}
      </Table>
      <TableFooter pagination={{ currentPage: page, postsPerPage: limit, totalPosts: total }} label="agencies" />
    </>
  );
};
