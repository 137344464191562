import { ApolloError } from '@apollo/client';
import { useRef } from 'react';
import { useLazySearch } from '@/app/common';
import { useSearchVendorsLazyQuery, useSearchVendorsSuspenseQuery } from './search-vendors.query.generated';

export interface VendorSearchItem {
  id: string;
  name: string;
};

export type UseSearchVendorsHook = {
  searchVendors: (term: string) => void;
  vendors: VendorSearchItem[];
  error: ApolloError | null;
};

export const useSearchVendors = (): UseSearchVendorsHook => {
  const initialQueryRef = useRef(useSearchVendorsSuspenseQuery());
  const { data } = initialQueryRef.current;
  const initialVendors = data.vendors.items.map(vendor => ({
    id: vendor.id,
    name: vendor.name,
  }));

  const [searchVendors] = useSearchVendorsLazyQuery();
  const { search, results, error } = useLazySearch<VendorSearchItem>({
    initialValues: initialVendors,
    onSearch: async (term: string) => {
      const result = await searchVendors({
        variables: {
          term,
        },
      });

      if (result.error) {
        throw result.error;
      }

      if (!result.data) {
        throw new Error('Empty vendors search response returned while data was expected');
      }

      return result.data.vendors.items.map(vendor => ({
        id: vendor.id,
        name: vendor.name,
      }));
    },
  });

  return {
    searchVendors: search,
    vendors: results,
    error: error,
  };
};
