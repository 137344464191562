import { createFilters } from '@/app/common';

type CreativeFilters = {
  search: string | null;
};

export const {
  FiltersProvider: CreativeFiltersProvider,
  useFilters: useCreativeFilters,
} = createFilters<CreativeFilters>({
  filterId: 'creatives',
  normalizer: (filters) => ({
    search: filters.search && filters.search.length > 0 ? filters.search : null,
  }),
});
