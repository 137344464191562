import { ApolloError } from '@apollo/client';
import { mapAgencyDetail, mapAgencyUser, mapAgencyVendor } from './agency.mapper';
import { useAgencySuspenseQuery } from './get-agency-detail.query.generated';
import { AgencyDetail, AgencyUserItem, AgencyVendorItem } from '../../interfaces';

type UseAgencyDetailHook = {
  agency: AgencyDetail;
  agencyUsersTotal: number;
  agencyUsers: AgencyUserItem[];
  agencyVendorsTotal: number;
  agencyVendors: AgencyVendorItem[];
  refreshAgency: () => Promise<AgencyDetail>;
  error?: ApolloError;
};

export const useAgencyDetail = (id: string): UseAgencyDetailHook => {
  const { data, error, refetch } = useAgencySuspenseQuery({
    variables: {
      id,
    },
  });

  const refreshAgency = async () => {
    const { data, error } = await refetch();
    if (error) {
      throw new Error('Unable to refresh agency detail.');
    }

    return mapAgencyDetail(data.agency);
  };

  return {
    agency: mapAgencyDetail(data.agency),
    agencyUsersTotal: data.agency.users.total,
    agencyUsers: data.agency.users.items.map(mapAgencyUser),
    agencyVendorsTotal: data.agency.vendors.total,
    agencyVendors: data.agency.vendors.items.map(mapAgencyVendor),
    refreshAgency,
    error,
  };
};
