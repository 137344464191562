import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, ChevronRightIcon, FieldWrapper, InputField, WhiteCard } from '@/app/components';
import { SalesPersonField } from '@/app/modules/companies/components';
import { CampaignDetail, UpdateCampaignFormData } from '../../interfaces';

interface IdentifiedReference {
  name: string;
  value: string;
};

interface UpdateCampaignFormRawData {
  name: string;
  salesPerson: IdentifiedReference;
};

interface UpdateCampaignFormProps {
  campaign: CampaignDetail;
  onSubmit: (formData: UpdateCampaignFormData) => Promise<void>;
  onCancel: () => void;
};

export const UpdateCampaignForm: React.FC<UpdateCampaignFormProps> = ({
  campaign,
  onSubmit,
  onCancel,
}) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting, isValidating },
  } = useForm<UpdateCampaignFormRawData>({
    mode: 'onChange',
    defaultValues: {
      name: campaign.name,
      salesPerson: {
        name: campaign.salesPerson,
        value: campaign.salesPersonId,
      },
    },
  });

  const doSubmit = async (formData: UpdateCampaignFormRawData) => {
    await onSubmit({
      ...formData,
      salesPerson: formData.salesPerson.value,
    });
  };

  return (
    <form onSubmit={handleSubmit(doSubmit)} noValidate>
      <WhiteCard>
        <FieldWrapper>
          <InputField
            {...register('name', {
              required: 'This field is required and cannot be empty',
              maxLength: {
                value: 250,
                message: 'Field value is too long',
              },
            })}
            label="Campaign name"
            error={errors.name}
            required
          />
        </FieldWrapper>

        <FieldWrapper>
          <SalesPersonField
            control={control}
            name="salesPerson"
            rules={{
              required: 'This field is required and cannot be empty',
            }}
            required
          />
        </FieldWrapper>
      </WhiteCard>

      <div className="flex justify-between mt-8">
        <div>
          <Button label="Cancel" variant="secondary" onClick={onCancel} />
        </div>

        <div>
          <Button
            type="submit"
            label="Proceed"
            variant="primary"
            icon={ChevronRightIcon}
            iconPosition="right"
            disabled={isSubmitting || isValidating}
          />
        </div>
      </div>
    </form>
  );
};
