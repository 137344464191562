/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiSearchVendorsQueryVariables = Types.Exact<{
  term: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ApiSearchVendorsQuery = { __typename?: 'Query', vendors: { __typename?: 'VendorResult', total: number, items: Array<{ __typename?: 'LeanVendor', id: string, name: string }> } };


export const SearchVendorsDocument = gql`
    query SearchVendors($term: String) {
  vendors(page: 1, limit: 10, search: $term) {
    total
    items {
      id
      name
    }
  }
}
    `;

/**
 * __useSearchVendorsQuery__
 *
 * To run a query within a React component, call `useSearchVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchVendorsQuery({
 *   variables: {
 *      term: // value for 'term'
 *   },
 * });
 */
export function useSearchVendorsQuery(baseOptions?: Apollo.QueryHookOptions<ApiSearchVendorsQuery, ApiSearchVendorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiSearchVendorsQuery, ApiSearchVendorsQueryVariables>(SearchVendorsDocument, options);
      }
export function useSearchVendorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiSearchVendorsQuery, ApiSearchVendorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiSearchVendorsQuery, ApiSearchVendorsQueryVariables>(SearchVendorsDocument, options);
        }
export function useSearchVendorsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApiSearchVendorsQuery, ApiSearchVendorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiSearchVendorsQuery, ApiSearchVendorsQueryVariables>(SearchVendorsDocument, options);
        }
export type SearchVendorsQueryHookResult = ReturnType<typeof useSearchVendorsQuery>;
export type SearchVendorsLazyQueryHookResult = ReturnType<typeof useSearchVendorsLazyQuery>;
export type SearchVendorsSuspenseQueryHookResult = ReturnType<typeof useSearchVendorsSuspenseQuery>;
export type SearchVendorsQueryResult = Apollo.QueryResult<ApiSearchVendorsQuery, ApiSearchVendorsQueryVariables>;