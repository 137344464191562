import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ComboboxField, FieldWrapper } from '@/app/components';
import { ActivityFormRawData } from './activity-form.interface';
import { useSearchTarget } from '../../api';
import { ActivityTarget, AdvertisingTarget } from '../../interfaces';

export interface TargetSelectFieldProps {
  target: AdvertisingTarget;
  activityTarget?: ActivityTarget;
};

export const TargetSelectField: React.FC<TargetSelectFieldProps> = ({
  target,
  activityTarget,
}) => {
  const { control, formState: { errors } } = useFormContext<ActivityFormRawData>();
  const { searchTarget, values } = useSearchTarget(target, activityTarget);

  const handleSearch = async (term: string) => {
    await searchTarget(term);
  };

  return (
    <FieldWrapper>
      <ComboboxField
        control={control}
        name={`targets.${target.id}`}
        label={target.name}
        options={values.map(value => ({
          name: value.value,
          value: value.id,
        }))}
        error={errors.targets?.[target.id]}
        onSearch={handleSearch}
        multiple={target.multiple}
        rules={{
          required: 'This field is required and cannot be empty',
        }}
        required
      />
    </FieldWrapper>
  );
};
