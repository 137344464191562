import { Role } from '@/app/common';
import { UseSearchUsersHook, useSearchUsers } from './search-users.hook';

export const useSearchTraffickerUsers = (): UseSearchUsersHook => {
  return useSearchUsers([
    Role.SuperAdmin,
    Role.OrganizationAdmin,
    Role.MarketAdmin,
    Role.Retailer,
  ]);
};
