import { ColumnDef, Row } from '@tanstack/react-table';
import React, { Fragment, MouseEvent, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppMeta } from '@/app/common';
import {
  AutoDisabledButton,
  AutoDisabledButtonChildrenProps,
  BinIcon,
  Button,
  ButtonCircle,
  CloseIcon,
  DefinitionList,
  EditIcon,
  EmptySlate,
  ExpandableTableRow,
  PauseIcon,
  PlayIcon,
  RoundedFrame,
  Table,
  TableEmptyRow,
  TicksIcon,
} from '@/app/components';
import { ActivityCreativeRow } from './activity-creative-row';
import { ActivitiesViewMode } from './activity-list.interface';
import { createListColumns } from './activity-view-list';
import { createPerformanceColumns } from './activity-view-performance';
import { useActivityWorkflow } from '../../api';
import { UseActivityStatusHook, useActivityStatus } from '../../hooks';
import { ActivityCreativeItem, ActivityItem } from '../../interfaces';
import { ActivityStatus } from '../activity-status';
import { PopupNote, usePopupNote } from '../popup-note';

export interface ActivityListProps {
  viewMode?: ActivitiesViewMode;
  activities: ActivityItem[];
  showCreatives?: boolean;
  onRowClick?: (row: ActivityItem) => void;
  children?: React.ReactNode;
};

export const ActivityList: React.FC<ActivityListProps> = ({
  viewMode = 'list',
  activities,
  showCreatives = false,
  onRowClick,
  children,
}) => {
  const { i18n } = useTranslation();
  const { currency } = useAppMeta();
  const { approve, reject, request, cancel, pause, resume } = useActivityWorkflow();
  const activityStates: Record<string, UseActivityStatusHook> = activities.reduce(
    (stack, activity) => {
      const activityStatusTuple = useActivityStatus(activity.status, {
        APPROVE: () => approve(activity.id),
        REJECT: (note: string) => reject(activity.id, note),
        REQUEST: () => request(activity.id),
        CANCEL: () => cancel(activity.id),
        PAUSE: () => pause(activity.id),
        RESUME: () => resume(activity.id),
      });

      return {
        ...stack,
        [activity.id]: activityStatusTuple,
      };
    },
    {},
  );

  const getActivityState = useCallback(
    (activity: ActivityItem): UseActivityStatusHook => activityStates[activity.id],
    [activityStates],
  );
  const columns = useMemo<ColumnDef<ActivityItem>[]>(
    () => viewMode === 'list'
      ? createListColumns({ getActivityState, i18n, currency })
      : createPerformanceColumns({ getActivityState, i18n }),
    [viewMode, getActivityState],
  );

  const renderCreatives = (row: Row<ActivityItem>) => {
    if (row.original.creatives.length === 0) {
      const campaignId = row.original.campaignId;
      const activityId = row.original.id;
      const link = `/campaigns/${campaignId}/line-item/${activityId}/edit`;
      return (
        <TableEmptyRow row={row} depth={1}>
          Creatives are missing,&nbsp;
          <Link to={link} className="underline">please add a creative</Link>.
        </TableEmptyRow>
      );
    }

    return row.original.creatives.map(creative => (
      <tr key={creative.id}>
        <td colSpan={row.getVisibleCells().length}>
          <ActivityCreativeRow creative={creative} indent />
        </td>
      </tr>
    ));
  };

  if (activities.length === 0) {
    return (
      <div className="mt-4 flex flex-col space-y-2">
        <EmptySlate title="Line Items" linkLabel="Add Line Item" link="line-item">
          <p>
            There are no line items under this campaign yet&hellip;
          </p>
        </EmptySlate>
      </div>
    );
  }

  return (
    <div className="mt-4 flex flex-col space-y-2">
      <Table<ActivityItem, ActivityCreativeItem>
        data={activities}
        columns={columns}
      >
        {({ row }) => {
          const dateFormatter = new Intl.DateTimeFormat(i18n.language, { dateStyle: 'long' });
          const priceFormatter = new Intl.NumberFormat(i18n.language, { style: 'currency', currency });
          const numberFormatter = new Intl.NumberFormat(i18n.language, { style: 'percent' });
          const activity = row.original;
          const activityStatusTuple = activityStates[activity.id];

          return (
            <Fragment key={row.id}>
              <ExpandableTableRow
                key={row.id}
                row={row}
                onClick={onRowClick}
                placeholder={
                  <ActivityRowPlaceholder activity={activity} activityStateMachine={activityStatusTuple} />
                }
              >
                <div className="mb-3">
                  <ActivityStatus status={activityStatusTuple[0].currentState} />
                </div>

                <RoundedFrame>
                  <DefinitionList
                    data={[
                      { term: 'From', description: dateFormatter.format(activity.startDate) },
                      { term: 'To', description: activity.endDate ? dateFormatter.format(activity.endDate) : 'not set' },
                      { term: 'Budget', description: `${priceFormatter.format(activity.unitPrice)} / ${activity.rateType}` },
                      { term: 'Total', description: priceFormatter.format(activity.totalPrice) },
                      { term: 'Impressions:', description: activity.metrics.impressions.toString() },
                      { term: 'Views', description: activity.metrics.views.toString() },
                      { term: 'Visibility', description: numberFormatter.format(activity.metrics.visibility / 100) },
                      { term: 'Clicks', description: activity.metrics.clicks.toString() },
                      { term: 'CTR', description: numberFormatter.format(activity.metrics.ctr / 100) },
                      { term: 'OSP', description: numberFormatter.format(activity.metrics.osp / 100) },
                    ]}
                    columns={3}
                  />
                </RoundedFrame>
              </ExpandableTableRow>

              {showCreatives && renderCreatives(row)}
            </Fragment>
          );
        }}
      </Table>
      {children}
    </div>
  );
};

interface ActivityRowPlaceholderProps {
  activity: ActivityItem;
  activityStateMachine: UseActivityStatusHook;
};

const ActivityRowPlaceholder: React.FC<ActivityRowPlaceholderProps> = ({
  activity,
  activityStateMachine,
}) => {
  const rejectButtonRef = useRef<HTMLDivElement>(null);
  const { popupNoteId, openPopupNote, closePopupNote } = usePopupNote();
  const [activityStatus, triggerWorkflow] = activityStateMachine;

  const handleSubmitRejection = async (note: string) => {
    await triggerWorkflow('REJECT', note);
    closePopupNote();
  };

  const TogglePauseButton = ({
    disabled,
    onClick,
  }: AutoDisabledButtonChildrenProps) => {
    const handlePause = (
      event: MouseEvent<HTMLButtonElement>,
      onClickCallback: (event: MouseEvent<HTMLButtonElement>) => void,
    ) => {
      event.stopPropagation();
      onClickCallback(event);
      triggerWorkflow('PAUSE');
    };

    const handleResume = (
      event: MouseEvent<HTMLButtonElement>,
      onClickCallback: (event: MouseEvent<HTMLButtonElement>) => void,
    ) => {
      event.stopPropagation();
      onClickCallback(event);
      triggerWorkflow('RESUME');
    };

    if (activityStatus.can('PAUSE')) {
      return (
        <ButtonCircle
          variant="tertiary"
          label="Pause"
          icon={PauseIcon}
          onClick={event => handlePause(event, onClick)}
          disabled={disabled}
          loading={disabled}
        />
      );
    } else if (activityStatus.can('RESUME')) {
      return (
        <ButtonCircle
          variant="tertiary"
          label="Resume"
          icon={PlayIcon}
          onClick={event => handleResume(event, onClick)}
          disabled={disabled}
          loading={disabled}
        />
      );
    }

    return null;
  };

  return (
    <div className="flex items-center gap-x-4">
      {activityStatus.can('APPROVE') && (
        <div>
          <Button
            size="sm"
            label="Approve"
            variant="confirm"
            icon={TicksIcon}
            onClick={e => {
              e.stopPropagation();
              triggerWorkflow('APPROVE');
            }}
          />
        </div>
      )}

      {activityStatus.can('REJECT') && (
        <div ref={rejectButtonRef}>
          <Button
            size="sm"
            label="Disapprove"
            variant="decline"
            icon={CloseIcon}
            onClick={e => {
              e.stopPropagation();
              openPopupNote(activity.id);
            }}
          />
        </div>
      )}

      {activityStatus.can('REQUEST') && (
        <div>
          <Button
            size="sm"
            label="Send for approval"
            variant="secondary"
            onClick={e => {
              e.stopPropagation();
              triggerWorkflow('REQUEST');
            }}
          />
        </div>
      )}

      <AutoDisabledButton duration={5}>
        {buttonProps => <TogglePauseButton {...buttonProps} />}
      </AutoDisabledButton>

      {activityStatus.can('CANCEL') && (
        <div>
          <Button
            size="sm"
            label="Cancel"
            variant="quinary"
            onClick={e => {
              e.stopPropagation();
              triggerWorkflow('CANCEL');
            }}
          />
        </div>
      )}

      <button className="text-purple-600" type="button">
        {EditIcon}
      </button>

      <button className="text-purple-600" type="button">
        {BinIcon}
      </button>

      {popupNoteId === activity.id && (
        <PopupNote
          actionRef={rejectButtonRef}
          position="bottom"
          onClose={closePopupNote}
          onSubmit={handleSubmitRejection}
        />
      )}
    </div>
  );
};
