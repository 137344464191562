import { Field, Label, Switch  } from '@headlessui/react';
import React, { forwardRef } from 'react';
import { FieldValues, Path, PathValue } from 'react-hook-form';

export interface SwitchProps<T extends FieldValues> {
  name: Path<T>;
  id?: string;
  label?: string;
  labelPosition?: 'left' | 'right';
  value?: PathValue<T, Path<T>>;
  disabled?: boolean;
  isFullWidth?: boolean;
  backgroundColor?: string;
  onChange?: (value: boolean) => void;
};

export const SwitchButton = forwardRef<HTMLDivElement, SwitchProps<FieldValues>>(({
  label,
  labelPosition = 'right',
  id,
  value = false,
  isFullWidth = false,
  backgroundColor = 'bg-slate-200',
  onChange,
}, ref) => {
  const handleSwitchChange = (value: boolean) => {
    onChange?.(value);
  };

  const handleLabelClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    onChange?.(!value);
  };

  return (
    <div className="flex items-center" ref={ref}>
      <Field as="div">
        <div
          className={`
            flex
            items-center
            gap-x-3
            ${labelPosition === 'right' ? 'flex-row' : 'flex-row-reverse'}
            ${isFullWidth ? 'w-full justify-between' : ''}
          `}
        >
          <Switch
            checked={value}
            id={id}
            onChange={(value) => handleSwitchChange(value)}
            className={`
              group
              inline-flex
              h-6
              w-11
              items-center
              rounded-full
              bg-gray-200
              transition
              ${value ? 'bg-purple-200' : backgroundColor}
            `}
          >
            <span
              className={`
                inline-block
                h-4
                w-4
                transform
                rounded-full
                translate-x-1
                ${value ? 'translate-x-6 bg-purple-600' : 'translate-x-1 bg-slate-500'}
              `}
            />
          </Switch>
          {label !== undefined && (
            <Label
              className="text-sm text-gray-900 data-[disabled]:opacity-50"
              htmlFor={id}
              onClick={handleLabelClick}
            >
              {label}
            </Label>
          )}
        </div>
      </Field>
    </div>
  );
});
