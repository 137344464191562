import { TOOL_ICONS, mapRateType } from '@/app/common';
import { useAdvertisingLibrarySuspenseQuery } from './get-advertising-library.query.generated';
import { AdvertisingCategory, AdvertisingTool } from '../../interfaces';

interface UseAdvertisingLibraryHook extends AdvertisingLibraryData {};

interface AdvertisingLibraryData {
  categories: AdvertisingCategory[];
  toolsByCategory: Record<string, AdvertisingTool[]>;
  tools: AdvertisingTool[];
};

export const useAdvertisingLibrary = (): UseAdvertisingLibraryHook => {
  const { data } = useAdvertisingLibrarySuspenseQuery();

  const {
    categories,
    tools,
    toolsByCategory,
  } = data.advertisingLibrary.reduce<AdvertisingLibraryData>((stack, current) => {
    const category: AdvertisingCategory = {
      id: current.id,
      name: current.name,
    };

    const categoryTools: AdvertisingTool[] = current.tools.map(tool => ({
      id: tool.id,
      name: tool.name,
      description: tool.description,
      icon: tool.icon ? TOOL_ICONS[tool.icon] : null,
      rateType: mapRateType(tool.rateType),
      unitPrice: tool.unitPrice,
      targets: tool.targets.length > 0
        ? tool.targets.map(target => target.name).join(', ')
        : null,
    }));

    return {
      categories: [
        ...stack.categories,
        category,
      ],
      tools: [
        ...stack.tools,
        ...categoryTools,
      ],
      toolsByCategory: {
        ...stack.toolsByCategory,
        [category.id]: categoryTools,
      },
    };
  }, {
    categories: [],
    tools: [],
    toolsByCategory: {},
  });

  return {
    categories,
    tools,
    toolsByCategory,
  };
};
