import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useAdminMode } from '@/app/common';
import { AdminMode, Header, Sidebar } from '@/app/components';
import styles from './layout.module.css';

export const MainLayout: React.FC = () => {
  const [isNavCollapsed, setIsNavCollapsed] = useState<boolean>(false);
  const [adminModeEnabled, switchAdminMode] = useAdminMode();

  const adminModeClasses = `
    border-2
    border-solid
    border-purple-600
  `;

  return (
    <div
      className={`
        relative
        z-10
        ${styles.layout}
      `}
    >
      <div
        className={`
          max-w-[1920px]
          mx-auto
          shadow-body
          relative
          ${adminModeEnabled ? adminModeClasses : ''}
        `}
      >
        {adminModeEnabled &&
          <AdminMode isNavCollapsed={isNavCollapsed} />
        }

        <div
          className={`
            flex
            min-h-[calc(100vh-0.25rem)]
            ${adminModeEnabled ? 'py-1.5 pr-1.5 pl-0.5' : 'py-2 pr-2 pl-1'}
          `}
        >
          <aside
            id="main-sidebar"
            aria-expanded={!isNavCollapsed}
            className={`
              transition-[width]
              duration-300
              flex-shrink-0
              overflow-hidden
              sticky
              top-0
              z-0
              ${isNavCollapsed ? 'w-16' : 'w-52'}
            `}
          >
            <Sidebar
              isNavCollapsed={isNavCollapsed}
              setIsNavCollapsed={setIsNavCollapsed}
              adminModeEnabled={adminModeEnabled}
              switchAdminMode={switchAdminMode}
            />
          </aside>
          <main className="flex-1 rounded-sm bg-slate-50 py-4 px-6">
            <div className="max-w-6xl mx-auto h-full flex flex-col">
              <Header adminModeEnabled={adminModeEnabled} switchAdminMode={switchAdminMode} />
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};
