import type {
  StateMachineConfig,
  StateMachineInterface,
  StateType,
  TransitionMap,
  TransitionType,
} from './state-machine.interface';

export class CruxoStateMachine<
  S extends StateType,
  T extends TransitionType,
> implements StateMachineInterface<S, T> {

  public currentState: S;
  private readonly transitionMap: TransitionMap<S, T>;

  constructor(
    private readonly config: StateMachineConfig<S, T>,
  ) {
    this.currentState = this.config.initial;
    this.transitionMap = this.config.transitions.reduce<TransitionMap<S, T>>(
      (stack, transition) => ({
        ...stack,
        [transition.from]: {
          ...stack[transition.from],
          [transition.name]: transition,
        },
      }),
      {},
    );
  }

  can(event: T): boolean {
    return (this.transitionMap[this.currentState] ?? this.transitionMap['*'])?.[event] !== undefined;
  }

  move(event: T): this {
    const newState = (this.transitionMap[this.currentState] ?? this.transitionMap['*'])?.[event]?.to;
    if (!newState) {
      return this;
    }

    this.currentState = newState;
    return this;
  }
}
