import { ComboboxOptions } from '../combobox';
import { SelectboxOptions } from '../selectbox';

type GetSelectboxButtonValueParams = {
  multiple: boolean;
  selectedOptions: SelectboxOptions[];
  placeholder: string;
};

/**
 * Returns the text for the Selectbox button based on the given options.
 *
 * @param params - The function parameters.
 * @param params.multiple - Specifies if multiple options can be selected.
 * @param params.selectedOptions - The currently selected options.
 * @param params.placeholder - The text to display when no options are selected.
 * @returns The text to display on the Selectbox button.
 */

export const getSelectboxButtonValue = ({
  multiple,
  selectedOptions,
  placeholder,
}: GetSelectboxButtonValueParams): string => {
  if (multiple && selectedOptions.length > 0) {
    return `${selectedOptions.length} selected items`;
  } else if (!multiple && selectedOptions.length > 0) {
    return selectedOptions[0].name;
  }

  return placeholder;
};

/**
 * Returns an object containing the data-value and data-name attributes for the given selected options.
 *
 * @param selectedOptions - The selected options. Can be an array of SelectboxOptions or a single SelectboxOptions.
 * @returns An object with data-value and data-name attributes.
 */
export const getDataOptionAttributes = (
  selectedOptions: SelectboxOptions | SelectboxOptions[] | null,
  multiple: boolean,
) => {
  if (!selectedOptions || (Array.isArray(selectedOptions) && selectedOptions.length === 0)) {
    return {
      'data-value': '',
      'data-name': '',
    };
  } else if (Array.isArray(selectedOptions)) {
    if (multiple) {
      return {
        'data-value': (selectedOptions ?? []).map(option => option.value),
        'data-name': (selectedOptions ?? []).map(option => option.name),
      };
    }

    return {
      'data-value': selectedOptions[0].value,
      'data-name': selectedOptions[0].name,
    };
  }

  return {
    'data-value': selectedOptions.value,
    'data-name': selectedOptions.name,
  };
};
