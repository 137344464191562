import React from 'react';
import styles from './admin-mode.module.css';

export interface AdminModeProps {
  isNavCollapsed: boolean;
};

export const AdminMode: React.FC<AdminModeProps> = ({ isNavCollapsed }) => {
  if (isNavCollapsed) {
    return null;
  }

  return (
    <div className="absolute z-10 left-11 top-0">
      <span
        className={`
            px-2
            pb-0.5
            text-xs
            relative
            block
            rounded-b-xs
            bg-purple-600
            text-white
            ${styles.adminMode}
          `}
      >
        Admin Mode
      </span>
    </div>
  );
};
