/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiCompanyUserMarketItemFragment = { __typename?: 'Market', id: string, name: string };

export type ApiGetCompanyUserMarketsQueryVariables = Types.Exact<{
  page: Types.Scalars['Int']['input'];
  limit: Types.Scalars['Int']['input'];
}>;


export type ApiGetCompanyUserMarketsQuery = { __typename?: 'Query', markets: { __typename?: 'MarketResult', total: number, items: Array<{ __typename?: 'Market', id: string, name: string }> } };

export const CompanyUserMarketItemFragmentDoc = gql`
    fragment CompanyUserMarketItem on Market {
  id
  name
}
    `;
export const GetCompanyUserMarketsDocument = gql`
    query GetCompanyUserMarkets($page: Int!, $limit: Int!) {
  markets(page: $page, limit: $limit) {
    total
    items {
      ...CompanyUserMarketItem
    }
  }
}
    ${CompanyUserMarketItemFragmentDoc}`;

/**
 * __useGetCompanyUserMarketsQuery__
 *
 * To run a query within a React component, call `useGetCompanyUserMarketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyUserMarketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyUserMarketsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetCompanyUserMarketsQuery(baseOptions: Apollo.QueryHookOptions<ApiGetCompanyUserMarketsQuery, ApiGetCompanyUserMarketsQueryVariables> & ({ variables: ApiGetCompanyUserMarketsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiGetCompanyUserMarketsQuery, ApiGetCompanyUserMarketsQueryVariables>(GetCompanyUserMarketsDocument, options);
      }
export function useGetCompanyUserMarketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiGetCompanyUserMarketsQuery, ApiGetCompanyUserMarketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiGetCompanyUserMarketsQuery, ApiGetCompanyUserMarketsQueryVariables>(GetCompanyUserMarketsDocument, options);
        }
export function useGetCompanyUserMarketsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApiGetCompanyUserMarketsQuery, ApiGetCompanyUserMarketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiGetCompanyUserMarketsQuery, ApiGetCompanyUserMarketsQueryVariables>(GetCompanyUserMarketsDocument, options);
        }
export type GetCompanyUserMarketsQueryHookResult = ReturnType<typeof useGetCompanyUserMarketsQuery>;
export type GetCompanyUserMarketsLazyQueryHookResult = ReturnType<typeof useGetCompanyUserMarketsLazyQuery>;
export type GetCompanyUserMarketsSuspenseQueryHookResult = ReturnType<typeof useGetCompanyUserMarketsSuspenseQuery>;
export type GetCompanyUserMarketsQueryResult = Apollo.QueryResult<ApiGetCompanyUserMarketsQuery, ApiGetCompanyUserMarketsQueryVariables>;