import { Row } from '@tanstack/react-table';
import React from 'react';
import { InfroStrip} from '@/app/components';

type TableEmptyRowProps<T> = {
  row: Row<T>;
  depth?: number;
  children: React.ReactNode;
};

export const TableEmptyRow = <T,>({
  row,
  depth,
  children,
}: TableEmptyRowProps<T>) => {
  return (
    <tr>
      <td colSpan={row.getVisibleCells().length}>
        <div
          className={`
            px-4
            py-3
            rounded-sm
            border
            border-dashed
            ${depth ? `ml-${depth * 6}` : ''}
          `}
        >
          <InfroStrip
            type="info"
            label={children}
          />
        </div>
      </td>
    </tr>
  );
};
