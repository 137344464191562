import React from 'react';

export type FilterItemColorType = (
  | 'blue'
  | 'green'
  | 'yellow'
  | 'red'
  | 'grey'
  | 'black'
  | 'transparent'
);

interface FilterItemProps {
  color?: FilterItemColorType;
  label: string;
  onClick?: () => void;
};

export const FilterItem: React.FC<FilterItemProps> = ({
  label,
  color = 'transparent',
  onClick,
}) => {

  const handleClick = () => {
    if (onClick !== undefined) {
      onClick();
    }
  };

  const getColorClassName = () => {
    switch (color) {
      case 'grey':
        return 'text-slate-800 bg-slate-300';
      case 'blue':
        return 'text-slate-800 bg-purple-300';
      case 'green':
        return 'text-slate-800 bg-green-300';
      case 'yellow':
        return 'text-slate-800 bg-yellow-300';
      case 'red':
        return 'text-slate-800 bg-red-300';
      case 'black':
        return 'text-white bg-slate-600';
      case 'transparent':
        return 'text-slate-800 bg-transparent';
      default:
        throw new Error(`Unknown color: ${color}`);
    }
  };

  return (
    <button
      className={`
        inline-flex
        items-center
        rounded-md
        px-3
        py-1
        leading-6
        self-center
        text-sm
        ${getColorClassName()}
      `}
      type="button"
      onClick={handleClick}
    >
      {label}
    </button>
  );
};
