import { ApolloError } from '@apollo/client';
import { mapActivityDetail } from './activity.mapper';
import { useActivityDetailSuspenseQuery } from './get-activity-detail.query.generated';
import { ActivityDetail } from '../../interfaces';

type UseActivityDetailHook = {
  activity: ActivityDetail;
  advertisingToolId: string;
  refreshActivity: () => Promise<ActivityDetail>;
  error: ApolloError | undefined;
};

export const useActivityDetail = (id: string): UseActivityDetailHook => {
  const { data, error, refetch } = useActivityDetailSuspenseQuery({
    variables: {
      id,
    },
  });

  const refreshActivity = async () => {
    const { data, error } = await refetch();
    if (error) {
      throw new Error('Unable to refresh activity detail.');
    }

    return mapActivityDetail(data.activity);
  };

  return {
    activity: mapActivityDetail(data.activity),
    advertisingToolId: data.activity.advertisingTool.id,
    refreshActivity,
    error,
  };
};
