import dayjs, { Dayjs, extend } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Timezone } from '../../interfaces';

extend(utc);
extend(timezone);

export const useLocalDate = (timezone: Timezone, time?: string): Dayjs => {
  return dayjs.tz(time, timezone.replace('_', '/'));
};
