import React, { ReactNode, createContext, useContext } from 'react';
import { DialogState, useDialog } from './use-dialog.hook';

interface DialogContextProps {
  dialogState: DialogState;
  openDialog: (dialogName: string) => void;
  closeDialog: (dialogName: string) => void;
};

const DialogContext = createContext<DialogContextProps | undefined>(undefined);

type DialogProviderProps = {
  children: ReactNode;
};

export const DialogProvider = ({ children }: DialogProviderProps) => {
  const { dialogState, openDialog, closeDialog } = useDialog();

  return (
    <DialogContext.Provider value={{ dialogState, openDialog, closeDialog }}>
      {children}
    </DialogContext.Provider>
  );
};

export const useDialogContext = () => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a DialogProvider');
  }
  return context;
};
