import { Suspense } from 'react';
import { useFormContext } from 'react-hook-form';
import { ComboboxField } from '@/app/components';
import { CampaignFormRawData } from './create-campaign-form.interface';
import { useSearchVendorContacts } from '../../api';

interface PrimaryContactSelectProps {
  selectedVendor: string | null;
};

export const PrimaryContactSelect = ({
  selectedVendor,
}: PrimaryContactSelectProps) => {
  const { control } = useFormContext<CampaignFormRawData>();

  if (!selectedVendor) {
    return (
      <ComboboxField
        control={control}
        name="primaryContact"
        label="Advertiser contact"
        placeholder="Select advertiser contact"
        options={[]}
        disabled
        required
      />
    );
  }

  return (
    <Suspense>
      <SearchableVendorContacts
        selectedVendor={selectedVendor}
      />
    </Suspense>
  );
};

interface SearchableVendorContactsProps extends PrimaryContactSelectProps {
  selectedVendor: string;
};

export const SearchableVendorContacts: React.FC<SearchableVendorContactsProps> = ({
  selectedVendor,
}) => {
  const { control, formState: { errors } } = useFormContext<CampaignFormRawData>();
  const { searchVendorContacts, vendorContacts } = useSearchVendorContacts(selectedVendor);

  const handleSearch = async (term: string) => {
    await searchVendorContacts(term);
  };

  return (
    <ComboboxField
      control={control}
      name="primaryContact"
      label="Advertiser contact"
      placeholder="Select advertiser contact"
      onSearch={handleSearch}
      options={vendorContacts.map(contact => ({
        name: contact.name,
        value: contact.id,
      }))}
      error={errors.primaryContact}
      required
    />
  );
};
