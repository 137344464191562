import React from 'react';
import { Link } from 'react-router-dom';
import { CreativeWithDestination } from '../../interfaces';

interface CreativeDestinationInfoProps {
  creative: CreativeWithDestination;
};

export const CreativeDestinationInfo: React.FC<CreativeDestinationInfoProps> = ({
  creative,
}) => (
  <>
    <span className="font-medium mr-2">{creative.destinationType}</span>
    <Link to={creative.destinationUrl} target="_blank">Show destination</Link>
  </>
);
