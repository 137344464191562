import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ActivitiesCreatePage } from './activities-create.page';
import { ActivitiesDetailPage } from './activities-detail.page';
import { ActivitiesEditPage } from './activities-edit.page';
import { CampaignsCreate } from './campaigns-create.page';
import { CampaignsDetail } from './campaigns-detail.page';
import { CampaignsEditPage } from './campaigns-edit.page';
import { CampaignsListPage } from './campaigns-list.page';
import { CampaignsSelectPage } from './campaigns-select.page';
import { PopupNoteProvider } from './components';
import { CampaignFiltersProvider } from './hooks';

export const Campaigns: React.FC = () => (
  <PopupNoteProvider>
    <CampaignFiltersProvider>
      <Routes>
        <Route index element={<CampaignsListPage />} />
        <Route path="create">
          <Route index element={<CampaignsCreate />} />
        </Route>
        <Route path="select" element={<CampaignsSelectPage />} />
        <Route path=":id">
          <Route index element={<CampaignsDetail />} />
          <Route path="edit" element={<CampaignsEditPage />} />
          <Route path="line-item">
            <Route index element={<ActivitiesCreatePage />} />
            <Route path=":activityId">
              <Route index element={<ActivitiesDetailPage />} />
              <Route path="edit" element={<ActivitiesEditPage />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </CampaignFiltersProvider>
  </PopupNoteProvider>
);

export default Campaigns;
