import React, { Suspense } from 'react';
import { SelectedToolLoader } from './tool-loader';
import { SelectedAdvertisingTool } from '../../interfaces';

export interface ActivityBuilderProps {
  children: (tool: SelectedAdvertisingTool) => React.ReactNode;
  fallback: React.ReactElement;
  selectedTool: string | null;
};

export const ActivityBuilder: React.FC<ActivityBuilderProps> = ({
  children,
  fallback,
  selectedTool,
}) => {
  if (!selectedTool) {
    return fallback;
  }

  return (
    <Suspense fallback={fallback}>
      <SelectedToolLoader toolId={selectedTool}>
        {(tool) => children(tool)}
      </SelectedToolLoader>
    </Suspense>
  );
};
