import React from 'react';

export type StatusLabelColor = (
  | 'green'
  | 'lightGreen'
  | 'red'
  | 'lightRed'
  | 'yellow'
  | 'gray'
  | 'lightGray'
  | 'darkGray'
);

interface StatusLabelProps {
  color: StatusLabelColor;
  label: string;
  hasBorder?: boolean;
};

export const StatusLabel: React.FC<StatusLabelProps> = ({
  label,
  color,
  hasBorder = true,
}) => {

  const getColorClassName = () => {
    switch (color) {
      case 'green':
        return 'bg-green-700';
      case 'lightGreen':
        return 'bg-green-300';
      case 'red':
        return 'bg-red-700';
      case 'lightRed':
        return 'bg-red-300';
      case 'yellow':
        return 'bg-yellow-600';
      case 'gray':
        return 'bg-gray-600';
      case 'lightGray':
        return 'bg-gray-300';
      case 'darkGray':
        return 'bg-gray-800';
      default:
        throw new Error(`Unknown color: ${color}`);
    }
  };

  return (
    <div
      className={`
        inline-flex
        items-center
        gap-x-2
        leading-3
        text-sm
        rounded-sm
        ${hasBorder ? 'border border-gray-300 p-2' : ''}
      `}
    >
      <span
        className={`
          rounded-full
          w-4
          h-4
          ${getColorClassName()}
        `}
      />
      <span className="pr-1">{label}</span>
    </div>
  );
};
