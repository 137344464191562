/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiCreateAgencyMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
  billingInfo: Types.ApiCreateCompanyBillingInput;
  defaultTrafficker: Types.InputMaybe<Types.Scalars['ID']['input']>;
  defaultSalesPerson: Types.InputMaybe<Types.Scalars['ID']['input']>;
  vendors: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
}>;


export type ApiCreateAgencyMutation = { __typename?: 'Mutation', createAgency: { __typename?: 'Agency', id: string } };


export const CreateAgencyDocument = gql`
    mutation CreateAgency($name: String!, $billingInfo: CreateCompanyBillingInput!, $defaultTrafficker: ID, $defaultSalesPerson: ID, $vendors: [String!]) {
  createAgency(
    name: $name
    billingInfo: $billingInfo
    defaultTrafficker: $defaultTrafficker
    defaultSalesPerson: $defaultSalesPerson
    vendors: $vendors
  ) {
    id
  }
}
    `;
export type ApiCreateAgencyMutationFn = Apollo.MutationFunction<ApiCreateAgencyMutation, ApiCreateAgencyMutationVariables>;

/**
 * __useCreateAgencyMutation__
 *
 * To run a mutation, you first call `useCreateAgencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAgencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAgencyMutation, { data, loading, error }] = useCreateAgencyMutation({
 *   variables: {
 *      name: // value for 'name'
 *      billingInfo: // value for 'billingInfo'
 *      defaultTrafficker: // value for 'defaultTrafficker'
 *      defaultSalesPerson: // value for 'defaultSalesPerson'
 *      vendors: // value for 'vendors'
 *   },
 * });
 */
export function useCreateAgencyMutation(baseOptions?: Apollo.MutationHookOptions<ApiCreateAgencyMutation, ApiCreateAgencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiCreateAgencyMutation, ApiCreateAgencyMutationVariables>(CreateAgencyDocument, options);
      }
export type CreateAgencyMutationHookResult = ReturnType<typeof useCreateAgencyMutation>;
export type CreateAgencyMutationResult = Apollo.MutationResult<ApiCreateAgencyMutation>;
export type CreateAgencyMutationOptions = Apollo.BaseMutationOptions<ApiCreateAgencyMutation, ApiCreateAgencyMutationVariables>;