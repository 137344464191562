import React from 'react';
import { ActivityCreativeRow } from './activity-creative-row';
import { ActivityCreativeItem } from '../../interfaces';

export interface CreativeListProps {
  children?: React.ReactNode;
  creatives: ActivityCreativeItem[];
  expanded: boolean;
};

export const CreativeList: React.FC<CreativeListProps> = ({
  children,
  creatives,
  expanded = false,
}) => {
  return (
    <div className="mt-4 flex flex-col space-y-2 text-sm">
      {creatives.length > 0 ? (
        creatives.map(creative => (
          <ActivityCreativeRow
            key={creative.id}
            creative={creative}
            expanded={expanded}
          />
        ))
      ) : children}
    </div>
  );
};
