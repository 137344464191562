import debounce from 'lodash.debounce';
import { Context, useCallback, useEffect, useRef, useState } from 'react';
import { Filters, FiltersContextType, FiltersNormalizeFn, NormalizedFilters } from './filters.interface';

export const getFilterKey = (filterId: string): string => `cruxo.filters.${filterId}`;

type FiltersProviderType = React.FC<React.PropsWithChildren>;
export type FiltersProviderOptions<F extends Filters, N extends NormalizedFilters> = {
  filterId: string;
  rememberPath?: RegExp;
  normalizer: FiltersNormalizeFn<F, N>;
};

export const createFiltersProvider = <F extends Filters, N extends NormalizedFilters>(
  context: Context<FiltersContextType<F, N> | null>,
  options: FiltersProviderOptions<F, N>,
): FiltersProviderType => {
  return ({
    children,
  }: React.PropsWithChildren) => {
    const {
      filterId,
      normalizer,
      rememberPath,
    } = options;
    const filterKey = getFilterKey(filterId);
    const filtersRaw = localStorage.getItem(filterKey);
    const [filters, setFiltersInternal] = useState<F>(filtersRaw && JSON.parse(filtersRaw) || {});
    const useRememberPath = rememberPath !== undefined;

    const setFiltersCallback = useCallback((filters: Partial<F>) => {
      setFiltersInternal(currentFilters => ({
        ...currentFilters,
        ...filters,
      }));
      localStorage.setItem(filterKey, JSON.stringify(filters));
    }, []);

    const setFilters = useCallback((filters: Partial<F>, debounce = true) => {
      if (debounce) {
        debouncedSetFilters.current(filters);
        return;
      }

      setFiltersCallback(filters);
    }, []);

    const debouncedSetFilters = useRef(debounce(setFiltersCallback, 300));
    const normalizedFilters = normalizer(filters);

    // forgot filter when use navigates to different page
    useEffect(() => {
      localStorage.setItem(filterKey, JSON.stringify(filters));
      return () => {
        const matchRememberPath = rememberPath?.test(window.location.pathname) ?? false;
        if (!useRememberPath || !matchRememberPath) {
          localStorage.removeItem(filterKey);
        }
      };
    }, [window.location.pathname]);

    return (
      <context.Provider value={{ filterId, filters: normalizedFilters, setFilters }}>
        {children}
      </context.Provider>
    );
  };
};
