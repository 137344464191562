import { mapApiLocaleToLocale, mapApiTimezoneToTimezone } from '@/app/common';
import {
  ApiAgencyDetailFragment,
  ApiAgencyUserItemFragment,
  ApiAgencyVendorItemFragment,
} from './get-agency-detail.query.generated';
import {
  AgencyDetail,
  AgencyUserItem,
  AgencyVendorItem,
} from '../../interfaces';

export const mapAgencyDetail = (
  agency: ApiAgencyDetailFragment,
): AgencyDetail => ({
  id: agency.id,
  name: agency.name,
  businessId: agency.billingInfo.businessId,
  businessName: agency.billingInfo.businessName,
  vatNumber: agency.billingInfo.vatNumber || null,
  defaultSalesPerson: agency.defaultSalesPerson ? {
    id: agency.defaultSalesPerson.id,
    name: agency.defaultSalesPerson.name,
  } : null,
  defaultTrafficker: agency.defaultTrafficker ? {
    id: agency.defaultTrafficker.id,
    name: agency.defaultTrafficker.name,
  } : null,
});

export const mapAgencyUser = (
  user: ApiAgencyUserItemFragment,
): AgencyUserItem => ({
  id: user.id,
  username: user.username,
  name: user.name,
  locale: user.locale && mapApiLocaleToLocale(user.locale) || null,
  timezone: user.timezone && mapApiTimezoneToTimezone(user.timezone) || null,
  state: 'active',
});

export const mapAgencyVendor = (
  vendor: ApiAgencyVendorItemFragment,
): AgencyVendorItem => ({
  id: vendor.id,
  name: vendor.name,
  businessName: vendor.billingInfo.businessName,
  businessId: vendor.billingInfo.businessId,
  vatNumber: vendor.billingInfo.vatNumber,
});
