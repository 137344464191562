import React from 'react';
import { Button, FiltersIcon, InputField, MagnifyingGlassIcon, TabSwitch } from '@/app/components';

interface TableHeaderProps {
  children?: React.ReactNode;
  onSearch?: (value: string) => Promise<void>;
  onToggleFilters?: () => void;
  handleViewSwitch?: (selectedIndex: number) => void;
  viewTypes?: string[];
  selectedViewType?: number;
  searchValue?: string | null;
};

export const TableHeader: React.FC<TableHeaderProps> = ({
  children,
  onToggleFilters,
  handleViewSwitch,
  onSearch,
  searchValue = null,
  viewTypes = ['List', 'Performance'],
  selectedViewType = 0,
}) => {
  return (
    <section className="flex justify-between items-center">
      <div className="flex items-center gap-x-3">
        {onToggleFilters !== undefined && (
          <div>
            <Button
              label="Filters"
              variant="tertiary"
              icon={FiltersIcon}
              onClick={onToggleFilters}
            />
          </div>
        )}
        {onSearch !== undefined && (
          <InputField
            name="search"
            placeholder="Search..."
            icon={MagnifyingGlassIcon}
            iconPosition="left"
            onChange={event => onSearch(event.target.value)}
            value={searchValue ?? undefined}
          />
        )}
      </div>

      {(children !== undefined || handleViewSwitch !== undefined) && (
        <div className="flex items-center gap-x-8">
          {children}

          {handleViewSwitch !== undefined && (
            <div className="shrink-0">
              <TabSwitch
                items={viewTypes}
                label="View type"
                onSwitch={handleViewSwitch}
                selected={selectedViewType}
                compact
              />
            </div>
          )}
        </div>
      )}
    </section>
  );
};
