import { MouseEvent, useEffect, useRef, useState } from 'react';

type OnClickHandler = (event: MouseEvent<HTMLButtonElement>) => void;

export type AutoDisabledButtonChildrenProps = {
  disabled: boolean;
  onClick: OnClickHandler;
};

export type AutoDisabledButtonProps = {
  children: (data: AutoDisabledButtonChildrenProps) => React.ReactNode;
  onClick?: OnClickHandler;
  duration?: number;
};

export const AutoDisabledButton: React.FC<AutoDisabledButtonProps> = ({
  children,
  onClick,
  duration = 10,
}) => {
  const timerRef = useRef<NodeJS.Timeout>();
  const [disabled, toggleDisabled] = useState<boolean>(false);
  const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
    onClick?.(event);
    toggleDisabled(true);
    timerRef.current = setTimeout(() => toggleDisabled(false), duration * 1000);
  };

  useEffect(() => {
    return () => {
      timerRef.current && clearTimeout(timerRef.current);
    };
  }, []);

  return children({
    onClick: handleOnClick,
    disabled,
  });
};
