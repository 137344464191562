import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type UseAdminMode = [
  boolean,
  () => void,
];

export const useAdminMode = (): UseAdminMode => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAdminMode = location.pathname.includes('/admin');

  const switchAdminMode = useCallback(() => {
    navigate(isAdminMode ? '/' : '/admin');
  }, [isAdminMode]);

  return [
    isAdminMode,
    switchAdminMode,
  ];
};
