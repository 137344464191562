import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { Button, InputField, Notification } from '@/app/components';
import { useResetPassword } from '../../api';

interface PasswordResetFormRawData {
  password: string;
  passwordConfirm: string;
};

export interface PasswordResetFormData {
  password: string;
};

interface PasswordResetFormProps {
  token: string;
};

export const PasswordResetForm: React.FC<PasswordResetFormProps> = ({
  token,
}) => {
  const timerRef = useRef<NodeJS.Timeout>();
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean | null>(null);
  const { register, reset, handleSubmit, watch, formState: { errors } } = useForm<PasswordResetFormRawData>({
    mode: 'onChange',
  });
  const { resetPassword, loading } = useResetPassword();
  const navigate = useNavigate();

  const doSubmit = async (formData: PasswordResetFormRawData) => {
    try {
      setError(null);
      setSuccess(null);
      await resetPassword(token, formData.password);
      timerRef.current = setTimeout(() => navigate('/login'), 4000);
      setSuccess(true);
      reset();
    } catch {
      setError('Error occurred, please try again');
      setSuccess(false);
    }
  };

  useEffect(() => {
    return () => {
      timerRef.current && clearTimeout(timerRef.current);
    };
  }, []);

  return (
    <>
      <header className="mb-6">
        <h1 className="text-3xl font-medium">Vytvoření hesla</h1>
      </header>

      <form onSubmit={handleSubmit(doSubmit)} noValidate>
        {success && (
          <div className="mb-4">
            <Notification
              color="blue"
              label={
                <p>
                  Your password had been changed successfully and you can
                  {' '}
                  <Link to="/login">Sign in</Link>
                </p>
              }
              type="info"
            />
          </div>
        )}

        <div className="mb-4">
          <InputField
            {...register('password', {
              required: 'This field is required and cannot be empty',
              minLength: {
                value: 6,
                message: 'Password has to be at least 6 characters long',
              },
            })}
            type="password"
            label="Password"
            required
            error={errors.password}
            disabled={success === true}
          />
        </div>

        <div className="mb-4">
          <InputField
            {...register('passwordConfirm', {
              required: 'This field is required and cannot be empty',
              minLength: {
                value: 6,
                message: 'Password has to be at least 6 characters long',
              },
              validate: (password: string) => {
                return password === watch('password') ? true : 'Passwords does not match.';
              },
            })}
            type="password"
            label="Password again"
            required
            error={errors.passwordConfirm}
            disabled={success === true}
          />
        </div>

        {error !== null && (
          <div className="mt-3">
            <Notification
              color="red"
              label={error}
              type="error"
            />
          </div>
        )}

        <div className="flex flex-col mt-8">
          <Button
            label="Vytvořit heslo"
            type="submit"
            disabled={loading || success === true}
          />

          <div className="text-sm mt-8 text-center text-slate-500">
            <span className="mr-1">Znáte svoje heslo?</span>
            <Link to={'/login'} className="underline">
              Přihlásit se
            </Link>
          </div>
        </div>
      </form>
    </>
  );
};
