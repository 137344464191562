import React from 'react';
import { Page } from './page';
import { ChevronLeftIcon } from '../../icons';

interface PrevProps {
  page: number;
  disabled?: boolean;
};

export const Prev: React.FC<PrevProps> = ({
  page,
  disabled = false,
}) => (
  <Page
    page={page}
    active={false}
    disabled={disabled}
    aria-label="Předchozí"
  >
    <span className="stroke-slate-800 hover:stroke-purple-600">
      {ChevronLeftIcon}
    </span>
  </Page>
);
