import { useLazySearch } from '@/app/common';
import { useAgencySearchVendorsLazyQuery, useAgencySearchVendorsSuspenseQuery } from './search-vendors.query.generated';
import { mapVendor } from './vendor.mapper';
import { VendorBrandItem } from '../../interfaces';

type UseSearchVendorsHook = {
  vendors: VendorBrandItem[];
  searchVendors: (term: string) => void,
  error?: string;
};

export const useSearchVendors = (): UseSearchVendorsHook => {
  const { data, error } = useAgencySearchVendorsSuspenseQuery();
  const [searchVendors] = useAgencySearchVendorsLazyQuery();
  const initialValues = data.vendors.items.map(mapVendor);

  const { results, search } = useLazySearch({
    initialValues,
    onSearch: async (term: string) => {
      const result = await searchVendors({
        variables: {
          limit: 15,
          search: term,
        },
      });

      if (result.error) {
        throw result.error;
      }

      if (!result.data) {
        throw new Error('Empty search response returned while data was expected');
      }

      return result.data.vendors.items.map(mapVendor);
    },
  });

  return {
    vendors: results,
    searchVendors: search,
    error: error?.message,
  };
};
