import React, { useState } from 'react';
import { ButtonCircle, DefinitionList, DefinitionListItem, MinusIcon, PlusIcon, RoundedFrame } from '@/app/components';

export interface SelectableItemType {
  id: string;
  title: string;
  description?: string;
  moreContent?: DefinitionListItem[];
};

export interface SelectableItemProps {
  item: SelectableItemType;
  isSelected: boolean;
  onToggle: () => void;
};

export const SelectableItem: React.FC<SelectableItemProps> = ({
  item,
  isSelected,
  onToggle,
}) => {
  const [showMore, setShowMore] = useState(false);

  const onShowMore = () => {
    setShowMore(!showMore);
  };

  const itemClassName = `
    shadow-md
    rounded-sm
    px-4
    py-3
  `;

  const itemSelectedClassName = `
    border
    border-purple-600
    bg-purple-50
  `;

  return (
    <div className={`${itemClassName} ${isSelected ? itemSelectedClassName : ''}`}>
      <div className="flex justify-between items-center">
        <div>
          <h4 className="text-sm font-medium">{item.title}</h4>
          {item.description && (
            <p className="text-sm mt-1">{item.description}</p>
          )}

          {item.moreContent !== undefined && (
            <button className="text-sm mt-3 text-purple-600" onClick={onShowMore} type="button">
              {showMore ? 'Show less' : 'Show more'}
            </button>
          )}
        </div>
        <ButtonCircle
          variant="secondary"
          size={'lg'}
          label={isSelected ? 'Remove item' : 'Add item'}
          icon={isSelected ? MinusIcon : PlusIcon}
          onClick={onToggle}
        />
      </div>

      {showMore && item.moreContent !== undefined && (
        <div className="mt-3 bg-white rounded-sm">
          <RoundedFrame>
            <DefinitionList data={item.moreContent} />
          </RoundedFrame>
        </div>
      )}

    </div>
  );
};
