import { useFormContext } from 'react-hook-form';
import { SelectboxField } from '@/app/components';
import { ActivityFilterFormValues } from './activity-filter.interface';
import { ACTIVITY_STATUS } from '../../interfaces';

export const StatusField = () => {
  const { control } = useFormContext<ActivityFilterFormValues>();
  const options = [
    ACTIVITY_STATUS.draft,
    ACTIVITY_STATUS.pendingApproval,
    ACTIVITY_STATUS.approved,
    ACTIVITY_STATUS.rejected,
    ACTIVITY_STATUS.incomplete,
    ACTIVITY_STATUS.ready,
    ACTIVITY_STATUS.online,
    ACTIVITY_STATUS.paused,
    ACTIVITY_STATUS.closed,
    ACTIVITY_STATUS.cancelled,
  ].map(status => ({ name: status, value: status }));

  return (
    <SelectboxField
      control={control}
      name="status"
      options={options}
      label="Status"
      placeholder="Select from the list..."
    />
  );
};
