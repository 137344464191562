/* eslint-disable node/no-unsupported-features/es-syntax */
import { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Loader } from '@/app/components';
import { CenteredCardLayout, SecuredLayout, Skeleton } from '@/app/layouts';
import {
  Login,
  Page404,
  RequestResetPasswordPage,
  ResetPasswordPage,
} from '@/app/modules';

// Import modules
const CreativeFormats = lazy(() =>
  import('@/app/modules/creative-formats').then((module) => ({
    default: module.CreativeFormats,
  })),
);

const Markets = lazy(() =>
  import('@/app/modules/markets').then((module) => ({
    default: module.Markets,
  })),
);

const Campaigns = lazy(() =>
  import('@/app/modules/campaigns').then((module) => ({
    default: module.Campaigns,
  })),
);

const Activities = lazy(() =>
  import('@/app/modules/campaigns').then((module) => ({
    default: module.Activities,
  })),
);

const AdvertisingToolsLibrary = lazy(() =>
  import('@/app/modules/campaigns').then((module) => ({
    default: module.ToolsLibrary,
  })),
);

const CreativeLibrary = lazy(() =>
  import('@/app/modules/campaigns').then((module) => ({
    default: module.CreativeLibrary,
  })),
);

const Targets = lazy(() =>
  import('@/app/modules/targets').then((module) => ({
    default: module.Targets,
  })),
);

const Brands = lazy(() =>
  import('@/app/modules/brands').then((module) => ({
    default: module.Brands,
  })),
);

const Users = lazy(() =>
  import('@/app/modules/users').then((module) => ({
    default: module.Users,
  })),
);

const AdvertisingTools = lazy(() =>
  import('@/app/modules/advertising-tools').then((module) => ({
    default: module.AdvertisingTools,
  })),
);

const MyOrganization = lazy(() =>
  import('@/app/modules/my-organization').then((module) => ({
    default: module.MyOrganization,
  })),
);

const Companies = lazy(() =>
  import('@/app/modules/companies').then((module) => ({
    default: module.Companies,
  })),
);

// Import static pages
const Home = lazy(() =>
  import('@/app/modules/home').then((module) => ({
    default: module.Home,
  })),
);

const DesignLibrary = lazy(() =>
  import('@/app/modules/design-library').then((module) => ({
    default: module.DesignLibrary,
  })),
);

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={<Skeleton />}>
          <Route path="" element={<SecuredLayout />}>
            <Route index element={<Home />} />
            <Route path="design-library/*" element={<DesignLibrary />} />
            <Route path="advertising-tools/*" element={<AdvertisingToolsLibrary />} />
            <Route path="campaigns/*" element={<Campaigns />} />
            <Route path="line-items/*" element={<Activities />} />
            <Route path="creative-library/*" element={<CreativeLibrary />} />
            <Route path="*" element={<Page404 />} />
          </Route>

          <Route path="/admin" element={<SecuredLayout />}>
            <Route index element={<Navigate to={'my-organization'} />} />
            <Route path="my-organization/*" element={<MyOrganization />} />
            <Route path="advertising-tools/*" element={<AdvertisingTools />} />
            <Route path="creative-formats/*" element={<CreativeFormats />} />
            <Route path="markets/*" element={<Markets />} />
            <Route path="users/*" element={<Users />} />
            <Route path="targets/*" element={<Targets />} />
            <Route path="brands/*" element={<Brands />} />
            <Route path="companies/*" element={<Companies />} />
            <Route path="*" element={<Page404 />} />
          </Route>

          <Route element={<CenteredCardLayout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<RequestResetPasswordPage />} />
            <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
