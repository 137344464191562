import { Tool1Icon } from '@/app/components';

export const mockedDialogDetail = {
  title: 'Sponsored product on HP',
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  icon: Tool1Icon,
};

export const mockedDialogList = {
  title: 'Advertising tools',
};
