import {
  Field,
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import React, { forwardRef } from 'react';
import { ChevronUpDownIcon, TicksIcon } from '@/app/components';
import { FieldError } from './interfaces';
import { getDataOptionAttributes } from './utils';

export interface SelectboxOptions {
  name: string;
  value: string;
};

export interface SelectedButtonProps<O extends SelectboxOptions> {
  selectedOptions: SelectboxValue<O>;
  multiple: boolean;
  placeholder: string;
};

export type SelectboxValue<O extends SelectboxOptions = SelectboxOptions> = O | O[] | null;

export interface SelectboxProps<O extends SelectboxOptions> {
  id: string;
  options: O[];
  value?: SelectboxValue<O>;
  label?: string;
  disabled?: boolean;
  inline?: boolean;
  required?: boolean | string;
  multiple?: boolean;
  error?: FieldError;
  placeholder?: string;
  onChange?: (value: SelectboxValue<O>) => void;
  onBlur?: () => void;
};

export const SelectboxInner = <O extends SelectboxOptions>(
  {
    id,
    label,
    value = null,
    options,
    error,
    inline = false,
    disabled = false,
    required = false,
    multiple = false,
    placeholder = '',
    onChange,
    onBlur,
    ...rest
  }: SelectboxProps<O>,
  ref: React.ForwardedRef<HTMLDivElement>,
) => {
  const handleSelectChange = (selectedOptions: SelectboxValue<O>) => {
    if (!selectedOptions) {
      onChange?.(multiple ? [] : null);
    }

    onChange?.(selectedOptions);
  };

  const dataOptionAttributes = getDataOptionAttributes(value, multiple);

  const disabledClass = disabled === true
    ? 'cursor-not-allowed bg-slate-100 text-slate-500'
    : 'cursor-default bg-white';

  const errorClass = error !== undefined
    ? 'border-red-700 text-red-700'
    : 'border-slate-300 data-[focus]:border-purple-500';

  const buttonClass = `
    relative
    w-full
    rounded-md
    py-2
    pl-4
    pr-7
    h-10
    text-left
    border
    text-sm
    leading-6
    truncated
    overflow-hidden
    focus:outline-none
    data-[active]:border-purple-500
    ${disabledClass}
    ${errorClass}
  `;

  const optionsClass = `
    absolute
    z-10
    mt-1
    max-h-60
    overflow-auto
    rounded-xs
    bg-white
    py-1
    text-base
    shadow-lg
    ring-1
    ring-black/5
    focus:outline-none
    sm:text-sm
    transition-[opacity]
    w-[var(--button-width)]
    data-[leave]:data-[closed]:opacity-0
  `;

  const optionClassName = `
    relative
    cursor-default
    select-none
    py-2
    pl-3
    pr-4
    group
    flex
    items-center
    gap-2
    select-none
    text-slate-800
    data-[focus]:bg-purple-200
  `;

  return (
    <Field disabled={disabled}>
      {/* Label inline position */}
      {label !== undefined && !inline && (
        <Label
          htmlFor={id}
          className="mb-1 text-sm leading-6 text-slate-800 block"
        >
          {label}
          {!required && (
            <span className="text-slate-400 ml-1">
              (optional)
            </span>
          )}
        </Label>
      )}

      <div className={`relative w-full ${inline === true ? 'flex items-center' : ''}`}>

        {/* Label block position */}
        {label !== undefined && inline === true && (
          <Label
            className="text-sm text-slate-800 inline-block mr-2"
            htmlFor={id}
          >
            {label}
            {!required && (
              <span className="text-slate-400 ml-1">
                (optional)
              </span>
            )}
          </Label>
        )}

        <Listbox
          {...dataOptionAttributes}
          value={value}
          onChange={handleSelectChange}
          multiple={multiple}
          ref={ref}
          as="div"
        >
          <ListboxButton
            {...rest}
            className={buttonClass}
            onBlur={onBlur}
          >
            <SelectedButton
              selectedOptions={value}
              placeholder={placeholder}
              multiple={multiple}
            />

            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              {ChevronUpDownIcon}
            </span>
          </ListboxButton>

          <ListboxOptions
            anchor="bottom"
            transition
            className={optionsClass}
          >
            {options.map((option, key) => (
              <ListboxOption
                key={`selectbox_${id}_${key}`}
                value={option}
                className={optionClassName}
              >
                <span className="invisible group-data-[selected]:visible">{TicksIcon}</span>
                <span>{option.name}</span>
              </ListboxOption>
            ))}
          </ListboxOptions>
        </Listbox>
      </div>

      {error?.message !== undefined &&
        <p className="mt-1 text-xs text-red-700 leading-4">{error.message}</p>
      }
    </Field>
  );
};

declare module 'react' {
  function forwardRef<T, P = object>(
    render: (props: P, ref: React.Ref<T>) => React.ReactNode | null
  ): (props: P & React.RefAttributes<T>) => React.ReactNode | null;
}

export const Selectbox = forwardRef(SelectboxInner);

export const SelectedButton = <O extends SelectboxOptions>({
  selectedOptions,
  placeholder,
}: SelectedButtonProps<O>) => {
  const getTextValue = () => {
    if (Array.isArray(selectedOptions)) {
      if (selectedOptions.length > 0) {
        return `${selectedOptions.length} selected items`;
      }

      return placeholder;
    }

    return selectedOptions?.name ?? placeholder;
  };

  const name = getTextValue();
  return (
    <span
      className={`
        block
        truncate
        ${name === placeholder ? 'text-slate-500' : ''}
      `}
    >
      {name}
    </span>
  );
};
