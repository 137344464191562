import React from 'react';
import { usePaneSwitcher } from './pane-context';

interface PaneSwitcherMenuProps {
  label?: string;
  compact?: boolean;
};

export const PaneSwitcherMenu: React.FC<PaneSwitcherMenuProps> = ({
  label,
  compact = false,
}) => {
  const { panes, activePane, switchPane } = usePaneSwitcher();

  const handleClick = (id: string) => {
    switchPane(id);
  };

  return (
    <div className="flex items-center space-x-2.5">
      {label !== undefined && (
        <span className="text-gray-700 text-sm">{label}</span>
      )}
      <div className={`flex bg-slate-200 rounded-md ${!compact ? 'grow' : ''}`}>
        {Object.values(panes).map((pane) => (
          <button
            key={pane.id}
            type="button"
            onClick={() => handleClick(pane.id)}
            className={`
              text-sm
              grow
              leading-6
              rounded-md
              text-center
              ${compact ? 'px-3.5 py-1' : 'px-3 py-2'}
              ${pane.id === activePane ? 'bg-purple-200' : 'bg-transparent'}
            `}
          >
            {pane.title}
          </button>
        ))}
      </div>
    </div>
  );
};
