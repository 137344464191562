import React from 'react';
import { Link } from 'react-router-dom';
import styles from './logo.module.css';

export interface LogoProps {
  isNavCollapsed: boolean;
};

export const Logo: React.FC<LogoProps> = ({ isNavCollapsed }) => {
  const isAdminMode = location.pathname.includes('/admin');
  return(
    <Link
      to={isAdminMode ? '/admin' : '/'}
      aria-label="Cruxo homepage"
      className={`${isNavCollapsed ? 'px-2.5 py-4' : 'p-4'}`}
    >
      <div
        className={`
        transition-[width]
        duration-200
        ${styles.logoBackground}
        ${isNavCollapsed ? styles.logoCollapsed : styles.logo}
      `}
      />
    </Link>
  );
};
