import React from 'react';
import { Outlet } from 'react-router-dom';
import styles from './layout.module.css';

export const CenteredCardLayout: React.FC = () => (
  <div
    className="
      bg-slate-50
      flex
      items-center
      justify-center
      relative
      min-h-screen
    "
  >
    <div className="w-[360px] rounded-sm shadow-md overflow-hidden">
      <div className="px-8 py-5 flex bg-slate-200">
        <div className={styles.loginLogo} role="img" aria-label="Cruxo Logo">
          <span className="sr-only">Cruxo logo</span>
        </div>
      </div>
      <div className="p-8 bg-white">
        <Outlet />
      </div>
    </div>
  </div>
);
