import React from 'react';
import { LanguageSwitcherItem } from './language.interface';

export type LanguageDropdownProps = {
  languages: LanguageSwitcherItem[];
  onSelect: (selectedMarket: LanguageSwitcherItem) => void;
};

export const LanguageDropdown: React.FC<LanguageDropdownProps> = ({
  languages,
  onSelect,
}) => {
  const linkClass = `
    w-full
    text-left
    leading-6
    text-sm
    text-slate-800
    hover:bg-purple-100
    py-2
    px-3
    flex
    items-center
    gap-x-2
  `;

  return (
    <div
      className="
        w-40
        top-[calc(100%+0.25rem)]
        py-2
        absolute
        right-0
        z-20
        rounded-sm
        border
        border-slate-300
        bg-white
      "
    >
      <ul>
        {languages.map((market) => (
          <li key={market.id}>
            <button
              type="button"
              className={linkClass}
              onClick={() => onSelect(market)}
            >
              <span className="w-8 h-8 rounded-full overflow-hidden flex items-center justify-center bg-purple-200">
                {market.id}
              </span>
              <span className="tracking-wide text-nowrap pl-1">
                {market.name}
              </span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};
