import { createFilters } from '@/app/common';

type AgencyFilters = {
  search: string | null;
};

export const {
  FiltersProvider: AgencyFiltersProvider,
  useFilters: useAgencyFilters,
} = createFilters<AgencyFilters>({
  filterId: 'agencies',
  normalizer: (filters) => ({
    search: (filters.search ?? '').length > 0 ? filters.search : null,
  }),
});
