import React from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import {
  PageContentBox,
  PaneSwitcher,
  PaneSwitcherContent,
  PaneSwitcherMenu,
} from '@/app/components';
import { useCreateCampaign } from './api';
import { CreateCampaignForm, SelectCampaignForm } from './components';
import { CreateCampaignFormData } from './interfaces';

export const CampaignsSelectPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { createCampaign } = useCreateCampaign();

  const toolIdParam = searchParams.get('tool');
  const campaignIdParam = searchParams.get('campaign');

  const handleCreateCampaign = async (data: CreateCampaignFormData) => {
    const campaign = await createCampaign(data);
    navigate({
      pathname: `../${campaign.id}/line-item`,
      search: toolIdParam ? createSearchParams({ tool: toolIdParam }).toString() : undefined,
    });
  };

  const handleSelectCampaign = async (campaignId: string) => {
    navigate({
      pathname: `../${campaignId}/line-item`,
      search: toolIdParam ? createSearchParams({ tool: toolIdParam }).toString() : undefined,
    });
  };

  const handleCancel = () => {
    if (campaignIdParam) {
      navigate(`../${campaignIdParam}/line-item`);
      return;
    }

    navigate('/advertising-tools');
  };

  return (
    <PageContentBox maxWidth="2xl">
      <header className="mb-4">
        <h1 className="text-3xl font-medium">New campaign</h1>
      </header>

      <PaneSwitcher>
        <PaneSwitcherContent id="create" title="Create new" active={campaignIdParam === null}>
          <CreateCampaignForm
            onSubmit={handleCreateCampaign}
            onCancel={handleCancel}
          >
            <div className="mb-6">
              <PaneSwitcherMenu />
            </div>
          </CreateCampaignForm>
        </PaneSwitcherContent>

        <PaneSwitcherContent id="select" title="Select existing" active={campaignIdParam !== null}>
          <SelectCampaignForm
            onSubmit={handleSelectCampaign}
            onCancel={handleCancel}
          >
            <div className="mb-6">
              <PaneSwitcherMenu />
            </div>
          </SelectCampaignForm>
        </PaneSwitcherContent>
      </PaneSwitcher>
    </PageContentBox>
  );
};
