import { ApiAdvertisingToolRate } from '../api';
import { RateType } from '../interfaces';

export const mapRateType = (type: ApiAdvertisingToolRate): RateType => {
  switch (type) {
    case ApiAdvertisingToolRate.CostPerClick:
      return 'cpc';
    case ApiAdvertisingToolRate.CostPerMeasurement:
      return 'cpm';
    case ApiAdvertisingToolRate.FlatRate:
      return 'flatRate';

    default:
      throw new Error(`Unable to convert advertising tool rate type for unknown value ${type}`);
  }
};
