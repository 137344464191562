/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiVendorUserItemFragment = { __typename?: 'VendorUser', id: string, username: string, name: string, locale: Types.ApiLocale | null, timezone: Types.ApiTimezone | null };

export type ApiVendorBrandItemFragment = { __typename?: 'Brand', id: string, name: string };

export type ApiVendorDetailFragment = { __typename?: 'Vendor', id: string, name: string, billingInfo: { __typename?: 'CompanyBilling', businessName: string, businessId: string, vatNumber: string | null }, defaultSalesPerson: { __typename?: 'LeanUser', id: string, name: string } | null, defaultTrafficker: { __typename?: 'LeanUser', id: string, name: string } | null, brands: { __typename?: 'VendorBrandResult', total: number, items: Array<{ __typename?: 'Brand', id: string, name: string }> }, users: { __typename?: 'VendorUserResult', total: number, items: Array<{ __typename?: 'VendorUser', id: string, username: string, name: string, locale: Types.ApiLocale | null, timezone: Types.ApiTimezone | null }> } };

export type ApiVendorQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type ApiVendorQuery = { __typename?: 'Query', vendor: { __typename?: 'Vendor', id: string, name: string, billingInfo: { __typename?: 'CompanyBilling', businessName: string, businessId: string, vatNumber: string | null }, defaultSalesPerson: { __typename?: 'LeanUser', id: string, name: string } | null, defaultTrafficker: { __typename?: 'LeanUser', id: string, name: string } | null, brands: { __typename?: 'VendorBrandResult', total: number, items: Array<{ __typename?: 'Brand', id: string, name: string }> }, users: { __typename?: 'VendorUserResult', total: number, items: Array<{ __typename?: 'VendorUser', id: string, username: string, name: string, locale: Types.ApiLocale | null, timezone: Types.ApiTimezone | null }> } } };

export const VendorBrandItemFragmentDoc = gql`
    fragment VendorBrandItem on Brand {
  id
  name
}
    `;
export const VendorUserItemFragmentDoc = gql`
    fragment VendorUserItem on VendorUser {
  id
  username
  name
  locale
  timezone
}
    `;
export const VendorDetailFragmentDoc = gql`
    fragment VendorDetail on Vendor {
  id
  name
  billingInfo {
    businessName
    businessId
    vatNumber
  }
  defaultSalesPerson {
    id
    name
  }
  defaultTrafficker {
    id
    name
  }
  brands(page: 1, limit: 100) {
    total
    items {
      ...VendorBrandItem
    }
  }
  users(page: 1, limit: 100) {
    total
    items {
      ...VendorUserItem
    }
  }
}
    ${VendorBrandItemFragmentDoc}
${VendorUserItemFragmentDoc}`;
export const VendorDocument = gql`
    query Vendor($id: String!) {
  vendor(id: $id) {
    ...VendorDetail
  }
}
    ${VendorDetailFragmentDoc}`;

/**
 * __useVendorQuery__
 *
 * To run a query within a React component, call `useVendorQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVendorQuery(baseOptions: Apollo.QueryHookOptions<ApiVendorQuery, ApiVendorQueryVariables> & ({ variables: ApiVendorQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiVendorQuery, ApiVendorQueryVariables>(VendorDocument, options);
      }
export function useVendorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiVendorQuery, ApiVendorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiVendorQuery, ApiVendorQueryVariables>(VendorDocument, options);
        }
export function useVendorSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApiVendorQuery, ApiVendorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiVendorQuery, ApiVendorQueryVariables>(VendorDocument, options);
        }
export type VendorQueryHookResult = ReturnType<typeof useVendorQuery>;
export type VendorLazyQueryHookResult = ReturnType<typeof useVendorLazyQuery>;
export type VendorSuspenseQueryHookResult = ReturnType<typeof useVendorSuspenseQuery>;
export type VendorQueryResult = Apollo.QueryResult<ApiVendorQuery, ApiVendorQueryVariables>;