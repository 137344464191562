import React from 'react';
import { ToolIconType } from '@/app/common';
import * as ToolIcons from '../icons';

interface ToolIconProps {
  type: ToolIconType | null;
};

export const ToolIcon: React.FC<ToolIconProps> = ({ type }) => {
  const getToolIcon = () => {
    switch (type) {
      case 'BANNER_CAROUSEL_HP_GREEN':
        return ToolIcons.Tool1Icon;
      case 'BANNER_CATEGORY_GREEN':
        return ToolIcons.Tool2Icon;
      case 'BANNER_SPONSORED_CATEGORY_GREEN':
        return ToolIcons.Tool4Icon;
      case 'BANNER_UNDER_CAROUSEL':
        return ToolIcons.Tool3Icon;
      case 'SPONSORED_PRODUCT_CART':
        return ToolIcons.Tool6Icon;
      case 'SPONSORED_PRODUCT_CART_PLUS':
        return ToolIcons.Tool5Icon;
      case 'SPONSORED_PRODUCT_CATEGORY_POSITION_1_GREEN':
        return ToolIcons.Tool7Icon;
      case 'SPONSORED_PRODUCT_CATEGORY_POSITION_1_PURPLE':
        return ToolIcons.Tool8Icon;
      case 'SPONSORED_PRODUCT_CATEGORY_POSITION_3_GREEN':
        return ToolIcons.Tool9Icon;
      case 'SPONSORED_PRODUCT_CATEGORY_POSITION_3_PURPLE':
        return ToolIcons.Tool10Icon;
      case 'SPONSORED_PRODUCT_CATEGORY_POSITION_4_GREEN':
        return ToolIcons.Tool11Icon;
      case 'SPONSORED_PRODUCT_CATEGORY_POSITION_4_PURPLE':
        return ToolIcons.Tool12Icon;
      case 'SPONSORED_PRODUCT_HP_GREEN':
        return ToolIcons.Tool13Icon;
      case 'SPONSORED_PRODUCT_HP_PURPLE':
        return ToolIcons.Tool14Icon;
      case 'SPONSORED_PRODUCT_PRODUCT_DETAIL_GREEN':
        return ToolIcons.Tool15Icon;
      case 'SPONSORED_PRODUCT_PRODUCT_DETAIL_PURPLE':
        return ToolIcons.Tool16Icon;
      case 'SPONSORED_PRODUCT_SEARCH':
        return ToolIcons.Tool17Icon;
      default:
        return null;
    }
  };

  return getToolIcon();
};
