/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiSwitchContextMutationVariables = Types.Exact<{
  contextId: Types.Scalars['String']['input'];
}>;


export type ApiSwitchContextMutation = { __typename?: 'Mutation', switchContext: { __typename?: 'AuthToken', refreshToken: string, token: string, currentContext: { __typename?: 'AuthContext', id: string, marketId: string, marketName: string, orgId: string, role: Types.ApiRole, userId: string } } };


export const SwitchContextDocument = gql`
    mutation SwitchContext($contextId: String!) {
  switchContext(contextId: $contextId) {
    refreshToken
    token
    currentContext {
      id
      marketId
      marketName
      orgId
      role
      userId
    }
  }
}
    `;
export type ApiSwitchContextMutationFn = Apollo.MutationFunction<ApiSwitchContextMutation, ApiSwitchContextMutationVariables>;

/**
 * __useSwitchContextMutation__
 *
 * To run a mutation, you first call `useSwitchContextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchContextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchContextMutation, { data, loading, error }] = useSwitchContextMutation({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useSwitchContextMutation(baseOptions?: Apollo.MutationHookOptions<ApiSwitchContextMutation, ApiSwitchContextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiSwitchContextMutation, ApiSwitchContextMutationVariables>(SwitchContextDocument, options);
      }
export type SwitchContextMutationHookResult = ReturnType<typeof useSwitchContextMutation>;
export type SwitchContextMutationResult = Apollo.MutationResult<ApiSwitchContextMutation>;
export type SwitchContextMutationOptions = Apollo.BaseMutationOptions<ApiSwitchContextMutation, ApiSwitchContextMutationVariables>;