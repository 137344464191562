import React, { Suspense } from 'react';
import { useFormContext } from 'react-hook-form';
import { ComboboxField, ComboboxSearch } from '@/app/components';
import { VendorFormRawData } from './vendor-form.interface';
import { useSearchBrands } from '../../api';

export const BrandsSearchField = () => {
  return (
    <Suspense fallback={<BrandsSearchFieldSkeleton />}>
      <BrandsLoader />
    </Suspense>
  );
};

export const BrandsLoader: React.FC = () => {
  const { control, watch } = useFormContext<VendorFormRawData>();
  const { brands, searchBrands } = useSearchBrands();
  const handleSearch = (term: string) => {
    searchBrands(term);
  };

  const selectedBrandsIds = watch('brands').map(b => b.value);
  const notSelectedBrands = brands.filter(b => !selectedBrandsIds.includes(b.id));

  return (
    <ComboboxField
      name="brands"
      control={control}
      options={notSelectedBrands.map(brand => ({
        value: brand.id,
        name: brand.name,
      }))}
      placeholder="Search for brand..."
      onSearch={handleSearch}
      displayValue={false}
      multiple
    />
  );
};

export const BrandsSearchFieldSkeleton = () => (
  <ComboboxSearch
    id="vendorForm.brands"
    options={[]}
    placeholder="Search for brand..."
    multiple
    disabled
  />
);
