import { StatusLabel, StatusLabelColor } from '@/app/components';
import { ActivityStatus as ActivityStatusType } from '../../interfaces';

interface ActivityStatusProps {
  status: ActivityStatusType;
};

export const ActivityStatus: React.FC<ActivityStatusProps> = ({
  status,
}) => {
  const getStatusColor = (status: ActivityStatusType): StatusLabelColor => {
    switch (status) {
      case 'online':
        return 'green';
      case 'ready':
      case 'approved':
        return 'lightGreen';
      case 'rejected':
        return 'red';
      case 'cancelled':
        return 'darkGray';
      case 'paused':
        return 'gray';
      case 'closed':
        return 'lightGray';

      case 'draft':
      default:
        return 'yellow';
    }
  };

  const getStatusLabel = (status: ActivityStatusType) => {
    switch (status) {
      case 'pendingApproval':
        return 'Waiting for approval';
      case 'rejected':
        return 'Rejected';
      case 'approved':
        return 'Approved';
      case 'incomplete':
        return 'Incomplete';
      case 'ready':
        return 'Ready';
      case 'online':
        return 'Online';
      case 'paused':
        return 'paused';
      case 'closed':
        return 'Closed';
      case 'cancelled':
        return 'Cancelled';

      case 'draft':
      default:
        return 'Draft';
    }
  };

  return (
    <StatusLabel
      color={getStatusColor(status)}
      label={getStatusLabel(status)}
      hasBorder={false}
    />
  );
};
