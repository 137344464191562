import React from 'react';
import {
  ActivitiesIcon,
  AdToolsIcon,
  AdminIcon,
  AvailabilityIcon,
  BrandsIcon,
  CampaignsIcon,
  CompaniesIcon,
  CreativesIcon,
  DashboardIcon,
  HelpIcon,
  LandingPagesIcon,
  LineItemsIcon,
  MarketsIcon,
  MediaIcon,
  OrganizationsIcon,
  SettingsIcon,
  TargetingIcon,
  UsersIcon,
} from '@/app/components';

export type MenuIconsType = (
  | 'adTools'
  | 'admin'
  | 'brands'
  | 'companies'
  | 'campaigns'
  | 'settings'
  | 'activities'
  | 'creatives'
  | 'availability'
  | 'help'
  | 'dashboard'
  | 'landingPages'
  | 'lineItems'
  | 'targeting'
  | 'users'
  | 'organizations'
  | 'markets'
  | 'media'
);

export interface MenuIconProps {
  name: MenuIconsType,
  label: string,
};

export const MenuIcon: React.FC<MenuIconProps> = ({name, label}) => {
  const getIcon = (name: MenuIconsType) => {
    switch (name) {
      case 'adTools':
        return AdToolsIcon;
      case 'brands':
        return BrandsIcon;
      case 'admin':
        return AdminIcon;
      case 'companies':
        return CompaniesIcon;
      case 'dashboard':
        return DashboardIcon;
      case 'campaigns':
        return CampaignsIcon;
      case 'settings':
        return SettingsIcon;
      case 'activities':
        return ActivitiesIcon;
      case 'creatives':
        return CreativesIcon;
      case 'availability':
        return AvailabilityIcon;
      case 'help':
        return HelpIcon;
      case 'landingPages':
        return LandingPagesIcon;
      case 'lineItems':
        return LineItemsIcon;
      case 'targeting':
        return TargetingIcon;
      case 'users':
        return UsersIcon;
      case 'organizations':
        return OrganizationsIcon;
      case 'markets':
        return MarketsIcon;
      case 'media':
        return MediaIcon;
      default:
        throw new Error(`Unknown icon: ${name}`);
    }
  };

  const IconComponent = getIcon(name);

  return (
    <span aria-label={label}>
      {IconComponent}
    </span>
  );
};
