import { ApolloError } from '@apollo/client';
import { usePagination } from '@/app/common';
import { useVendorsSuspenseQuery } from './get-vendors.query.generated';
import { mapVendorItem } from './vendor.mapper';
import { useVendorFilters } from '../../hooks';
import { VendorItem } from '../../interfaces';

type UseVendorsHook = {
  vendors: VendorItem[];
  total: number;
  page: number;
  limit: number;
  error: ApolloError | undefined;
};
export const useVendors = (): UseVendorsHook => {
  const limit = 10;
  const [page] = usePagination();
  const [filters] = useVendorFilters();
  const { data, error } = useVendorsSuspenseQuery({
    variables: {
      ...filters,
      page,
      limit,
    },
  });

  return {
    total: data.vendors.total,
    vendors: data.vendors.items.map(mapVendorItem),
    page,
    limit,
    error,
  };
};
