import { Suspense } from 'react';
import { useFormContext } from 'react-hook-form';
import { ComboboxField, ComboboxSearch } from '@/app/components';
import { CampaignFormRawData } from './select-campaign-form.interface';
import { useSearchCampaigns } from '../../api';

interface CampaignFieldProps {
  selectedCampaign: string | null;
};

export const CampaignField = (props: CampaignFieldProps) => {
  return (
    <Suspense fallback={<CampaignFieldSkeleton />}>
      <SearchableCampaignField {...props} />
    </Suspense>
  );
};

interface SearchableCampaignFieldProps extends CampaignFieldProps {};

export const SearchableCampaignField: React.FC<SearchableCampaignFieldProps> = ({
  selectedCampaign,
}) => {
  const { control, formState: { errors } } = useFormContext<CampaignFormRawData>();
  const { campaigns, searchCampaigns } = useSearchCampaigns(selectedCampaign);

  const handleSearch = async (term: string) => {
    await searchCampaigns(term);
  };

  const campaignOptions = campaigns.map(campaign => ({
    name: campaign.name,
    value: campaign.id,
  }));

  return (
    <ComboboxField
      control={control}
      name="campaign"
      label="Campaign"
      placeholder="Search for campaign..."
      onSearch={handleSearch}
      options={campaignOptions}
      defaultValue={selectedCampaign ? campaignOptions[0] : null}
      error={errors.campaign}
      required
    />
  );
};

export const CampaignFieldSkeleton = () => (
  <ComboboxSearch
    id="selectCampaign.campaign"
    label="Campaign"
    placeholder="Search for campaign..."
    options={[]}
    disabled
    required
  />
);
