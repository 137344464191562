import { ApolloError } from '@apollo/client';
import { useLazySearch } from '@/app/common';
import {
  useSearchVendorContactsLazyQuery,
  useSearchVendorContactsSuspenseQuery,
} from './search-vendor-contacts.query.generated';

export interface VendorContactSearchItem {
  id: string;
  name: string;
};

export type UseSearchVendorContactsHook = {
  searchVendorContacts: (term: string) => void;
  vendorContacts: VendorContactSearchItem[];
  error: ApolloError | null;
};

export const useSearchVendorContacts = (vendor: string): UseSearchVendorContactsHook => {
  const { data } = useSearchVendorContactsSuspenseQuery({
    variables: {
      vendor,
      term: null,
    },
  });
  const initialContacts = data.vendor.users.items.map(user => ({
    id: user.id,
    name: user.name,
  }));

  const [searchContacts] = useSearchVendorContactsLazyQuery();
  const { search, results, error } = useLazySearch<VendorContactSearchItem>({
    initialValues: initialContacts,
    onSearch: async (term: string) => {
      const result = await searchContacts({
        variables: {
          vendor,
          term,
        },
      });

      if (result.error) {
        throw result.error;
      }

      if (!result.data) {
        throw new Error('Empty contacts search response returned while data was expected');
      }

      return result.data.vendor.users.items.map(contact => ({
        id: contact.id,
        name: contact.name,
      }));
    },
  });

  return {
    searchVendorContacts: search,
    vendorContacts: results,
    error: error,
  };
};
