/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiActivityDetailFragment = { __typename?: 'Activity', id: string, campaignId: string, name: string, icon: Types.ApiAdvertisingToolIcon | null, status: Types.ApiActivityStatus, statusNote: string | null, startDate: string, endDate: string | null, rateType: Types.ApiAdvertisingToolRate, orderedAmount: number, unitPrice: number, totalPrice: number, metrics: { __typename?: 'Metrics', impressions: number, views: number, clicks: number, visibility: number, ctr: number, osp: number }, creatives: { __typename?: 'CreativeResult', total: number, items: Array<{ __typename?: 'Creative', id: string, name: string, status: Types.ApiCreativeStatus, statusNote: string | null, destinationType: Types.ApiCreativeDestinationTypes, destinationUrl: string, destinationId: string | null, format: { __typename?: 'IdentifiedCreativeFormat', id: string, name: string }, contents: Array<{ __typename?: 'CreativeField', fieldId: string, type: Types.ApiCreativeFieldType, slug: string, name: string, position: number, value: string | null, mediaValue: { __typename?: 'CreativeFieldMediaValue', id: string, name: string, size: number, mimeType: string, path: string, url: string } | null }> }> }, advertisingTool: { __typename?: 'AdvertisingTool', id: string }, targets: Array<{ __typename?: 'ActivityTarget', targetId: string, valueLimit: number, values: Array<{ __typename?: 'ActivityTargetValue', id: string, value: string }> }> };

export type ApiActivityDetailCreativeFragment = { __typename?: 'Creative', id: string, name: string, status: Types.ApiCreativeStatus, statusNote: string | null, destinationType: Types.ApiCreativeDestinationTypes, destinationUrl: string, destinationId: string | null, format: { __typename?: 'IdentifiedCreativeFormat', id: string, name: string }, contents: Array<{ __typename?: 'CreativeField', fieldId: string, type: Types.ApiCreativeFieldType, slug: string, name: string, position: number, value: string | null, mediaValue: { __typename?: 'CreativeFieldMediaValue', id: string, name: string, size: number, mimeType: string, path: string, url: string } | null }> };

export type ApiActivityDetailCreativeContentFragment = { __typename?: 'CreativeField', fieldId: string, type: Types.ApiCreativeFieldType, slug: string, name: string, position: number, value: string | null, mediaValue: { __typename?: 'CreativeFieldMediaValue', id: string, name: string, size: number, mimeType: string, path: string, url: string } | null };

export type ApiActivityDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ApiActivityDetailQuery = { __typename?: 'Query', activity: { __typename?: 'Activity', id: string, campaignId: string, name: string, icon: Types.ApiAdvertisingToolIcon | null, status: Types.ApiActivityStatus, statusNote: string | null, startDate: string, endDate: string | null, rateType: Types.ApiAdvertisingToolRate, orderedAmount: number, unitPrice: number, totalPrice: number, metrics: { __typename?: 'Metrics', impressions: number, views: number, clicks: number, visibility: number, ctr: number, osp: number }, creatives: { __typename?: 'CreativeResult', total: number, items: Array<{ __typename?: 'Creative', id: string, name: string, status: Types.ApiCreativeStatus, statusNote: string | null, destinationType: Types.ApiCreativeDestinationTypes, destinationUrl: string, destinationId: string | null, format: { __typename?: 'IdentifiedCreativeFormat', id: string, name: string }, contents: Array<{ __typename?: 'CreativeField', fieldId: string, type: Types.ApiCreativeFieldType, slug: string, name: string, position: number, value: string | null, mediaValue: { __typename?: 'CreativeFieldMediaValue', id: string, name: string, size: number, mimeType: string, path: string, url: string } | null }> }> }, advertisingTool: { __typename?: 'AdvertisingTool', id: string }, targets: Array<{ __typename?: 'ActivityTarget', targetId: string, valueLimit: number, values: Array<{ __typename?: 'ActivityTargetValue', id: string, value: string }> }> } };

export const ActivityDetailCreativeContentFragmentDoc = gql`
    fragment ActivityDetailCreativeContent on CreativeField {
  fieldId
  type
  slug
  name
  position
  value
  mediaValue {
    id
    name
    size
    mimeType
    path
    url
  }
}
    `;
export const ActivityDetailCreativeFragmentDoc = gql`
    fragment ActivityDetailCreative on Creative {
  id
  name
  status
  statusNote
  format {
    id
    name
  }
  contents {
    ...ActivityDetailCreativeContent
  }
  destinationType
  destinationUrl
  destinationId
}
    ${ActivityDetailCreativeContentFragmentDoc}`;
export const ActivityDetailFragmentDoc = gql`
    fragment ActivityDetail on Activity {
  id
  campaignId
  name
  icon
  status
  statusNote
  startDate
  endDate
  rateType
  orderedAmount
  unitPrice
  totalPrice
  metrics {
    impressions
    views
    clicks
    visibility
    ctr
    osp
  }
  creatives {
    total
    items {
      ...ActivityDetailCreative
    }
  }
  advertisingTool {
    id
  }
  targets {
    targetId
    valueLimit
    values {
      id
      value
    }
  }
}
    ${ActivityDetailCreativeFragmentDoc}`;
export const ActivityDetailDocument = gql`
    query ActivityDetail($id: ID!) {
  activity(id: $id) {
    ...ActivityDetail
  }
}
    ${ActivityDetailFragmentDoc}`;

/**
 * __useActivityDetailQuery__
 *
 * To run a query within a React component, call `useActivityDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivityDetailQuery(baseOptions: Apollo.QueryHookOptions<ApiActivityDetailQuery, ApiActivityDetailQueryVariables> & ({ variables: ApiActivityDetailQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiActivityDetailQuery, ApiActivityDetailQueryVariables>(ActivityDetailDocument, options);
      }
export function useActivityDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiActivityDetailQuery, ApiActivityDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiActivityDetailQuery, ApiActivityDetailQueryVariables>(ActivityDetailDocument, options);
        }
export function useActivityDetailSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApiActivityDetailQuery, ApiActivityDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiActivityDetailQuery, ApiActivityDetailQueryVariables>(ActivityDetailDocument, options);
        }
export type ActivityDetailQueryHookResult = ReturnType<typeof useActivityDetailQuery>;
export type ActivityDetailLazyQueryHookResult = ReturnType<typeof useActivityDetailLazyQuery>;
export type ActivityDetailSuspenseQueryHookResult = ReturnType<typeof useActivityDetailSuspenseQuery>;
export type ActivityDetailQueryResult = Apollo.QueryResult<ApiActivityDetailQuery, ApiActivityDetailQueryVariables>;