import { Role, useLazySearch } from '@/app/common';
import { useSearchUsersLazyQuery, useSearchUsersSuspenseQuery } from './search-users.query.generated';
import { mapManagedPerson } from './user.mapper';
import { AgencyManagedPerson, VendorManagedPerson } from '../../interfaces';

type SearchResultItem = AgencyManagedPerson | VendorManagedPerson;

export type UseSearchUsersHook = {
  users: SearchResultItem[];
  searchUsers: (term: string) => void,
  error?: string;
};

export const useSearchUsers = (roles: Role[]): UseSearchUsersHook => {
  const { data, error } = useSearchUsersSuspenseQuery({
    variables: {
      limit: 15,
      search: null,
      roles,
    },
  });
  const [searchUsersQuery] = useSearchUsersLazyQuery();
  const initialValues = data.users.items.map(mapManagedPerson);
  const { results, search } = useLazySearch({
    initialValues,
    onSearch: async (term: string) => {
      const result = await searchUsersQuery({
        variables: {
          limit: 10,
          search: term,
          roles,
        },
      });

      if (result.error) {
        throw result.error;
      }

      if (!result.data) {
        throw new Error('Empty search response returned while data was expected');
      }

      return result.data.users.items.map(mapManagedPerson);
    },
  });

  return {
    users: results,
    searchUsers: search,
    error: error?.message,
  };
};
