import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import {
  Button,
  ChevronRightIcon,
  FieldWrapper,
  WhiteCard,
} from '@/app/components';
import { CampaignField } from './campaign-field';
import { CampaignFormRawData } from './select-campaign-form.interface';

interface SelectCampaignFormProps {
  children?: React.ReactNode;
  onSubmit: (campaignId: string) => Promise<void>;
  onCancel: () => void;
};

export const SelectCampaignForm: React.FC<SelectCampaignFormProps> = ({
  children,
  onSubmit,
  onCancel,
}) => {
  const [searchParams] = useSearchParams();
  const formMethods = useForm<CampaignFormRawData>({
    mode: 'onChange',
  });
  const {
    handleSubmit,
    formState: { isSubmitting, isValidating },
  } = formMethods;

  const doSubmit = async (formData: CampaignFormRawData) => {
    if (!formData.campaign?.value) {
      throw new Error('Select campaign form cannot be send without value.');
    }

    onSubmit(formData.campaign?.value);
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(doSubmit)} noValidate>
        <WhiteCard>
          {children}

          <div>
            <FieldWrapper>
              <CampaignField selectedCampaign={searchParams.get('campaign')} />
            </FieldWrapper>
          </div>
        </WhiteCard>

        <div className="flex justify-between mt-8">
          <div>
            <Button label="Cancel" variant="secondary" onClick={onCancel} />
          </div>

          <div>
            <Button
              type="submit"
              label="Proceed"
              variant="primary"
              icon={ChevronRightIcon}
              iconPosition="right"
              disabled={isSubmitting || isValidating}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
