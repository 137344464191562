import React from 'react';
import {
  EmptySlate,
  ListContentBox,
  PageContentBox,
} from '@/app/components';
export const Home: React.FC = () => {
  return(
    <PageContentBox fullHeight>
      <div className="flex justify-between mb-4">
        <h1 className="text-3xl">Dashboards</h1>
      </div>

      <ListContentBox fullHeight>
        <EmptySlate title="Dashboards">
          <p>
            Dashboards to be integrated soon&hellip;
          </p>
        </EmptySlate>
      </ListContentBox>
    </PageContentBox>
  );
};
