import { joiResolver } from '@hookform/resolvers/joi';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Button,
  ChevronRightIcon,
  ComboboxField,
  FieldWrapper,
  InputField,
  WhiteCard,
} from '@/app/components';
import { CampaignFormRawData } from './create-campaign-form.interface';
import { AgencySelect } from './searchable-agencies';
import { SecondaryContactSelect } from './searchable-agency-contacts';
import { PrimaryContactSelect } from './searchable-vendor-contacts';
import { validationSchema } from './validation.schema';
import { useSearchVendors } from '../../api';
import { CreateCampaignFormData } from '../../interfaces';

interface CreateCampaignFormProps {
  children?: React.ReactNode;
  onSubmit: (data: CreateCampaignFormData) => Promise<void>;
  onCancel: () => void;
};

export const CreateCampaignForm: React.FC<CreateCampaignFormProps> = ({
  children,
  onSubmit,
  onCancel,
}) => {
  const { searchVendors, vendors } = useSearchVendors();
  const resolver = joiResolver(validationSchema, {
    abortEarly: false,
  });
  const formMethods = useForm<CampaignFormRawData>({
    resolver,
    mode: 'onBlur',
    defaultValues: {
      name: '',
      vendor: null,
      primaryContact: null,
      agency: null,
      secondaryContact: null,
    },
  });
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting, isValidating },
  } = formMethods;

  const doSubmit = async (formData: CampaignFormRawData) => {
    if (!formData.vendor) throw new Error('Campaign vendor cannot be empty.');
    if (!formData.primaryContact) throw new Error('Campaign primary contact cannot be empty.');

    await onSubmit({
      ...formData,
      vendor: formData.vendor.value,
      primaryContact: formData.primaryContact.value,
      agency: formData.agency?.value ?? null,
      secondaryContact: formData.secondaryContact?.value ?? null,
    });
  };

  const handleSearchVendors = async (term: string) => {
    await searchVendors(term);
  };

  const selectedVendor = watch('vendor');
  const selectedAgency = watch('agency');
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(doSubmit)} noValidate>
        <WhiteCard>
          {children}

          <div>
            <FieldWrapper>
              <InputField
                {...register('name')}
                label="Campaign name"
                error={errors.name}
                required
              />
            </FieldWrapper>

            <FieldWrapper>
              <ComboboxField
                control={control}
                name="vendor"
                label="Advertiser"
                placeholder="Select Advertiser"
                onSearch={handleSearchVendors}
                options={vendors.map(vendor => ({
                  name: vendor.name,
                  value: vendor.id,
                }))}
                error={errors.vendor}
                required
              />
            </FieldWrapper>

            <FieldWrapper>
              <PrimaryContactSelect
                selectedVendor={selectedVendor ? selectedVendor.value : null}
              />
            </FieldWrapper>

            <FieldWrapper>
              <AgencySelect
                selectedVendor={selectedVendor ? selectedVendor.value : null}
              />
            </FieldWrapper>

            <FieldWrapper>
              <SecondaryContactSelect
                selectedAgency={selectedAgency ? selectedAgency.value : null}
              />
            </FieldWrapper>
          </div>
        </WhiteCard>

        <div className="flex justify-between mt-8">
          <div>
            <Button label="Cancel" variant="secondary" onClick={onCancel} />
          </div>

          <div>
            <Button
              type="submit"
              label="Proceed"
              variant="primary"
              icon={ChevronRightIcon}
              iconPosition="right"
              disabled={isSubmitting || isValidating}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
