import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Badge,
  Button,
  FieldWrapper,
  InfroStrip,
  InputField,
  Notification,
  ToggleSection,
  WhiteCard,
} from '@/app/components';
import { BrandsSearchField } from './brands-search-field';
import { VendorFormRawData } from './vendor-form.interface';
import {
  VendorBrandItem,
  VendorDetail,
  VendorFormData,
} from '../../interfaces';
import { SalesPersonField, TraffickerField } from '../form-fields';

interface VendorFormProps {
  vendor?: VendorDetail;
  vendorBrands?: VendorBrandItem[];
  onSubmit: (data: VendorFormData) => void;
  onCancel: () => void;
  error?: string | null;
};

export const VendorForm: React.FC<VendorFormProps> = ({
  vendor,
  vendorBrands = [],
  onSubmit,
  onCancel,
  error = null,
}) => {
  const isEditMode = vendor !== undefined;
  const formMethods = useForm<VendorFormRawData>({
    mode: 'onChange',
    defaultValues: {
      name: vendor?.name ?? '',
      businessId: vendor?.businessId ?? '',
      businessName: vendor?.businessName ?? '',
      vatNumber: vendor?.vatNumber ?? '',
      defaultSalesPerson: vendor?.defaultSalesPerson ? {
        value: vendor.defaultSalesPerson.id,
        name: vendor.defaultSalesPerson.name,
      } : null,
      defaultTrafficker: vendor?.defaultTrafficker ? {
        value: vendor.defaultTrafficker.id,
        name: vendor.defaultTrafficker.name,
      } : null,
      brands: vendorBrands.map(brand => ({
        value: brand.id,
        name: brand.name,
      })),
    },
  });
  const {
    control,
    setValue,
    watch,
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValidating },
  } = formMethods;

  const doSubmit = async (data: VendorFormRawData)=> {
    await onSubmit({
      name: data.name,
      businessId: data.businessId,
      businessName: data.businessName,
      vatNumber: data.vatNumber || null,
      defaultSalesPerson: data.defaultSalesPerson?.value ?? null,
      defaultTrafficker: data.defaultTrafficker?.value ?? null,
      brands: data.brands.map(brand => brand.value),
    });
  };

  const removeSelectedBrand = (brandId: string) => {
    const updatedSelection = watch('brands').filter((item) => item.value !== brandId);
    if (updatedSelection) {
      setValue('brands', updatedSelection);
    }
  };

  const selectedBrands = watch('brands');

  return (
    <>
      {error !== null && (
        <div className="mt-5 mb-7">
          <Notification type="error" color="red" label={error} />
        </div>
      )}

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(doSubmit)} noValidate>
          <ToggleSection title="General Info" margin="mb-5">
            <WhiteCard>
              <FieldWrapper>
                <InputField
                  {...register('name', {
                    required: 'This field is required and cannot be empty',
                    minLength: {
                      value: 3,
                      message: 'Field value has to contain at least 3 characters',
                    },
                    maxLength: {
                      value: 100,
                      message: 'Field value is too long',
                    },
                  })}
                  label="Name"
                  error={errors.name}
                  required
                />
              </FieldWrapper>

              <FieldWrapper>
                <InputField
                  {...register('businessName', {
                    required: 'This field is required and cannot be empty',
                    maxLength: {
                      value: 200,
                      message: 'Field value is too long',
                    },
                  })}
                  label="Business name"
                  error={errors.businessName}
                  required
                />
              </FieldWrapper>

              <FieldWrapper>
                <div className="flex gap-x-6">
                  <div className="w-1/2">
                    <InputField
                      {...register('businessId', {
                        required: 'This field is required and cannot be empty',
                        maxLength: {
                          value: 100,
                          message: 'Field value is too long',
                        },
                      })}
                      label="Company ID"
                      error={errors.businessId}
                      required
                    />
                  </div>

                  <div className="w-1/2">
                    <InputField
                      {...register('vatNumber', {
                        maxLength: {
                          value: 100,
                          message: 'Field value is too long',
                        },
                      })}
                      label="VAT"
                      error={errors.vatNumber}
                    />
                  </div>
                </div>
              </FieldWrapper>
            </WhiteCard>
          </ToggleSection>

          <ToggleSection title="Default Contacts" margin="mb-5">
            <WhiteCard>
              <FieldWrapper>
                <SalesPersonField control={control} name="defaultSalesPerson" />
              </FieldWrapper>

              <FieldWrapper>
                <TraffickerField control={control} name="defaultTrafficker" />
              </FieldWrapper>
            </WhiteCard>
          </ToggleSection>

          <ToggleSection title="Brands" margin="mb-5">
            <div className="mb-4">
              <InfroStrip
                type="info"
                label={
                  <span>
                    Brands can be added later. You can only select those that have been <br/>
                    previously imported in Brands section.
                  </span>
                }
              />
            </div>

            <WhiteCard>
              <FieldWrapper>
                <BrandsSearchField />
              </FieldWrapper>

              <FieldWrapper>
                {selectedBrands.length > 0 ? (
                  <div className="flex flex-wrap gap-x-1 gap-y-1.5 mt-4">
                    {selectedBrands.map((brand) => (
                      <Badge
                        key={`badge-${brand.value}`}
                        label={brand.name}
                        color="black"
                        size="sm"
                        onClick={() => removeSelectedBrand(brand.value)}
                      />
                    ))}
                  </div>
                ) : (
                  <InfroStrip
                    label="Currently there are no added brands"
                    type="info"
                  />
                )}
              </FieldWrapper>
            </WhiteCard>
          </ToggleSection>

          <div className="flex justify-between">
            <div>
              <Button label="Cancel" variant="secondary" onClick={onCancel} />
            </div>

            <div>
              <Button
                type="submit"
                label={isEditMode ? 'Save Advertiser' : 'Create Advertiser'}
                variant="primary"
                iconPosition="right"
                disabled={isSubmitting || isValidating}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};
