import { mapApiLocaleToLocale, mapApiTimezoneToTimezone } from '@/app/common';
import {
  ApiVendorBrandItemFragment,
  ApiVendorDetailFragment,
  ApiVendorUserItemFragment,
} from './get-vendor-detail.query.generated';
import {
  VendorBrandItem,
  VendorDetail,
  VendorUserItem,
} from '../../interfaces';

export const mapVendorDetail = (
  agency: ApiVendorDetailFragment,
): VendorDetail => ({
  id: agency.id,
  name: agency.name,
  businessId: agency.billingInfo.businessId,
  businessName: agency.billingInfo.businessName,
  vatNumber: agency.billingInfo.vatNumber || null,
  defaultSalesPerson: agency.defaultSalesPerson ? {
    id: agency.defaultSalesPerson.id,
    name: agency.defaultSalesPerson.name,
  } : null,
  defaultTrafficker: agency.defaultTrafficker ? {
    id: agency.defaultTrafficker.id,
    name: agency.defaultTrafficker.name,
  } : null,
});

export const mapVendorUser = (
  user: ApiVendorUserItemFragment,
): VendorUserItem => ({
  id: user.id,
  username: user.username,
  name: user.name,
  locale: user.locale && mapApiLocaleToLocale(user.locale) || null,
  timezone: user.timezone && mapApiTimezoneToTimezone(user.timezone) || null,
  state: 'active',
});

export const mapVendorBrand = (
  brand: ApiVendorBrandItemFragment,
): VendorBrandItem => ({
  id: brand.id,
  name: brand.name,
});
