import { ApiCreativeDestinationTypes, ApiCreativeFieldType, CreativeFieldType } from '@/app/common';
import { useCreateCreativeMutation } from './create-creative.query.generated';
import { mapCreative } from './creative.mapper';
import { ActivityCreativeItem, CreativeFormData } from '../../interfaces';

type UseCreateCreativeHook = {
  createCreative: (
    vendorId: string,
    formatId: string,
    formData: CreativeFormData,
  ) => Promise<ActivityCreativeItem>;
  loading: boolean;
};

export const useCreateCreative = (): UseCreateCreativeHook => {
  const [creativeMutation, { loading }] = useCreateCreativeMutation();

  const createCreative = async (
    vendorId: string,
    formatId: string,
    formData: CreativeFormData,
  ) => {
    const doSubmit = async () => {
      const { data, errors } = await creativeMutation({
        variables: {
          ...formData,
          vendorId,
          formatId,
          contents: Object.values(formData.contents).map((content, index) => ({
            ...content,
            position: index + 1,
            type: mapFieldType(content.type),
          })),
          destination: {
            ...formData.destination,
            type: mapDestinationType(formData.destination.type),
            product: formData.destination.product ? {
              id: formData.destination.product.id,
              catalog: formData.destination.product.catalog,
              externalId: formData.destination.product.externalId,
              title: formData.destination.product.title,
              url: formData.destination.product.url,
            } : null,
          },
        },
      });

      // Error
      if (errors !== undefined && errors.length > 0) {
        const errorMessage = errors[0].message;
        throw new Error(errorMessage);
      }

      // No error, no data
      if (!data) {
        throw new Error('Server returned empty unexpected response.');
      }

      return data;
    };

    const result = await doSubmit();
    return mapCreative(result.createCreative);
  };

  return {
    createCreative,
    loading,
  };
};

const mapDestinationType = (destinationType: string) => {
  switch (destinationType) {
    case 'product': return ApiCreativeDestinationTypes.Product;
    case 'lp': return ApiCreativeDestinationTypes.Lp;
    case 'custom': return ApiCreativeDestinationTypes.Custom;

    default:
      throw new Error(`Unable to convert destination type to ApiConvertType for unknown value ${destinationType}`);
  }
};

const mapFieldType = (fieldType: CreativeFieldType) => {
  switch (fieldType) {
    case 'image': return ApiCreativeFieldType.Image;
    case 'imageConversion': return ApiCreativeFieldType.ImageConversion;
    case 'number': return ApiCreativeFieldType.Number;
    case 'product': return ApiCreativeFieldType.Product;
    case 'sku': return ApiCreativeFieldType.Sku;
    case 'text': return ApiCreativeFieldType.Text;

    default:
      throw new Error(`Unable to convert field type to ApiFieldType for unknown value ${fieldType}`);
  }
};
