import { CommonDialog, DialogFooter, DialogHeader } from '@/app/components';

interface ChangeToolDialogProps {
  isOpened: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

export const ChangeToolDialog: React.FC<ChangeToolDialogProps> = ({
  isOpened, onConfirm, onClose,
}) => (
  <CommonDialog isOpen={isOpened} onClose={onClose}>
    <DialogHeader
      title="Change tool"
      description="If you change already selected tool, all previous Line item settings will be lost. Are you sure?"
    />
    <DialogFooter onConfirm={onConfirm} onClose={onClose} />
  </CommonDialog>
);
