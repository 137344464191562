import React from 'react';
import { Page } from './page';
import { ChevronRightIcon } from '../../icons';

interface NextProps {
  page: number;
  disabled?: boolean;
};

export const Next: React.FC<NextProps> = ({
  page,
  disabled = false,
}) => (
  <Page
    page={page}
    active={false}
    disabled={disabled}
    aria-label="Další"
  >
    <span className="stroke-slate-800 hover:stroke-purple-600">
      {ChevronRightIcon}
    </span>
  </Page>
);
