/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiAuthContextFragment = { __typename?: 'AuthContext', id: string, orgId: string, marketId: string, marketName: string, userId: string };

export type ApiContextsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ApiContextsQuery = { __typename?: 'Query', contexts: Array<{ __typename?: 'AuthContext', id: string, orgId: string, marketId: string, marketName: string, userId: string }> };

export const AuthContextFragmentDoc = gql`
    fragment AuthContext on AuthContext {
  id
  orgId
  marketId
  marketName
  userId
}
    `;
export const ContextsDocument = gql`
    query contexts {
  contexts {
    ...AuthContext
  }
}
    ${AuthContextFragmentDoc}`;

/**
 * __useContextsQuery__
 *
 * To run a query within a React component, call `useContextsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContextsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContextsQuery({
 *   variables: {
 *   },
 * });
 */
export function useContextsQuery(baseOptions?: Apollo.QueryHookOptions<ApiContextsQuery, ApiContextsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiContextsQuery, ApiContextsQueryVariables>(ContextsDocument, options);
      }
export function useContextsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiContextsQuery, ApiContextsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiContextsQuery, ApiContextsQueryVariables>(ContextsDocument, options);
        }
export function useContextsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApiContextsQuery, ApiContextsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiContextsQuery, ApiContextsQueryVariables>(ContextsDocument, options);
        }
export type ContextsQueryHookResult = ReturnType<typeof useContextsQuery>;
export type ContextsLazyQueryHookResult = ReturnType<typeof useContextsLazyQuery>;
export type ContextsSuspenseQueryHookResult = ReturnType<typeof useContextsSuspenseQuery>;
export type ContextsQueryResult = Apollo.QueryResult<ApiContextsQuery, ApiContextsQueryVariables>;