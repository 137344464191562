import React, { Suspense } from 'react';
import {
  EmptySlate,
  ListContentBox,
  Loader,
  PageContentBox,
} from '@/app/components';
import { CreativeFilter, CreativesList } from './components';
import { CreativeFiltersProvider } from './hooks';

export const CreativeLibraryListPage: React.FC = () => {

  return (
    <PageContentBox fullHeight>
      <header className="flex justify-between mb-4">
        <h1 className="text-3xl">Creative Library</h1>
      </header>

      <CreativeFiltersProvider>
        <CreativeFilter />

        <ListContentBox fullHeight>
          <Suspense fallback={<Loader />}>
            <CreativesList>
              <EmptySlate title="Creatives">
                <p>
                  It seems like you haven't created any creatives yet&hellip;
                </p>
              </EmptySlate>
            </CreativesList>
          </Suspense>
        </ListContentBox>
      </CreativeFiltersProvider>
    </PageContentBox>
  );
};
