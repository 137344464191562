import React from 'react';
import { LanguageSwitcher } from './language-switcher';
import { MarketSwitcher } from './market-switcher';
import { UserPanel } from './user-panel';

type HeaderProps = {
  adminModeEnabled: boolean;
  switchAdminMode: () => void;
};

export const Header: React.FC<HeaderProps> = ({
  adminModeEnabled,
  switchAdminMode,
}) => {
  return (
    <div className="flex justify-between mb-7">
      <div className="flex items-center">
        <nav aria-label="Breadcrumb">
          <ol>
            <li className="text-sm" aria-current="page">Kampaně</li>
          </ol>
        </nav>
      </div>

      <div className="flex space-x-2">
        <LanguageSwitcher />
        <MarketSwitcher showFlags={false} />
        <UserPanel
          adminModeEnabled={adminModeEnabled}
          switchAdminMode={switchAdminMode}
        />
      </div>
    </div>
  );
};
