import debounce from 'lodash.debounce';
import { useRef } from 'react';
import { UseSearchHook, UseSearchProps, useSearch } from './use-search';

type UseLazySearchProps<T> = UseSearchProps<T>;

type UseLazySearchHook<T> = UseSearchHook<T>;

export const useLazySearch = <T,>(props: UseLazySearchProps<T>): UseLazySearchHook<T> => {
  const { search, results, error } = useSearch<T>(props);
  const debouncedSearch = useRef(debounce(search, 300));

  return {
    search: debouncedSearch.current,
    results,
    error,
  };
};
