import { useCreateCampaignMutation } from './create-campaign.query.generated';
import { CreateCampaignFormData } from '../../interfaces';

type UseCreateCampaignHook = {
  createCampaign: (formData: CreateCampaignFormData) => Promise<{ id: string }>;
  loading: boolean;
};

export const useCreateCampaign = (): UseCreateCampaignHook => {
  const [campaignMutation, { loading }] = useCreateCampaignMutation();

  const createCampaign = async (formData: CreateCampaignFormData) => {
    const doSubmit = async () => {
      const { data, errors } = await campaignMutation({
        variables: formData,
      });

      // Error
      if (errors !== undefined && errors.length > 0) {
        const errorMessage = errors[0].message;
        throw new Error(errorMessage);
      }

      // No error, no data
      if (!data) {
        throw new Error('Server returned empty unexpected response.');
      }

      return data;
    };

    const result = await doSubmit();
    return {
      id: result.createCampaign.id,
    };
  };

  return {
    createCampaign,
    loading,
  };
};
