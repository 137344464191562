import { useMemo } from 'react';
import { ActionArgType, StateMachineInterface, createStateMachine, useStateMachine, useUserRole } from '@/app/common';
import {
  ActivityStatus,
  ActivityStatusTransitions,
} from '../../interfaces';

export type UseActivityStatusHook = [
  StateMachineInterface<ActivityStatus, ActivityStatusTransitions>,
  updateState: (transition: ActivityStatusTransitions, ...data: ActionArgType[]) => void,
];

export const useActivityStatus = (
  initialStatus: ActivityStatus,
  actions: Record<ActivityStatusTransitions, (...data: ActionArgType[]) => Promise<void>>,
): UseActivityStatusHook => {
  const { isRetailer } = useUserRole();
  const stateMachine = useMemo(() => createStateMachine<ActivityStatus, ActivityStatusTransitions>({
    initial: initialStatus,
    transitions: isRetailer()
      ? [
        // approval steps
        { name: 'APPROVE', from: 'draft', to: 'approved' },
        { name: 'REJECT', from: 'draft', to: 'rejected' },
        { name: 'APPROVE', from: 'pendingApproval', to: 'approved' },
        { name: 'REJECT', from: 'pendingApproval', to: 'rejected' },
        { name: 'APPROVE', from: 'rejected', to: 'approved' },
        { name: 'REJECT', from: 'rejected', to: 'rejected' },

        // cancel steps
        { name: 'CANCEL', from: 'approved', to: 'cancelled' },
        { name: 'CANCEL', from: 'draft', to: 'cancelled' },
        { name: 'CANCEL', from: 'incomplete', to: 'cancelled' },
        { name: 'CANCEL', from: 'online', to: 'cancelled' },
        { name: 'CANCEL', from: 'paused', to: 'cancelled' },
        { name: 'CANCEL', from: 'pendingApproval', to: 'cancelled' },
        { name: 'CANCEL', from: 'ready', to: 'cancelled' },
        { name: 'CANCEL', from: 'rejected', to: 'cancelled' },

        // pause/resume
        { name: 'PAUSE', from: 'online', to: 'paused' },
        { name: 'RESUME', from: 'paused', to: 'online' },
      ] : [
        { name: 'REQUEST', from: 'draft', to: 'pendingApproval' },
        { name: 'REQUEST', from: 'rejected', to: 'pendingApproval' },
      ],
  }), [initialStatus]);

  return useStateMachine<ActivityStatus, ActivityStatusTransitions>(stateMachine, { actions });
};
