/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiAgencyUserItemFragment = { __typename?: 'AgencyUser', id: string, username: string, name: string, locale: Types.ApiLocale | null, timezone: Types.ApiTimezone | null };

export type ApiAgencyVendorItemFragment = { __typename?: 'LeanVendor', id: string, name: string, billingInfo: { __typename?: 'CompanyBilling', businessName: string, businessId: string, vatNumber: string | null } };

export type ApiAgencyDetailFragment = { __typename?: 'Agency', id: string, name: string, billingInfo: { __typename?: 'CompanyBilling', businessName: string, businessId: string, vatNumber: string | null }, defaultSalesPerson: { __typename?: 'LeanUser', id: string, name: string } | null, defaultTrafficker: { __typename?: 'LeanUser', id: string, name: string } | null, vendors: { __typename?: 'AgencyVendorResult', total: number, items: Array<{ __typename?: 'LeanVendor', id: string, name: string, billingInfo: { __typename?: 'CompanyBilling', businessName: string, businessId: string, vatNumber: string | null } }> }, users: { __typename?: 'AgencyUserResult', total: number, items: Array<{ __typename?: 'AgencyUser', id: string, username: string, name: string, locale: Types.ApiLocale | null, timezone: Types.ApiTimezone | null }> } };

export type ApiAgencyQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type ApiAgencyQuery = { __typename?: 'Query', agency: { __typename?: 'Agency', id: string, name: string, billingInfo: { __typename?: 'CompanyBilling', businessName: string, businessId: string, vatNumber: string | null }, defaultSalesPerson: { __typename?: 'LeanUser', id: string, name: string } | null, defaultTrafficker: { __typename?: 'LeanUser', id: string, name: string } | null, vendors: { __typename?: 'AgencyVendorResult', total: number, items: Array<{ __typename?: 'LeanVendor', id: string, name: string, billingInfo: { __typename?: 'CompanyBilling', businessName: string, businessId: string, vatNumber: string | null } }> }, users: { __typename?: 'AgencyUserResult', total: number, items: Array<{ __typename?: 'AgencyUser', id: string, username: string, name: string, locale: Types.ApiLocale | null, timezone: Types.ApiTimezone | null }> } } };

export const AgencyVendorItemFragmentDoc = gql`
    fragment AgencyVendorItem on LeanVendor {
  id
  name
  billingInfo {
    businessName
    businessId
    vatNumber
  }
}
    `;
export const AgencyUserItemFragmentDoc = gql`
    fragment AgencyUserItem on AgencyUser {
  id
  username
  name
  locale
  timezone
}
    `;
export const AgencyDetailFragmentDoc = gql`
    fragment AgencyDetail on Agency {
  id
  name
  billingInfo {
    businessName
    businessId
    vatNumber
  }
  defaultSalesPerson {
    id
    name
  }
  defaultTrafficker {
    id
    name
  }
  vendors(page: 1, limit: 100) {
    total
    items {
      ...AgencyVendorItem
    }
  }
  users(page: 1, limit: 100) {
    total
    items {
      ...AgencyUserItem
    }
  }
}
    ${AgencyVendorItemFragmentDoc}
${AgencyUserItemFragmentDoc}`;
export const AgencyDocument = gql`
    query Agency($id: String!) {
  agency(id: $id) {
    ...AgencyDetail
  }
}
    ${AgencyDetailFragmentDoc}`;

/**
 * __useAgencyQuery__
 *
 * To run a query within a React component, call `useAgencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgencyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAgencyQuery(baseOptions: Apollo.QueryHookOptions<ApiAgencyQuery, ApiAgencyQueryVariables> & ({ variables: ApiAgencyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiAgencyQuery, ApiAgencyQueryVariables>(AgencyDocument, options);
      }
export function useAgencyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiAgencyQuery, ApiAgencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiAgencyQuery, ApiAgencyQueryVariables>(AgencyDocument, options);
        }
export function useAgencySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApiAgencyQuery, ApiAgencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiAgencyQuery, ApiAgencyQueryVariables>(AgencyDocument, options);
        }
export type AgencyQueryHookResult = ReturnType<typeof useAgencyQuery>;
export type AgencyLazyQueryHookResult = ReturnType<typeof useAgencyLazyQuery>;
export type AgencySuspenseQueryHookResult = ReturnType<typeof useAgencySuspenseQuery>;
export type AgencyQueryResult = Apollo.QueryResult<ApiAgencyQuery, ApiAgencyQueryVariables>;