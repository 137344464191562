import {
  AustriaFlagIcon,
  CzechFlagIcon,
  HungaryFlagIcon,
  PolandFlagIcon,
  SlovakiaFlagIcon,
} from '@/app/components';

export const getFlagIcon = (market: string) => {
  switch (market) {
    case 'cz':
      return CzechFlagIcon;
    case 'pl':
      return PolandFlagIcon;
    case 'sk':
      return SlovakiaFlagIcon;
    case 'au':
      return AustriaFlagIcon;
    case 'hu':
      return HungaryFlagIcon;
  }
};
