/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiDeleteAgencyMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type ApiDeleteAgencyMutation = { __typename?: 'Mutation', deleteAgency: boolean };


export const DeleteAgencyDocument = gql`
    mutation DeleteAgency($id: String!) {
  deleteAgency(id: $id)
}
    `;
export type ApiDeleteAgencyMutationFn = Apollo.MutationFunction<ApiDeleteAgencyMutation, ApiDeleteAgencyMutationVariables>;

/**
 * __useDeleteAgencyMutation__
 *
 * To run a mutation, you first call `useDeleteAgencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAgencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAgencyMutation, { data, loading, error }] = useDeleteAgencyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAgencyMutation(baseOptions?: Apollo.MutationHookOptions<ApiDeleteAgencyMutation, ApiDeleteAgencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiDeleteAgencyMutation, ApiDeleteAgencyMutationVariables>(DeleteAgencyDocument, options);
      }
export type DeleteAgencyMutationHookResult = ReturnType<typeof useDeleteAgencyMutation>;
export type DeleteAgencyMutationResult = Apollo.MutationResult<ApiDeleteAgencyMutation>;
export type DeleteAgencyMutationOptions = Apollo.BaseMutationOptions<ApiDeleteAgencyMutation, ApiDeleteAgencyMutationVariables>;