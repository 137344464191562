import { useDialogContext } from '@/app/common';
import {
  Button,
  FieldError,
  FieldWrapper,
  InputField,
  Selectbox,
  ToggleSection,
  WhiteCard,
} from '@/app/components';
import { targetingOptions } from '../../utils';

interface ActivityBuilderSkeletonProps {
  error?: FieldError;
};

export const ActivityBuilderSkeleton: React.FC<ActivityBuilderSkeletonProps> = ({
  error,
}) => {
  const { openDialog } = useDialogContext();

  return (
    <ToggleSection title="Line item" margin="mb-5">
      <WhiteCard>
        <section className="my-4">
          <div className="flex justify-center w-full border rounded-sm border-dashed border-slate-300 p-4">
            <div className="  ">
              <Button
                label="Add advertising tool"
                variant="secondary"
                onClick={() => openDialog('advertising-detail')}
              />
            </div>
          </div>

          {error !== undefined && (
            <p className="mt-1 text-xs text-red-700 leading-4">{error.message}</p>
          )}
        </section>

        <FieldWrapper>
          <Selectbox
            id="createActivity.target"
            options={targetingOptions}
            label="Targeting"
            disabled
          />
        </FieldWrapper>

        <FieldWrapper>
          <div className="flex items-start gap-x-4">
            <div className="grow shrink basis-0">
              <InputField
                type="date"
                name="createActivity.startDate"
                label="From"
                placeholder="Pick a date"
                disabled
              />
            </div>

            <div className="grow shrink basis-0">
              <InputField
                type="date"
                name="createActivity.endDate"
                label="Until"
                placeholder="Pick a date"
                disabled
              />
            </div>

            <div className="pt-9 shrink">
              <button
                type="button"
                disabled
                className="text-sm text-slate-600"
              >
                Check availability
              </button>
            </div>
          </div>
        </FieldWrapper>
      </WhiteCard>
    </ToggleSection>
  );
};
