import React, { Suspense } from 'react';
import { Control, FieldValues, Path } from 'react-hook-form';
import { ComboboxField, ComboboxSearch } from '@/app/components';
import { useSearchTraffickerUsers } from '../../api';

interface TraffickerFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
};

export const TraffickerField = <T extends FieldValues>({
  control,
  name,
}: TraffickerFieldProps<T>) => {
  return (
    <Suspense fallback={<TraffickerFieldSkeleton />}>
      <TraffickersLoader control={control} name={name} />
    </Suspense>
  );
};

interface TraffickersLoaderProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
};

export const TraffickersLoader = <T extends FieldValues>({
  control,
  name,
}: TraffickersLoaderProps<T>) => {
  const { users, searchUsers } = useSearchTraffickerUsers();
  const handleSearch = (term: string) => {
    searchUsers(term);
  };

  return (
    <ComboboxField
      control={control}
      name={name}
      label="Default Trafficker"
      placeholder="Select trafficker..."
      options={users.map(user => ({
        value: user.id,
        name: user.name,
      }))}
      onSearch={handleSearch}
    />
  );
};

export const TraffickerFieldSkeleton = () => (
  <ComboboxSearch
    id="vendorForm.trafficker"
    options={[]}
    label="Default Trafficker"
    placeholder="Select trafficker..."
    disabled
  />
);
