import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import React from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  ComboboxField,
  DefinitionList,
  FieldWrapper,
  InputField,
  RadioButton,
  RoundedFrame,
  WhiteCard,
} from '@/app/components';
import { CreativeFormRawData } from './activity-form.interface';
import { CreativeEditorField } from './creative-editor-field';
import { useCreateCreative, useDestinations, useUpdateCreative } from '../../api';
import {
  ActivityCreativeItem,
  CampaignDetail,
  CreativeFormat,
  CreativeFormatField,
} from '../../interfaces';
import { mockedLandingPageOptions } from '../../mocked-data';

interface CreativeEditorProps {
  campaign: CampaignDetail;
  format: CreativeFormat;
  creative?: ActivityCreativeItem;
  onSubmit: (creative: ActivityCreativeItem) => void;
  onCancel: () => void;
};

enum Destination {
  PRODUCT = 'product',
  LP = 'lp',
  CUSTOM = 'custom',
};

export const CreativeEditor: React.FC<CreativeEditorProps> = ({
  campaign,
  format,
  creative = undefined,
  onSubmit,
  onCancel,
}) => {
  const { createCreative } = useCreateCreative();
  const { updateCreative } = useUpdateCreative();
  const destinations = useDestinations(creative?.destinationType, creative?.destinationId);
  const resolver = joiResolver(createValidationSchema(format), { abortEarly: false });
  const {
    register,
    control,
    watch,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<{ creative: CreativeFormRawData }>({
    mode: 'onChange',
    resolver,
    defaultValues: {
      creative: {
        name: creative?.name ?? '',
        destinationType: creative?.destinationType ?? '',
        destinationCustomUrl: creative?.destinationType === 'custom' ? creative?.destinationUrl : '',
        destinationProduct: creative?.destinationType === 'product' ? destinations.products[0] : null,
        destinationLandingPage: null,
        contents: (creative?.contents ?? []).reduce((stack, current) => ({
          ...stack,
          [current.fieldId]: current.mediaValue ?? current.value ?? null,
        }), {}),
      },
    },
  });

  const handleSaveCreative = async (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const isValid = await trigger('creative', { shouldFocus: true });
    if (!isValid) {
      return;
    }

    const {
      destinationType,
      destinationCustomUrl,
      destinationProduct,
      destinationLandingPage: _destinationLandingPage,
      ...formData
    } = getValues('creative');

    const creativeBody = {
      ...formData,
      destination: {
        type: destinationType,
        url: destinationProduct?.url ?? destinationCustomUrl,
        product: destinationProduct,
      },
      contents: Object.keys(formData.contents).map(fieldId => {
        const field = format.fieldsById[fieldId];
        const value = formData.contents[fieldId];
        let normalizedValue = null;
        if (value && typeof value === 'string' && value.length > 0) {
          normalizedValue = value;
        } else if (value && typeof value === 'object') {
          normalizedValue = value.id;
        }

        return {
          fieldId,
          type: field.type,
          slug: field.slug,
          name: field.name,
          value: normalizedValue,
        };
      }),
    };

    const response = creative === undefined
      ? await createCreative(campaign.vendorId, format.id, creativeBody)
      : await updateCreative(creative.id, creativeBody);

    onSubmit(response);
  };

  const handleCancelCreative = () => {
    onCancel();
  };

  const handleEnterPress = (event: React.KeyboardEvent) => {
    event.preventDefault();
    trigger('creative');
  };

  const handleSearchProductDestination = async (term: string) => {
    await destinations.searchProducts(term);
  };

  const selectedDestination = watch('creative.destinationType');
  return (
    <div className="mt-4">
      <WhiteCard>
        <FieldWrapper>
          <RoundedFrame>
            <DefinitionList columns={1} data={[
              { term: 'Creative format', description: format.name },
            ]} />
          </RoundedFrame>
        </FieldWrapper>

        <FieldWrapper>
          <InputField
            {...register('creative.name', { required: true })}
            label="Creative name"
            error={errors.creative?.name}
            onEnter={handleEnterPress}
            required
          />
        </FieldWrapper>

        {format.fields.map(field => (
          <FieldWrapper key={field.id}>
            <CreativeEditorField
              {...register(`creative.contents.${field.id}`, { required: field.required })}
              type={field.type}
              label={field.name}
              id={`creative.contents.${field.id}`}
              control={control}
              required={field.required}
              error={errors.creative?.contents?.[field.id]}
              onEnter={handleEnterPress}
            />
          </FieldWrapper>
        ))}

        <FieldWrapper>
          <div className="text-sm  mb-2">
            Click Destination
            {errors.creative?.destinationType && (
              <span className="text-xs text-red-500 block">
                {errors.creative.destinationType.message?.toString()}
              </span>
            )}
          </div>

          <div
            className={`
              flex
              flex-wrap
              border
              rounded-sm
              border-gray-300
              px-4
              py-3
              mb-2
              ${selectedDestination === Destination.PRODUCT ? 'border-purple-600 bg-purple-50' : ''}
            `}
          >
            <div className="w-full py-2">
              <div className="mb-3">
                <RadioButton
                  {...register('creative.destinationType', {
                    required: 'Select a destination for your clicks.',
                  })}
                  label="Product"
                  id="creative.destinationType.product"
                  //onChange={handleDestinationChange}
                  value={Destination.PRODUCT}
                />
              </div>

              <div className="pl-8">
                <ComboboxField
                  name="creative.destinationProduct"
                  control={control}
                  options={destinations.products.map(value => ({
                    ...value,
                    name: value.title,
                  }))}
                  onSearch={handleSearchProductDestination}
                  disabled={selectedDestination !== Destination.PRODUCT}
                  rules={{
                    required: 'Select destination product for your clicks.',
                  }}
                  required
                />
              </div>
            </div>
          </div>

          <div
            className={`
              flex
              flex-wrap
              border
              rounded-sm
              border-gray-300
              px-4
              py-3
              mb-2
              ${selectedDestination === Destination.LP ? 'border-purple-600 bg-purple-50' : ''}
            `}
          >
            <div className="w-full py-2">
              <div className="mb-3 flex justify-between items-center">
                <RadioButton
                  {...register('creative.destinationType', {
                    required: 'Select a destination for your clicks.',
                  })}
                  label="Landing page"
                  id="creative.destinationType.landingPage"
                  value={Destination.LP}
                />
                <button className="text-purple-600 text-sm">Create new</button>
              </div>

              <div className="pl-8">
                <ComboboxField
                  name="creative.destinationLandingPage"
                  control={control}
                  options={mockedLandingPageOptions}
                  disabled={selectedDestination !== Destination.LP}
                  rules={{
                    required: 'Select destination product for your clicks.',
                  }}
                  required
                />
              </div>
            </div>
          </div>

          <div
            className={`
              flex
              flex-wrap
              border
              rounded-sm
              border-gray-300
              px-4
              py-3
              ${selectedDestination === Destination.CUSTOM ? 'border-purple-600 bg-purple-50' : ''}
            `}
          >
            <div className="w-full py-2">
              <div className="mb-3">
                <RadioButton
                  {...register('creative.destinationType', {
                    required: 'Select a destination for your clicks.',
                  })}
                  label="Custom URL"
                  id="creative.destinationType.custom"
                  value={Destination.CUSTOM}
                />
              </div>

              <div className="pl-8">
                <InputField
                  {...register('creative.destinationCustomUrl', {
                    required: selectedDestination === Destination.CUSTOM,
                  })}
                  placeholder="https://"
                  disabled={selectedDestination !== Destination.CUSTOM}
                  error={errors.creative?.destinationCustomUrl}
                />
              </div>
            </div>
          </div>
        </FieldWrapper>
      </WhiteCard>

      <div className="bg-slate-50 pb-4 pt-8 -mt-4 rounded-b-sm shadow-bottom">
        <div className="flex justify-center space-x-4">
          <div>
            <Button label="Cancel" variant="secondary" onClick={handleCancelCreative} />
          </div>

          <div>
            <Button
              label="Save"
              variant="primary"
              onClick={handleSaveCreative}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const createValidationSchema = (format: CreativeFormat) => {
  const fieldsSchema = format.fields.reduce((stack, field) => {
    const operators = {
      string: ['min', 'max', 'required'],
      number: ['integer', 'min', 'max', 'required'],
    } as const;

    const createFieldSchema = (field: CreativeFormatField) => {
      if (field.type === 'number') {
        const getMessage = () => {
          if (field.min !== null && field.max !== null) {
            return `Must be number between ${field.min} and ${field.max}.`;
          } else if (field.min !== null) {
            return `Minimal value ${field.min} required.`;
          }

          return `Maximal value ${field.max} exceeded.`;
        };

        return operators.number.reduce((schema, op) => {
          switch (op) {
            case 'integer': return schema.integer();
            case 'min': return field.min !== null ? schema.min(field.min) : schema;
            case 'max': return field.max !== null ? schema.max(field.max) : schema;
            case 'required': return field.required ? schema.required() : schema.allow('', null);
            default: return schema;
          }
        }, Joi.number().messages({
          'number.min': getMessage(),
          'number.max': getMessage(),
        }));
      }

      if (field.type === 'image') {
        return operators.number.reduce((schema, op) => {
          switch (op) {
            case 'required': return field.required ? schema.required() : schema.allow('', null);
            default: return schema;
          }
        }, Joi.object());
      }

      if (field.type === 'text') {
        const getMessage = () => {
          if (field.minLength !== null && field.maxLength !== null) {
            return `Must be between ${field.minLength} and ${field.maxLength} characters long.`;
          } else if (field.minLength !== null) {
            return `Minimum ${field.minLength} characters required.`;
          }

          return `Maximum ${field.maxLength} characters exceeded.`;
        };

        return operators.number.reduce((schema, op) => {
          switch (op) {
            case 'min': return field.minLength !== null ? schema.min(field.minLength) : schema;
            case 'max': return field.maxLength !== null ? schema.max(field.maxLength) : schema;
            case 'required': return field.required ? schema.required() : schema.allow('', null);
            default: return schema;
          }
        }, Joi.string().messages({
          'string.min': getMessage(),
          'string.max': getMessage(),
        }));
      }

      return Joi.any();
    };

    return {
      ...stack,
      [field.id]: createFieldSchema(field),
    };
  }, {});

  return Joi
    .object({
      creative: Joi.object().keys({
        name: Joi.string().max(250).required(),
        destinationType: Joi.string().required(),
        destinationCustomUrl: Joi
          .string()
          .uri()
          .max(1000)
          .when('destinationType', {
            is: Destination.CUSTOM,
            then: Joi.required(),
            otherwise: Joi.allow('').optional(),
          }),
        destinationProduct: Joi
          .object()
          .when('destinationType', {
            is: Destination.PRODUCT,
            then: Joi.required(),
            otherwise: Joi.allow('', null).optional(),
          })
          .messages({
            'object.base': 'Select destination product for your clicks.',
          }),
        destinationLandingPage: Joi
          .object()
          .when('destinationType', {
            is: Destination.LP,
            then: Joi.required(),
            otherwise: Joi.allow('', null).optional(),
          })
          .messages({
            'object.base': 'Select destination landing page for your clicks.',
          }),
        contents: Joi.object().keys({
          ...fieldsSchema,
        }).unknown(true),
      }).unknown(true),
    })
    .messages({
      'string.base': 'This field is required and cannot be empty',
      'string.empty': 'This field is required and cannot be empty',
      'string.max': 'Field value is too long',
      'string.uri': 'Field value has to be a valid URL',
      'any.required': 'This field is required and cannot be empty',
    });
};
