import { ApiAuthContextFragment } from './get-contexts.query.generated';
import { AuthContext } from '../../interfaces';

export const mapContext = (context: ApiAuthContextFragment): AuthContext => ({
  id: context.id,
  orgId: context.orgId,
  userId: context.userId,
  marketId: context.marketId,
  marketName: context.marketName,
});
