import { ColumnDef } from '@tanstack/react-table';
import { i18n } from 'i18next';
import { MouseEvent } from 'react';
import { Currency } from '@/app/common';
import { AutoDisabledButton, AutoDisabledButtonChildrenProps, ButtonCircle, PauseIcon, PlayIcon, ToolIcon } from '@/app/components';
import { UseActivityStatusHook } from '../../hooks';
import { ActivityItem } from '../../interfaces';
import { ActivityStatus } from '../activity-status';

export const createListColumns = ({
  getActivityState,
  i18n,
  currency,
}: {
  getActivityState: (activity: ActivityItem) => UseActivityStatusHook;
  i18n: i18n;
  currency: Currency;
}): ColumnDef<ActivityItem>[] => [
  {
    accessorKey: 'name',
    header: () => (
      <span>Name</span>
    ),
    cell: ({ row }) => (
      <>
        <ToolIcon type={row.original.icon} />
        {row.original.name}
      </>
    ),
    size: 400,
  },
  {
    accessorKey: 'startDate',
    header: () => <span>From</span>,
    cell: (info) => {
      const date = info.getValue<Date | null>();
      if (!date) return null;

      return new Intl.DateTimeFormat(i18n.language, {
        dateStyle: 'medium',
      }).format(date);
    },
    size: 200,
  },
  {
    accessorKey: 'endDate',
    header: () => <span>Until</span>,
    cell: (info) => {
      const date = info.getValue<Date | null>();
      if (!date) return null;

      return new Intl.DateTimeFormat(i18n.language, {
        dateStyle: 'medium',
      }).format(date);
    },
    size: 200,
  },
  {
    accessorKey: 'unitPrice',
    header: () => <span>Budget</span>,
    cell: (info) => {
      const unitPrice = new Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency,
      }).format(info.getValue<number>());
      const rateType = info.row.original.rateType;

      return (
        <>
          {unitPrice}&nbsp;/&nbsp;{rateType}
        </>
      );
    },
    size: 150,
  },
  {
    accessorKey: 'totalPrice',
    header: () => <span>Total</span>,
    cell: (info) => {
      return new Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency,
      }).format(info.getValue<number>());
    },
    size: 150,
  },
  {
    accessorKey: 'status',
    header: () => <span>State</span>,
    cell: ({ row }) => {
      return (
        <ActivityStatus status={getActivityState(row.original)[0].currentState} />
      );
    },
    size: 150,
  },
  {
    accessorKey: 'workflow',
    header: () => '',
    cell: ({ row }) => {
      const [activityStatus, triggerWorkflow] = getActivityState(row.original);
      const ToggleButton = ({
        disabled,
        onClick,
      }: AutoDisabledButtonChildrenProps) => {
        const handlePause = (
          event: MouseEvent<HTMLButtonElement>,
          onClickCallback: (event: MouseEvent<HTMLButtonElement>) => void,
        ) => {
          event.stopPropagation();
          onClickCallback(event);
          triggerWorkflow('PAUSE');
        };

        const handleResume = (
          event: MouseEvent<HTMLButtonElement>,
          onClickCallback: (event: MouseEvent<HTMLButtonElement>) => void,
        ) => {
          event.stopPropagation();
          onClickCallback(event);
          triggerWorkflow('RESUME');
        };

        if (activityStatus.can('PAUSE')) {
          return (
            <ButtonCircle
              variant="tertiary"
              label="Pause"
              icon={PauseIcon}
              onClick={event => handlePause(event, onClick)}
              disabled={disabled}
              loading={disabled}
            />
          );
        } else if (activityStatus.can('RESUME')) {
          return (
            <ButtonCircle
              variant="tertiary"
              label="Resume"
              icon={PlayIcon}
              onClick={event => handleResume(event, onClick)}
              disabled={disabled}
              loading={disabled}
            />
          );
        }

        return null;
      };

      return (
        <AutoDisabledButton duration={5}>
          {buttonProps => <ToggleButton {...buttonProps} />}
        </AutoDisabledButton>
      );
    },
    size: 50,
    enableSorting: false,
  },
];
