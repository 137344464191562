import { ToolIconType } from '@/app/common';
import {
  DefinitionList,
  DefinitionListItem,
  RoundedFrame,
  ToolIcon,
} from '@/app/components';

interface SummaryBoxProps {
  title: string;
  icon?: ToolIconType | null;
  definitionList: DefinitionListItem[];
  children?: React.ReactNode;
};

export const SummaryBox: React.FC<SummaryBoxProps> = ({
  title,
  icon = null,
  definitionList,
  children,
}) => (
  <RoundedFrame>
    <h3 className="text-lg font-medium mb-4 flex items-center gap-x-3 w-full">
      {icon && <ToolIcon type={icon} />}
      {title}
    </h3>
    <DefinitionList data={definitionList} columns={1} />
    {children && (
      <div className="flex justify-center w-full mt-4">
        <div>
          {children}
        </div>
      </div>
    )}
  </RoundedFrame>
);
