
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Badge,
  Button,
  FieldWrapper,
  InfroStrip,
  InputField,
  Notification,
  ToggleSection,
  WhiteCard,
} from '@/app/components';
import { AgencyFormRawData } from './agency-form.interface';
import { VendorsSearchField } from './vendors-search-field';
import {
  AgencyDetail,
  AgencyFormData,
  AgencyVendorItem,
} from '../../interfaces';
import { SalesPersonField, TraffickerField } from '../form-fields';

interface AgencyFormProps {
  agency?: AgencyDetail;
  agencyVendors?: AgencyVendorItem[];
  onSubmit: (data: AgencyFormData) => void;
  onCancel: () => void;
  error?: string | null;
};

export const AgencyForm: React.FC<AgencyFormProps> = ({
  agency,
  agencyVendors = [],
  onSubmit,
  onCancel,
  error = null,
}) => {
  const isEditMode = agency !== undefined;
  const formMethods = useForm<AgencyFormRawData>({
    mode: 'onChange',
    defaultValues: {
      name: agency?.name ?? '',
      businessId: agency?.businessId ?? '',
      businessName: agency?.businessName ?? '',
      vatNumber: agency?.vatNumber ?? '',
      defaultSalesPerson: agency?.defaultSalesPerson ? {
        value: agency.defaultSalesPerson.id,
        name: agency.defaultSalesPerson.name,
      } : null,
      defaultTrafficker: agency?.defaultTrafficker ? {
        value: agency.defaultTrafficker.id,
        name: agency.defaultTrafficker.name,
      } : null,
      vendors: agencyVendors.map(vendor => ({
        value: vendor.id,
        name: vendor.name,
      })),
    },
  });
  const {
    control,
    setValue,
    watch,
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValidating },
  } = formMethods;

  const doSubmit = async (data: AgencyFormRawData)=> {
    await onSubmit({
      name: data.name,
      businessId: data.businessId,
      businessName: data.businessName,
      vatNumber: data.vatNumber || null,
      defaultSalesPerson: data.defaultSalesPerson?.value ?? null,
      defaultTrafficker: data.defaultTrafficker?.value ?? null,
      vendors: data.vendors.map(vendor => vendor.value),
    });
  };

  const removeSelectedVendor = (vendorId: string) => {
    const updatedSelection = watch('vendors').filter((item) => item.value !== vendorId);
    if (updatedSelection) {
      setValue('vendors', updatedSelection);
    }
  };

  const selectedVendors = watch('vendors');

  return (
    <>
      {error !== null && (
        <div className="mt-5 mb-7">
          <Notification type="error" color="red" label={error} />
        </div>
      )}

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(doSubmit)} noValidate>
          <ToggleSection title="General Info" margin="mb-5">
            <WhiteCard>
              <FieldWrapper>
                <InputField
                  {...register('name', {
                    required: 'This field is required and cannot be empty',
                    minLength: {
                      value: 3,
                      message: 'Field value has to contain at least 3 characters',
                    },
                    maxLength: {
                      value: 100,
                      message: 'Field value is too long',
                    },
                  })}
                  label="Name"
                  error={errors.name}
                  required
                />
              </FieldWrapper>

              <FieldWrapper>
                <InputField
                  {...register('businessName', {
                    required: 'This field is required and cannot be empty',
                    maxLength: {
                      value: 200,
                      message: 'Field value is too long',
                    },
                  })}
                  label="Business name"
                  error={errors.businessName}
                  required
                />
              </FieldWrapper>

              <FieldWrapper>
                <div className="flex gap-x-6">
                  <div className="w-1/2">
                    <InputField
                      {...register('businessId', {
                        required: 'This field is required and cannot be empty',
                        maxLength: {
                          value: 100,
                          message: 'Field value is too long',
                        },
                      })}
                      label="Company ID"
                      error={errors.businessId}
                      required
                    />
                  </div>

                  <div className="w-1/2">
                    <InputField
                      {...register('vatNumber', {
                        maxLength: {
                          value: 100,
                          message: 'Field value is too long',
                        },
                      })}
                      label="VAT"
                      error={errors.vatNumber}
                    />
                  </div>
                </div>
              </FieldWrapper>
            </WhiteCard>
          </ToggleSection>

          <ToggleSection title="Default Contacts" margin="mb-5">
            <WhiteCard>
              <FieldWrapper>
                <SalesPersonField control={control} name="defaultSalesPerson" />
              </FieldWrapper>

              <FieldWrapper>
                <TraffickerField control={control} name="defaultTrafficker" />
              </FieldWrapper>
            </WhiteCard>
          </ToggleSection>

          <ToggleSection title="Advertisers" margin="mb-5">
            <div className="mb-4">
              <InfroStrip type="info" label="Advertisers can be added later" />
            </div>

            <WhiteCard>
              <FieldWrapper>
                <VendorsSearchField />
              </FieldWrapper>

              <FieldWrapper>
                {selectedVendors.length > 0 ? (
                  <div className="flex flex-wrap gap-x-1 gap-y-1.5 mt-4">
                    {selectedVendors.map((vendor) => (
                      <Badge
                        key={`badge-${vendor.value}`}
                        label={vendor.name}
                        color="black"
                        size="sm"
                        onClick={() => removeSelectedVendor(vendor.value)}
                      />
                    ))}
                  </div>
                ) : (
                  <InfroStrip
                    label="Currently there are no assigned advertisers"
                    type="info"
                  />
                )}
              </FieldWrapper>
            </WhiteCard>
          </ToggleSection>

          <div className="flex justify-between">
            <div>
              <Button label="Cancel" variant="secondary" onClick={onCancel} />
            </div>

            <div>
              <Button
                type="submit"
                label={isEditMode ? 'Save Agency' : 'Create Agency'}
                variant="primary"
                iconPosition="right"
                disabled={isSubmitting || isValidating}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};
