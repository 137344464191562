/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiVendorItemFragment = { __typename?: 'LeanVendor', id: string, name: string, billingInfo: { __typename?: 'CompanyBilling', businessName: string, businessId: string, vatNumber: string | null } };

export type ApiVendorsQueryVariables = Types.Exact<{
  page: Types.Scalars['Int']['input'];
  limit: Types.Scalars['Int']['input'];
  search: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ApiVendorsQuery = { __typename?: 'Query', vendors: { __typename?: 'VendorResult', total: number, items: Array<{ __typename?: 'LeanVendor', id: string, name: string, billingInfo: { __typename?: 'CompanyBilling', businessName: string, businessId: string, vatNumber: string | null } }> } };

export const VendorItemFragmentDoc = gql`
    fragment VendorItem on LeanVendor {
  id
  name
  billingInfo {
    businessName
    businessId
    vatNumber
  }
}
    `;
export const VendorsDocument = gql`
    query Vendors($page: Int!, $limit: Int!, $search: String) {
  vendors(page: $page, limit: $limit, search: $search) {
    total
    items {
      ...VendorItem
    }
  }
}
    ${VendorItemFragmentDoc}`;

/**
 * __useVendorsQuery__
 *
 * To run a query within a React component, call `useVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useVendorsQuery(baseOptions: Apollo.QueryHookOptions<ApiVendorsQuery, ApiVendorsQueryVariables> & ({ variables: ApiVendorsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiVendorsQuery, ApiVendorsQueryVariables>(VendorsDocument, options);
      }
export function useVendorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiVendorsQuery, ApiVendorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiVendorsQuery, ApiVendorsQueryVariables>(VendorsDocument, options);
        }
export function useVendorsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApiVendorsQuery, ApiVendorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiVendorsQuery, ApiVendorsQueryVariables>(VendorsDocument, options);
        }
export type VendorsQueryHookResult = ReturnType<typeof useVendorsQuery>;
export type VendorsLazyQueryHookResult = ReturnType<typeof useVendorsLazyQuery>;
export type VendorsSuspenseQueryHookResult = ReturnType<typeof useVendorsSuspenseQuery>;
export type VendorsQueryResult = Apollo.QueryResult<ApiVendorsQuery, ApiVendorsQueryVariables>;