import { Row } from '@tanstack/react-table';
import { TableBodyColumn } from './table-body-column';

export type TableRowProps<T> = {
  row: Row<T>;
  onClick?: (row: T) => void;
  children?: (row: Row<T>) => React.ReactNode;
};

export const TableRow = <T,>({
  row,
  onClick,
  children = (row: Row<T>) => (
    <>
      {row.getVisibleCells().map((cell, index) => (
        <TableBodyColumn {...cell} index={index} key={index} />
      ))}
    </>
  ),
}: TableRowProps<T>) => {
  const handleRowClick = () => {
    onClick?.(row.original);
  };

  return (
    <tr
      key={`row-${row.id}`}
      className={`
        shadow-sm
        rounded-sm
        ${onClick ? 'cursor-pointer group' : ''}
      `}
      onClick={handleRowClick}
    >
      {children(row)}
    </tr>
  );
};
