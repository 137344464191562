import { useMetaSuspenseQuery } from './get-app-meta.query.generated';
import { useAppId } from '../../hooks';
import { AppMetadata, Currency, Locale, Timezone } from '../../interfaces';

type UseMetaHook = AppMetadata;

export const useAppMeta = (): UseMetaHook => {
  const slug = useAppId();
  const { data, error } = useMetaSuspenseQuery({
    variables: {
      slug,
    },
  });

  if (error) {
    return {
      id: slug,
      locale: Locale.EnGb,
      timezone: Timezone.EuropePrague,
      currency: Currency.Eur,
    };
  }

  return {
    id: data.meta.id,
    locale: data.me?.locale ?? data.workspace?.locale ?? data.meta.locale,
    timezone: data.me?.timezone ?? data.workspace?.timezone ?? data.meta.timezone,
    currency: data.workspace?.currency ?? data.meta.currency,
  };
};
