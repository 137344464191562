import { useLoginMutation } from './login.generated';
import { useAppId, useAuth } from '../../hooks';
import { useLazyCurrentUser } from '../get-current-user';

type UseLoginHook = {
  loginUser: (username: string, password: string) => Promise<void>;
  loading: boolean;
};

export const useLogin = (): UseLoginHook => {
  const [loginMutation, { loading }] = useLoginMutation();
  const [fetchCurrentUser] = useLazyCurrentUser();
  const { login } = useAuth();
  const organizationId = useAppId();

  const loginUser = async (
    username: string,
    password: string,
  ) => {
    const doLogin = async () => {
      const { data, errors } = await loginMutation({
        variables: {
          username,
          password,
          organizationId,
        },
      });

      // Error
      if (errors !== undefined && errors.length > 0) {
        const errorMessage = errors[0].message;
        throw new Error(errorMessage);
      }

      // No error, no data
      if (!data) {
        throw new Error('Login returned empty unexpected response.');
      }

      return data.login;
    };

    const loginData = await doLogin();
    const { currentUser, currentWorkspace } = await fetchCurrentUser(loginData.token);
    login({
      ...loginData,
      currentUser,
      currentWorkspace,
    });
  };

  return {
    loginUser,
    loading,
  };
};
