import { Cell, Row, flexRender } from '@tanstack/react-table';
import { MouseEvent, useState } from 'react';
import { TableBodyColumn } from './table-body-column';
import { TablePlaceholderColumn } from './table-placeholder-column';
import { TableRow, TableRowProps } from './table-row';
import { ChevronRightIcon } from '../icons';

type ExpandableTableRowProps<T> = (
  & Omit<TableRowProps<T>, 'children'>
  & {
    children?: React.ReactNode;
    placeholder?: React.ReactElement | React.ReactNode;
  }
);

export const ExpandableTableRow = <T,>({
  row,
  children,
  placeholder,
  onClick,
  ...props
}: ExpandableTableRowProps<T>) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleExpand = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setExpanded(isExpanded => !isExpanded);
  };

  const renderToggle = (cell: Cell<T, unknown>) => (
    <div className="flex items-center gap-x-2">
      <button
        onClick={handleExpand}
        type="button"
      >
        <span
          className={`
          w-8
          h-10
          flex
          items-center
          justify-center
          transition-transform
          origin-center
          ${expanded ? 'rotate-90' : ''}
        `}
        >
          {ChevronRightIcon}
        </span>
      </button>
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </div>
  );

  const renderCell = (cell: Cell<T, unknown>) => flexRender(cell.column.columnDef.cell, cell.getContext());

  const renderPlaceholder = (row: Row<T>) => (
    <td colSpan={row.getVisibleCells().length}>
      <TablePlaceholderColumn {...row.getVisibleCells()[0]}>
        {renderToggle(row.getVisibleCells()[0])}
        {placeholder}
      </TablePlaceholderColumn>
    </td>
  );

  const renderCells = (row: Row<T>) => (
    <>
      {row.getVisibleCells().map((cell, index) => (
        <TableBodyColumn {...cell} index={index} key={index}>
          {index === 0 ? renderToggle(cell) : renderCell(cell)}
        </TableBodyColumn>
      ))}
    </>
  );

  return (
    <>
      <TableRow {...props} onClick={expanded ? undefined : onClick} row={row}>
        {(row) => expanded ? (
          <>{renderPlaceholder(row)}</>
        ) : (
          <>{renderCells(row)}</>
        )}
      </TableRow>

      {expanded && (
        <tr>
          <td colSpan={row.getVisibleCells().length}>
            <div
              className={`
                px-4
                py-3
                bg-white
                shadow-sm
                rounded-b-sm
                -mt-3
              `}
            >
              {children}
            </div>
          </td>
        </tr>
      )}
    </>
  );
};
