import { ApolloProvider } from '@apollo/client';
import dayjs from 'dayjs';
import localization, { use } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import App from '@/app/app';
import { AuthProvider, DialogProvider, PermissionsProvider, createApolloClient } from '@/app/common';

import '@/app/styles/main.css';

use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    supportedLngs: [
      'en',
      'cs',
    ],
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['cookie', 'localStorage', 'sessionStorage', 'navigator'],
    },
  });

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={localization} defaultNS={'translation'}>
      <ApolloProvider client={createApolloClient()}>
        <AuthProvider>
          <BrowserRouter>
            <DialogProvider>
              <PermissionsProvider>
                <App />
              </PermissionsProvider>
            </DialogProvider>
          </BrowserRouter>
        </AuthProvider>
      </ApolloProvider>
    </I18nextProvider>
  </React.StrictMode>,
);
