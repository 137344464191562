import { ApolloError } from '@apollo/client';
import { usePagination } from '@/app/common';
import { mapCreative } from './creative.mapper';
import { useGetCreativesSuspenseQuery } from './get-creatives.query.generated';
import { useCreativeFilters } from '../../hooks';
import { ActivityCreativeItem } from '../../interfaces';

type UseCreativesHook = (args?: {
  limit?: number;
  vendorId?: string;
  allowedFormats?: string[];
}) => {
  total: number;
  creatives: ActivityCreativeItem[];
  error: ApolloError | undefined;
  page: number;
  limit: number;
};

export const useCreatives: UseCreativesHook = ({
  limit = 10,
  vendorId = null,
  allowedFormats = null,
} = {}) => {
  const [page] = usePagination();
  const [filters] = useCreativeFilters();
  const { data, error } = useGetCreativesSuspenseQuery({
    variables: {
      page,
      limit,
      search: filters.search,
      vendorId,
      formats: allowedFormats,
    },
  });

  return {
    total: data.creatives.total,
    creatives: data.creatives.items.map(activity => mapCreative(activity)),
    error,
    page,
    limit,
  };
};
