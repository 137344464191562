/* eslint-disable @typescript-eslint/no-explicit-any */
import debounce from 'lodash.debounce';
import { useRef } from 'react';

const asyncDebounce = <T>(func: (...args: any[]) => Promise<T>, wait: number) => {
  const debounced = debounce((resolve, reject, args) => {
    func(...args).then(resolve).catch(reject);
  }, wait);

  return (...args: any[]): Promise<T> => {
    return new Promise<T>((resolve, reject) => {
      debounced(resolve, reject, args);
    });
  };
};

export const useDebounce = <T>(func: (...args: any[]) => Promise<T>, wait: number) => {
  const debounced = useRef(asyncDebounce<T>(func, wait));
  return debounced.current;
};
