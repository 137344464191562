/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiCampaignItemFragment = { __typename?: 'LeanCampaign', id: string, name: string, startDate: string | null, endDate: string | null, status: Types.ApiCampaignStatus, price: number, vendor: { __typename?: 'IdentifiedVendor', id: string, name: string }, agency: { __typename?: 'IdentifiedAgency', id: string, name: string } | null, salesPerson: { __typename?: 'IdentifiedUser', id: string, name: string }, metrics: { __typename?: 'Metrics', impressions: number, views: number, clicks: number, visibility: number, ctr: number, osp: number } };

export type ApiCampaignsQueryVariables = Types.Exact<{
  page: Types.Scalars['Int']['input'];
  limit: Types.Scalars['Int']['input'];
  search: Types.InputMaybe<Types.Scalars['String']['input']>;
  vendor: Types.InputMaybe<Types.Scalars['ID']['input']>;
  status: Types.InputMaybe<Types.ApiCampaignStatus>;
  salesPerson: Types.InputMaybe<Types.Scalars['ID']['input']>;
  startDate: Types.InputMaybe<Types.Scalars['String']['input']>;
  endDate: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ApiCampaignsQuery = { __typename?: 'Query', campaigns: { __typename?: 'CampaignResult', total: number, items: Array<{ __typename?: 'LeanCampaign', id: string, name: string, startDate: string | null, endDate: string | null, status: Types.ApiCampaignStatus, price: number, vendor: { __typename?: 'IdentifiedVendor', id: string, name: string }, agency: { __typename?: 'IdentifiedAgency', id: string, name: string } | null, salesPerson: { __typename?: 'IdentifiedUser', id: string, name: string }, metrics: { __typename?: 'Metrics', impressions: number, views: number, clicks: number, visibility: number, ctr: number, osp: number } }> } };

export const CampaignItemFragmentDoc = gql`
    fragment CampaignItem on LeanCampaign {
  id
  name
  startDate
  endDate
  status
  price
  vendor {
    id
    name
  }
  agency {
    id
    name
  }
  salesPerson {
    id
    name
  }
  metrics {
    impressions
    views
    clicks
    visibility
    ctr
    osp
  }
}
    `;
export const CampaignsDocument = gql`
    query Campaigns($page: Int!, $limit: Int!, $search: String, $vendor: ID, $status: CampaignStatus, $salesPerson: ID, $startDate: String, $endDate: String) {
  campaigns(
    page: $page
    limit: $limit
    search: $search
    vendor: $vendor
    status: $status
    salesPerson: $salesPerson
    startDate: $startDate
    endDate: $endDate
  ) {
    total
    items {
      ...CampaignItem
    }
  }
}
    ${CampaignItemFragmentDoc}`;

/**
 * __useCampaignsQuery__
 *
 * To run a query within a React component, call `useCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      vendor: // value for 'vendor'
 *      status: // value for 'status'
 *      salesPerson: // value for 'salesPerson'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useCampaignsQuery(baseOptions: Apollo.QueryHookOptions<ApiCampaignsQuery, ApiCampaignsQueryVariables> & ({ variables: ApiCampaignsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiCampaignsQuery, ApiCampaignsQueryVariables>(CampaignsDocument, options);
      }
export function useCampaignsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiCampaignsQuery, ApiCampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiCampaignsQuery, ApiCampaignsQueryVariables>(CampaignsDocument, options);
        }
export function useCampaignsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApiCampaignsQuery, ApiCampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiCampaignsQuery, ApiCampaignsQueryVariables>(CampaignsDocument, options);
        }
export type CampaignsQueryHookResult = ReturnType<typeof useCampaignsQuery>;
export type CampaignsLazyQueryHookResult = ReturnType<typeof useCampaignsLazyQuery>;
export type CampaignsSuspenseQueryHookResult = ReturnType<typeof useCampaignsSuspenseQuery>;
export type CampaignsQueryResult = Apollo.QueryResult<ApiCampaignsQuery, ApiCampaignsQueryVariables>;