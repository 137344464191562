import { ReactNode, createContext, useCallback, useContext, useReducer } from 'react';
import { useAuthToken, useLazyCurrentUser, useRefreshToken, useSwitchWorkspace } from '@/app/common';
import { AuthState, LoadUserPayload, LoginPayload } from './auth.interface';
import { authReducer } from './auth.reducer';

const initialAuthState: AuthState = {
  token: null,
  refreshToken: null,
  currentUser: null,
  currentWorkspace: null,
  loading: true,
  error: null,
};

export type AuthContextType = {
  state: AuthState;
  login: (payload: LoginPayload) => void;
  loginByRefreshToken: () => Promise<void>;
  logout: () => void;
  loadUser: (payload: LoadUserPayload) => void;
  switchWorkspace: (workspaceId: string) => Promise<void>;
};

const AuthContext = createContext<AuthContextType>({
  state: initialAuthState,
  login: () => {},
  loginByRefreshToken: () => Promise.resolve(),
  logout: () => {},
  loadUser: () => {},
  switchWorkspace: () => Promise.resolve(),
});

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [state, dispatch] = useReducer(authReducer, initialAuthState);
  const { refreshToken, setToken, setRefreshToken, clearTokens } = useAuthToken();
  const [fetchCurrentUser] = useLazyCurrentUser();
  const [switchCurrentWorkspace] = useSwitchWorkspace();
  const [loginByRefreshTokenHook] = useRefreshToken();

  const login = useCallback((payload: LoginPayload) => {
    setToken(payload.token);
    setRefreshToken(payload.refreshToken);
    dispatch({ type: 'LOGIN', payload });
  }, []);

  const logout = useCallback(() => {
    clearTokens();
    dispatch({ type: 'LOGOUT' });
  }, []);

  const loadUser = useCallback((payload: LoadUserPayload) => {
    dispatch({ type: 'LOAD_USER', payload });
  }, []);

  const loginByRefreshToken = useCallback(async () => {
    try {
      const currentUser = await fetchCurrentUser();
      dispatch({ type: 'LOAD_USER', payload: currentUser });
    } catch {
      // try to login by refresh token
      if (refreshToken) {
        try {
          const loginData = await loginByRefreshTokenHook(refreshToken);
          setToken(loginData.token);
          setRefreshToken(loginData.refreshToken);
          dispatch({ type: 'LOGIN', payload: loginData });
        } catch {
          // refresh token was probably invalid
          dispatch({ type: 'LOGIN_FAILED' });
        }
      }
    }
  }, []);

  const switchWorkspace = useCallback(async (workspaceId: string) => {
    try {
      const loginData = await switchCurrentWorkspace(workspaceId);
      setToken(loginData.token);
      setRefreshToken(loginData.refreshToken);
      dispatch({ type: 'SWITCH_CONTEXT', payload: loginData });
    } catch {
      dispatch({ type: 'LOGIN_FAILED' });
    }
  }, []);

  return (
    <AuthContext.Provider value={{
      state,
      login,
      loginByRefreshToken,
      logout,
      loadUser,
      switchWorkspace,
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};
