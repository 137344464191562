import { useState } from 'react';

export interface DialogState {
  [key: string]: boolean;
};

export const useDialog = () => {
  const [dialogState, setDialogState] = useState<DialogState>({});

  const openDialog = (dialogName: string) => {
    setDialogState(prevState => ({ ...prevState, [dialogName]: true }));
  };

  const closeDialog = (dialogName: string) => {
    setDialogState(prevState => ({ ...prevState, [dialogName]: false }));
  };

  return {
    dialogState,
    openDialog,
    closeDialog,
  };
};
