import { CurrentUser, Role } from '../interfaces';

export const hasRoleVendor = (user: CurrentUser): boolean => user.role === Role.Vendor;
export const hasRoleAgency = (user: CurrentUser): boolean => user.role === Role.Agency;

export const isUserRetailerType = (user: CurrentUser): boolean => {
  if (hasRoleAgency(user) || hasRoleVendor(user)) {
    return false;
  }

  return true;
};
