import { ApiAdvertisingToolIcon } from '../api';
import { ToolIconType } from '../interfaces';

export const mapStringToAdvertisingToolIconEnum = (type: ToolIconType): ApiAdvertisingToolIcon => {
  const key = Object.values(ApiAdvertisingToolIcon).find(apiType => apiType === type);

  if (!key) {
    throw new Error(`Unable to convert ToolIconType into ApiAdvertisingToolIcon for unknown value ${type}`);
  }

  return key;
};
