import { useLazySearch } from '@/app/common';
import { mapBrand } from './brand.mapper';
import { useSearchBrandsLazyQuery, useSearchBrandsSuspenseQuery } from './search-brands.query.generated';
import { VendorBrandItem } from '../../interfaces';

type UseSearchBrandsHook = {
  brands: VendorBrandItem[];
  searchBrands: (term: string) => void,
  error?: string;
};

export const useSearchBrands = (): UseSearchBrandsHook => {
  const { data, error } = useSearchBrandsSuspenseQuery();
  const [searchBrands] = useSearchBrandsLazyQuery();
  const initialValues = data.brands.items.map(mapBrand);

  const { results, search } = useLazySearch({
    initialValues,
    onSearch: async (term: string) => {
      const result = await searchBrands({
        variables: {
          limit: 15,
          search: term,
        },
      });

      if (result.error) {
        throw result.error;
      }

      if (!result.data) {
        throw new Error('Empty search response returned while data was expected');
      }

      return result.data.brands.items.map(mapBrand);
    },
  });

  return {
    brands: results,
    searchBrands: search,
    error: error?.message,
  };
};
