import { ApolloError } from '@apollo/client';
import { useEffect, useRef } from 'react';
import { ApiActivityStatus, useAuth, usePagination } from '@/app/common';
import { mapActivity } from './activity.mapper';
import { useActivitiesSuspenseQuery } from './get-activities.query.generated';
import { useActivityFilters } from '../../hooks';
import { ACTIVITY_STATUS, ActivityResult, ActivityStatus } from '../../interfaces';

type UseActivitiesHook = {
  total: ActivityResult['total'];
  activities: ActivityResult['items'];
  error: ApolloError | undefined;
  page: number;
  limit: number;
};

export const useActivities = (): UseActivitiesHook => {
  const { state: { currentWorkspace } } = useAuth();
  const workspaceRef = useRef(currentWorkspace);
  const limit = 10;
  const [page] = usePagination();
  const { filters } = useActivityFilters();
  const { data, error, refetch } = useActivitiesSuspenseQuery({
    variables: {
      page,
      limit,
      search: filters.search,
      vendor: filters.vendor?.value ?? null,
      status: filters.status ? mapStatus(filters.status.value) : null,
      salesPerson: filters.salesPerson?.value ?? null,
      startDate: filters.startDate ? (new Date(filters.startDate)).toISOString() : null,
      endDate: filters.endDate ? (new Date(filters.endDate)).toISOString() : null,
    },
  });

  useEffect(() => {
    if (currentWorkspace?.id !== workspaceRef.current?.id) {
      workspaceRef.current = currentWorkspace;
      refetch();
    }
  }, [currentWorkspace?.id]);

  return {
    total: data.activities.total,
    activities: data.activities.items.map(activity => mapActivity(activity)),
    error,
    page,
    limit,
  };
};

const mapStatus = (status: ActivityStatus): ApiActivityStatus | null => {
  switch (status) {
    case ACTIVITY_STATUS.draft:
      return ApiActivityStatus.Draft;
    case ACTIVITY_STATUS.pendingApproval:
      return ApiActivityStatus.PendingApproval;
    case ACTIVITY_STATUS.approved:
      return ApiActivityStatus.Approved;
    case ACTIVITY_STATUS.rejected:
      return ApiActivityStatus.Rejected;
    case ACTIVITY_STATUS.incomplete:
      return ApiActivityStatus.Incomplete;
    case ACTIVITY_STATUS.ready:
      return ApiActivityStatus.Ready;
    case ACTIVITY_STATUS.online:
      return ApiActivityStatus.Online;
    case ACTIVITY_STATUS.paused:
      return ApiActivityStatus.Paused;
    case ACTIVITY_STATUS.closed:
      return ApiActivityStatus.Closed;
    case ACTIVITY_STATUS.cancelled:
      return ApiActivityStatus.Cancelled;

    default:
      return null;
  }
};
