export const TOOL_ICONS = {
  BANNER_CAROUSEL_HP_GREEN: 'BANNER_CAROUSEL_HP_GREEN',
  BANNER_CATEGORY_GREEN: 'BANNER_CATEGORY_GREEN',
  BANNER_SPONSORED_CATEGORY_GREEN: 'BANNER_SPONSORED_CATEGORY_GREEN',
  BANNER_UNDER_CAROUSEL: 'BANNER_UNDER_CAROUSEL',
  SPONSORED_PRODUCT_CART: 'SPONSORED_PRODUCT_CART',
  SPONSORED_PRODUCT_CART_PLUS: 'SPONSORED_PRODUCT_CART_PLUS',
  SPONSORED_PRODUCT_CATEGORY_POSITION_1_GREEN: 'SPONSORED_PRODUCT_CATEGORY_POSITION_1_GREEN',
  SPONSORED_PRODUCT_CATEGORY_POSITION_1_PURPLE: 'SPONSORED_PRODUCT_CATEGORY_POSITION_1_PURPLE',
  SPONSORED_PRODUCT_CATEGORY_POSITION_3_GREEN: 'SPONSORED_PRODUCT_CATEGORY_POSITION_3_GREEN',
  SPONSORED_PRODUCT_CATEGORY_POSITION_3_PURPLE: 'SPONSORED_PRODUCT_CATEGORY_POSITION_3_PURPLE',
  SPONSORED_PRODUCT_CATEGORY_POSITION_4_GREEN: 'SPONSORED_PRODUCT_CATEGORY_POSITION_4_GREEN',
  SPONSORED_PRODUCT_CATEGORY_POSITION_4_PURPLE: 'SPONSORED_PRODUCT_CATEGORY_POSITION_4_PURPLE',
  SPONSORED_PRODUCT_HP_GREEN: 'SPONSORED_PRODUCT_HP_GREEN',
  SPONSORED_PRODUCT_HP_PURPLE: 'SPONSORED_PRODUCT_HP_PURPLE',
  SPONSORED_PRODUCT_PRODUCT_DETAIL_GREEN: 'SPONSORED_PRODUCT_PRODUCT_DETAIL_GREEN',
  SPONSORED_PRODUCT_PRODUCT_DETAIL_PURPLE: 'SPONSORED_PRODUCT_PRODUCT_DETAIL_PURPLE',
  SPONSORED_PRODUCT_SEARCH: 'SPONSORED_PRODUCT_SEARCH',
} as const;

export type ToolIconType = keyof typeof TOOL_ICONS;
