import { AbilityBuilder, MongoQuery, PureAbility, createAliasResolver } from '@casl/ability';
import { CurrentUser } from '../../interfaces';

export type AbilityType = PureAbility<[Permissions, Subjects | 'all'], MongoQuery>;
export type DefinePermissions = (user: CurrentUser, builder: AbilityBuilder<AbilityType>) => void;

export type PermissionsContextType = AbilityType;

export type Permissions = (
  | 'read'
  | 'create'
  | 'update'
  | 'delete'

  // special aliases
  | 'crud' // full crud
  | 'manage' // create/update/delete
);

export type Subjects = (
  | 'user'
  | 'organization'
  | 'market'

  | 'brand'
  | 'agency'
  | 'vendor'

  | 'advertisingTool'
  | 'advertisingCategory'
  | 'advertisingTarget'

  | 'creativeFormat'

  | 'creative'
  | 'campaign'
  | 'activity'
);

export const resolvePermissions = createAliasResolver({
  crud: ['read', 'create', 'update', 'delete'],
});
