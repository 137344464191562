import { Control, Controller, FieldValues, Path, PathValue } from 'react-hook-form';
import { Selectbox, SelectboxOptions, SelectboxProps } from './selectbox';

interface SelectboxFieldProps<O extends SelectboxOptions, T extends FieldValues> extends
  Omit<SelectboxProps<O>, 'onBlur' | 'value' | 'id'> {
    control: Control<T>;
    defaultValue?: PathValue<T, Path<T>>;
    name: Path<T>;
  };

export const SelectboxField = <O extends SelectboxOptions, T extends FieldValues>({
  name,
  label,
  options,
  defaultValue,
  error,
  inline = false,
  disabled = false,
  required = false,
  placeholder = '',
  control,
  multiple = false,
  onChange: customOnChange,
  ...rest
}: SelectboxFieldProps<O, T>) => (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{ required }}
        render={({ field: { onChange, value, ...fieldProps } }) => (
          <Selectbox<O>
            {...fieldProps}
            {...rest}
            value={value}
            options={options}
            label={label}
            error={error}
            inline={inline}
            disabled={disabled}
            required={required}
            placeholder={placeholder}
            multiple={multiple}
            id={name}
            onChange={(e) => {
              onChange(e);
              if (customOnChange) {
                customOnChange(e);
              }
            }}
          />
        )}
      />
    </>
  );
