/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiSearchCampaignsQueryVariables = Types.Exact<{
  search: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ApiSearchCampaignsQuery = { __typename?: 'Query', campaigns: { __typename?: 'CampaignResult', items: Array<{ __typename?: 'LeanCampaign', id: string, name: string }> } };


export const SearchCampaignsDocument = gql`
    query SearchCampaigns($search: String) {
  campaigns(search: $search, limit: 15) {
    items {
      id
      name
    }
  }
}
    `;

/**
 * __useSearchCampaignsQuery__
 *
 * To run a query within a React component, call `useSearchCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCampaignsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchCampaignsQuery(baseOptions?: Apollo.QueryHookOptions<ApiSearchCampaignsQuery, ApiSearchCampaignsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiSearchCampaignsQuery, ApiSearchCampaignsQueryVariables>(SearchCampaignsDocument, options);
      }
export function useSearchCampaignsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiSearchCampaignsQuery, ApiSearchCampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiSearchCampaignsQuery, ApiSearchCampaignsQueryVariables>(SearchCampaignsDocument, options);
        }
export function useSearchCampaignsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApiSearchCampaignsQuery, ApiSearchCampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiSearchCampaignsQuery, ApiSearchCampaignsQueryVariables>(SearchCampaignsDocument, options);
        }
export type SearchCampaignsQueryHookResult = ReturnType<typeof useSearchCampaignsQuery>;
export type SearchCampaignsLazyQueryHookResult = ReturnType<typeof useSearchCampaignsLazyQuery>;
export type SearchCampaignsSuspenseQueryHookResult = ReturnType<typeof useSearchCampaignsSuspenseQuery>;
export type SearchCampaignsQueryResult = Apollo.QueryResult<ApiSearchCampaignsQuery, ApiSearchCampaignsQueryVariables>;