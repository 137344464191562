import React, { useEffect, useState } from 'react';
import {
  Button,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@/app/components';
import { useCreativeStatus } from '../../../hooks';
import { ActivityCreativeItem } from '../../../interfaces';
import { CreativeStatus } from '../../creative-status';

interface CreativeRowProps {
  creative: ActivityCreativeItem;
  isExpanded?: boolean;
  onSelect: (creative: ActivityCreativeItem) => void;
};

export const CreativeRow: React.FC<CreativeRowProps> = ({
  creative,
  onSelect,
  isExpanded = false,
}) => {
  const [expanded, setExpanded] = useState(isExpanded);
  const [creativeStatus] = useCreativeStatus(creative.status);

  const handleSelect = () => {
    onSelect(creative);
  };

  useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);

  return (
    <div
      className="
        w-full
        p-4
        rounded-sm
        shadow-row
        bg-white
      "
    >
      <div
        className="
          flex
          justify-between
          items-center
          gap-x-2
        "
      >
        <div className="flex items-center gap-x-2">
          <button
            onClick={() => setExpanded(expanded => !expanded)}
            type="button"
          >
            <span
              className={`
                w-6
                h-6
                flex
                items-center
                justify-center
                transition-transform
                origin-center
                ${expanded ? 'rotate-180' : ''}
              `}
            >
              {ChevronDownIcon}
            </span>
          </button>

          <span className="text-sm font-semibold">
            {creative.name}
          </span>
        </div>

        <div>
          <Button
            onClick={handleSelect}
            label="Select"
            variant="primary"
            icon={ChevronRightIcon}
            iconPosition="right"
          />
        </div>
      </div>

      {expanded && (
        <div className="pt-2">
          <div className="mb-4">
            <CreativeStatus status={creativeStatus.currentState} />
          </div>

          <div className="flex items-start space-x-4">
            {creative.previewImage && (
              <div className="rounded-xs overflow-hidden">
                <img src={creative.previewImage.url} width={180} alt={creative.previewImage.name} />
              </div>
            )}

            <div className="w-full">
              <dl className="flex w-full flex-col gap-y-1 text-sm leading-5">
                {creative.previewContents.map((content, index) => (
                  <div key={`content_${content.term}_${index}`} className="flex space-x-2">
                    <dt>{content.term}:</dt>
                    <dd className="font-medium">{content.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
