import React from 'react';

interface TabSwitchProps {
  items: string[];
  label?: string;
  compact?: boolean;
  selected?: number;
  onSwitch: (selectedIndex: number) => void;
};

export const TabSwitch: React.FC<TabSwitchProps> = ({
  items,
  label,
  compact = false,
  selected: activeIndex = 0,
  onSwitch,
}) => {
  const handleClick = (index: number) => {
    onSwitch(index);
  };

  return (
    <div className="flex items-center space-x-2.5">
      {label !== undefined && (
        <span className="text-gray-700 text-sm">{label}</span>
      )}
      <div className={`flex bg-slate-200 rounded-md ${!compact ? 'grow' : ''}`}>
        {items.map((item, index) => (
          <button
            key={index}
            onClick={() => handleClick(index)}
            type="button"
            className={`
              text-sm
              grow
              leading-6
              rounded-md
              text-center
              ${compact ? 'px-3.5 py-1' : 'px-3 py-2'}
              ${activeIndex === index ? 'bg-purple-200' : 'bg-transparent'}
            `}
          >
            {item}
          </button>
        ))}
      </div>
    </div>
  );
};
