/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiRequestPasswordResetMutationVariables = Types.Exact<{
  username: Types.Scalars['String']['input'];
}>;


export type ApiRequestPasswordResetMutation = { __typename?: 'Mutation', requestPasswordReset: boolean };

export type ApiResetPasswordMutationVariables = Types.Exact<{
  token: Types.Scalars['String']['input'];
  password: Types.Scalars['String']['input'];
}>;


export type ApiResetPasswordMutation = { __typename?: 'Mutation', resetPassword: boolean };

export type ApiVerifyPasswordResetTokenMutationVariables = Types.Exact<{
  token: Types.Scalars['String']['input'];
}>;


export type ApiVerifyPasswordResetTokenMutation = { __typename?: 'Mutation', verifyPasswordResetToken: boolean };


export const RequestPasswordResetDocument = gql`
    mutation RequestPasswordReset($username: String!) {
  requestPasswordReset(username: $username)
}
    `;
export type ApiRequestPasswordResetMutationFn = Apollo.MutationFunction<ApiRequestPasswordResetMutation, ApiRequestPasswordResetMutationVariables>;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<ApiRequestPasswordResetMutation, ApiRequestPasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiRequestPasswordResetMutation, ApiRequestPasswordResetMutationVariables>(RequestPasswordResetDocument, options);
      }
export type RequestPasswordResetMutationHookResult = ReturnType<typeof useRequestPasswordResetMutation>;
export type RequestPasswordResetMutationResult = Apollo.MutationResult<ApiRequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = Apollo.BaseMutationOptions<ApiRequestPasswordResetMutation, ApiRequestPasswordResetMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($token: String!, $password: String!) {
  resetPassword(token: $token, password: $password)
}
    `;
export type ApiResetPasswordMutationFn = Apollo.MutationFunction<ApiResetPasswordMutation, ApiResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ApiResetPasswordMutation, ApiResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiResetPasswordMutation, ApiResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ApiResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ApiResetPasswordMutation, ApiResetPasswordMutationVariables>;
export const VerifyPasswordResetTokenDocument = gql`
    mutation VerifyPasswordResetToken($token: String!) {
  verifyPasswordResetToken(token: $token)
}
    `;
export type ApiVerifyPasswordResetTokenMutationFn = Apollo.MutationFunction<ApiVerifyPasswordResetTokenMutation, ApiVerifyPasswordResetTokenMutationVariables>;

/**
 * __useVerifyPasswordResetTokenMutation__
 *
 * To run a mutation, you first call `useVerifyPasswordResetTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyPasswordResetTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyPasswordResetTokenMutation, { data, loading, error }] = useVerifyPasswordResetTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyPasswordResetTokenMutation(baseOptions?: Apollo.MutationHookOptions<ApiVerifyPasswordResetTokenMutation, ApiVerifyPasswordResetTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiVerifyPasswordResetTokenMutation, ApiVerifyPasswordResetTokenMutationVariables>(VerifyPasswordResetTokenDocument, options);
      }
export type VerifyPasswordResetTokenMutationHookResult = ReturnType<typeof useVerifyPasswordResetTokenMutation>;
export type VerifyPasswordResetTokenMutationResult = Apollo.MutationResult<ApiVerifyPasswordResetTokenMutation>;
export type VerifyPasswordResetTokenMutationOptions = Apollo.BaseMutationOptions<ApiVerifyPasswordResetTokenMutation, ApiVerifyPasswordResetTokenMutationVariables>;