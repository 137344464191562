import { Header, RowData, flexRender } from '@tanstack/react-table';
import { DownArrowsIcon, UpArrowsIcon, VerticalArrowsIcon } from '../icons';

type TableHeadColumnProps<T extends RowData> = Header<T, unknown>;

export const TableHeadColumn = <T,>(props: TableHeadColumnProps<T>) => {
  return (
    <th
      key={`th-${props.id}`}
      className="px-4 pb-3 text-left font-normal"
      colSpan={props.colSpan}
    >
      {props.isPlaceholder ? null : (
        <div
          className={getHeadSortedClasses(props)}
          onClick={props.column.getToggleSortingHandler()}
        >
          {flexRender(
            props.column.columnDef.header,
            props.getContext(),
          )}
          {{
            asc: UpArrowsIcon,
            desc: DownArrowsIcon,
          }[props.column.getIsSorted() as string] ?? null}
          {props.column.getCanSort() && !props.column.getIsSorted() && VerticalArrowsIcon}
        </div>
      )}
    </th>
  );
};

const getHeadSortedClasses = <T,>(header: Header<T, unknown>): string => {
  let classes = 'flex items-center';

  if (header.column.getCanSort()) {
    classes += ' cursor-pointer select-none';
  }

  if (header.column.getIsSorted()) {
    classes += ' font-medium';
  }

  return classes;
};
