import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  CloseIcon,
  DefinitionList,
  InfroStrip,
  Notification,
  RoundedFrame,
  SectionHeading,
  TicksIcon,
} from '@/app/components';
import { useCreativeDetail, useCreativeWorkflow } from './api';
import { CreativeDestinationInfo, CreativeStatus, PopupNote } from './components';
import { useCreativeStatus } from './hooks';

export const CreativeLibraryDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  if (!id) {
    throw new Error('CreativeDetailPage cannot be access without id param.');
  }

  const rejectButtonRef = useRef<HTMLDivElement>(null);
  const [rejectionPopupVisible, setRejectionPopupVisible] = useState<boolean>(false);
  const { creative, refreshCreative } = useCreativeDetail(id);
  const { approve, reject, request } = useCreativeWorkflow();
  const [creativeStatus, trigger] = useCreativeStatus(creative.status, {
    APPROVE: () => approve(creative.id),
    REJECT: (note: string) => reject(creative.id, note),
    REQUEST: () => request(creative.id),
  });

  const handleSubmitRejection = async (note: string) => {
    await trigger('REJECT', note);
    await refreshCreative();
    setRejectionPopupVisible(false);
  };

  return (
    <div>
      <div className="flex justify-between mb-5">
        <header>
          <h1 className="text-3xl font-medium mb-1.5">
            {creative.name}
          </h1>
        </header>

        <div className="flex space-x-2 items-center">
          {creativeStatus.can('REQUEST') && (
            <div>
              <Button
                label="Send for approval"
                variant="secondary"
                onClick={() => trigger('REQUEST')}
              />
            </div>
          )}

          {creativeStatus.can('APPROVE') && (
            <div>
              <Button
                label="Approve"
                variant="confirm"
                icon={TicksIcon}
                onClick={() => trigger('APPROVE')}
              />
            </div>
          )}

          {creativeStatus.can('REJECT') && (
            <div ref={rejectButtonRef}>
              <Button
                label="Disapprove"
                variant="decline"
                icon={CloseIcon}
                onClick={() => setRejectionPopupVisible(true)}
              />
            </div>
          )}
        </div>
      </div>

      <div className="mb-5">
        <CreativeStatus status={creativeStatus.currentState} />
        {creative.statusNote && (
          <div className="mt-5">
            <Notification
              type="warning"
              color="yellow"
              label={
                <p>
                  <strong>Creative had been disapproved with following reason:</strong>
                  {' '}
                  {creative.statusNote}
                </p>
              }
            />
          </div>
        )}
      </div>

      <RoundedFrame margin="mb-5">
        <DefinitionList
          data={[
            ...creative.previewContents,
            {
              term: 'Destination',
              description: (
                <CreativeDestinationInfo creative={creative} />
              ),
            },
          ]}
          columns={1}
        />
      </RoundedFrame>

      <section className="mb-5">
        <header className="mb-4">
          <SectionHeading heading="Preview Images" />
        </header>

        {creative.previewImages.length === 0 ? (
          <InfroStrip label="Creative does not contain any images." type="info" />
        ) : (
          creative.previewImages.map((previewImage, index) => (
            <RoundedFrame key={`preview-image_${index}`} padding="p-2">
              <img
                src={previewImage.url}
                alt={previewImage.name}
                width={1752}
                height={1271}
                className="rounded-xs"
              />
            </RoundedFrame>
          ))
        )}
      </section>

      {rejectionPopupVisible && (
        <PopupNote
          actionRef={rejectButtonRef}
          position="bottom"
          onClose={() => setRejectionPopupVisible(false)}
          onSubmit={handleSubmitRejection}
        />
      )}
    </div>
  );
};
