import { useTranslation } from 'react-i18next';
import { SetFilters, createFilters } from '@/app/common';
import { CampaignStatus } from '../../interfaces';

export type CampaignFilters = {
  viewMode: CampaignsViewMode;
  search: string | null;
  vendor: CampaignFiltersSelection | null;
  status: CampaignFiltersSelection<CampaignStatus> | null;
  salesPerson: CampaignFiltersSelection | null;
  startDate: string | null;
  endDate: string | null;
};

export type CampaignFiltersSelection<T extends string = string> = {
  name: string;
  value: T;
};

export type CampaignFiltersActiveBadge = {
  key: string;
  displayValue: string;
};

export type CampaignsViewMode = (
  | 'list'
  | 'performance'
);

type UseCampaignFiltersHook = {
  filters: CampaignFilters;
  filterBadges: CampaignFiltersActiveBadge[];
  setFilters: SetFilters<Partial<CampaignFilters>>;
};

export const {
  FiltersProvider: CampaignFiltersProvider,
  useFilters: useCampaignFiltersInternal,
} = createFilters<CampaignFilters>({
  filterId: 'campaigns',
  rememberPath: /^\/campaigns/,
  normalizer: (filters) => ({
    viewMode: filters.viewMode === 'performance' ? 'performance' : 'list',
    search: filters.search && filters.search.length > 0 ? filters.search : null,
    vendor: filters.vendor ?? null,
    status: filters.status ?? null,
    salesPerson: filters.salesPerson ?? null,
    startDate: filters.startDate ?? null,
    endDate: filters.endDate ?? null,
  }),
});

export const useCampaignFilters = (): UseCampaignFiltersHook => {
  const [filters, setFilters] = useCampaignFiltersInternal();
  const { i18n } = useTranslation();
  const dateFormatter = new Intl.DateTimeFormat(i18n.language);

  const filterBadges: CampaignFiltersActiveBadge[] = [
    ...(!filters.vendor ? [] : [{
      key: 'vendor',
      displayValue: filters.vendor.name,
    }]),
    ...(!filters.status ? [] : [{
      key: 'status',
      displayValue: filters.status.name,
    }]),
    ...(!filters.salesPerson ? [] : [{
      key: 'salesPerson',
      displayValue: filters.salesPerson.name,
    }]),
    ...(!filters.startDate ? [] : [{
      key: 'startDate',
      displayValue: dateFormatter.format(new Date(filters.startDate)),
    }]),
    ...(!filters.endDate ? [] : [{
      key: 'endDate',
      displayValue: dateFormatter.format(new Date(filters.endDate)),
    }]),
  ];

  return {
    filters,
    filterBadges,
    setFilters,
  };
};
