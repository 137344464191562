import { useRequestPasswordResetMutation } from './reset-password.mutations.generated';

type UseRequestPasswordResetHook = {
  requestPasswordReset: (username: string) => Promise<void>;
  loading: boolean;
};

export const useRequestResetPassword = (): UseRequestPasswordResetHook => {
  const [requestPasswordResetMutation, { loading }] = useRequestPasswordResetMutation();

  const requestPasswordReset = async (
    username: string,
  ) => {
    const doSubmit = async () => {
      const { data, errors } = await requestPasswordResetMutation({
        variables: {
          username,
        },
      });

      // Error
      if (errors !== undefined && errors.length > 0) {
        const errorMessage = errors[0].message;
        throw new Error(errorMessage);
      }

      // No error, no data
      if (!data) {
        throw new Error('Server returned empty unexpected response.');
      }

      return data;
    };

    await doSubmit();
  };

  return {
    requestPasswordReset,
    loading,
  };
};
