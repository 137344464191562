import { ColumnDef } from '@tanstack/react-table';
import { i18n } from 'i18next';
import { MouseEvent } from 'react';
import { AutoDisabledButton, AutoDisabledButtonChildrenProps, ButtonCircle, PauseIcon, PlayIcon, ToolIcon } from '@/app/components';
import { UseActivityStatusHook } from '../../hooks';
import { ActivityItem } from '../../interfaces';
import { ActivityStatus } from '../activity-status';

export const createPerformanceColumns = ({
  getActivityState,
  i18n,
}: {
  getActivityState: (activity: ActivityItem) => UseActivityStatusHook;
  i18n: i18n;
}): ColumnDef<ActivityItem>[] => [
  {
    accessorKey: 'name',
    header: () => (
      <span>Name</span>
    ),
    cell: ({ row }) => (
      <>
        <ToolIcon type={row.original.icon} />
        {row.original.name}
      </>
    ),
    size: 400,
  },
  {
    accessorKey: 'metrics.impressions',
    header: () => <span>Impressions</span>,
    enableSorting: false,
    size: 80,
  },
  {
    accessorKey: 'metrics.views',
    header: () => <span>Views</span>,
    enableSorting: false,
    size: 80,
  },
  {
    accessorKey: 'metrics.visibility',
    header: () => <span>Visibility</span>,
    cell: (info) => {
      return new Intl.NumberFormat(i18n.language, {
        style: 'percent',
      }).format(info.getValue<number>() / 100);
    },
    enableSorting: false,
    size: 80,
  },
  {
    accessorKey: 'metrics.clicks',
    header: () => <span>Clicks</span>,
    enableSorting: false,
    size: 80,
  },
  {
    accessorKey: 'metrics.ctr',
    header: () => <span>CTR</span>,
    cell: (info) => {
      return new Intl.NumberFormat(i18n.language, {
        style: 'percent',
      }).format(info.getValue<number>() / 100);
    },
    enableSorting: false,
    size: 80,
  },
  {
    accessorKey: 'status',
    header: () => <span>State</span>,
    cell: ({ row }) => {
      return (
        <ActivityStatus status={getActivityState(row.original)[0].currentState} />
      );
    },
    size: 150,
  },
  {
    accessorKey: 'workflow',
    header: () => '',
    cell: ({ row }) => {
      const [activityStatus, triggerWorkflow] = getActivityState(row.original);
      const ToggleButton = ({
        disabled,
        onClick,
      }: AutoDisabledButtonChildrenProps) => {
        const handlePause = (
          event: MouseEvent<HTMLButtonElement>,
          onClickCallback: (event: MouseEvent<HTMLButtonElement>) => void,
        ) => {
          event.stopPropagation();
          onClickCallback(event);
          triggerWorkflow('PAUSE');
        };

        const handleResume = (
          event: MouseEvent<HTMLButtonElement>,
          onClickCallback: (event: MouseEvent<HTMLButtonElement>) => void,
        ) => {
          event.stopPropagation();
          onClickCallback(event);
          triggerWorkflow('RESUME');
        };

        if (activityStatus.can('PAUSE')) {
          return (
            <ButtonCircle
              variant="tertiary"
              label="Pause"
              icon={PauseIcon}
              onClick={event => handlePause(event, onClick)}
              disabled={disabled}
              loading={disabled}
            />
          );
        } else if (activityStatus.can('RESUME')) {
          return (
            <ButtonCircle
              variant="tertiary"
              label="Resume"
              icon={PlayIcon}
              onClick={event => handleResume(event, onClick)}
              disabled={disabled}
              loading={disabled}
            />
          );
        }

        return null;
      };

      return (
        <AutoDisabledButton duration={5}>
          {buttonProps => <ToggleButton {...buttonProps} />}
        </AutoDisabledButton>
      );
    },
    size: 50,
    enableSorting: false,
  },
];
