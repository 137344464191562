import { useCallback } from 'react';
import { Role } from '../../interfaces';
import { useAuth } from '../auth';

type UseUserRole = {
  role: Role | null;
  isAgencyUser: () => boolean;
  isVendorUser: () => boolean;
  isRetailer: () => boolean;
};

export const useUserRole = (): UseUserRole => {
  const { state: { currentUser }} = useAuth();
  const role = currentUser?.role ?? null;

  const isAgencyUser = useCallback(() => role !== null && [Role.Agency].includes(role), [role]);
  const isVendorUser = useCallback(() => role !== null && [Role.Vendor].includes(role), [role]);
  const isRetailer = useCallback(() => role !== null && ![Role.Agency, Role.Vendor].includes(role), [role]);

  return {
    role: currentUser?.role ?? null,
    isAgencyUser,
    isVendorUser,
    isRetailer,
  };
};
