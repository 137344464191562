import { ColumnDef } from '@tanstack/react-table';
import { i18n } from 'i18next';
import { Currency } from '@/app/common';
import { CampaignItem } from '../../interfaces';
import { CampaignStatus } from '../campaign-status';

export const createPerformanceColumns = ({
  i18n,
  currency,
}: {
  i18n: i18n;
  currency: Currency;
}): ColumnDef<CampaignItem>[] => [
  {
    accessorKey: 'name',
    header: () => <span>Name</span>,
    size: 440,
  },
  {
    accessorKey: 'metrics.impressions',
    header: () => <span>Impressions</span>,
    enableSorting: false,
    size: 80,
  },
  {
    accessorKey: 'metrics.views',
    header: () => <span>Views</span>,
    enableSorting: false,
    size: 80,
  },
  {
    accessorKey: 'metrics.visibility',
    header: () => <span>Visibility</span>,
    cell: (info) => {
      return new Intl.NumberFormat(i18n.language, {
        style: 'percent',
      }).format(info.getValue<number>() / 100);
    },
    enableSorting: false,
    size: 80,
  },
  {
    accessorKey: 'metrics.clicks',
    header: () => <span>Clicks</span>,
    enableSorting: false,
    size: 80,
  },
  {
    accessorKey: 'metrics.ctr',
    header: () => <span>CTR</span>,
    cell: (info) => {
      return new Intl.NumberFormat(i18n.language, {
        style: 'percent',
      }).format(info.getValue<number>() / 100);
    },
    enableSorting: false,
    size: 80,
  },
  {
    accessorKey: 'status',
    header: () => <span>State</span>,
    cell: ({ row }) => {
      return (
        <CampaignStatus status={row.original.status} />
      );
    },
    size: 150,
  },
  {
    accessorKey: 'price',
    header: () => <span>Price</span>,
    cell: (info) => {
      return new Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency,
      }).format(info.getValue<number>());
    },
    size: 150,
  },
];
