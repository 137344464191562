import React, { useState } from 'react';
import { FilterItem } from './filter-item';

interface FilterListProps {
  items: { id: string, name: string }[];
  active?: string;
  onSelect?: (id: string) => void;
};

export const FilterList: React.FC<FilterListProps> = ({
  items,
  active = null,
  onSelect,
}) => {
  const [activeItem, setActiveItem] = useState<string | null>(active ?? null);

  const handleFilterSelect = (id: string) => {
    setActiveItem(id === activeItem ? null : id);
    onSelect?.(id);
  };

  return (
    <div className="flex flex-wrap items-center w-full gap-1.5">
      {items.map(({ id, name }) => (
        <FilterItem
          key={`filter-${id}`}
          color={id === activeItem ? 'blue' : 'transparent'}
          label={name}
          onClick={() => handleFilterSelect(id)}
        />
      ))}
    </div>
  );
};
