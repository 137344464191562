/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiRequestActivityApprovalMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ApiRequestActivityApprovalMutation = { __typename?: 'Mutation', requestActivityApproval: boolean };

export type ApiRejectActivityMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  note: Types.Scalars['String']['input'];
}>;


export type ApiRejectActivityMutation = { __typename?: 'Mutation', rejectActivity: boolean };

export type ApiApproveActivityMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ApiApproveActivityMutation = { __typename?: 'Mutation', approveActivity: boolean };

export type ApiCancelActivityMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ApiCancelActivityMutation = { __typename?: 'Mutation', cancelActivity: boolean };

export type ApiPauseActivityMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ApiPauseActivityMutation = { __typename?: 'Mutation', pauseActivity: boolean };

export type ApiResumeActivityMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ApiResumeActivityMutation = { __typename?: 'Mutation', resumeActivity: boolean };


export const RequestActivityApprovalDocument = gql`
    mutation RequestActivityApproval($id: ID!) {
  requestActivityApproval(id: $id)
}
    `;
export type ApiRequestActivityApprovalMutationFn = Apollo.MutationFunction<ApiRequestActivityApprovalMutation, ApiRequestActivityApprovalMutationVariables>;

/**
 * __useRequestActivityApprovalMutation__
 *
 * To run a mutation, you first call `useRequestActivityApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestActivityApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestActivityApprovalMutation, { data, loading, error }] = useRequestActivityApprovalMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRequestActivityApprovalMutation(baseOptions?: Apollo.MutationHookOptions<ApiRequestActivityApprovalMutation, ApiRequestActivityApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiRequestActivityApprovalMutation, ApiRequestActivityApprovalMutationVariables>(RequestActivityApprovalDocument, options);
      }
export type RequestActivityApprovalMutationHookResult = ReturnType<typeof useRequestActivityApprovalMutation>;
export type RequestActivityApprovalMutationResult = Apollo.MutationResult<ApiRequestActivityApprovalMutation>;
export type RequestActivityApprovalMutationOptions = Apollo.BaseMutationOptions<ApiRequestActivityApprovalMutation, ApiRequestActivityApprovalMutationVariables>;
export const RejectActivityDocument = gql`
    mutation RejectActivity($id: ID!, $note: String!) {
  rejectActivity(id: $id, note: $note)
}
    `;
export type ApiRejectActivityMutationFn = Apollo.MutationFunction<ApiRejectActivityMutation, ApiRejectActivityMutationVariables>;

/**
 * __useRejectActivityMutation__
 *
 * To run a mutation, you first call `useRejectActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectActivityMutation, { data, loading, error }] = useRejectActivityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useRejectActivityMutation(baseOptions?: Apollo.MutationHookOptions<ApiRejectActivityMutation, ApiRejectActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiRejectActivityMutation, ApiRejectActivityMutationVariables>(RejectActivityDocument, options);
      }
export type RejectActivityMutationHookResult = ReturnType<typeof useRejectActivityMutation>;
export type RejectActivityMutationResult = Apollo.MutationResult<ApiRejectActivityMutation>;
export type RejectActivityMutationOptions = Apollo.BaseMutationOptions<ApiRejectActivityMutation, ApiRejectActivityMutationVariables>;
export const ApproveActivityDocument = gql`
    mutation ApproveActivity($id: ID!) {
  approveActivity(id: $id)
}
    `;
export type ApiApproveActivityMutationFn = Apollo.MutationFunction<ApiApproveActivityMutation, ApiApproveActivityMutationVariables>;

/**
 * __useApproveActivityMutation__
 *
 * To run a mutation, you first call `useApproveActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveActivityMutation, { data, loading, error }] = useApproveActivityMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApproveActivityMutation(baseOptions?: Apollo.MutationHookOptions<ApiApproveActivityMutation, ApiApproveActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiApproveActivityMutation, ApiApproveActivityMutationVariables>(ApproveActivityDocument, options);
      }
export type ApproveActivityMutationHookResult = ReturnType<typeof useApproveActivityMutation>;
export type ApproveActivityMutationResult = Apollo.MutationResult<ApiApproveActivityMutation>;
export type ApproveActivityMutationOptions = Apollo.BaseMutationOptions<ApiApproveActivityMutation, ApiApproveActivityMutationVariables>;
export const CancelActivityDocument = gql`
    mutation CancelActivity($id: ID!) {
  cancelActivity(id: $id)
}
    `;
export type ApiCancelActivityMutationFn = Apollo.MutationFunction<ApiCancelActivityMutation, ApiCancelActivityMutationVariables>;

/**
 * __useCancelActivityMutation__
 *
 * To run a mutation, you first call `useCancelActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelActivityMutation, { data, loading, error }] = useCancelActivityMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelActivityMutation(baseOptions?: Apollo.MutationHookOptions<ApiCancelActivityMutation, ApiCancelActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiCancelActivityMutation, ApiCancelActivityMutationVariables>(CancelActivityDocument, options);
      }
export type CancelActivityMutationHookResult = ReturnType<typeof useCancelActivityMutation>;
export type CancelActivityMutationResult = Apollo.MutationResult<ApiCancelActivityMutation>;
export type CancelActivityMutationOptions = Apollo.BaseMutationOptions<ApiCancelActivityMutation, ApiCancelActivityMutationVariables>;
export const PauseActivityDocument = gql`
    mutation PauseActivity($id: ID!) {
  pauseActivity(id: $id)
}
    `;
export type ApiPauseActivityMutationFn = Apollo.MutationFunction<ApiPauseActivityMutation, ApiPauseActivityMutationVariables>;

/**
 * __usePauseActivityMutation__
 *
 * To run a mutation, you first call `usePauseActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseActivityMutation, { data, loading, error }] = usePauseActivityMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePauseActivityMutation(baseOptions?: Apollo.MutationHookOptions<ApiPauseActivityMutation, ApiPauseActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiPauseActivityMutation, ApiPauseActivityMutationVariables>(PauseActivityDocument, options);
      }
export type PauseActivityMutationHookResult = ReturnType<typeof usePauseActivityMutation>;
export type PauseActivityMutationResult = Apollo.MutationResult<ApiPauseActivityMutation>;
export type PauseActivityMutationOptions = Apollo.BaseMutationOptions<ApiPauseActivityMutation, ApiPauseActivityMutationVariables>;
export const ResumeActivityDocument = gql`
    mutation ResumeActivity($id: ID!) {
  resumeActivity(id: $id)
}
    `;
export type ApiResumeActivityMutationFn = Apollo.MutationFunction<ApiResumeActivityMutation, ApiResumeActivityMutationVariables>;

/**
 * __useResumeActivityMutation__
 *
 * To run a mutation, you first call `useResumeActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumeActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumeActivityMutation, { data, loading, error }] = useResumeActivityMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResumeActivityMutation(baseOptions?: Apollo.MutationHookOptions<ApiResumeActivityMutation, ApiResumeActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiResumeActivityMutation, ApiResumeActivityMutationVariables>(ResumeActivityDocument, options);
      }
export type ResumeActivityMutationHookResult = ReturnType<typeof useResumeActivityMutation>;
export type ResumeActivityMutationResult = Apollo.MutationResult<ApiResumeActivityMutation>;
export type ResumeActivityMutationOptions = Apollo.BaseMutationOptions<ApiResumeActivityMutation, ApiResumeActivityMutationVariables>;