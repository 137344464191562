import React, { useCallback, useEffect, useRef, useState } from 'react';
// TODO: temporary image
import avaterImg from '@/app/assets/images/temp/avatar.png';
import { useAuth } from '@/app/common';
import { ChevronUpDownIcon, UserDropdown } from '@/app/components';

type UserPanelProps = {
  adminModeEnabled: boolean;
  switchAdminMode: () => void;
};

export const UserPanel: React.FC<UserPanelProps> = ({
  adminModeEnabled,
  switchAdminMode,
}) => {
  const { state: { currentUser: user }} = useAuth();
  const role = user?.role;

  // States
  const [isDropdownActive, setIsDropdownActive] = useState<boolean>(false);

  // Reference to the dropdown element
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Close dropdown on click outside
  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownActive(false);
    }
  };

  const handleSwitchAdminMode = useCallback(() => {
    switchAdminMode();
    setIsDropdownActive(false);
  }, [switchAdminMode]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!user) {
    return null;
  }

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        className="
          flex
          items-center
          space-x-2
          text-slate-900
          hover:text-purple-800
          border
          border-slate-300
          rounded-xl
          p-1
          text-left
        "
        type="button"
        onClick={ () => setIsDropdownActive(!isDropdownActive) }
      >
        <div>
          <img
            className="w-8 h-8 rounded-full"
            src={avaterImg}
            alt="Jméno Příjmení avatar"
          />
        </div>
        <div className="flex flex-col">
          <span className="leading-none text-sm font-medium mb-1">
            {user.name}
          </span>
          <div className="leading-none text-xs">
            <span>{role}</span>
          </div>
        </div>
        <div className="pr-3">
          {ChevronUpDownIcon}
        </div>
      </button>

      {isDropdownActive && (
        <UserDropdown
          adminModeEnabled={adminModeEnabled}
          switchAdminMode={handleSwitchAdminMode}
        />
      )}
    </div>
  );
};
