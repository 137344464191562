/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiCreateCampaignInitialDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ApiCreateCampaignInitialDataQuery = { __typename?: 'Query', vendors: { __typename?: 'VendorResult', total: number, items: Array<{ __typename?: 'LeanVendor', id: string, name: string }> } };


export const CreateCampaignInitialDataDocument = gql`
    query CreateCampaignInitialData {
  vendors(page: 1, limit: 10) {
    total
    items {
      id
      name
    }
  }
}
    `;

/**
 * __useCreateCampaignInitialDataQuery__
 *
 * To run a query within a React component, call `useCreateCampaignInitialDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateCampaignInitialDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateCampaignInitialDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreateCampaignInitialDataQuery(baseOptions?: Apollo.QueryHookOptions<ApiCreateCampaignInitialDataQuery, ApiCreateCampaignInitialDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiCreateCampaignInitialDataQuery, ApiCreateCampaignInitialDataQueryVariables>(CreateCampaignInitialDataDocument, options);
      }
export function useCreateCampaignInitialDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiCreateCampaignInitialDataQuery, ApiCreateCampaignInitialDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiCreateCampaignInitialDataQuery, ApiCreateCampaignInitialDataQueryVariables>(CreateCampaignInitialDataDocument, options);
        }
export function useCreateCampaignInitialDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApiCreateCampaignInitialDataQuery, ApiCreateCampaignInitialDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiCreateCampaignInitialDataQuery, ApiCreateCampaignInitialDataQueryVariables>(CreateCampaignInitialDataDocument, options);
        }
export type CreateCampaignInitialDataQueryHookResult = ReturnType<typeof useCreateCampaignInitialDataQuery>;
export type CreateCampaignInitialDataLazyQueryHookResult = ReturnType<typeof useCreateCampaignInitialDataLazyQuery>;
export type CreateCampaignInitialDataSuspenseQueryHookResult = ReturnType<typeof useCreateCampaignInitialDataSuspenseQuery>;
export type CreateCampaignInitialDataQueryResult = Apollo.QueryResult<ApiCreateCampaignInitialDataQuery, ApiCreateCampaignInitialDataQueryVariables>;