import { ApiCurrency, ApiLocale, ApiRole, ApiTimezone } from '@/app/common';

export interface LocaleItem {
  name: string;
  value: string;
};

export const locales: LocaleItem[] = Object
  .values(ApiLocale)
  .map(item => ({ name: item, value: item }));

export interface TimezoneItem {
  name: string;
  value: string;
};

export const timezones: TimezoneItem[] = Object
  .values(ApiTimezone)
  .map(item => ({ name: item, value: item }));

export interface CurrencyItem {
  name: string;
  value: string;
};

export const currencies: CurrencyItem[] = Object
  .values(ApiCurrency)
  .map(item => ({ name: item, value: item }));

export interface RoleItem {
  name: string;
  value: string;
};

export const roles: RoleItem[] = Object
  .values(ApiRole)
  .filter(item => ![ApiRole.SuperAdmin, ApiRole.OrganizationAdmin].includes(item))
  .map(item => ({ name: item, value: item }));
