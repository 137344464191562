import { ApolloError } from '@apollo/client';
import { mapCampaignDetail } from './campaign-detail.mapper';
import { useCampaignDetailsSuspenseQuery } from './get-campaign-detail.query.generated';
import { CampaignDetail } from '../../interfaces';

type UseCampaignDetailHook = {
  campaign: CampaignDetail;
  refreshCampaign: () => Promise<CampaignDetail>;
  error: ApolloError | undefined;
};

export const useCampaignDetail = (id: string): UseCampaignDetailHook => {
  const { data, error, refetch } = useCampaignDetailsSuspenseQuery({
    variables: {
      id,
    },
  });

  const refreshCampaign = async () => {
    const { data, error } = await refetch();
    if (error) {
      throw new Error('Unable to refresh campaign detail.');
    }

    return mapCampaignDetail(data.campaign);
  };

  return {
    campaign: mapCampaignDetail(data.campaign),
    refreshCampaign,
    error,
  };
};
