/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiUserItemFragment = { __typename?: 'LeanUser', id: string, username: string, name: string, locale: Types.ApiLocale | null, timezone: Types.ApiTimezone | null, isActive: boolean, roles: Array<Types.ApiRole> };

export type ApiUsersQueryVariables = Types.Exact<{
  page: Types.Scalars['Int']['input'];
  limit: Types.Scalars['Int']['input'];
  roles: Types.InputMaybe<Array<Types.ApiRole> | Types.ApiRole>;
  search: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ApiUsersQuery = { __typename?: 'Query', users: { __typename?: 'UserResult', total: number, items: Array<{ __typename?: 'LeanUser', id: string, username: string, name: string, locale: Types.ApiLocale | null, timezone: Types.ApiTimezone | null, isActive: boolean, roles: Array<Types.ApiRole> }> } };

export const UserItemFragmentDoc = gql`
    fragment UserItem on LeanUser {
  id
  username
  name
  locale
  timezone
  isActive
  roles
}
    `;
export const UsersDocument = gql`
    query Users($page: Int!, $limit: Int!, $roles: [Role!], $search: String) {
  users(page: $page, limit: $limit, roles: $roles, search: $search) {
    total
    items {
      ...UserItem
    }
  }
}
    ${UserItemFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      roles: // value for 'roles'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useUsersQuery(baseOptions: Apollo.QueryHookOptions<ApiUsersQuery, ApiUsersQueryVariables> & ({ variables: ApiUsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiUsersQuery, ApiUsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiUsersQuery, ApiUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiUsersQuery, ApiUsersQueryVariables>(UsersDocument, options);
        }
export function useUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApiUsersQuery, ApiUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiUsersQuery, ApiUsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersSuspenseQueryHookResult = ReturnType<typeof useUsersSuspenseQuery>;
export type UsersQueryResult = Apollo.QueryResult<ApiUsersQuery, ApiUsersQueryVariables>;