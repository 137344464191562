import { ChangeEvent, useRef, useState } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { Button } from '../button';
import { BinIcon } from '../icons';

type AcceptedFormat = (
  | 'jpeg'
  | 'png'
  | 'gif'
  | 'webp'
);

export type ImageFieldValue = {
  id: string;
  name: string;
  url: string;
};

export interface ImageFieldProps<T extends FieldValues> {
  control: Control<T>;
  label: string;
  id: string;
  name: Path<T>;
  required?: boolean;
  defaultValue?: T[string] | null;
  accept?: AcceptedFormat[];
  size?: number; // in kB
  width?: number;
  height?: number;
  onSelect: (file: File) => Promise<ImageFieldValue>;
  onChange?: (media: ImageFieldValue | null) => void;
};

export const ImageField = <T extends FieldValues>({
  control,
  label,
  id,
  name,
  required = false,
  defaultValue = null,
  accept = ['jpeg', 'gif', 'png', 'webp'],
  size = 300,
  width = undefined,
  height = undefined,
  onSelect,
  onChange,
}: ImageFieldProps<T>) => {
  const { field } = useController({
    name,
    defaultValue: defaultValue ?? undefined,
    control,
    rules: { required },
  });
  const [previewUrl, setPreviewUrl] = useState<ImageFieldValue | null>(field.value ?? null);
  const [error, setError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const allowedFormats = accept.map(format => format.toUpperCase()).join('/');
  const acceptedSizeInBytes = size * 1024;
  const acceptedMimeTypes = accept.length === 0
    ? 'image/*'
    : accept.map(format => `image/${format}`).join(',');

  const handleSelectFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (!fileList || fileList.length === 0) return;
    if (fileList[0].size > (size * 1024)) {
      setError('Selected file exceeded maximal file size.');
      return;
    }

    setError(null);
    setPreviewUrl({
      id: Math.random().toString(),
      name: 'preview-image',
      url: URL.createObjectURL(fileList[0]),
    });
    const mediaId = await onSelect(fileList[0]);

    field.onChange(mediaId);
    onChange?.(mediaId);
  };

  const handleCancel = () => {
    setError(null);
    setPreviewUrl(null);
    field.onChange(null);
    onChange?.(null);
  };

  return (
    <>
      <label className="text-sm  mb-2" htmlFor={id}>
        {label}
        {!required && (
          <span className="text-slate-400 ml-1">
            (optional)
          </span>
        )}
      </label>

      {previewUrl !== null ? (
        <div className="border border-dashed rounded-xs px-2 pt-2 pb-5">
          <div className="flex flex-col gap-y-4 items-center border-slate-300">
            <div>
              <img
                src={previewUrl.url}
                alt={previewUrl.name}
                width="100%"
                className="rounded-xs"
              />
            </div>
            <div>
              <Button
                variant="secondary"
                icon={BinIcon}
                label="Remove"
                onClick={handleCancel}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="border border-dashed rounded-xs px-4 py-5">
          <div className="flex flex-col gap-y-4 items-center border-slate-300">
            <div className="flex flex-col items-center gap-y-2 text-sm">
              <span>Drag & drop image</span>
              <span>or</span>
            </div>

            <div className="relative">
              <Button
                label="Select file on drive"
                variant="secondary"
                onClick={() => fileInputRef.current?.click()}
              />
              <input
                type="file"
                name={name}
                id={id}
                className="opacity-0 absolute top-0 left-0 w-full h-full z-0 cursor-pointer"
                ref={fileInputRef}
                accept={acceptedMimeTypes}
                size={acceptedSizeInBytes}
                onChange={handleSelectFile}
              />
            </div>

            <div className="flex flex-col gap-y-1 items-center">
              <span className="text-sm">Required file format</span>
              <span className="text-xs font-semibold">
                {allowedFormats}
                {width && height && `, ${width}x${height}px`}
                &nbsp;(max.&nbsp;{size}&nbsp;kB)
              </span>
            </div>
          </div>
        </div>
      )}

      {error !== undefined && (
        <p className="mt-1 text-xs text-red-700 leading-4">{error}</p>
      )}
    </>
  );
};
