type UseAppIdHook = string;

export const useAppId = (): UseAppIdHook => {
  const isLocalhost = window.location.hostname === 'localhost';
  const isPhoenixUrl = window.location.hostname.includes('.cruxo.io');

  if (!isLocalhost && isPhoenixUrl) {
    const subdomains = window.location.hostname.split('.');
    return subdomains[0];
  }

  // cruxo organization fallback for local development
  return 'cruxo';
};
