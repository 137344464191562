/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiAdvertisingLibraryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ApiAdvertisingLibraryQuery = { __typename?: 'Query', advertisingLibrary: Array<{ __typename?: 'AdvertisingLibraryItem', id: string, name: string, tools: Array<{ __typename?: 'AdvertisingLibraryTool', id: string, name: string, description: string | null, icon: Types.ApiAdvertisingToolIcon | null, rateType: Types.ApiAdvertisingToolRate, unitPrice: number, targets: Array<{ __typename?: 'IdentifiedTarget', id: string, name: string }> }> }> };


export const AdvertisingLibraryDocument = gql`
    query AdvertisingLibrary {
  advertisingLibrary {
    id
    name
    tools {
      id
      name
      description
      icon
      rateType
      unitPrice
      targets {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useAdvertisingLibraryQuery__
 *
 * To run a query within a React component, call `useAdvertisingLibraryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvertisingLibraryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvertisingLibraryQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdvertisingLibraryQuery(baseOptions?: Apollo.QueryHookOptions<ApiAdvertisingLibraryQuery, ApiAdvertisingLibraryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiAdvertisingLibraryQuery, ApiAdvertisingLibraryQueryVariables>(AdvertisingLibraryDocument, options);
      }
export function useAdvertisingLibraryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiAdvertisingLibraryQuery, ApiAdvertisingLibraryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiAdvertisingLibraryQuery, ApiAdvertisingLibraryQueryVariables>(AdvertisingLibraryDocument, options);
        }
export function useAdvertisingLibrarySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApiAdvertisingLibraryQuery, ApiAdvertisingLibraryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiAdvertisingLibraryQuery, ApiAdvertisingLibraryQueryVariables>(AdvertisingLibraryDocument, options);
        }
export type AdvertisingLibraryQueryHookResult = ReturnType<typeof useAdvertisingLibraryQuery>;
export type AdvertisingLibraryLazyQueryHookResult = ReturnType<typeof useAdvertisingLibraryLazyQuery>;
export type AdvertisingLibrarySuspenseQueryHookResult = ReturnType<typeof useAdvertisingLibrarySuspenseQuery>;
export type AdvertisingLibraryQueryResult = Apollo.QueryResult<ApiAdvertisingLibraryQuery, ApiAdvertisingLibraryQueryVariables>;