import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useDialogContext } from '@/app/common';
import {
  Button,
  ComboboxField,
  FieldError,
  FieldWrapper,
  PlusIcon,
  WhiteCard,
} from '@/app/components';
import { SelectCreativesDialog } from './dialogs';
import { ActivityCreativeItem, CreativeFormat } from '../../interfaces';

interface CreativesFormatSelectorProps {
  formats: CreativeFormat[];
  vendorId: string;
  error?: FieldError;
  onSelectFormat: (format: string) => void;
  onSelectCreative: (creative: ActivityCreativeItem) => void;
};

export const CreativesFormatSelector: React.FC<CreativesFormatSelectorProps> = ({
  formats,
  vendorId,
  error,
  onSelectFormat,
  onSelectCreative,
}) => {
  const { control, setValue, watch } = useFormContext();
  const { dialogState, openDialog, closeDialog } = useDialogContext();
  const selectedFormat = watch('activityForm.creativeFormatType', null);

  const handleCreateNew = () => {
    if (!selectedFormat || Array.isArray(selectedFormat)) {
      throw new Error('Selected field type is expected to be a single choice value.');
    }

    onSelectFormat(selectedFormat.value);
    setValue('activityForm.creativeFormatType', null);
  };

  const handleSelectExisting = () => {
    openDialog('select-creative');
  };

  const handleSelectCreative = (creative: ActivityCreativeItem) => {
    onSelectCreative(creative);
    closeDialog('select-creative');
    setValue('activityForm.creativeFormatType', null);
  };

  return (
    <WhiteCard padding="mt-4">
      <div className="border rounded-sm border-dashed border-slate-300 p-4">
        <FieldWrapper>
          <ComboboxField
            control={control}
            name="activityForm.creativeFormatType"
            placeholder="Select format for your creative"
            options={formats.map(format => ({
              value: format.id,
              name: format.name,
              description: format.description ?? '',
            }))}
            label="Format for a new Creative"
          />
        </FieldWrapper>

        <FieldWrapper>
          <div className="flex justify-center gap-x-3">
            <div>
              <Button
                label="Create new"
                variant="secondary"
                icon={PlusIcon}
                onClick={handleCreateNew}
                disabled={selectedFormat === null}
              />
            </div>

            <div>
              <Button
                label="Selected from existing creatives"
                variant="secondary"
                icon={PlusIcon}
                onClick={handleSelectExisting}
              />
            </div>
          </div>
        </FieldWrapper>
      </div>

      {error !== undefined && (
        <p className="mt-1 text-xs text-red-700 leading-4">{error.message}</p>
      )}

      <SelectCreativesDialog
        isOpened={dialogState['select-creative']}
        vendorId={vendorId}
        allowedFormats={formats.map(format => format.id)}
        onClose={() => closeDialog('select-creative')}
        onSelect={handleSelectCreative}
      />
    </WhiteCard>
  );
};
