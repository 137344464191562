import { Context, useContext } from 'react';
import { Filters, FiltersContextType, NormalizedFilters, SetFilters } from './filters.interface';

export type UseFiltersHookFn<
  F extends Filters,
  N extends NormalizedFilters,
> = () => UseFiltersHookTupple<F, N>;

export type UseFiltersHookTupple<
  F extends Filters,
  N extends NormalizedFilters,
> = [
  filters: N,
  setFilters: SetFilters<Partial<F>>,
];

export const createUseFilters = <F extends Filters, N extends NormalizedFilters>(
  context: Context<FiltersContextType<F, N> | null>,
): UseFiltersHookFn<F, N> => {
  return () => {
    const filtersContext = useContext(context);
    if (!filtersContext) {
      throw new Error('useFilters must be used within a FiltersProvider');
    }

    return [
      filtersContext.filters,
      filtersContext.setFilters,
    ];
  };
};
