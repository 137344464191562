import { ApolloError } from '@apollo/client';
import { useGetCompanyUserMarketsSuspenseQuery } from './get-markets.query.generated';
import { MarketItem, mapMarket } from './market.mapper';

type UseMarketsHook = {
  total: number;
  markets: MarketItem[];
  error: ApolloError | undefined;
};

export const useMarkets = (): UseMarketsHook => {
  const { data, error } = useGetCompanyUserMarketsSuspenseQuery({
    variables: {
      page: 1,
      limit: 100,
    },
  });

  return {
    total: data.markets.total,
    markets: data.markets.items.map(mapMarket),
    error,
  };
};
