import React from 'react';
import {
  Badge,
  Button,
  ButtonCircle,
  CalendarIcon,
  Checkbox,
  ChevronRightIcon,
  ComboboxSearch,
  InfoCircleIcon,
  InfroStrip,
  InputField,
  Notification,
  Paginator,
  PlusIcon,
  RadioButton,
  Selectbox,
  Tabs,
  Tooltip,
} from '@/app/components';
import { ToolIcon } from '../components/tool-icon';


// Selectbox options
const selectBoxOptions = [
  { name: 'Option 1', value: '1' },
  { name: 'Option 2', value: '2' },
  { name: 'Option 3', value: '3' },
  { name: 'Option 4', value: '4' },
];

// Pagination API response example
const paginationObject = {
  postsPerPage: 10,
  totalPosts: 90,
  currentPage: 2,
  showPrevNext: true,
};

// Tabs options
const tabsOptions = ['Example Tab 1', 'Example Tab 2', 'Example Tab 3'];

export const DesignLibrary: React.FC = () => {
  return(
    <div>
      <div>
        <h2 className="text-3xl font-semibold mb-4">Buttons</h2>
        <div className="mb-24">
          <h3 className="text-2xl mb-4">Primary</h3>
          <div className="flex items-center space-x-4 mb-4">
            <span className="w-1/12">Default</span>
            <div className="flex space-x-2">
              <Button
                link="#"
                label="Vytvořit kampaň"
                variant="primary"
              />
              <Button
                link="#"
                label="Vytvořit kampaň"
                variant="primary"
                icon={PlusIcon}
                size="md"
              />
              <Button
                link="#"
                label="Vytvořit kampaň"
                variant="primary"
                icon={ChevronRightIcon}
                iconPosition="right"
                size="sm"
              />
              <ButtonCircle
                link="#"
                label="Vytvořit kampaň"
                variant="primary"
                icon={PlusIcon}
                size={'lg'}
              />
              <ButtonCircle
                link="#"
                label="Vytvořit kampaň"
                variant="primary"
                icon={PlusIcon}
                size={'md'}
              />
              <ButtonCircle
                link="#"
                label="Vytvořit kampaň"
                variant="primary"
                icon={PlusIcon}
                size={'xs'}
              />
            </div>
          </div>
          <div className="flex items-center space-x-4 mb-4">
            <span className="w-1/12">Disabled</span>
            <div className="flex space-x-2">
              <Button
                link="#"
                disabled
                label="Vytvořit kampaň"
                variant="primary"
              />
              <Button
                link="#"
                disabled
                label="Vytvořit kampaň"
                variant="primary"
                icon={PlusIcon}
                size="md"
              />
              <Button
                link="#"
                disabled
                label="Vytvořit kampaň"
                variant="primary"
                icon={ChevronRightIcon}
                iconPosition="right"
                size="sm"
              />
              <ButtonCircle
                link="#"
                disabled
                label="Vytvořit kampaň"
                variant="primary"
                icon={PlusIcon}
                size={'lg'}
              />
              <ButtonCircle
                link="#"
                disabled
                label="Vytvořit kampaň"
                variant="primary"
                icon={PlusIcon}
                size={'md'}
              />
              <ButtonCircle
                link="#"
                disabled
                label="Vytvořit kampaň"
                variant="primary"
                icon={PlusIcon}
                size={'xs'}
              />
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <span className="w-1/12">Loading</span>
            <div className="flex space-x-2">
              <Button
                link="#"
                loading
                label="Vytvořit
                kampaň"
                variant="primary"
              />
              <Button
                link="#"
                loading
                label="Vytvořit kampaň"
                variant="primary"
                icon={PlusIcon}
                size="md"
              />
              <Button
                link="#"
                loading
                label="Vytvořit kampaň"
                variant="primary"
                icon={ChevronRightIcon}
                iconPosition="right"
                size="sm"
              />
            </div>
          </div>
        </div>
        <div className="mb-24">
          <h3 className="text-2xl mb-4">Secondary</h3>
          <div className="flex items-center space-x-4 mb-4">
            <span className="w-1/12">Default</span>
            <div className="flex space-x-2">
              <Button
                link="#"
                label="Vytvořit kampaň"
                variant="secondary"
              />
              <Button
                link="#"
                label="Vytvořit kampaň"
                variant="secondary"
                icon={PlusIcon}
                size="md"
              />
              <Button
                link="#"
                label="Vytvořit kampaň"
                variant="secondary"
                icon={ChevronRightIcon}
                iconPosition="right"
                size="sm"
              />
              <ButtonCircle
                link="#"
                label="Vytvořit kampaň"
                variant="secondary"
                icon={PlusIcon}
                size={'lg'}
              />
              <ButtonCircle
                link="#"
                label="Vytvořit kampaň"
                variant="secondary"
                icon={PlusIcon}
                size={'md'}
              />
              <ButtonCircle
                link="#"
                label="Vytvořit kampaň"
                variant="secondary"
                icon={PlusIcon}
                size={'xs'}
              />
            </div>
          </div>
          <div className="flex items-center space-x-4 mb-4">
            <span className="w-1/12">Disabled</span>
            <div className="flex space-x-2">
              <Button
                link="#"
                disabled
                label="Vytvořit kampaň"
                variant="secondary"
              />
              <Button
                link="#"
                disabled
                label="Vytvořit kampaň"
                variant="secondary"
                icon={PlusIcon}
                size="md"
              />
              <Button
                link="#"
                disabled
                label="Vytvořit kampaň"
                variant="secondary"
                icon={ChevronRightIcon}
                iconPosition="right"
                size="sm"
              />
              <ButtonCircle
                link="#"
                disabled
                label="Vytvořit kampaň"
                variant="secondary"
                icon={PlusIcon}
                size={'lg'}
              />
              <ButtonCircle
                link="#"
                disabled
                label="Vytvořit kampaň"
                variant="secondary"
                icon={PlusIcon}
                size={'md'}
              />
              <ButtonCircle
                link="#"
                disabled
                label="Vytvořit kampaň"
                variant="secondary"
                icon={PlusIcon}
                size={'xs'}
              />
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <span className="w-1/12">Loading</span>
            <div className="flex space-x-2">
              <Button
                link="#"
                loading
                label="Vytvořit kampaň"
                variant="secondary"
              />
              <Button
                link="#"
                loading
                label="Vytvořit kampaň"
                variant="secondary"
                icon={PlusIcon}
                size="md"
              />
              <Button
                link="#"
                loading
                label="Vytvořit kampaň"
                variant="secondary"
                icon={ChevronRightIcon}
                iconPosition="right"
                size="sm"
              />
            </div>
          </div>
        </div>

        <div className="mb-16">
          <h3 className="text-2xl mb-4">Tertiary</h3>
          <div className="flex items-center space-x-4 mb-4">
            <span className="w-1/12">Default</span>
            <div className="flex space-x-2">
              <Button
                link="#"
                label="Vytvořit kampaň"
                variant="tertiary"
              />
              <Button
                link="#"
                label="Vytvořit kampaň"
                variant="tertiary"
                icon={PlusIcon}
                size="md"
              />
              <Button
                link="#"
                label="Vytvořit kampaň"
                variant="tertiary"
                icon={ChevronRightIcon}
                iconPosition="right"
                size="sm"
              />
              <ButtonCircle
                link="#"
                label="Vytvořit kampaň"
                variant="tertiary"
                icon={PlusIcon}
                size={'lg'}
              />
              <ButtonCircle
                link="#"
                label="Vytvořit kampaň"
                variant="tertiary"
                icon={PlusIcon}
                size={'md'}
              />
              <ButtonCircle
                link="#"
                label="Vytvořit kampaň"
                variant="tertiary"
                icon={PlusIcon}
                size={'xs'}
              />
            </div>
          </div>
          <div className="flex items-center space-x-4  mb-4">
            <span className="w-1/12">Disabled</span>
            <div className="flex space-x-2">
              <Button
                link="#"
                disabled
                label="Vytvořit kampaň"
                variant="tertiary"
              />
              <Button
                link="#"
                disabled
                label="Vytvořit kampaň"
                variant="tertiary"
                icon={PlusIcon}
                size="md"
              />
              <Button
                link="#"
                disabled
                label="Vytvořit kampaň"
                variant="tertiary"
                icon={ChevronRightIcon}
                iconPosition="right"
                size="sm"
              />
              <ButtonCircle
                link="#"
                disabled
                label="Vytvořit kampaň"
                variant="tertiary"
                icon={PlusIcon}
                size={'lg'}
              />
              <ButtonCircle
                link="#"
                disabled
                label="Vytvořit kampaň"
                variant="tertiary"
                icon={PlusIcon}
                size={'md'}
              />
              <ButtonCircle
                link="#"
                disabled
                label="Vytvořit kampaň"
                variant="tertiary"
                icon={PlusIcon}
                size={'xs'}
              />
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <span className="w-1/12">Loading</span>
            <div className="flex space-x-2">
              <Button
                link="#"
                loading
                label="Vytvořit kampaň"
                variant="tertiary"
              />
              <Button
                link="#"
                loading
                label="Vytvořit kampaň"
                variant="tertiary"
                icon={PlusIcon}
                size="md"
              />
              <Button
                link="#"
                loading
                label="Vytvořit kampaň"
                variant="tertiary"
                icon={ChevronRightIcon}
                iconPosition="right"
                size="sm"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Form Fields */}
      <div>
        <h2 className="text-3xl font-semibold mb-4">Form Fields</h2>
        <div className="mb-24">
          <div className="flex items-center space-x-4 mb-4">
            <span className="w-44">Empty</span>
            <div className="flex space-x-6">
              <InputField
                name="input1"
                label="Label"
                icon={CalendarIcon}
                iconPosition="left"
              />
              <InputField
                name="input2"
                label="Label"
                icon={CalendarIcon}
              />
              <div className="mt-7">
                <InputField
                  name="input3"
                  icon={CalendarIcon}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-4 mb-4">
            <span className="w-44">Placeholder</span>
            <div className="flex space-x-6">
              <InputField
                name="input4"
                label="Label"
                placeholder="Select box..."
              />
              <InputField
                name="input5"
                label="Label"
                placeholder="Select box..."
                icon={CalendarIcon}
              />
              <InputField
                name="input6"
                placeholder="Select box..."
              />
            </div>
          </div>
          <div className="flex items-center space-x-4 mb-4">
            <span className="w-44">Filled</span>
            <div className="flex space-x-6">
              <InputField
                name="input7"
                label="Label"
                value="Input field..."
                placeholder="Select box..."
              />
              <InputField
                name="input8"
                label="Label"
                value="Input field..."
                placeholder="Select box..."
                icon={CalendarIcon}
              />
              <InputField
                name="input9"
                value="Input field..."
                placeholder="Select box..."
              />
            </div>
          </div>
          <div className="flex items-center space-x-4 mb-4">
            <span className="w-44">Disabled</span>
            <div className="flex space-x-6">
              <InputField
                name="input10"
                disabled
                label="Label"
                value="Input field..."
                placeholder="Select box..."
              />
              <InputField
                name="input11"
                disabled
                label="Label"
                value="Input field..."
                placeholder="Select box..."
                icon={CalendarIcon}
              />
              <InputField
                name="input12"
                disabled
                value="Input field..."
                placeholder="Select box..."
              />
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <span className="w-44">Error</span>
            <div className="flex space-x-6">
              <InputField
                name="input13"
                label="Label"
                value="Input field..."
                placeholder="Select box..."
                error={{
                  message: 'Error message here...',
                }}
              />
              <InputField
                name="input14"
                label="Label"
                value="Input field..."
                placeholder="Select box..."
                icon={CalendarIcon}
                error={{
                  message: 'Error message here...',
                }}
              />
              <InputField
                name="input15"
                value="Input field..."
                placeholder="Select box..."
                error={{
                  message: 'Error message here...',
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Selectbox */}
      <div>
        <h2 className="text-3xl font-semibold mb-4">Selectboxes</h2>
        <div className="mb-24">
          <div className="flex items-center space-x-4 mb-4">
            <span className="w-1/5">No selection</span>
            <div className="flex space-x-6 w-full">
              <div className="w-1/3">
                <Selectbox
                  id="select1"
                  options={selectBoxOptions}
                  label="Label"
                />
              </div>
              <div className="w-1/3">
                <Selectbox
                  id="select2"
                  options={selectBoxOptions}
                  label="Label"
                />
              </div>
              <div className="w-1/3">
                <Selectbox
                  id="select3"
                  options={selectBoxOptions}
                  label="Label"
                />
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-4 mb-4">
            <span className="w-1/5">Option selected</span>
            <div className="flex space-x-6 w-full">
              <div className="w-1/3">
                <Selectbox
                  id="select4"
                  options={selectBoxOptions}
                  label="Label"
                  value={selectBoxOptions[0]}
                />
              </div>
              <div className="w-1/3">
                <Selectbox
                  id="select5"
                  options={selectBoxOptions}
                  label="Label"
                  value={selectBoxOptions[0]}
                />
              </div>
              <div className="w-1/3">
                <Selectbox
                  id="select6"
                  options={selectBoxOptions}
                  label="Label"
                  value={selectBoxOptions[0]}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-4 mb-4">
            <span className="w-1/5">Option selected multiple</span>
            <div className="flex space-x-6 w-full">
              <div className="w-1/3">
                <Selectbox
                  id="select4_multiple"
                  options={selectBoxOptions}
                  label="Label"
                  value={[selectBoxOptions[0]]}
                  multiple
                />
              </div>
              <div className="w-1/3">
                <Selectbox
                  id="select5_multiple"
                  options={selectBoxOptions}
                  label="Label"
                  value={[selectBoxOptions[0]]}
                  multiple
                />
              </div>
              <div className="w-1/3">
                <Selectbox
                  id="select6_multiple"
                  options={selectBoxOptions}
                  label="Label"
                  value={[selectBoxOptions[0]]}
                  multiple
                />
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-4 mb-4">
            <span className="w-1/5">Disabled</span>
            <div className="flex space-x-6 w-full">
              <div className="w-1/3">
                <Selectbox
                  id="select7"
                  options={selectBoxOptions}
                  label="Label"
                  disabled
                />
              </div>
              <div className="w-1/3">
                <Selectbox
                  id="select8"
                  options={selectBoxOptions}
                  label="Label"
                  disabled
                />
              </div>
              <div className="w-1/3">
                <Selectbox
                  id="select9"
                  options={selectBoxOptions}
                  label="Label"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-4 mb-4">
            <span className="w-1/5">Error</span>
            <div className="flex space-x-6 w-full">
              <div className="w-1/3">
                <Selectbox
                  id="select10"
                  options={selectBoxOptions}
                  label="Label"
                  error={{
                    message: 'Error message here...',
                  }}
                />
              </div>
              <div className="w-1/3">
                <Selectbox
                  id="select11"
                  options={selectBoxOptions}
                  label="Label"
                  error={{
                    message: 'Error message here...',
                  }}
                />
              </div>
              <div className="w-1/3">
                <Selectbox
                  id="select12"
                  options={selectBoxOptions}
                  label="Label"
                  error={{
                    message: 'Error message here...',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Combobox */}
      <div>
        <h2 className="text-3xl font-semibold mb-4">Comboboxes</h2>
        <div className="mb-24">
          <div className="flex items-center space-x-4 mb-4">
            <span className="w-1/5">No selection</span>
            <div className="flex space-x-6 w-full">
              <div className="w-1/3">
                <ComboboxSearch
                  id="combobox1"
                  options={selectBoxOptions}
                  label="Label"
                />
              </div>
              <div className="w-1/3">
                <ComboboxSearch
                  id="combobox2"
                  options={selectBoxOptions}
                  label="Label"
                />
              </div>
              <div className="w-1/3">
                <ComboboxSearch
                  id="combobox3"
                  options={selectBoxOptions}
                  label="Label"
                />
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-4 mb-4">
            <span className="w-1/5">Option selected</span>
            <div className="flex space-x-6 w-full">
              <div className="w-1/3">
                <ComboboxSearch
                  id="combobox14"
                  options={selectBoxOptions}
                  label="Label"
                  value={selectBoxOptions[0]}
                />
              </div>
              <div className="w-1/3">
                <ComboboxSearch
                  id="combobox15"
                  options={selectBoxOptions}
                  label="Label"
                  value={selectBoxOptions[1]}
                />
              </div>
              <div className="w-1/3">
                <ComboboxSearch
                  id="combobox16"
                  options={selectBoxOptions}
                  label="Label"
                  value={selectBoxOptions[2]}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-4 mb-4">
            <span className="w-1/5">Option selected multi</span>
            <div className="flex space-x-6 w-full">
              <div className="w-1/3">
                <ComboboxSearch
                  id="combobox4"
                  options={selectBoxOptions}
                  label="Label"
                  value={[selectBoxOptions[0]]}
                  multiple
                />
              </div>
              <div className="w-1/3">
                <ComboboxSearch
                  id="combobox5"
                  options={selectBoxOptions}
                  label="Label"
                  value={[selectBoxOptions[0],selectBoxOptions[1]]}
                  multiple
                />
              </div>
              <div className="w-1/3">
                <ComboboxSearch
                  id="combobox6"
                  options={selectBoxOptions}
                  label="Label"
                  value={[selectBoxOptions[0]]}
                  multiple
                />
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-4 mb-4">
            <span className="w-1/5">Disabled</span>
            <div className="flex space-x-6 w-full">
              <div className="w-1/3">
                <ComboboxSearch
                  id="combobox7"
                  options={selectBoxOptions}
                  label="Label"
                  disabled
                />
              </div>
              <div className="w-1/3">
                <ComboboxSearch
                  id="combobox8"
                  options={selectBoxOptions}
                  label="Label"
                  disabled
                />
              </div>
              <div className="w-1/3">
                <ComboboxSearch
                  id="combobox9"
                  options={selectBoxOptions}
                  label="Label"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-4 mb-4">
            <span className="w-1/5">Error</span>
            <div className="flex space-x-6 w-full">
              <div className="w-1/3">
                <ComboboxSearch
                  id="combobox10"
                  options={selectBoxOptions}
                  label="Label"
                  error={{
                    message: 'Error message here...',
                  }}
                />
              </div>
              <div className="w-1/3">
                <ComboboxSearch
                  id="combobox11"
                  options={selectBoxOptions}
                  label="Label"
                  error={{
                    message: 'Error message here...',
                  }}
                />
              </div>
              <div className="w-1/3">
                <ComboboxSearch
                  id="combobox12"
                  options={selectBoxOptions}
                  label="Label"
                  error={{
                    message: 'Error message here...',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Checkboxes */}
      <div>
        <h2 className="text-3xl font-semibold mb-4">Checkboxes</h2>
        <div className="mb-24">
          <div className="flex items-center space-x-4 mb-4">
            <span className="w-1/5">Unchecked</span>
            <div className="flex space-x-2 w-full">
              <div className="w-1/6 flex space-x-2">
                <Checkbox
                  name="unchecked"
                  checked={false}
                />
                <Checkbox
                  name="unchecked"
                  checked={false}
                  size="sm"
                />
              </div>
              <div className="w-1/3">
                <Checkbox
                  name="unchecked"
                  label="Checkbox
                  medium"
                  checked={false}
                />
              </div>
              <div className="w-1/3">
                <Checkbox
                  name="unchecked"
                  label="Checkbox small"
                  size="sm"
                  checked={false}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-4 mb-4">
            <span className="w-1/5">Checked</span>
            <div className="flex space-x-2 w-full">
              <div className="w-1/6 flex space-x-2">
                <Checkbox
                  name="checked"
                  checked={true}
                />
                <Checkbox
                  name="checked"
                  checked={true}
                  size="sm"
                />
              </div>
              <div className="w-1/3">
                <Checkbox
                  name="checked"
                  label="Checkbox
                  medium"
                  checked={true}
                />
              </div>
              <div className="w-1/3">
                <Checkbox
                  name="checked"
                  label="Checkbox small"
                  size="sm"
                  checked={true}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-4 mb-4">
            <span className="w-1/5">Unchecked/Disabled</span>
            <div className="flex space-x-2 w-full">
              <div className="w-1/6 flex space-x-2">
                <Checkbox
                  name="uncheckedDisabled"
                  disabled
                  checked={false}
                />
                <Checkbox
                  name="uncheckedDisabled"
                  disabled
                  checked={false}
                  size="sm"
                />
              </div>
              <div className="w-1/3">
                <Checkbox
                  name="uncheckedDisabled"
                  disabled
                  label="Checkbox medium"
                  checked={false}
                />
              </div>
              <div className="w-1/3">
                <Checkbox
                  name="uncheckedDisabled"
                  disabled
                  label="Checkbox small"
                  size="sm"
                  checked={false}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-4 mb-4">
            <span className="w-1/5">Checked/Disabled</span>
            <div className="flex space-x-2 w-full">
              <div className="w-1/6 flex space-x-2">
                <Checkbox
                  name="checkedDisabled"
                  disabled
                  checked={true}
                />
                <Checkbox
                  name="checkedDisabled"
                  disabled
                  checked={true}
                  size="sm"
                />
              </div>
              <div className="w-1/3">
                <Checkbox
                  name="checkedDisabled"
                  disabled
                  label="Checkbox medium"
                  checked={true}
                />
              </div>
              <div className="w-1/3">
                <Checkbox
                  name="checkedDisabled"
                  disabled
                  label="Checkbox small"
                  size="sm"
                  checked={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Radio Buttons */}
      <div>
        <h2 className="text-3xl font-semibold mb-4">Radio Buttons</h2>
        <div className="mb-24">
          <div className="flex items-center space-x-4 mb-4">
            <span className="w-1/5">Unchecked</span>
            <div className="flex space-x-2 w-full">
              <div className="w-1/6 flex space-x-2">
                <RadioButton
                  name="name1"
                  id="radio1"
                  checked={false}
                />
                <RadioButton
                  name="name1"
                  id="radio2"
                  checked={false}
                  size="sm"
                />
              </div>
              <div className="w-1/3">
                <RadioButton
                  name="name1"
                  id="radio3"
                  label="Radio button medium"
                  checked={false}
                />
              </div>
              <div className="w-1/3">
                <RadioButton
                  name="name1"
                  id="radio4"
                  label="Radio button small"
                  size="sm"
                  checked={false}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-4 mb-4">
            <span className="w-1/5">Checked</span>
            <div className="flex space-x-2 w-full">
              <div className="w-1/6 flex space-x-2">
                <RadioButton
                  name="name2"
                  id="radio5"
                  checked={true}
                />
                <RadioButton
                  name="name2"
                  id="radio6"
                  checked={true}
                  size="sm"
                />
              </div>
              <div className="w-1/3">
                <RadioButton
                  name="name2"
                  id="radio7"
                  label="Radio button medium"
                  checked={true}
                />
              </div>
              <div className="w-1/3">
                <RadioButton
                  name="name2"
                  id="radio8"
                  label="Radio button small"
                  size="sm"
                  checked={true}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-4 mb-4">
            <span className="w-1/5">Unchecked/Disabled</span>
            <div className="flex space-x-2 w-full">
              <div className="w-1/6 flex space-x-2">
                <RadioButton
                  name="name3"
                  id="radio9"
                  disabled
                  checked={false}
                />
                <RadioButton
                  name="name3"
                  id="radio10"
                  disabled
                  checked={false}
                  size="sm"
                />
              </div>
              <div className="w-1/3">
                <RadioButton
                  name="name3"
                  id="radio11"
                  disabled
                  label="Radio button medium"
                  checked={false}
                />
              </div>
              <div className="w-1/3">
                <RadioButton
                  name="name3"
                  id="radio12"
                  disabled
                  label="Radio button small"
                  size="sm"
                  checked={false}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-4 mb-4">
            <span className="w-1/5">Checked/Disabled</span>
            <div className="flex space-x-2 w-full">
              <div className="w-1/6 flex space-x-2">
                <RadioButton
                  name="name4"
                  id="radio13"
                  disabled
                  checked={true}
                />
                <RadioButton
                  name="name4"
                  id="radio14"
                  disabled
                  checked={true}
                  size="sm"
                />
              </div>
              <div className="w-1/3">
                <RadioButton
                  name="name4"
                  id="radio15"
                  disabled
                  label="Radio button medium"
                  checked={true}
                />
              </div>
              <div className="w-1/3">
                <RadioButton
                  name="name4"
                  id="radio16"
                  disabled
                  label="Radio button small"
                  size="sm"
                  checked={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Chips / Badges */}
      <div>
        <h2 className="text-3xl font-semibold mb-4">Chips</h2>
        <div className="mb-24">
          <div className="flex items-center space-x-10 mb-4">
            <span className="w-1/12">Grey</span>
            <div className="flex space-x-4">
              <Badge label="On-line" color="grey" size="lg" />
              <Badge label="On-line" color="grey" size="md" />
              <Badge label="On-line" color="grey" size="sm" />
            </div>
            <div className="flex space-x-4">
              <Badge label="On-line" color="grey" size="lg" onClick={() => {}} />
              <Badge label="On-line" color="grey" size="md" onClick={() => {}} />
              <Badge label="On-line" color="grey" size="sm" onClick={() => {}} />
            </div>
          </div>

          <div className="flex items-center space-x-10 mb-4">
            <span className="w-1/12">Blue</span>
            <div className="flex space-x-4">
              <Badge label="On-line" color="blue" size="lg" />
              <Badge label="On-line" color="blue" size="md" />
              <Badge label="On-line" color="blue" size="sm" />
            </div>
            <div className="flex space-x-4">
              <Badge label="On-line" color="blue" size="lg" onClick={() => {}} />
              <Badge label="On-line" color="blue" size="md" onClick={() => {}} />
              <Badge label="On-line" color="blue" size="sm" onClick={() => {}} />
            </div>
          </div>

          <div className="flex items-center space-x-10 mb-4">
            <span className="w-1/12">Green</span>
            <div className="flex space-x-4">
              <Badge label="On-line" color="green" size="lg" />
              <Badge label="On-line" color="green" size="md" />
              <Badge label="On-line" color="green" size="sm" />
            </div>
            <div className="flex space-x-4">
              <Badge label="On-line" color="green" size="lg" onClick={() => {}} />
              <Badge label="On-line" color="green" size="md" onClick={() => {}} />
              <Badge label="On-line" color="green" size="sm" onClick={() => {}} />
            </div>
          </div>

          <div className="flex items-center space-x-10 mb-4">
            <span className="w-1/12">Yellow</span>
            <div className="flex space-x-4">
              <Badge label="On-line" color="yellow" size="lg" />
              <Badge label="On-line" color="yellow" size="md" />
              <Badge label="On-line" color="yellow" size="sm" />
            </div>
            <div className="flex space-x-4">
              <Badge
                label="On-line"
                color="yellow"
                size="lg"
                onClick={() => {}}
              />
              <Badge
                label="On-line"
                color="yellow"
                size="md"
                onClick={() => {}}
              />
              <Badge
                label="On-line"
                color="yellow"
                size="sm"
                onClick={() => {}}
              />
            </div>
          </div>

          <div className="flex items-center space-x-10 mb-4">
            <span className="w-1/12">Red</span>
            <div className="flex space-x-4">
              <Badge label="On-line" color="red" size="lg" />
              <Badge label="On-line" color="red" size="md" />
              <Badge label="On-line" color="red" size="sm" />
            </div>
            <div className="flex space-x-4">
              <Badge label="On-line" color="red" size="lg" onClick={() => {}} />
              <Badge label="On-line" color="red" size="md" onClick={() => {}} />
              <Badge label="On-line" color="red" size="sm" onClick={() => {}} />
            </div>
          </div>
        </div>
      </div>

      {/* Notifications */}
      <div className="mb-32">
        <h2 className="text-3xl font-semibold mb-4">Notifications</h2>
        <div className="mb-24">
          <div className="flex items-center space-x-10 mb-4">
            <span className="w-1/12">Grey</span>
            <div className="flex space-x-4">
              <Notification
                label="Lorem ipsum dolor sit amet, consectetur adipiscing elit, quisque quis dapibus velit"
                color="grey"
                type="info"
                onClick={() => {}}
              />
            </div>
          </div>

          <div className="flex items-center space-x-10 mb-4">
            <span className="w-1/12">Blue</span>
            <div className="flex space-x-4">
              <Notification
                label="Lorem ipsum dolor sit amet, consectetur adipiscing elit, quisque quis dapibus velit"
                color="blue"
                type="info"
                onClick={() => {}}
              />
            </div>
          </div>

          <div className="flex items-center space-x-10 mb-4">
            <span className="w-1/12">Green</span>
            <div className="flex space-x-4">
              <Notification
                label="Lorem ipsum dolor sit amet, consectetur adipiscing elit, quisque quis dapibus velit"
                color="green"
                type="success"
                onClick={() => {}}
              />
            </div>
          </div>

          <div className="flex items-center space-x-10 mb-4">
            <span className="w-1/12">Yellow</span>
            <div className="flex space-x-4">
              <Notification
                label="Lorem ipsum dolor sit amet, consectetur adipiscing elit, quisque quis dapibus velit"
                color="yellow"
                type="warning"
                onClick={() => {}}
              />
            </div>
          </div>

          <div className="flex items-center space-x-10 mb-4">
            <span className="w-1/12">Red</span>
            <div className="flex space-x-4">
              <Notification
                label="Lorem ipsum dolor sit amet, consectetur adipiscing elit, quisque quis dapibus velit"
                color="red"
                type="error"
                onClick={() => {}}
              />
            </div>
          </div>

          <div className="flex items-center space-x-10 mb-4">
            <span className="w-1/12">Upload error</span>
            <div className="flex space-x-4">
              <Notification
                label="Selected file exceeded maximal file size."
                color="red"
                type="error"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Info strips */}
      <div className="mb-32">
        <h2 className="text-3xl font-semibold mb-4">Info Strips</h2>
        <div className="mb-24">
          <div className="flex items-center space-x-10 mb-4">
            <span className="w-1/12">Info</span>
            <div className="flex space-x-4">
              <InfroStrip
                label="Lorem ipsum dolor sit amet, consectetur adipiscing elit, quisque quis dapibus velit"
                type="info"
                onClick={() => {}}
              />
            </div>
          </div>

          <div className="flex items-center space-x-10 mb-4">
            <span className="w-1/12">Success</span>
            <div className="flex space-x-4">
              <InfroStrip
                label="Lorem ipsum dolor sit amet, consectetur adipiscing elit, quisque quis dapibus velit"
                type="success"
                onClick={() => {}}
              />
            </div>
          </div>

          <div className="flex items-center space-x-10 mb-4">
            <span className="w-1/12">Warning</span>
            <div className="flex space-x-4">
              <InfroStrip
                label="Lorem ipsum dolor sit amet, consectetur adipiscing elit, quisque quis dapibus velit"
                type="warning"
                onClick={() => {}}
              />
            </div>
          </div>

          <div className="flex items-center space-x-10 mb-4">
            <span className="w-1/12">Error</span>
            <div className="flex space-x-4">
              <InfroStrip
                label="Lorem ipsum dolor sit amet, consectetur adipiscing elit, quisque quis dapibus velit"
                type="error"
                onClick={() => {}}
              />
            </div>
          </div>

        </div>
      </div>

      {/* Tabs */}
      <div className="mb-32">
        <h2 className="text-3xl font-semibold mb-4">Tabs</h2>
        <div className="mb-24">
          <Tabs tabs={tabsOptions} />
        </div>
      </div>

      {/* Tooltips */}
      <div className="mb-32">
        <h2 className="text-3xl font-semibold mb-4">Tooltips</h2>
        <div className="mb-24">
          <div className="flex items-center space-x-10 mb-4">
            <span className="w-auto">Top / Bottom / Left / Right</span>
            <div className="flex space-x-14 items-center">
              <Tooltip text="To je tooltip!" position="top">
                <button type="button">{InfoCircleIcon}</button>
              </Tooltip>
              <Tooltip text="To je tooltip!" position="bottom">
                <button type="button">{InfoCircleIcon}</button>
              </Tooltip>
              <Tooltip text="To je tooltip!" position="left">
                <button type="button">{InfoCircleIcon}</button>
              </Tooltip>
              <Tooltip text="To je tooltip!" position="right">
                <button type="button">{InfoCircleIcon}</button>
              </Tooltip>
            </div>
          </div>
          <div className="flex items-center space-x-10 mb-4">
            <span className="w-auto">Max Width</span>
            <div className="flex space-x-14 items-center">
              <Tooltip
                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, quisque quis dapibus velit"
                position="top"
              >
                <button type="button">{InfoCircleIcon}</button>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>

      {/* Pagination */}
      <div className="mb-32">
        <h2 className="text-3xl font-semibold mb-4">Pagination</h2>
        <div className="mb-24">
          <div className="flex items-center space-x-10 mb-4">
            <span className="w-auto">Pagination</span>
            <div className="flex space-x-14 items-center">
              <Paginator
                currentPage={paginationObject.currentPage}
                itemsPerPage={paginationObject.postsPerPage}
                itemsCount={paginationObject.totalPosts}
              />
            </div>
          </div>

          <div className="flex items-center space-x-10 mb-4">
            <span className="w-auto">Load more</span>
            <div className="flex space-x-14 items-center">
              <Button link="#" label="Load more" variant="secondary" />
            </div>
          </div>
        </div>
      </div>

      {/* Tool icons */}
      <div className="mb-32">
        <h2 className="text-3xl font-semibold mb-4">Tool icons</h2>
        <div className="grid grid-cols-1 gap-2">
          <div className="flex gap-x-4 items-center mb-4">
            <ToolIcon type="BANNER_CAROUSEL_HP_GREEN" />
            <span>BANNER_CAROUSEL_HP_GREEN</span>
          </div>

          <div className="flex gap-x-4 items-center mb-4">
            <ToolIcon type="BANNER_CATEGORY_GREEN" />
            <span>BANNER_CATEGORY_GREEN</span>
          </div>

          <div className="flex gap-x-4 items-center mb-4">
            <ToolIcon type="BANNER_SPONSORED_CATEGORY_GREEN" />
            <span>BANNER_SPONSORED_CATEGORY_GREEN</span>
          </div>

          <div className="flex gap-x-4 items-center mb-4">
            <ToolIcon type="BANNER_UNDER_CAROUSEL" />
            <span>BANNER_UNDER_CAROUSEL</span>
          </div>

          <div className="flex gap-x-4 items-center mb-4">
            <ToolIcon type="SPONSORED_PRODUCT_CART" />
            <span>SPONSORED_PRODUCT_CART</span>
          </div>

          <div className="flex gap-x-4 items-center mb-4">
            <ToolIcon type="SPONSORED_PRODUCT_CART_PLUS" />
            <span>SPONSORED_PRODUCT_CART_PLUS</span>
          </div>

          <div className="flex gap-x-4 items-center mb-4">
            <ToolIcon type="SPONSORED_PRODUCT_CATEGORY_POSITION_1_GREEN" />
            <span>SPONSORED_PRODUCT_CATEGORY_POSITION_1_GREEN</span>
          </div>

          <div className="flex gap-x-4 items-center mb-4">
            <ToolIcon type="SPONSORED_PRODUCT_CATEGORY_POSITION_1_PURPLE" />
            <span>SPONSORED_PRODUCT_CATEGORY_POSITION_1_PURPLE</span>
          </div>

          <div className="flex gap-x-4 items-center mb-4">
            <ToolIcon type="SPONSORED_PRODUCT_CATEGORY_POSITION_3_GREEN" />
            <span>SPONSORED_PRODUCT_CATEGORY_POSITION_3_GREEN</span>
          </div>

          <div className="flex gap-x-4 items-center mb-4">
            <ToolIcon type="SPONSORED_PRODUCT_CATEGORY_POSITION_3_PURPLE" />
            <span>SPONSORED_PRODUCT_CATEGORY_POSITION_3_PURPLE</span>
          </div>

          <div className="flex gap-x-4 items-center mb-4">
            <ToolIcon type="SPONSORED_PRODUCT_CATEGORY_POSITION_4_GREEN" />
            <span>SPONSORED_PRODUCT_CATEGORY_POSITION_4_GREEN</span>
          </div>

          <div className="flex gap-x-4 items-center mb-4">
            <ToolIcon type="SPONSORED_PRODUCT_CATEGORY_POSITION_4_PURPLE" />
            <span>SPONSORED_PRODUCT_CATEGORY_POSITION_4_PURPLE</span>
          </div>

          <div className="flex gap-x-4 items-center mb-4">
            <ToolIcon type="SPONSORED_PRODUCT_HP_GREEN" />
            <span>SPONSORED_PRODUCT_HP_GREEN</span>
          </div>

          <div className="flex gap-x-4 items-center mb-4">
            <ToolIcon type="SPONSORED_PRODUCT_HP_PURPLE" />
            <span>SPONSORED_PRODUCT_HP_PURPLE</span>
          </div>

          <div className="flex gap-x-4 items-center mb-4">
            <ToolIcon type="SPONSORED_PRODUCT_PRODUCT_DETAIL_GREEN" />
            <span>SPONSORED_PRODUCT_PRODUCT_DETAIL_GREEN</span>
          </div>

          <div className="flex gap-x-4 items-center mb-4">
            <ToolIcon type="SPONSORED_PRODUCT_PRODUCT_DETAIL_PURPLE" />
            <span>SPONSORED_PRODUCT_PRODUCT_DETAIL_PURPLE</span>
          </div>

          <div className="flex gap-x-4 items-center mb-4">
            <ToolIcon type="SPONSORED_PRODUCT_SEARCH" />
            <span>SPONSORED_PRODUCT_SEARCH</span>
          </div>
        </div>
      </div>
    </div>
  );
};
