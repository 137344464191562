import React, { useEffect, useRef, useState } from 'react';
import { useAuth, useAuthContexts, useWorkspaceRedirects } from '@/app/common';
import { ChevronUpDownIcon, MarketDropdown } from '@/app/components';
import { CurrentMarket, MarketSwitcherItem } from './market.interface';
import { getFlagIcon } from './utils';

export type MarketSwitcherProps = {
  showFlags?: boolean;
};

export const MarketSwitcher: React.FC<MarketSwitcherProps> = ({ showFlags = true }) => {
  const [isDropdownActive, setIsDropdownActive] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { state: { currentWorkspace }, switchWorkspace } = useAuth();
  const { contexts } = useAuthContexts();
  const navigate = useWorkspaceRedirects();

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownActive(false);
    }
  };

  const handleSwitchWorkspace = async (workspace: MarketSwitcherItem): Promise<void> => {
    setIsDropdownActive(false);
    await switchWorkspace(workspace.id);
    navigate();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!currentWorkspace || contexts.length < 2) {
    return null;
  }

  const currentMarket: CurrentMarket = {
    name: currentWorkspace.name,
    currency: 'EUR',
    id: currentWorkspace.id,
  };
  const markets: MarketSwitcherItem[] = contexts.map(context => ({
    id: context.marketId,
    name: context.marketName,
  }));

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        className="
          flex
          items-center
          space-x-2
          text-slate-900
          hover:text-purple-800
          border
          border-slate-300
          rounded-xl
          p-1
          h-8
          box-content
          text-left
        "
        type="button"
        onClick={ () => setIsDropdownActive(!isDropdownActive) }
      >
        {showFlags && (
          <div>
            <div className="flex items-center justify-center rounded-full bg-purple-200 w-8 h-8 overflow-hidden">
              {getFlagIcon(currentMarket.id)}
            </div>
          </div>
        )}
        <div className="flex flex-col ml-2">
          <span className="leading-none text-sm font-medium">
            {currentMarket.name}
          </span>
        </div>
        <div className="pr-3">
          {ChevronUpDownIcon}
        </div>
      </button>

      {isDropdownActive && (
        <MarketDropdown
          markets={markets}
          showFlags={showFlags}
          onSelect={handleSwitchWorkspace}
        />
      )}
    </div>
  );
};
