import { mapStringToLocaleEnum, mapStringToTimezoneEnum } from '@/app/common';
import { useCreateVendorUserMutation } from './create-vendor-user.query.generated';
import { VendorUserFormData } from '../../interfaces';

type UseCreateVendorUserHook = {
  createVendorUser: (vendorId: string, formData: VendorUserFormData) => Promise<void>;
  loading: boolean;
};

export const useCreateVendorUser = (): UseCreateVendorUserHook => {
  const [vendorUserMutation, { loading }] = useCreateVendorUserMutation();

  const createVendorUser = async (vendorId: string, formData: VendorUserFormData) => {
    const doSubmit = async () => {
      const { data, errors } = await vendorUserMutation({
        variables: {
          ...formData,
          vendorId,
          locale: formData.locale && mapStringToLocaleEnum(formData.locale) || null,
          timezone: formData.timezone && mapStringToTimezoneEnum(formData.timezone) || null,
        },
      });

      // Error
      if (errors !== undefined && errors.length > 0) {
        const errorMessage = errors[0].message;
        throw new Error(errorMessage);
      }

      // No error, no data
      if (!data) {
        throw new Error('Server returned empty unexpected response.');
      }

      return data;
    };

    await doSubmit();
  };

  return {
    createVendorUser,
    loading,
  };
};
