import { useUpdateVendorMutation } from './update-vendor.query.generated';
import { VendorFormData } from '../../interfaces';

type UseUpdateVendorHook = {
  updateVendor: (id: string, formData: VendorFormData) => Promise<void>;
  loading: boolean;
};

export const useUpdateVendor = (): UseUpdateVendorHook => {
  const [vendorMutation, { loading }] = useUpdateVendorMutation();

  const updateVendor = async (
    id: string,
    formData: VendorFormData,
  ) => {
    const doSubmit = async () => {
      const { data, errors } = await vendorMutation({
        variables: {
          ...formData,
          id,
          billingInfo: {
            businessId: formData.businessId,
            businessName: formData.businessName,
            vatNumber: formData.vatNumber,
          },
        },
      });

      // Error
      if (errors !== undefined && errors.length > 0) {
        const errorMessage = errors[0].message;
        throw new Error(errorMessage);
      }

      // No error, no data
      if (!data) {
        throw new Error('Server returned empty unexpected response.');
      }

      return data;
    };

    await doSubmit();
  };

  return {
    updateVendor,
    loading,
  };
};
