/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiCurrentUserFragment = { __typename?: 'CurrentUser', id: string, username: string, name: string, locale: Types.ApiLocale | null, timezone: Types.ApiTimezone | null, currentRole: Types.ApiRole };

export type ApiCurrentWorkspaceFragment = { __typename?: 'Workspace', id: string, name: string, currency: Types.ApiCurrency };

export type ApiCurrentUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ApiCurrentUserQuery = { __typename?: 'Query', me: { __typename?: 'CurrentUser', id: string, username: string, name: string, locale: Types.ApiLocale | null, timezone: Types.ApiTimezone | null, currentRole: Types.ApiRole } | null, workspace: { __typename?: 'Workspace', id: string, name: string, currency: Types.ApiCurrency } | null };

export const CurrentUserFragmentDoc = gql`
    fragment CurrentUser on CurrentUser {
  id
  username
  name
  locale
  timezone
  currentRole
}
    `;
export const CurrentWorkspaceFragmentDoc = gql`
    fragment CurrentWorkspace on Workspace {
  id
  name
  currency
}
    `;
export const CurrentUserDocument = gql`
    query currentUser {
  me {
    ...CurrentUser
  }
  workspace {
    ...CurrentWorkspace
  }
}
    ${CurrentUserFragmentDoc}
${CurrentWorkspaceFragmentDoc}`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<ApiCurrentUserQuery, ApiCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiCurrentUserQuery, ApiCurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiCurrentUserQuery, ApiCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiCurrentUserQuery, ApiCurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export function useCurrentUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApiCurrentUserQuery, ApiCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiCurrentUserQuery, ApiCurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserSuspenseQueryHookResult = ReturnType<typeof useCurrentUserSuspenseQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<ApiCurrentUserQuery, ApiCurrentUserQueryVariables>;