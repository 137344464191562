import { useTranslation } from 'react-i18next';
import { useAppMeta } from '@/app/common';
import {
  Button,
  ChevronRightIcon,
  DefinitionList,
  DialogContent,
  DialogHeader,
  RoundedFrame,
  ToggleSection,
} from '@/app/components';
import { useAdvertisingTool } from '../../api';

interface AdvertisingToolsDialogDetailProps {
  toolId: string;
  onSelect: (toolId: string) => void;
  onClose: () => void;
};

export const AdvertisingToolsDialogDetail: React.FC<AdvertisingToolsDialogDetailProps> = ({
  toolId,
  onSelect,
  onClose,
}) => {
  const { advertisingTool } = useAdvertisingTool(toolId);
  const { i18n } = useTranslation();
  const { currency } = useAppMeta();
  const priceFormatter = Intl.NumberFormat(i18n.language, { style: 'currency', currency });

  return (
    <>
      <DialogHeader
        title={advertisingTool.name}
        description={advertisingTool.description}
        titleIcon={advertisingTool.icon}
        titleSize="text-3xl"
      />

      <DialogContent canScroll>
        <ToggleSection title="Tool details" margin="mb-5">
          <RoundedFrame margin="mb-5">
            <DefinitionList columns={2} data={[
              {
                term: 'Advertising tool type',
                description: advertisingTool.toolType,
              },
            ]} />
          </RoundedFrame>
        </ToggleSection>


        <ToggleSection title="Tool Features" margin="mb-5">
          <RoundedFrame>
            <DefinitionList columns={2} data={[
              {
                term: 'AutoPause Flights',
                description: advertisingTool.autoPauseFlights && 'yes' || 'no',
              },
              {
                term: 'Use Default Position Value',
                description: advertisingTool.useDefaultPositionValue && 'yes' || 'no',
              },
              {
                term: 'Minimal Days Before Start',
                description: `${advertisingTool.minimalDaysBeforeStart ?? 0}`,
              },
              {
                term: 'Check Targetting Availability',
                description: advertisingTool.checkTargettingAvailability && 'yes' || 'no',
              },
            ]} />
          </RoundedFrame>
        </ToggleSection>

        <ToggleSection title="Rates & Prices" margin="mb-5">
          <RoundedFrame>
            <DefinitionList columns={2} data={[
              {
                term: 'Rate Type',
                description: advertisingTool.rateType,
              },
              {
                term: 'List Rate',
                description: priceFormatter.format(advertisingTool.unitPrice),
              },
              {
                term: 'Enabled auction',
                description: advertisingTool.auction && 'yes' || 'no',
              },
              {
                term: 'Minimal budget',
                description: priceFormatter.format(advertisingTool.minimalBudget),
              },
            ]} />
          </RoundedFrame>
        </ToggleSection>

        {advertisingTool.previewImage && (
          <ToggleSection title="Preview image">
            <RoundedFrame padding="p-2">
              <img
                src={advertisingTool.previewImage.url}
                alt={advertisingTool.previewImage.name}
                height={advertisingTool.previewImage.height ?? undefined}
                className="rounded-xs"
              />
            </RoundedFrame>
          </ToggleSection>
        )}
      </DialogContent>

      <div className="flex justify-between mt-8 gap-x-4">
        <div>
          <Button
            variant="secondary"
            label="Close detail"
            onClick={onClose}
          />
        </div>
        <div>
          <Button
            variant="primary"
            label="Select tool"
            icon={ChevronRightIcon}
            iconPosition="right"
            onClick={() => onSelect(toolId)}
          />
        </div>
      </div>
    </>
  );
};
