import { ColumnDef } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, TableFooter, TableRow} from '@/app/components';
import { useVendors } from '../../api';
import { AgencyVendorItem, VendorItem } from '../../interfaces';

interface VendorListProps {
  children: React.ReactNode;
};

export const VendorList: React.FC<VendorListProps> = ({
  children,
}) => {
  const {
    vendors,
    total,
    page,
    limit,
  } = useVendors();
  const navigate = useNavigate();
  const columns = useMemo<ColumnDef<VendorItem | AgencyVendorItem>[]>(
    () => [
      {
        accessorKey: 'name',
        cell: (info) => info.getValue(),
        header: () => <span>Name</span>,
        size: 300,
      },
      {
        accessorKey: 'businessName',
        cell: (info) => info.getValue(),
        header: () => <span>Business name</span>,
        size: 300,
      },
      {
        accessorKey: 'vatNumber',
        header: () => <span>CID / VAT</span>,
        cell: ({ row }) => {
          return [
            row.original.businessId,
            row.original.vatNumber,
          ].filter(v => v!== null).join(' / ');
        },
      },
    ],
    [],
  );

  const handleRowClick = (row: VendorItem | AgencyVendorItem) => navigate(row.id);

  if (total === 0) {
    return children;
  }

  return (
    <>
      <Table<VendorItem | AgencyVendorItem> data={vendors} columns={columns}>
        {({ row }) => <TableRow key={row.id} row={row} onClick={handleRowClick} />}
      </Table>
      <TableFooter pagination={{ currentPage: page, postsPerPage: limit, totalPosts: total }} label="advertisers" />
    </>
  );
};
