import { ApolloError } from '@apollo/client';
import { useLazySearch } from '@/app/common';
import { useSearchCampaignsLazyQuery, useSearchCampaignsSuspenseQuery } from './search-campaigns.query.generated';
import { useSearchSingleCampaignSuspenseQuery } from './search-single-campaign.query.generated';

export interface CampaignSearchItem {
  id: string;
  name: string;
};

export type UseSearchCampaignsHook = {
  searchCampaigns: (term: string) => void;
  campaigns: CampaignSearchItem[];
  error: ApolloError | null;
};

export const useSearchCampaigns = (id: string | null): UseSearchCampaignsHook => {
  const { data: campaignsData } = useSearchCampaignsSuspenseQuery({
    variables: {
      search: null,
    },
  });
  const { data: selectedCampaignData } = useSearchSingleCampaignSuspenseQuery({
    variables: {
      id: id ?? '0',
    },
    errorPolicy: 'ignore',
  });

  const initialCampaigns = campaignsData.campaigns.items.map(campaign => ({
    id: campaign.id,
    name: campaign.name,
  }));

  const selectedCampaign = selectedCampaignData && {
    id: selectedCampaignData.campaign.id,
    name: selectedCampaignData.campaign.name,
  } || null;

  const [searchCampaigns] = useSearchCampaignsLazyQuery();
  const { search, results, error } = useLazySearch<CampaignSearchItem>({
    initialValues: [
      ...selectedCampaign && [selectedCampaign] || [],
      ...initialCampaigns.filter(c => c.id !== selectedCampaign.id),
    ].slice(0, 10),
    onSearch: async (term: string) => {
      const result = await searchCampaigns({
        variables: {
          search: term,
        },
      });

      if (result.error) {
        throw result.error;
      }

      if (!result.data) {
        throw new Error('Empty agencies search response returned while data was expected');
      }

      return result.data.campaigns.items.map(campaign => ({
        id: campaign.id,
        name: campaign.name,
      }));
    },
  });

  return {
    searchCampaigns: search,
    campaigns: results,
    error: error,
  };
};
