import { ApiCompanyUserMarketItemFragment } from './get-markets.query.generated';

export interface MarketItem {
  id: string;
  name: string;
};

export const mapMarket = (
  market: ApiCompanyUserMarketItemFragment,
): MarketItem => ({
  id: market.id,
  name: market.name,
});
