import React from 'react';
import { isUserRetailerType, useAuth } from '@/app/common';
import { MenuItem, SubmenuItem } from '@/app/components';

export interface NavigationProps {
  isNavCollapsed: boolean;
  adminModeEnabled: boolean;
};

export interface RoleBasedNavigationProps {
  isNavCollapsed: boolean;
};

export const Navigation: React.FC<NavigationProps> = ({
  isNavCollapsed,
  adminModeEnabled,
}) => {
  const { state: { currentUser }} = useAuth();
  if (!currentUser) {
    return null;
  }

  const resolveNavigationComponent = () => {
    if (!isUserRetailerType(currentUser)) return VendorNavigation;
    if (adminModeEnabled) return AdminNavigation;
    return RetailerNavigation;
  };

  const NavComponent = resolveNavigationComponent();
  return (
    <nav aria-label="Hlavní navigace">
      <NavComponent isNavCollapsed={isNavCollapsed} />
    </nav>
  );
};

const VendorNavigation: React.FC<RoleBasedNavigationProps> = (
  props,
) => {
  return (
    <ul className="flex flex-col space-y-2">
      <MenuItem {...props} link="/" label="Dashboards" iconName="dashboard" />
      <MenuItem {...props} link="/advertising-tools" label="Advertising Tools" iconName="adTools" />
      <MenuItem {...props} link="/campaigns" label="Campaigns" iconName="campaigns" />
      <MenuItem {...props} link="/line-items" label="Line Items" iconName="lineItems" />
      {/*<MenuItem {...props} link="/landing-pages" label="Landing Pages" iconName="landingPages" />*/}
      <MenuItem {...props} link="/creative-library" label="Creative Library" iconName="creatives" />
    </ul>
  );
};

const RetailerNavigation: React.FC<RoleBasedNavigationProps> = (
  props,
) => {
  return (
    <ul className="flex flex-col space-y-2">
      <MenuItem {...props} link="/" label="Dashboards" iconName="dashboard" />
      <MenuItem {...props} link="/campaigns" label="Campaigns" iconName="campaigns" />
      <MenuItem {...props} link="/line-items" label="Line Items" iconName="lineItems" />
      <MenuItem {...props} link="/advertising-tools" label="Advertising Tools" iconName="adTools" />
      {/*<MenuItem {...props} link="/landing-pages" label="Landing Pages" iconName="landingPages" />*/}
      <MenuItem {...props} link="/creative-library" label="Creative Library" iconName="creatives" />
      {/*<MenuItem {...props} link="/media-library" label="Media Library" iconName="media" />*/}
      {/*<MenuItem {...props} link="/availability" label="Availability" iconName="availability" />*/}
      {/*<MenuItem {...props} link="/settings" label="Settings" iconName="settings" />*/}
    </ul>
  );
};

const AdminNavigation: React.FC<RoleBasedNavigationProps> = (
  props,
) => {
  return (
    <ul className="flex flex-col space-y-2">
      <MenuItem {...props} link="/admin/my-organization" label="My Organization" iconName="organizations">
        <SubmenuItem link="/admin/my-organization" label="Organization info" />
        <SubmenuItem link="/admin/my-organization/billing-info" label="Billing info" />
        <SubmenuItem link="/admin/my-organization/settings" label="Settings" />
      </MenuItem>
      <MenuItem {...props} link="/admin/markets" label="Markets" iconName="markets" />
      <MenuItem {...props} link="/admin/advertising-tools" label="Advertising Tools" iconName="adTools">
        <SubmenuItem link="/admin/advertising-tools" label="Advertising tools" />
        <SubmenuItem link="/admin/advertising-tools/categories" label="Categories" />
        {/*<SubmenuItem link="/admin/advertising-tools/landing-page-types" label="Landing Page Types" />*/}
      </MenuItem>
      <MenuItem {...props} link="/admin/creative-formats" label="Creative Formats" iconName="creatives" />
      <MenuItem {...props} link="/admin/targets" label="Targeting" iconName="targeting" />
      <MenuItem {...props} link="/admin/brands" label="Brands" iconName="brands" />
      <MenuItem {...props} link="/admin/companies" label="Companies" iconName="companies">
        <SubmenuItem link="/admin/companies/advertisers" label="Advertisers" />
        <SubmenuItem link="/admin/companies/agencies" label="Agencies" />
      </MenuItem>
      <MenuItem {...props} link="/admin/users" label="Users" iconName="users" />
    </ul>
  );
};
