import { useResetPasswordMutation } from './reset-password.mutations.generated';

type UseResetPasswordHook = {
  resetPassword: (token: string, password: string) => Promise<void>;
  loading: boolean;
};

export const useResetPassword = (): UseResetPasswordHook => {
  const [requestPasswordResetMutation, { loading }] = useResetPasswordMutation();

  const resetPassword = async (
    token: string,
    password: string,
  ) => {
    const doSubmit = async () => {
      const { data, errors } = await requestPasswordResetMutation({
        variables: {
          token,
          password,
        },
      });

      // Error
      if (errors !== undefined && errors.length > 0) {
        const errorMessage = errors[0].message;
        throw new Error(errorMessage);
      }

      // No error, no data
      if (!data) {
        throw new Error('Server returned empty unexpected response.');
      }

      return data;
    };

    await doSubmit();
  };

  return {
    resetPassword,
    loading,
  };
};
