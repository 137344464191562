import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  DefinitionList,
  PencilIcon,
  PlusIcon,
  RoundedFrame,
  SectionHeading,
  StatisticsList,
  SwitchButton,
  TabSwitch,
} from '@/app/components';
import { useCampaignDetail } from './api';
import { ActivitiesViewMode, ActivityList, CampaignStatus } from './components';
import { ActivityItem } from './interfaces';

export const CampaignsDetail: React.FC = () => {
  const { id } = useParams();
  if (id === undefined) {
    throw new Error('Unable to load campaign detail. ID parameter is missing.');
  }

  const navigate = useNavigate();
  const { campaign } = useCampaignDetail(id);
  const [showCreatives, setShowCreatives] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState<ActivitiesViewMode>('list');

  const handleViewModeSwitch = (viewModeIndex: number) => {
    setViewMode(viewModeIndex === 0 ? 'list' : 'performance');
  };

  const handleToggleCreatives = () => {
    setShowCreatives(expanded => !expanded);
  };

  const handleRowClick = (row: ActivityItem) => {
    const rowLink = `line-item/${row.id}`;
    navigate(rowLink);
  };

  return (
    <div className="mb-36">
      <div className="flex justify-between mb-4">
        <h1 className="text-3xl">{campaign.name}</h1>
        <div>
          <Button
            label="Edit campaign"
            icon={PencilIcon}
            variant="primary"
            link="edit"
          />
        </div>
      </div>

      <div className="mb-5">
        <CampaignStatus status={campaign.status} />
      </div>

      <RoundedFrame margin="mb-5">
        <DefinitionList data={[
          { term: 'Advertiser', description: campaign.vendor },
          { term: 'Advertiser contact', description: campaign.primaryContact },
          ...(campaign.agency ? [{ term: 'Agency', description: campaign.agency }] : []),
          ...(campaign.secondaryContact ? [{ term: 'Agency contact', description: campaign.secondaryContact }] : []),
          { term: 'Sales person', description: campaign.salesPerson },
        ]} columns={3} />
      </RoundedFrame>

      <section className="mb-8">
        <header className="mb-4">
          <SectionHeading heading="Statistics" />
        </header>

        <RoundedFrame>
          <StatisticsList {...campaign.metrics} />
        </RoundedFrame>
      </section>

      <section className="mb-8">
        <header className="flex items-center justify-between mb-4">
          <SectionHeading heading="Line items" />

          <div className="flex items-center gap-x-8">
            {campaign.activities.length > 0 && (
              <>
                <SwitchButton
                  onChange={handleToggleCreatives}
                  value={showCreatives}
                  label="Creatives"
                  labelPosition="left"
                  name="expand-creatives"
                />

                <div className="shrink-0">
                  <TabSwitch
                    items={['List', 'Performance']}
                    onSwitch={handleViewModeSwitch}
                    selected={viewMode === 'performance' ? 1 : 0}
                    label="View type"
                    compact
                  />
                </div>
              </>
            )}

            <div className="ml-8">
              <Button
                label="Add Line Item"
                variant="primary"
                link="line-item"
                icon={PlusIcon}
              />
            </div>
          </div>
        </header>

        <ActivityList
          viewMode={viewMode}
          activities={campaign.activities}
          onRowClick={handleRowClick}
          showCreatives={showCreatives}
        />
      </section>
    </div>
  );
};
