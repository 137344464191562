/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiCreateAgencyUserMutationVariables = Types.Exact<{
  agencyId: Types.Scalars['ID']['input'];
  username: Types.Scalars['String']['input'];
  name: Types.Scalars['String']['input'];
  locale: Types.InputMaybe<Types.ApiLocale>;
  timezone: Types.InputMaybe<Types.ApiTimezone>;
  markets: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type ApiCreateAgencyUserMutation = { __typename?: 'Mutation', createAgencyUser: { __typename?: 'User', id: string } };


export const CreateAgencyUserDocument = gql`
    mutation CreateAgencyUser($agencyId: ID!, $username: String!, $name: String!, $locale: Locale, $timezone: Timezone, $markets: [String!]!) {
  createAgencyUser(
    agencyId: $agencyId
    username: $username
    name: $name
    locale: $locale
    timezone: $timezone
    markets: $markets
  ) {
    id
  }
}
    `;
export type ApiCreateAgencyUserMutationFn = Apollo.MutationFunction<ApiCreateAgencyUserMutation, ApiCreateAgencyUserMutationVariables>;

/**
 * __useCreateAgencyUserMutation__
 *
 * To run a mutation, you first call `useCreateAgencyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAgencyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAgencyUserMutation, { data, loading, error }] = useCreateAgencyUserMutation({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *      username: // value for 'username'
 *      name: // value for 'name'
 *      locale: // value for 'locale'
 *      timezone: // value for 'timezone'
 *      markets: // value for 'markets'
 *   },
 * });
 */
export function useCreateAgencyUserMutation(baseOptions?: Apollo.MutationHookOptions<ApiCreateAgencyUserMutation, ApiCreateAgencyUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiCreateAgencyUserMutation, ApiCreateAgencyUserMutationVariables>(CreateAgencyUserDocument, options);
      }
export type CreateAgencyUserMutationHookResult = ReturnType<typeof useCreateAgencyUserMutation>;
export type CreateAgencyUserMutationResult = Apollo.MutationResult<ApiCreateAgencyUserMutation>;
export type CreateAgencyUserMutationOptions = Apollo.BaseMutationOptions<ApiCreateAgencyUserMutation, ApiCreateAgencyUserMutationVariables>;