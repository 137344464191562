import React from 'react';
import { useParams } from 'react-router-dom';
import { Notification } from '@/app/components';
import { PasswordRequestForm, PasswordResetForm, PasswordTokenChecker } from './components';

export interface ResetPasswordFormInputs {
  password: string;
  passwordRepeat: string;
};

export const ResetPasswordPage: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  if (!token) {
    throw new Error('Unknown token. Reset password page cannot be accessed without token param');
  }

  return(
    <PasswordTokenChecker token={token}>
      {(valid) => valid ? (
        <PasswordResetForm token={token} />
      ) : (
        <PasswordRequestForm>
          <Notification
            color="red"
            label="Your token had expired or is invalid. Please request a new one."
            type="error"
          />
        </PasswordRequestForm>
      )}
    </PasswordTokenChecker>
  );
};
