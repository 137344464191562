export const AustriaFlagIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clip-path="url(#clip0_3992_42226)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M37.3333 32H-5.33333V0H37.3333V32Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M37.3333 31.9999H-5.33333V21.3333H37.3333V31.9999ZM37.3333 10.6733H-5.33333V0.0065918H37.3333V10.6733Z" fill="#C8102E"/>
    </g>
    <defs>
      <clipPath id="clip0_3992_42226">
        <rect width="42.6667" height="32" fill="white" transform="translate(-5.33333)"/>
      </clipPath>
    </defs>
  </svg>
);

export const HungaryFlagIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clip-path="url(#clip0_3992_42239)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M37.3333 32H-5.33333V0H37.3333V32Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M37.3333 31.9999H-5.33333V21.3333H37.3333V31.9999Z" fill="#388D00"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M37.3333 10.6733H-5.33333V0.0065918H37.3333V10.6733Z" fill="#D43516"/>
    </g>
    <defs>
      <clipPath id="clip0_3992_42239">
        <rect width="42.6667" height="32" fill="white" transform="translate(-5.33333)"/>
      </clipPath>
    </defs>
  </svg>
);

export const CzechFlagIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clip-path="url(#clip0_3992_42253)">
      <path d="M-5.33333 0H37.3333V16H-5.33333V0Z" fill="white"/>
      <path d="M-5.33333 16H37.3333V32H-5.33333V16Z" fill="#D7141A"/>
      <path d="M18.6667 16L-5.33333 0V32L18.6667 16Z" fill="#11457E"/>
    </g>
    <defs>
      <clipPath id="clip0_3992_42253">
        <rect width="42.6667" height="32" fill="white" transform="translate(-5.33333)"/>
      </clipPath>
    </defs>
  </svg>
);

export const PolandFlagIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clip-path="url(#clip0_3992_42266)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M37.3333 32H-5.33333V0H37.3333V32Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M37.3333 32H-5.33333V16H37.3333V32Z" fill="#DC143C"/>
    </g>
    <defs>
      <clipPath id="clip0_3992_42266">
        <rect width="42.6667" height="32" fill="white" transform="translate(-5.33333)"/>
      </clipPath>
    </defs>
  </svg>
);

export const SlovakiaFlagIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clip-path="url(#clip0_3992_42279)">
      <path d="M-5.33333 0H37.3333V32H-5.33333V0Z" fill="#EE1C25"/>
      <path d="M-5.33333 0H37.3333V21.3333H-5.33333V0Z" fill="#0B4EA2"/>
      <path d="M-5.33333 0H37.3333V10.6667H-5.33333V0Z" fill="white"/>
      <path d="M10.2 24.72C7.33333 23.34 3.22667 20.5934 3.22667 15.1734C3.22667 9.7467 3.49333 7.28003 3.49333 7.28003H16.9133C16.9133 7.28003 17.1733 9.7467 17.1733 15.1734C17.1733 20.6 13.0667 23.3334 10.2 24.72Z" fill="white"/>
      <path d="M10.2 24C7.56667 22.7333 3.8 20.2133 3.8 15.24C3.8 10.2667 4.04 8 4.04 8H16.36C16.36 8 16.5933 10.2667 16.5933 15.24C16.6 20.22 12.8333 22.7333 10.2 24Z" fill="#EE1C25"/>
      <path d="M10.76 13.9334C11.4733 13.9467 12.8667 13.9734 14.1 13.5601C14.1 13.5601 14.0733 14.0067 14.0733 14.5201C14.0733 15.0334 14.1067 15.4801 14.1067 15.4801C12.9733 15.1001 11.5667 15.0934 10.76 15.1001V17.8467H9.64V15.1001C8.84 15.0934 7.43333 15.1001 6.3 15.4801C6.3 15.4801 6.33333 15.0334 6.33333 14.5201C6.33333 14.0001 6.3 13.5601 6.3 13.5601C7.53333 13.9734 8.92667 13.9467 9.63333 13.9334V12.2067C8.98667 12.2067 8.05333 12.2334 6.99333 12.5867C6.99333 12.5867 7.02667 12.1467 7.02667 11.6267C7.02667 11.1134 6.99333 10.6667 6.99333 10.6667C8.05333 11.0201 8.98667 11.0534 9.63333 11.0467C9.6 9.95341 9.28 8.58008 9.28 8.58008C9.28 8.58008 9.94 8.62674 10.2 8.62674C10.4667 8.62674 11.12 8.58008 11.12 8.58008C11.12 8.58008 10.8 9.95341 10.7667 11.0467C11.4133 11.0534 12.3467 11.0201 13.4067 10.6667C13.4067 10.6667 13.3733 11.1134 13.3733 11.6267C13.3733 12.1467 13.4067 12.5867 13.4067 12.5867C12.5523 12.3113 11.6574 12.1828 10.76 12.2067V13.9401V13.9334Z" fill="white"/>
      <path d="M10.2 17.5532C8.87333 17.5532 8.16667 19.3866 8.16667 19.3866C8.16667 19.3866 7.76667 18.5199 6.68667 18.5199C5.95333 18.5199 5.42 19.1666 5.07333 19.7732C6.40667 21.8866 8.53333 23.1932 10.2 23.9999C11.8667 23.1999 14 21.8866 15.3267 19.7732C14.98 19.1732 14.4467 18.5199 13.7133 18.5199C12.6333 18.5199 12.2333 19.3866 12.2333 19.3866C12.2333 19.3866 11.5333 17.5532 10.2 17.5532Z" fill="#0B4EA2"/>
    </g>
    <defs>
      <clipPath id="clip0_3992_42279">
        <rect width="42.6667" height="32" fill="white" transform="translate(-5.33333)"/>
      </clipPath>
    </defs>
  </svg>
);
