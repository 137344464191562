import { Tab } from '@headlessui/react';
import React from 'react';

export interface TabsProps {
  tabs: string[]
};

export const Tabs: React.FC<TabsProps> = ({ tabs }) => {

  const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(' ');
  };

  return (
    <div className="w-full max-w-md px-2">
      <Tab.Group>
        <Tab.List className="flex space-x-6 border-b">
          {tabs.map((tabTitle, index) => (
            <Tab
              key={`tab-${index}`}
              className={({ selected }) =>
                classNames(
                  'p-2 text-sm leading-6 text-slate-800',
                  'focus:outline-none',
                  selected
                    ? 'font-semibold border-b-2 border-purple-600'
                    : 'hover:text-purple-600 border-b-2 border-transparent',
                )
              }
            >
              {tabTitle}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
          {tabs.map((tabTitle, index) => (
            <Tab.Panel
              key={index}
              className={
                classNames(
                  'rounded-xl bg-white p-3',
                  'focus:outline-none',
                )
              }
            >
              Content: {tabTitle}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
