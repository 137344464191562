import { ApolloError } from '@apollo/client';
import { useLazySearch } from '@/app/common';
import { useSearchAgenciesLazyQuery, useSearchAgenciesSuspenseQuery } from './search-agencies.query.generated';

export interface AgencySearchItem {
  id: string;
  name: string;
};

export type UseSearchAgenciesHook = {
  searchAgencies: (term: string) => void;
  agencies: AgencySearchItem[];
  error: ApolloError | null;
};

export const useSearchAgencies = (vendor: string): UseSearchAgenciesHook => {
  const { data } = useSearchAgenciesSuspenseQuery({
    variables: {
      vendor,
      term: null,
    },
  });
  const initialAgencies = data.agencies.items.map(agency => ({
    id: agency.id,
    name: agency.name,
  }));

  const [searchAgencies] = useSearchAgenciesLazyQuery();
  const { search, results, error } = useLazySearch<AgencySearchItem>({
    initialValues: initialAgencies,
    onSearch: async (term: string) => {
      const result = await searchAgencies({
        variables: {
          vendor,
          term,
        },
      });

      if (result.error) {
        throw result.error;
      }

      if (!result.data) {
        throw new Error('Empty agencies search response returned while data was expected');
      }

      return result.data.agencies.items.map(agency => ({
        id: agency.id,
        name: agency.name,
      }));
    },
  });

  return {
    searchAgencies: search,
    agencies: results,
    error: error,
  };
};
