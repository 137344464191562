/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiSearchVendorContactsQueryVariables = Types.Exact<{
  vendor: Types.Scalars['String']['input'];
  term: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ApiSearchVendorContactsQuery = { __typename?: 'Query', vendor: { __typename?: 'Vendor', users: { __typename?: 'VendorUserResult', total: number, items: Array<{ __typename?: 'VendorUser', id: string, name: string }> } } };


export const SearchVendorContactsDocument = gql`
    query SearchVendorContacts($vendor: String!, $term: String) {
  vendor(id: $vendor) {
    users(search: $term) {
      total
      items {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useSearchVendorContactsQuery__
 *
 * To run a query within a React component, call `useSearchVendorContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchVendorContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchVendorContactsQuery({
 *   variables: {
 *      vendor: // value for 'vendor'
 *      term: // value for 'term'
 *   },
 * });
 */
export function useSearchVendorContactsQuery(baseOptions: Apollo.QueryHookOptions<ApiSearchVendorContactsQuery, ApiSearchVendorContactsQueryVariables> & ({ variables: ApiSearchVendorContactsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiSearchVendorContactsQuery, ApiSearchVendorContactsQueryVariables>(SearchVendorContactsDocument, options);
      }
export function useSearchVendorContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiSearchVendorContactsQuery, ApiSearchVendorContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiSearchVendorContactsQuery, ApiSearchVendorContactsQueryVariables>(SearchVendorContactsDocument, options);
        }
export function useSearchVendorContactsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApiSearchVendorContactsQuery, ApiSearchVendorContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiSearchVendorContactsQuery, ApiSearchVendorContactsQueryVariables>(SearchVendorContactsDocument, options);
        }
export type SearchVendorContactsQueryHookResult = ReturnType<typeof useSearchVendorContactsQuery>;
export type SearchVendorContactsLazyQueryHookResult = ReturnType<typeof useSearchVendorContactsLazyQuery>;
export type SearchVendorContactsSuspenseQueryHookResult = ReturnType<typeof useSearchVendorContactsSuspenseQuery>;
export type SearchVendorContactsQueryResult = Apollo.QueryResult<ApiSearchVendorContactsQuery, ApiSearchVendorContactsQueryVariables>;