/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiCreateCampaignMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
  vendor: Types.Scalars['ID']['input'];
  agency: Types.InputMaybe<Types.Scalars['ID']['input']>;
  primaryContact: Types.Scalars['ID']['input'];
  secondaryContact: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type ApiCreateCampaignMutation = { __typename?: 'Mutation', createCampaign: { __typename?: 'Campaign', id: string, name: string } };


export const CreateCampaignDocument = gql`
    mutation CreateCampaign($name: String!, $vendor: ID!, $agency: ID, $primaryContact: ID!, $secondaryContact: ID) {
  createCampaign(
    name: $name
    vendor: $vendor
    agency: $agency
    primaryContact: $primaryContact
    secondaryContact: $secondaryContact
  ) {
    id
    name
  }
}
    `;
export type ApiCreateCampaignMutationFn = Apollo.MutationFunction<ApiCreateCampaignMutation, ApiCreateCampaignMutationVariables>;

/**
 * __useCreateCampaignMutation__
 *
 * To run a mutation, you first call `useCreateCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampaignMutation, { data, loading, error }] = useCreateCampaignMutation({
 *   variables: {
 *      name: // value for 'name'
 *      vendor: // value for 'vendor'
 *      agency: // value for 'agency'
 *      primaryContact: // value for 'primaryContact'
 *      secondaryContact: // value for 'secondaryContact'
 *   },
 * });
 */
export function useCreateCampaignMutation(baseOptions?: Apollo.MutationHookOptions<ApiCreateCampaignMutation, ApiCreateCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiCreateCampaignMutation, ApiCreateCampaignMutationVariables>(CreateCampaignDocument, options);
      }
export type CreateCampaignMutationHookResult = ReturnType<typeof useCreateCampaignMutation>;
export type CreateCampaignMutationResult = Apollo.MutationResult<ApiCreateCampaignMutation>;
export type CreateCampaignMutationOptions = Apollo.BaseMutationOptions<ApiCreateCampaignMutation, ApiCreateCampaignMutationVariables>;