import React, { createContext, useContext, useState } from 'react';

export interface PopupNoteContextType {
  popupNoteId: string | null;
  openPopupNote: (popupNoteId: string) => void;
  closePopupNote: () => void;
};

export const PopupNoteContext = createContext<PopupNoteContextType | null>(null);

export const PopupNoteProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [popupNoteId, setPopupNoteId] = useState<string | null>(null);

  const openPopupNote = (popupNoteId: string) => {
    setPopupNoteId(popupNoteId);
  };

  const closePopupNote = () => {
    setPopupNoteId(null);
  };

  return (
    <PopupNoteContext.Provider value={{ popupNoteId, openPopupNote, closePopupNote }}>
      {children}
    </PopupNoteContext.Provider>
  );
};

export const usePopupNote = () => {
  const context = useContext(PopupNoteContext);
  if (!context) {
    throw new Error('usePopupNote must be used within a PopupNoteProvider');
  }
  return context;
};
