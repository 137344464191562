import React from 'react';
import { MarketSwitcherItem } from './market.interface';
import { getFlagIcon } from './utils';

export type MarketDropdownProps = {
  markets: MarketSwitcherItem[];
  showFlags: boolean;
  onSelect: (selectedMarket: MarketSwitcherItem) => void;
};

export const MarketDropdown: React.FC<MarketDropdownProps> = ({
  markets,
  showFlags,
  onSelect,
}) => {
  const linkClass = `
    w-full
    text-left
    leading-6
    text-sm
    text-slate-800
    hover:bg-purple-100
    py-2
    px-3
    flex
    items-center
    gap-x-2
  `;

  return (
    <div
      className="
        max-w-64
        min-w-48
        top-[calc(100%+0.25rem)]
        py-2
        absolute
        right-0
        z-20
        rounded-sm
        border
        border-slate-300
        bg-white
      "
    >
      <ul>
        {markets.map((market) => (
          <li key={market.id}>
            <button
              type="button"
              className={linkClass}
              onClick={() => onSelect(market)}
            >
              {showFlags && market.id !== undefined && (
                <span className="w-8 h-8 rounded-full overflow-hidden flex items-center justify-center bg-purple-100">
                  {getFlagIcon(market.id)}
                </span>
              )}
              <span className="tracking-wide text-nowrap pl-1">
                {market.name}
              </span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};
