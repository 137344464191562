// 14
export const Tool14Icon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clipPath="url(#clip0_1623_128793)">
      <rect width="32" height="32" rx="2" fill="white"/>
      <rect y="21" width="9.3333" height="11" rx="1.6" fill="#5C00F1"/>
      <rect x="11.332" y="21" width="9.33" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect x="22.6641" y="21" width="9.33" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect width="32" height="19" rx="1.6" fill="#DBCCFC"/>
    </g>
    <defs>
      <clipPath id="clip0_1623_128793">
        <rect width="32" height="32" rx="2" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);
// 13
export const Tool13Icon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clipPath="url(#clip0_1623_128813)">
      <rect y="21" width="9.3333" height="11" rx="1.6" fill="#25E682"/>
      <rect x="11.332" y="21" width="9.33" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect x="22.6641" y="21" width="9.33" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect width="32" height="19" rx="1.6" fill="#DBCCFC"/>
    </g>
    <defs>
      <clipPath id="clip0_1623_128813">
        <rect width="32" height="32" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);
// 1
export const Tool1Icon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clipPath="url(#clip0_1623_128938)">
      <rect y="21" width="32" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect width="32" height="19" rx="1.6" fill="#25E682"/>
    </g>
    <defs>
      <clipPath id="clip0_1623_128938">
        <rect width="32" height="32" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);
// 8
export const Tool8Icon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <mask id="mask0_3938_34147" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
      <path d="M32 0H0V32H32V0Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_3938_34147)">
      <path d="M7.7333 21H1.6C0.716344 21 0 21.7163 0 22.6V30.4C0 31.2837 0.716344 32 1.6 32H7.7333C8.61696 32 9.3333 31.2837 9.3333 30.4V22.6C9.3333 21.7163 8.61696 21 7.7333 21Z" fill="#DBCCFC"/>
      <path d="M19.0633 21H12.9333C12.0497 21 11.3333 21.7163 11.3333 22.6V30.4C11.3333 31.2837 12.0497 32 12.9333 32H19.0633C19.947 32 20.6633 31.2837 20.6633 30.4V22.6C20.6633 21.7163 19.947 21 19.0633 21Z" fill="#DBCCFC"/>
      <path d="M30.3933 21H24.2633C23.3796 21 22.6633 21.7163 22.6633 22.6V30.4C22.6633 31.2837 23.3796 32 24.2633 32H30.3933C31.277 32 31.9933 31.2837 31.9933 30.4V22.6C31.9933 21.7163 31.277 21 30.3933 21Z" fill="#DBCCFC"/>
      <path d="M7.7333 8H1.6C0.716344 8 0 8.71634 0 9.6V17.4C0 18.2837 0.716344 19 1.6 19H7.7333C8.61696 19 9.3333 18.2837 9.3333 17.4V9.6C9.3333 8.71634 8.61696 8 7.7333 8Z" fill="#5C00F1"/>
      <path d="M19.0633 8H12.9333C12.0497 8 11.3333 8.71634 11.3333 9.6V17.4C11.3333 18.2837 12.0497 19 12.9333 19H19.0633C19.947 19 20.6633 18.2837 20.6633 17.4V9.6C20.6633 8.71634 19.947 8 19.0633 8Z" fill="#DBCCFC"/>
      <path d="M30.3933 8H24.2633C23.3796 8 22.6633 8.71634 22.6633 9.6V17.4C22.6633 18.2837 23.3796 19 24.2633 19H30.3933C31.277 19 31.9933 18.2837 31.9933 17.4V9.6C31.9933 8.71634 31.277 8 30.3933 8Z" fill="#DBCCFC"/>
      <path d="M30.4 0H1.6C0.716344 0 0 0.716344 0 1.6V4.4C0 5.28366 0.716344 6 1.6 6H30.4C31.2837 6 32 5.28366 32 4.4V1.6C32 0.716344 31.2837 0 30.4 0Z" fill="#DBCCFC"/>
    </g>
  </svg>
);
// 7
export const Tool7Icon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clipPath="url(#clip0_1672_13048)">
      <rect width="32" height="32" rx="2" fill="white"/>
      <rect y="21" width="9.3333" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect x="11.332" y="21" width="9.33" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect x="22.6641" y="21" width="9.33" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect y="8" width="9.3333" height="11" rx="1.6" fill="#25E682"/>
      <rect x="11.332" y="8" width="9.33" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect x="22.6641" y="8" width="9.33" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect width="32" height="6" rx="1.6" fill="#DBCCFC"/>
    </g>
    <defs>
      <clipPath id="clip0_1672_13048">
        <rect width="32" height="32" rx="2" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);
// 4
export const Tool4Icon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clipPath="url(#clip0_1672_13056)">
      <rect width="32" height="32" rx="2" fill="white"/>
      <rect y="21" width="32" height="11" rx="1.6" fill="#25E682"/>
      <rect width="32" height="19" rx="1.6" fill="#DBCCFC"/>
    </g>
    <defs>
      <clipPath id="clip0_1672_13056">
        <rect width="32" height="32" rx="2" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);
// 10
export const Tool10Icon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clipPath="url(#clip0_1672_13059)">
      <rect width="32" height="32" rx="2" fill="white"/>
      <rect y="21" width="9.3333" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect x="11.332" y="21" width="9.33" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect x="22.6641" y="21" width="9.33" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect y="8" width="9.3333" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect x="11.332" y="8" width="9.33" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect x="22.6641" y="8" width="9.33" height="11" rx="1.6" fill="#5C00F1"/>
      <rect width="32" height="6" rx="1.6" fill="#DBCCFC"/>
    </g>
    <defs>
      <clipPath id="clip0_1672_13059">
        <rect width="32" height="32" rx="2" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);
// 9
export const Tool9Icon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clipPath="url(#clip0_1672_13067)">
      <rect width="32" height="32" rx="2" fill="white"/>
      <rect y="21" width="9.3333" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect x="11.332" y="21" width="9.33" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect x="22.6641" y="21" width="9.33" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect y="8" width="9.3333" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect x="11.332" y="8" width="9.33" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect x="22.6641" y="8" width="9.33" height="11" rx="1.6" fill="#25E682"/>
      <rect width="32" height="6" rx="1.6" fill="#DBCCFC"/>
    </g>
    <defs>
      <clipPath id="clip0_1672_13067">
        <rect width="32" height="32" rx="2" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);
// 3
export const Tool3Icon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clipPath="url(#clip0_1672_13075)">
      <rect width="32" height="32" rx="2" fill="white"/>
      <rect y="21" width="9.3333" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect x="11.332" y="21" width="9.33" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect x="22.6641" y="21" width="9.33" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect y="8" width="9.3333" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect x="11.332" y="8" width="9.33" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect x="22.6641" y="8" width="9.33" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect width="9.3333" height="6" rx="1.6" fill="#DBCCFC"/>
      <rect x="11.332" width="9.33" height="6" rx="1.6" fill="#DBCCFC"/>
      <rect x="22.6641" width="9.33" height="6" rx="1.6" fill="#25E682"/>
    </g>
    <defs>
      <clipPath id="clip0_1672_13075">
        <rect width="32" height="32" rx="2" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);
// 12
export const Tool12Icon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clipPath="url(#clip0_1672_13085)">
      <rect width="32" height="32" rx="2" fill="white"/>
      <rect y="21" width="9.3333" height="11" rx="1.6" fill="#5C00F1"/>
      <rect x="11.332" y="21" width="9.33" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect x="22.6641" y="21" width="9.33" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect y="8" width="9.3333" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect x="11.332" y="8" width="9.33" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect x="22.6641" y="8" width="9.33" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect width="32" height="6" rx="1.6" fill="#DBCCFC"/>
    </g>
    <defs>
      <clipPath id="clip0_1672_13085">
        <rect width="32" height="32" rx="2" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);
// 11
export const Tool11Icon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clipPath="url(#clip0_1672_13093)">
      <rect width="32" height="32" rx="2" fill="white"/>
      <rect y="21" width="9.3333" height="11" rx="1.6" fill="#25E682"/>
      <rect x="11.332" y="21" width="9.33" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect x="22.6641" y="21" width="9.33" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect y="8" width="9.3333" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect x="11.332" y="8" width="9.33" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect x="22.6641" y="8" width="9.33" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect width="32" height="6" rx="1.6" fill="#DBCCFC"/>
    </g>
    <defs>
      <clipPath id="clip0_1672_13093">
        <rect width="32" height="32" rx="2" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);
// 2
export const Tool2Icon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clipPath="url(#clip0_1672_13101)">
      <rect width="32" height="32" rx="2" fill="white"/>
      <rect y="21" width="9.3333" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect x="11.332" y="21" width="9.33" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect x="22.6641" y="21" width="9.33" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect y="8" width="9.3333" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect x="11.332" y="8" width="9.33" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect x="22.6641" y="8" width="9.33" height="11" rx="1.6" fill="#DBCCFC"/>
      <rect width="32" height="6" rx="1.6" fill="#25E682"/>
    </g>
    <defs>
      <clipPath id="clip0_1672_13101">
        <rect width="32" height="32" rx="2" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);
// 16
export const Tool16Icon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clipPath="url(#clip0_1672_13110)">
      <rect width="32" height="32" rx="2" fill="white"/>
      <rect width="20.67" height="25" rx="1.6" fill="#DBCCFC"/>
      <rect x="22.6719" y="14" width="9.3333" height="11" rx="1.6" fill="#5C00F1"/>
      <rect x="22.6719" width="9.33" height="5" rx="1.6" fill="#DBCCFC"/>
      <rect x="22.6719" y="7" width="9.33" height="5" rx="1.6" fill="#DBCCFC"/>
      <rect y="27" width="32" height="5" rx="1.6" fill="#DBCCFC"/>
    </g>
    <defs>
      <clipPath id="clip0_1672_13110">
        <rect width="32" height="32" rx="2" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);
// 15
export const Tool15Icon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clipPath="url(#clip0_1672_13116)">
      <rect width="32" height="32" rx="2" fill="white"/>
      <rect width="20.67" height="25" rx="1.6" fill="#DBCCFC"/>
      <rect x="22.6719" y="14" width="9.3333" height="11" rx="1.6" fill="#25E682"/>
      <rect x="22.6719" width="9.33" height="5" rx="1.6" fill="#DBCCFC"/>
      <rect x="22.6719" y="7" width="9.33" height="5" rx="1.6" fill="#DBCCFC"/>
      <rect y="27" width="32" height="5" rx="1.6" fill="#DBCCFC"/>
    </g>
    <defs>
      <clipPath id="clip0_1672_13116">
        <rect width="32" height="32" rx="2" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);
// 17
export const Tool17Icon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <mask id="mask0_3938_34245" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
      <path d="M32 0H0V32H32V0Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_3938_34245)">
      <path d="M30.4 18H1.6C0.716344 18 0 18.7163 0 19.6V22.4C0 23.2837 0.716344 24 1.6 24H30.4C31.2837 24 32 23.2837 32 22.4V19.6C32 18.7163 31.2837 18 30.4 18Z" fill="#DBCCFC"/>
      <path d="M30.4 26H1.6C0.716344 26 0 26.7163 0 27.6V30.4C0 31.2837 0.716344 32 1.6 32H30.4C31.2837 32 32 31.2837 32 30.4V27.6C32 26.7163 31.2837 26 30.4 26Z" fill="#DBCCFC"/>
      <path d="M30.4 0H1.6C0.716344 0 0 0.716344 0 1.6V14.4C0 15.2837 0.716344 16 1.6 16H30.4C31.2837 16 32 15.2837 32 14.4V1.6C32 0.716344 31.2837 0 30.4 0Z" fill="#DBCCFC"/>
      <path d="M22.5097 13.255C22.5097 18.3664 18.3662 22.51 13.2549 22.51C8.14354 22.51 4 18.3664 4 13.255C4 8.14361 8.14354 4 13.2549 4C18.3662 4 22.5097 8.14361 22.5097 13.255Z" fill="white" fill-opacity="0.5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2549 20.71C17.372 20.71 20.7097 17.3723 20.7097 13.255C20.7097 9.13769 17.372 5.8 13.2549 5.8C9.13768 5.8 5.8 9.13769 5.8 13.255C5.8 17.3723 9.13768 20.71 13.2549 20.71ZM13.2549 22.51C18.3662 22.51 22.5097 18.3664 22.5097 13.255C22.5097 8.14361 18.3662 4 13.2549 4C8.14354 4 4 8.14361 4 13.255C4 18.3664 8.14354 22.51 13.2549 22.51Z" fill="#5C00F1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1737 19.0661C19.5251 18.7146 20.095 18.7146 20.4464 19.0661L21.4464 20.0661C21.7979 20.4176 21.7979 20.9874 21.4464 21.3389C21.095 21.6904 20.5251 21.6904 20.1737 21.3389L19.1737 20.3389C18.8222 19.9874 18.8222 19.4176 19.1737 19.0661Z" fill="#5C00F1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M20.6362 20.5318C21.4563 19.7117 22.7859 19.7117 23.606 20.5318L28.4848 25.4107C29.3049 26.2308 29.3049 27.5604 28.4848 28.3805C27.6647 29.2006 26.335 29.2006 25.515 28.3805L20.6362 23.5017C19.8161 22.6816 19.8161 21.3519 20.6362 20.5318Z" fill="#5C00F1"/>
    </g>
  </svg>

);
// 6
export const Tool6Icon = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1672_13130)">
      <rect width="32" height="32" rx="2" transform="matrix(-1 0 0 1 32 0)" fill="white"/>
      <rect width="32" height="32" rx="1.6" fill="#DBCCFC"/>
      <path d="M28 7.2832H26.191C25.9207 7.32479 25.3302 7.62005 25.1306 8.46839C24.931 9.31673 23.5088 16.3072 22.8226 19.6965C22.6771 20.1331 22.1365 21.0064 21.1384 21.0064C20.1404 21.0064 12.7797 21.0064 9.22417 21.0064" stroke="#5C00F1" strokeWidth="1.8" strokeLinecap="round"/>
      <path d="M6.66534 8.5918L8.59906 16.2643C8.72382 16.597 9.22284 17.2624 10.2209 17.2624C11.2189 17.2624 19.3697 17.2624 23.3203 17.2624" stroke="#5C00F1" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
      <ellipse cx="1.62183" cy="1.62183" rx="1.62183" ry="1.62183" transform="matrix(-1 0 0 1 22.5742 23.3115)" stroke="#5C00F1" strokeWidth="1.8"/>
      <ellipse cx="1.62183" cy="1.62183" rx="1.62183" ry="1.62183" transform="matrix(-1 0 0 1 12.5938 23.3115)" stroke="#5C00F1" strokeWidth="1.8"/>
    </g>
    <defs>
      <clipPath id="clip0_1672_13130">
        <rect width="32" height="32" rx="2" transform="matrix(-1 0 0 1 32 0)" fill="white"/>
      </clipPath>
    </defs>
  </svg>

);
// 5
export const Tool5Icon = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1672_13137)">
      <rect width="32" height="32" rx="2" transform="matrix(-1 0 0 1 32 0)" fill="white"/>
      <rect width="32" height="32" rx="1.6" fill="#DBCCFC"/>
      <path d="M28 7.2832H26.191C25.9207 7.32479 25.3302 7.62005 25.1306 8.46839C24.931 9.31673 23.5088 16.3072 22.8226 19.6965C22.6771 20.1331 22.1365 21.0064 21.1384 21.0064C20.1404 21.0064 12.7797 21.0064 9.22417 21.0064" stroke="#5C00F1" strokeWidth="1.8" strokeLinecap="round"/>
      <path d="M6.66534 8.5918L8.59906 16.2643C8.72382 16.597 9.22284 17.2624 10.2209 17.2624C11.2189 17.2624 19.3697 17.2624 23.3203 17.2624" stroke="#5C00F1" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
      <ellipse cx="1.62183" cy="1.62183" rx="1.62183" ry="1.62183" transform="matrix(-1 0 0 1 22.5742 23.3115)" stroke="#5C00F1" strokeWidth="1.8"/>
      <ellipse cx="1.62183" cy="1.62183" rx="1.62183" ry="1.62183" transform="matrix(-1 0 0 1 12.5938 23.3115)" stroke="#5C00F1" strokeWidth="1.8"/>
      <path d="M13 10H19" stroke="#5C00F1" strokeWidth="1.8" strokeLinecap="round"/>
      <path d="M16 7L16 13" stroke="#5C00F1" strokeWidth="1.8" strokeLinecap="round"/>
    </g>
    <defs>
      <clipPath id="clip0_1672_13137">
        <rect width="32" height="32" rx="2" transform="matrix(-1 0 0 1 32 0)" fill="white"/>
      </clipPath>
    </defs>
  </svg>

);
