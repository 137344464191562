import React, { ChangeEvent, FocusEvent, TextareaHTMLAttributes, forwardRef } from 'react';
import { FieldError } from './interfaces';

export interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: FieldError;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void;
};

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(({
  label,
  error,
  id,
  value,
  required = false,
  className = '',
  ...rest
}, ref) => {
  const labelClass = `
    mb-1
    block
    text-slate-800
    text-sm
    font-normal
    leading-6
  `;

  const inputColorStateClass = error !== undefined
    ? 'border-red-700 text-red-700'
    : 'border-slate-300 text-slate-800';

  const textareaClass = `
    block
    w-full
    px-3
    py-2
    text-sm
    leading-6
    border
    rounded-sm
    focus:border
    focus:border-purple-600
    focus-visible:outline-none
    ${inputColorStateClass}
    ${className}
  `;

  return (
    <div>
      {label !== undefined && (
        <label htmlFor={id} className={labelClass} >
          {label}
          {!required && (
            <span className="text-slate-400 ml-1">
              (optional)
            </span>
          )}
        </label>
      )}

      <div className="relative rounded-lg">
        <textarea
          {...rest}
          id={id}
          className={textareaClass}
          defaultValue={value}
          ref={ref}
        />
      </div>

      {error?.message !== undefined && (
        <p className="mt-1 text-xs text-red-700 leading-4">{error.message}</p>
      )}
    </div>
  );
});
