import { ApolloError } from '@apollo/client';
import { useEffect, useState } from 'react';

export type UseSearchProps<T> = {
  onSearch: (term: string) => Promise<T[]>;
  initialValues?: T[];
};

export type UseSearchHook<T> = {
  search: (term: string) => void;
  results: T[];
  error: ApolloError | null;
};

export const useSearch = <T>({
  onSearch,
  initialValues = [],
}: UseSearchProps<T>): UseSearchHook<T> => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [results, setResults] = useState<T[]>(initialValues);
  const [error, setSearchError] = useState<ApolloError | null>(null);
  const [cache, populateCache] = useState<Record<string, T[]>>({});

  const search = async (term: string): Promise<void> => {
    setSearchTerm(term);
  };

  const doSearch = async (term: string) => {
    try {
      const results = await onSearch(term);
      setResults(results);
      populateCache(cache => ({
        ...cache,
        [term]: results,
      }));
    } catch (err) {
      if (!(err instanceof ApolloError)) {
        throw err;
      }

      setSearchError(err);
      setResults([]);
    }
  };

  useEffect(() => {
    // if we have data cached, use them directly
    if (cache[searchTerm] !== undefined) {
      setResults(cache[searchTerm]);
      return;
    }

    // if term is empty, return initial data
    if (searchTerm.length === 0) {
      setResults(initialValues);
      return;
    }

    doSearch(searchTerm);
  }, [searchTerm]);

  return {
    search,
    results,
    error,
  };
};
