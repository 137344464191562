import React from 'react';

interface WhiteCardProps {
  children: React.ReactNode;
  padding?: string;
};

export const WhiteCard: React.FC<WhiteCardProps> = ({ children, padding }) =>  (
  <div className={`card relative ${padding !== undefined ? padding : ''}`}>{children}</div>
);
