/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiSearchAgencyContactsQueryVariables = Types.Exact<{
  agency: Types.Scalars['String']['input'];
  term: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ApiSearchAgencyContactsQuery = { __typename?: 'Query', agency: { __typename?: 'Agency', users: { __typename?: 'AgencyUserResult', total: number, items: Array<{ __typename?: 'AgencyUser', id: string, name: string }> } } };


export const SearchAgencyContactsDocument = gql`
    query SearchAgencyContacts($agency: String!, $term: String) {
  agency(id: $agency) {
    users(search: $term) {
      total
      items {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useSearchAgencyContactsQuery__
 *
 * To run a query within a React component, call `useSearchAgencyContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAgencyContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAgencyContactsQuery({
 *   variables: {
 *      agency: // value for 'agency'
 *      term: // value for 'term'
 *   },
 * });
 */
export function useSearchAgencyContactsQuery(baseOptions: Apollo.QueryHookOptions<ApiSearchAgencyContactsQuery, ApiSearchAgencyContactsQueryVariables> & ({ variables: ApiSearchAgencyContactsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiSearchAgencyContactsQuery, ApiSearchAgencyContactsQueryVariables>(SearchAgencyContactsDocument, options);
      }
export function useSearchAgencyContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiSearchAgencyContactsQuery, ApiSearchAgencyContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiSearchAgencyContactsQuery, ApiSearchAgencyContactsQueryVariables>(SearchAgencyContactsDocument, options);
        }
export function useSearchAgencyContactsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApiSearchAgencyContactsQuery, ApiSearchAgencyContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiSearchAgencyContactsQuery, ApiSearchAgencyContactsQueryVariables>(SearchAgencyContactsDocument, options);
        }
export type SearchAgencyContactsQueryHookResult = ReturnType<typeof useSearchAgencyContactsQuery>;
export type SearchAgencyContactsLazyQueryHookResult = ReturnType<typeof useSearchAgencyContactsLazyQuery>;
export type SearchAgencyContactsSuspenseQueryHookResult = ReturnType<typeof useSearchAgencyContactsSuspenseQuery>;
export type SearchAgencyContactsQueryResult = Apollo.QueryResult<ApiSearchAgencyContactsQuery, ApiSearchAgencyContactsQueryVariables>;