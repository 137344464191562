import { ApiVendorItemFragment } from './get-vendors.query.generated';
import { VendorItem } from '../../interfaces';

export const mapVendorItem = (
  agency: ApiVendorItemFragment,
): VendorItem => ({
  id: agency.id,
  name: agency.name,
  businessId: agency.billingInfo.businessId,
  businessName: agency.billingInfo.businessName,
  vatNumber: agency.billingInfo.vatNumber || null,
});
