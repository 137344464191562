import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputField, Textarea, WhiteCard } from '@/app/components';
import { ActivityFormRawData } from './activity-form.interface';

export const CreativesNoteField: React.FC = () => {
  const { register, setValue, formState: { errors } } = useFormContext<ActivityFormRawData>();

  // unset note value
  useEffect(() => {
    return () => {
      setValue('note', null);
    };
  }, []);

  return (
    <WhiteCard padding="mt-4">
      <InputField
        {...register('note', {
          required: 'This field is required and cannot be empty',
        })}
        label="Please tell us what you would like to promote in the future?"
        className="min-h-20"
        error={errors.note}
        required
      />
    </WhiteCard>
  );
};
