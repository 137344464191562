import {
  ApiActivityStatus,
  ApiCreativeStatus,
  mapCreativeFieldType,
  mapRateType,
} from '@/app/common';
import {
  ApiActivityItemCreativeContentFragment,
  ApiActivityItemCreativeFragment,
  ApiActivityItemFragment,
} from './get-activities.query.generated';
import {
  ActivityCreativeItem,
  ActivityCreativeStatus,
  ActivityItem,
  ActivityStatus,
  CreativeContent,
  CreativePreviewContent,
  CreativePreviewImage,
} from '../../interfaces';

export const mapActivity = (
  activity: ApiActivityItemFragment,
): ActivityItem => {
  const creatives = activity.creatives.items.map(creative => mapCreative(creative));
  return {
    id: activity.id,
    campaignId: activity.campaignId,
    name: activity.name,
    status: mapActivityStatus(activity.status),
    statusNote: activity.statusNote,
    icon: null,
    startDate: new Date(activity.startDate),
    endDate: activity.endDate && new Date(activity.endDate) || null,
    rateType: mapRateType(activity.rateType),
    orderedAmount: activity.orderedAmount,
    unitPrice: activity.unitPrice,
    totalPrice: activity.totalPrice,
    metrics: {
      impressions: activity.metrics.impressions,
      views: activity.metrics.views,
      clicks: activity.metrics.clicks,
      visibility: activity.metrics.visibility,
      ctr: activity.metrics.ctr,
      osp: activity.metrics.osp,
    },
    creatives,
  };
};

const mapCreative = (
  creative: ApiActivityItemCreativeFragment,
): ActivityCreativeItem => {
  const contents = creative.contents.map(content => mapCreativeContent(content));
  return {
    id: creative.id,
    name: creative.name,
    status: mapCreativeStatus(creative.status),
    statusNote: creative.statusNote,
    previewImage: mapPreviewImage(contents),
    previewContents: mapPreviewContents(contents),
    contents,
    destinationType: creative.destinationType.toString().toLowerCase(),
    destinationUrl: creative.destinationUrl,
    destinationId: creative.destinationId,
    formatId: creative.format.id,
  };
};

const mapPreviewImage = (
  contents: CreativeContent[],
): CreativePreviewImage => {
  // filter contents of type image with populated value
  const imageContents = contents.filter(content => content.type === 'image' && content.mediaValue !== null);
  const requiredImageContents = imageContents.filter(content => content.required === true);
  const previewImageContent = requiredImageContents[0] ?? imageContents[0] ?? null;
  return previewImageContent && {
    name: previewImageContent.mediaValue?.name ?? '',
    url: previewImageContent.mediaValue?.url ?? '',
  } || null;
};

const mapPreviewContents = (
  contents: CreativeContent[],
): CreativePreviewContent[] => {
  // filter contents of type text with populated value
  const textContents = contents.filter(content => content.type === 'text' && content.value !== null);
  return textContents.map(content => ({
    term: content.name,
    description: content.value ?? '',
  }));
};

const mapCreativeContent = (
  content: ApiActivityItemCreativeContentFragment,
): CreativeContent => ({
  fieldId: content.fieldId,
  type: mapCreativeFieldType(content.type),
  slug: content.slug,
  name: content.name,
  position: content.position,
  required: false,
  value: content.value,
  mediaValue: content.mediaValue ? {
    id: content.mediaValue.id,
    name: content.mediaValue.name,
    size: content.mediaValue.size,
    mimeType: content.mediaValue.mimeType,
    path: content.mediaValue.path,
    url: content.mediaValue.url,
  } : null,
});

const mapActivityStatus = (status: ApiActivityStatus): ActivityStatus => {
  switch (status) {
    case ApiActivityStatus.Draft:
      return 'draft';
    case ApiActivityStatus.PendingApproval:
      return 'pendingApproval';
    case ApiActivityStatus.Rejected:
      return 'rejected';
    case ApiActivityStatus.Approved:
      return 'approved';
    case ApiActivityStatus.Incomplete:
      return 'incomplete';
    case ApiActivityStatus.Ready:
      return 'ready';
    case ApiActivityStatus.Online:
      return 'online';
    case ApiActivityStatus.Paused:
      return 'paused';
    case ApiActivityStatus.Closed:
      return 'closed';
    case ApiActivityStatus.Cancelled:
      return 'cancelled';

    default:
      throw new Error(`Unable to map activity status for unknown value ${status}`);
  }
};

const mapCreativeStatus = (status: ApiCreativeStatus): ActivityCreativeStatus => {
  switch (status) {
    case ApiCreativeStatus.Draft:
      return 'draft';
    case ApiCreativeStatus.PendingApproval:
      return 'pendingApproval';
    case ApiCreativeStatus.Approved:
      return 'approved';
    case ApiCreativeStatus.Rejected:
      return 'rejected';

    default:
      throw new Error(`Unable to map activity creative status for unknown value ${status}`);
  }
};
