import React from 'react';

interface RoundedFrameProps {
  children: React.ReactNode;
  margin?: string;
  padding?: string;
};

export const RoundedFrame: React.FC<RoundedFrameProps> = ({ children, padding, margin }) =>  (
  <section
    className={`
      flex
      flex-wrap
      border
      rounded-sm
      border-gray-300
      ${margin !== undefined ? margin : ''}
      ${padding !== undefined ? padding : 'px-4 py-3'}
    `}
  >
    {children}
  </section>
);
