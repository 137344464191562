import { ColumnDef } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableFooter,
  TableRow,
} from '@/app/components';
import { useCreatives } from '../../api';
import { CreativeItem } from '../../interfaces';
import { CreativeStatus } from '../creative-status';

export const CreativesList: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const navigate = useNavigate();
  const { creatives, total, page, limit } = useCreatives();

  const columns = useMemo<ColumnDef<CreativeItem>[]>(
    () => [
      {
        accessorKey: 'name',
        header: () => <span>Name</span>,
        size: 1000,
      },
      {
        accessorKey: 'status',
        header: () => <span>State</span>,
        cell: ({ row }) => {
          return (
            <CreativeStatus status={row.original.status} />
          );
        },
        size: 250,
      },
    ],
    [],
  );

  const handleRowClick = (row: CreativeItem) => navigate(row.id);

  return total > 0 ? (
    <>
      <Table<CreativeItem> data={creatives} columns={columns}>
        {({ row }) => <TableRow key={row.id} row={row} onClick={handleRowClick} />}
      </Table>
      <TableFooter
        pagination={{ totalPosts: total, currentPage: page, postsPerPage: limit }}
        label="Creatives"
      />
    </>
  ) : children;
};
