/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiCreativeDetailFragment = { __typename?: 'Creative', id: string, name: string, status: Types.ApiCreativeStatus, statusNote: string | null, destinationType: Types.ApiCreativeDestinationTypes, destinationUrl: string, destinationId: string | null, format: { __typename?: 'IdentifiedCreativeFormat', id: string, name: string }, contents: Array<{ __typename?: 'CreativeField', fieldId: string, type: Types.ApiCreativeFieldType, slug: string, name: string, position: number, value: string | null, mediaValue: { __typename?: 'CreativeFieldMediaValue', id: string, name: string, size: number, mimeType: string, path: string, url: string } | null }> };

export type ApiCreativeDetailContentFragment = { __typename?: 'CreativeField', fieldId: string, type: Types.ApiCreativeFieldType, slug: string, name: string, position: number, value: string | null, mediaValue: { __typename?: 'CreativeFieldMediaValue', id: string, name: string, size: number, mimeType: string, path: string, url: string } | null };

export type ApiGetCreativeDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ApiGetCreativeDetailQuery = { __typename?: 'Query', creative: { __typename?: 'Creative', id: string, name: string, status: Types.ApiCreativeStatus, statusNote: string | null, destinationType: Types.ApiCreativeDestinationTypes, destinationUrl: string, destinationId: string | null, format: { __typename?: 'IdentifiedCreativeFormat', id: string, name: string }, contents: Array<{ __typename?: 'CreativeField', fieldId: string, type: Types.ApiCreativeFieldType, slug: string, name: string, position: number, value: string | null, mediaValue: { __typename?: 'CreativeFieldMediaValue', id: string, name: string, size: number, mimeType: string, path: string, url: string } | null }> } };

export const CreativeDetailContentFragmentDoc = gql`
    fragment CreativeDetailContent on CreativeField {
  fieldId
  type
  slug
  name
  position
  value
  mediaValue {
    id
    name
    size
    mimeType
    path
    url
  }
}
    `;
export const CreativeDetailFragmentDoc = gql`
    fragment CreativeDetail on Creative {
  id
  name
  status
  statusNote
  format {
    id
    name
  }
  contents {
    ...CreativeDetailContent
  }
  destinationType
  destinationUrl
  destinationId
}
    ${CreativeDetailContentFragmentDoc}`;
export const GetCreativeDetailDocument = gql`
    query GetCreativeDetail($id: ID!) {
  creative(id: $id) {
    ...CreativeDetail
  }
}
    ${CreativeDetailFragmentDoc}`;

/**
 * __useGetCreativeDetailQuery__
 *
 * To run a query within a React component, call `useGetCreativeDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreativeDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreativeDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCreativeDetailQuery(baseOptions: Apollo.QueryHookOptions<ApiGetCreativeDetailQuery, ApiGetCreativeDetailQueryVariables> & ({ variables: ApiGetCreativeDetailQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiGetCreativeDetailQuery, ApiGetCreativeDetailQueryVariables>(GetCreativeDetailDocument, options);
      }
export function useGetCreativeDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiGetCreativeDetailQuery, ApiGetCreativeDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiGetCreativeDetailQuery, ApiGetCreativeDetailQueryVariables>(GetCreativeDetailDocument, options);
        }
export function useGetCreativeDetailSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApiGetCreativeDetailQuery, ApiGetCreativeDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiGetCreativeDetailQuery, ApiGetCreativeDetailQueryVariables>(GetCreativeDetailDocument, options);
        }
export type GetCreativeDetailQueryHookResult = ReturnType<typeof useGetCreativeDetailQuery>;
export type GetCreativeDetailLazyQueryHookResult = ReturnType<typeof useGetCreativeDetailLazyQuery>;
export type GetCreativeDetailSuspenseQueryHookResult = ReturnType<typeof useGetCreativeDetailSuspenseQuery>;
export type GetCreativeDetailQueryResult = Apollo.QueryResult<ApiGetCreativeDetailQuery, ApiGetCreativeDetailQueryVariables>;