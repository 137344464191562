import { CruxoStateMachine } from './state-machine';
import type { StateMachineConfig, StateMachineInterface, StateType, TransitionType } from './state-machine.interface';

export const createStateMachine =
  <
    S extends StateType,
    T extends TransitionType
  >
  (config: StateMachineConfig<S, T>): StateMachineInterface<S, T> => {
    return new CruxoStateMachine(config);
  };
