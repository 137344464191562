import { ApiLocale } from '../api/types.generated';
import { Locale } from '../interfaces';

export const mapStringToLocaleEnum = (value: string): ApiLocale => {
  switch (value) {
    case ApiLocale.CsCz:
      return ApiLocale.CsCz;
    case ApiLocale.EnGb:
      return ApiLocale.EnGb;

    default:
      throw new Error(`Unable to convert Locale object from string for unknown value ${value}`);
  }
};

export const mapApiLocaleToLocale = (locale: ApiLocale): Locale => {
  switch (locale) {
    case ApiLocale.CsCz:
      return Locale.CsCz;
    case ApiLocale.EnGb:
      return Locale.EnGb;

    default:
      throw new Error(`Unable to convert Locale from API constant for unknown value ${locale}`);
  }
};

export const mapLocaleToApiLocale = (locale: Locale): ApiLocale => {
  switch (locale) {
    case Locale.CsCz:
      return ApiLocale.CsCz;
    case Locale.EnGb:
      return ApiLocale.EnGb;

    default:
      throw new Error(`Unable to convert Locale to API constant for unknown value ${locale}`);
  }
};
