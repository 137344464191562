import React from 'react';
import * as Pagination from './ui';

interface PaginatorProps {
  itemsCount: number;
  itemsPerPage: number;
  currentPage: number;
};

export const Paginator: React.FC<PaginatorProps> = ({
  itemsCount,
  itemsPerPage,
  currentPage,
}) => {
  const pagesCount = Math.ceil(itemsCount / itemsPerPage);
  const isCurrentPageFirst = currentPage === 1;
  const isCurrentPageLast = currentPage === pagesCount;

  const prevPage = currentPage > 1 ? currentPage - 1 : 1;
  const nextPage = currentPage < pagesCount ? currentPage + 1 : pagesCount;

  let isPageNumberOutOfRange: boolean;

  const pageNumbers = [...new Array(pagesCount)].map((_, index) => {
    const pageNumber = index + 1;
    const isPageNumberFirst = pageNumber === 1;
    const isPageNumberLast = pageNumber === pagesCount;
    const isCurrentPageWithinTwoPageNumbers =
      Math.abs(pageNumber - currentPage) <= 2;

    if (
      isPageNumberFirst ||
      isPageNumberLast ||
      isCurrentPageWithinTwoPageNumbers
    ) {
      isPageNumberOutOfRange = false;
      return (
        <Pagination.Page
          key={pageNumber}
          page={pageNumber}
          active={pageNumber === currentPage}
        >
          {pageNumber}
        </Pagination.Page>
      );
    }

    if (!isPageNumberOutOfRange) {
      isPageNumberOutOfRange = true;
      return <Pagination.Ellipsis key={pageNumber} />;
    }

    return null;
  });

  return itemsCount === 0 ? null : (
    <Pagination.Container>
      <Pagination.Prev page={prevPage} disabled={isCurrentPageFirst} />
      {pageNumbers}
      <Pagination.Next page={nextPage} disabled={isCurrentPageLast} />
    </Pagination.Container>
  );
};
