import Joi from 'joi';

export const validationSchema = Joi.object({
  name: Joi.string().max(250).required(),
  vendor: Joi.object().required(),
  primaryContact: Joi.object().required(),
  agency: Joi.object().allow(null).optional(),
  secondaryContact: Joi.object().allow(null).optional(),
}).unknown(true).messages({
  'object.base': 'This field is required and cannot be empty',
  'string.empty': 'This field is required and cannot be empty',
  'string.max': 'Field value is too long',
  'any.required': 'This field is required and cannot be empty',
});
