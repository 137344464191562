import { ColumnDef } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, PlusIcon, SectionHeading, Table, TableRow } from '@/app/components';
import { AgencyUserItem, VendorUserItem } from '../../interfaces';

interface UsersListProps {
  children: React.ReactNode;
  headline: string;
  users: AgencyUserItem[] | VendorUserItem[];
};

export const UsersList: React.FC<UsersListProps> = ({
  children,
  headline,
  users,
}) => {
  const navigate = useNavigate();
  const columns = useMemo<ColumnDef<AgencyUserItem | VendorUserItem>[]>(
    () => [
      {
        accessorKey: 'username',
        cell: (info) => info.getValue(),
        header: () => <span>Username</span>,
      },
      {
        accessorKey: 'name',
        header: () => <span>Full name</span>,
      },
      {
        accessorKey: 'locale',
        header: () => <span>Locale</span>,
        sortUndefined: 'last',
      },
      {
        accessorKey: 'timezone',
        header: 'Timezone',
      },
      {
        accessorKey: 'roles',
        header: 'Roles',
      },
      {
        accessorKey: 'state',
        header: () => <span>State</span>,
        cell: ({ row }) => {
          return (
            <div className="flex items-center space-x-2">
              <span
                className={`
                  rounded-full
                  w-4
                  h-4
                  ${row.original.state === 'active' ? ' bg-green-500' : ' bg-red-500'}
                `}
              />
              <span>{row.original.state}</span>
            </div>
          );
        },
      },
    ],
    [],
  );

  //const handleRowClick = (row: AgencyUserItem | VendorUserItem) => navigate(`users/${row.id}`);

  return (
    <section>
      <header className="mb-4 flex items-center justify-between">
        <SectionHeading heading={headline} />
        <div>
          <Button
            label="Add User"
            icon={PlusIcon}
            variant="primary"
            link="users/create"
          />
        </div>
      </header>

      {users.length > 0 ? (
        <Table<AgencyUserItem | VendorUserItem> data={users} columns={columns}>
          {({ row }) => <TableRow key={row.id} row={row} />}
        </Table>
      ) : children}
    </section>
  );
};
