import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';

interface PageProps extends HTMLAttributes<HTMLAnchorElement> {
  children?: React.ReactNode,
  page: number;
  active?: boolean;
  disabled?: boolean;
};

export const Page: React.FC<PageProps> = ({
  children,
  page,
  active = false,
  disabled: _disabled = false,
  ...props
}) => (
  <li>
    <Link
      {...props}
      to={{ search: `?page=${page}` }}
      className={classNames(
        [
          'rounded-full',
          'flex',
          'items-center',
          'justify-center',
          'w-9',
          'h-9',
          'leading-4',
          'text-slate-800',
          'hover:underline',
          'hover:text-purple-600',
          'focus-visible:outline',
          'focus-visible:outline-1',
          'focus-visible:outline-offset-1',
          'focus-visible:outline-purple-600',
        ],
        !active ? [] : [
          'bg-slate-700',
          'text-white',
          'pointer-events-none',
        ],
      )}
    >
      {children}
    </Link>
  </li>
);
