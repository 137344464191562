import { Suspense } from 'react';
import { Control, FieldValues, Path, RegisterOptions } from 'react-hook-form';
import { ComboboxField, ComboboxSearch } from '@/app/components';
import { useSearchVendors } from '../../api';

interface VendorFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  id?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  rules?: Omit<RegisterOptions<T>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
};

interface VendorFieldInternalProps<T extends FieldValues> extends VendorFieldProps<T> {
  id: string;
  label: string;
  placeholder: string;
};

export const VendorField = <T extends FieldValues>({
  id = (Math.random() + 1).toString(36).substring(7),
  label = 'Advertiser',
  placeholder = 'Select advertiser...',
  ...props
}: VendorFieldProps<T>,
) => {
  const allProps: VendorFieldInternalProps<T> = {
    ...props,
    id,
    label,
    placeholder,
  };

  return (
    <Suspense fallback={<VendorFieldSkeleton {...allProps} />}>
      <VendorFieldLoader {...allProps} />
    </Suspense>
  );
};

export const VendorFieldLoader =
  <T extends FieldValues>(props: VendorFieldInternalProps<T>) => {
    const { vendors, searchVendors } = useSearchVendors();
    const handleSearch = (term: string) => {
      searchVendors(term);
    };

    return (
      <ComboboxField
        {...props}
        options={vendors.map(vendor => ({
          value: vendor.id,
          name: vendor.name,
        }))}
        onSearch={handleSearch}
      />
    );
  };

export const VendorFieldSkeleton =
  <T extends FieldValues>(props: VendorFieldInternalProps<T>) => (
    <ComboboxSearch
      {...props}
      options={[]}
      disabled
    />
  );
