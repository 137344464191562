import classNames from 'classnames';

export interface DialogContentProps {
  children: React.ReactNode;
  canScroll?: boolean;
  fixedHeight?: number;
};

export const DialogContent: React.FC<DialogContentProps> = ({
  children,
  canScroll = false,
  fixedHeight = undefined,
}) => (
  <div
    className={classNames([
      'pr-4',
      'pt-2',
      'pb-4',
      ...(canScroll ? [
        'overflow-y-auto',
        'max-h-[75vh]',
      ] : []),
      ...(fixedHeight ? [`h-[${fixedHeight}vh]`] : []),
    ])}
  >
    {children}
  </div>
);
