import {
  useApproveCreativeMutation,
  useRejectCreativeMutation,
  useRequestCreativeApprovalMutation,
} from './creative-workflow.query.generated';

type UseCreativeWorkflowHook = {
  request: (id: string) => Promise<void>;
  approve: (id: string) => Promise<void>;
  reject: (id: string, note: string) => Promise<void>;
};

export const useCreativeWorkflow = (): UseCreativeWorkflowHook => {
  const [approveMutation] = useApproveCreativeMutation();
  const [rejectMutation] = useRejectCreativeMutation();
  const [requestMutation] = useRequestCreativeApprovalMutation();

  const request = async (id: string) => {
    const doSubmit = async () => {
      const { data, errors } = await requestMutation({
        variables: {
          id,
        },
      });

      // Error
      if (errors !== undefined && errors.length > 0) {
        const errorMessage = errors[0].message;
        throw new Error(errorMessage);
      }

      // No error, no data
      if (!data) {
        throw new Error('Server returned empty unexpected response.');
      }

      return data;
    };

    await doSubmit();
  };

  const reject = async (id: string, note: string) => {
    const doSubmit = async () => {
      const { data, errors } = await rejectMutation({
        variables: {
          id,
          note,
        },
      });

      // Error
      if (errors !== undefined && errors.length > 0) {
        const errorMessage = errors[0].message;
        throw new Error(errorMessage);
      }

      // No error, no data
      if (!data) {
        throw new Error('Server returned empty unexpected response.');
      }

      return data;
    };

    await doSubmit();
  };

  const approve = async (id: string) => {
    const doSubmit = async () => {
      const { data, errors } = await approveMutation({
        variables: {
          id,
        },
      });

      // Error
      if (errors !== undefined && errors.length > 0) {
        const errorMessage = errors[0].message;
        throw new Error(errorMessage);
      }

      // No error, no data
      if (!data) {
        throw new Error('Server returned empty unexpected response.');
      }

      return data;
    };

    await doSubmit();
  };

  return {
    request,
    approve,
    reject,
  };
};
