import React from 'react';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDialogContext } from '@/app/common';
import {
  Button,
  PageContentBox,
  ToggleSection,
  WhiteCard,
} from '@/app/components';
import { useCampaignDetail, useCreateActivity } from './api';
import {
  ActivityForm,
  AdvertisingToolsDialog,
  ChangeToolDialog,
  SummaryBox,
} from './components';
import { ActivityFormData } from './interfaces';

export const ActivitiesCreatePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  if (!id) {
    throw new Error('Unknown campaign ID. Unable to open page with unknown ID.');
  }

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { campaign, refreshCampaign } = useCampaignDetail(id);
  const { createActivity } = useCreateActivity();
  const { dialogState, openDialog, closeDialog } = useDialogContext();

  const handleSubmit = async (data: ActivityFormData) => {
    await createActivity(campaign.id, data);
    await refreshCampaign();
    navigate('..');
  };

  const handleSelectTool = (toolId: string) => {
    navigate({ search: createSearchParams({
      tool: toolId,
    }).toString() });

    closeDialog('advertising-detail');
  };

  const confirmToolChange = () => {
    closeDialog('changeTool');
    openDialog('advertising-detail');
  };

  const handleChangeCampaign = () => {
    const toolId = searchParams.get('tool');
    navigate({
      pathname: '/campaigns/select',
      search: createSearchParams({
        ...(id && { campaign: id }),
        ...(toolId && { tool: toolId }),
      }).toString(),
    });
  };

  const handleCancel = () => {
    navigate('..');
  };

  return (
    <PageContentBox maxWidth="2xl">
      <header className="mb-4">
        <h1 className="text-3xl font-medium">New Line Item</h1>
      </header>

      <ActivityForm
        campaign={campaign}
        selectedTool={searchParams.get('tool')}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      >
        <ToggleSection title="Campaign" margin="mb-5">
          <WhiteCard>
            <SummaryBox
              title={campaign.name}
              definitionList={[
                { term: 'Advertiser', description: campaign.vendor },
                { term: 'Advertiser contact', description: campaign.primaryContact },
                { term: 'Agency', description: campaign.agency ?? 'None' },
                { term: 'Agency contact', description: campaign.secondaryContact ?? 'None' },
              ]}
            >
              <Button
                label="Change campaign"
                variant="secondary"
                onClick={handleChangeCampaign}
              />
            </SummaryBox>
          </WhiteCard>
        </ToggleSection>
      </ActivityForm>

      <AdvertisingToolsDialog
        onSelect={handleSelectTool}
        onClose={() => closeDialog('advertising-detail')}
        isOpened={dialogState['advertising-detail']}
      />

      <ChangeToolDialog
        onConfirm={confirmToolChange}
        onClose={() => closeDialog('changeTool')}
        isOpened={dialogState['changeTool']}
      />
    </PageContentBox>
  );
};
