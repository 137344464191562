/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiSearchTargetQueryVariables = Types.Exact<{
  targetId: Types.Scalars['ID']['input'];
  term: Types.Scalars['String']['input'];
}>;


export type ApiSearchTargetQuery = { __typename?: 'Query', adTarget: { __typename?: 'AdTargetDto', searchValues: Array<{ __typename?: 'AdTargetValue', id: string, value: string }> } };


export const SearchTargetDocument = gql`
    query SearchTarget($targetId: ID!, $term: String!) {
  adTarget(id: $targetId) {
    searchValues(term: $term) {
      id
      value
    }
  }
}
    `;

/**
 * __useSearchTargetQuery__
 *
 * To run a query within a React component, call `useSearchTargetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTargetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTargetQuery({
 *   variables: {
 *      targetId: // value for 'targetId'
 *      term: // value for 'term'
 *   },
 * });
 */
export function useSearchTargetQuery(baseOptions: Apollo.QueryHookOptions<ApiSearchTargetQuery, ApiSearchTargetQueryVariables> & ({ variables: ApiSearchTargetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiSearchTargetQuery, ApiSearchTargetQueryVariables>(SearchTargetDocument, options);
      }
export function useSearchTargetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiSearchTargetQuery, ApiSearchTargetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiSearchTargetQuery, ApiSearchTargetQueryVariables>(SearchTargetDocument, options);
        }
export function useSearchTargetSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApiSearchTargetQuery, ApiSearchTargetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiSearchTargetQuery, ApiSearchTargetQueryVariables>(SearchTargetDocument, options);
        }
export type SearchTargetQueryHookResult = ReturnType<typeof useSearchTargetQuery>;
export type SearchTargetLazyQueryHookResult = ReturnType<typeof useSearchTargetLazyQuery>;
export type SearchTargetSuspenseQueryHookResult = ReturnType<typeof useSearchTargetSuspenseQuery>;
export type SearchTargetQueryResult = Apollo.QueryResult<ApiSearchTargetQuery, ApiSearchTargetQueryVariables>;