import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  Observable,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useAuthToken } from '../hooks';

const authLink = setContext((_, { headers }) => {
  const { token } = useAuthToken();

  if (token === null || token === '') {
    return {
      headers,
    };
  }

  // autohrization header was defined from outside, we should ignore it here
  if (headers?.authorization !== undefined) {
    return {
      headers,
    };
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const httpLink = createHttpLink({
  uri: '/graphql',
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch (err.extensions.code) {
        case 'UNAUTHENTICATED':
          return new Observable(observer => {
            const { refreshToken, setToken, setRefreshToken } = useAuthToken();

            if (!refreshToken) {
              observer.error(new Error('No refresh token available'));
              return;
            }

            refreshTokenFunction(refreshToken).then(({ token, refreshToken }) => {
              setToken(token);
              setRefreshToken(refreshToken);
              operation.setContext(({ headers = {} }) => ({
                headers: {
                  ...headers,
                  Authorization: `Bearer ${token}`,
                },
              }));
              forward(operation).subscribe({
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              });
            }).catch(error => {
              observer.error(error);
            });
          });
        default:
          break;
      }
    }
  }

  if (networkError) {
    throw networkError;
  }
});

// TODO: Refresh token - not implemented yet, to demo
const refreshTokenFunction = async (refreshToken: string) => {
  const response = await fetch('/graphql', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
        mutation RefreshToken($refreshToken: String!) {
          refreshToken(refreshToken: $refreshToken) {
            token
            refreshToken
          }
        }
      `,
      variables: { refreshToken },
    }),
  });

  const data = await response.json();

  if (data.errors) {
    throw new Error(data.errors[0].message);
  }
  return data.data.refreshToken;
};

export const createApolloClient = (): ApolloClient<unknown> => new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
    },
  },
});
