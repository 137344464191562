import { ApolloError } from '@apollo/client';
import { useEffect, useRef } from 'react';
import { ApiCampaignStatus, useAuth, usePagination } from '@/app/common';
import { mapCampaign } from './campaign.mapper';
import { useCampaignsSuspenseQuery } from './get-campaigns.query.generated';
import { useCampaignFilters } from '../../hooks';
import { CAMPAIGN_STATUS, CampaignResult, CampaignStatus } from '../../interfaces';

type UseCampaignsHook = {
  total: CampaignResult['total'];
  campaigns: CampaignResult['items'];
  error: ApolloError | undefined;
  page: number;
  limit: number;
};

export const useCampaigns = (): UseCampaignsHook => {
  const { state: { currentWorkspace } } = useAuth();
  const workspaceRef = useRef(currentWorkspace);
  const [page] = usePagination();
  const { filters } = useCampaignFilters();
  const { data, error, refetch } = useCampaignsSuspenseQuery({
    variables: {
      page,
      limit: 10,
      search: filters.search,
      vendor: filters.vendor?.value ?? null,
      status: filters.status ? mapStatus(filters.status.value) : null,
      salesPerson: filters.salesPerson?.value ?? null,
      startDate: filters.startDate ? (new Date(filters.startDate)).toISOString() : null,
      endDate: filters.endDate ? (new Date(filters.endDate)).toISOString() : null,
    },
  });

  useEffect(() => {
    if (currentWorkspace?.id !== workspaceRef.current?.id) {
      workspaceRef.current = currentWorkspace;
      refetch();
    }
  }, [currentWorkspace?.id]);

  return {
    page,
    limit: 10,
    total: data.campaigns.total,
    campaigns: data.campaigns.items.map(campaign => mapCampaign(campaign)),
    error,
  };
};

const mapStatus = (status: CampaignStatus): ApiCampaignStatus | null => {
  switch (status) {
    case CAMPAIGN_STATUS.draft:
      return ApiCampaignStatus.Draft;
    case CAMPAIGN_STATUS.incomplete:
      return ApiCampaignStatus.Incomplete;
    case CAMPAIGN_STATUS.ready:
      return ApiCampaignStatus.Ready;
    case CAMPAIGN_STATUS.online:
      return ApiCampaignStatus.Online;
    case CAMPAIGN_STATUS.closed:
      return ApiCampaignStatus.Closed;

    default:
      return null;
  }
};
