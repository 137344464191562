import React, { useEffect, useRef, useState } from 'react';
import { BinIcon, Button, ChevronRightIcon, CloseIcon, DefinitionList, EditIcon, TicksIcon } from '@/app/components';
import { useCreativeWorkflow } from '../../api';
import { useCreativeStatus } from '../../hooks';
import { ActivityCreativeItem } from '../../interfaces';
import { CreativeStatus } from '../creative-status';
import { PopupNote, usePopupNote } from '../popup-note';

interface ActivityCreativeRowProps {
  creative: ActivityCreativeItem;
  expanded?: boolean;
  indent?: boolean;
};

export const ActivityCreativeRow: React.FC<ActivityCreativeRowProps> = ({
  creative,
  expanded: initialExpanded = false,
  indent = false,
}) => {
  const rejectButtonRef = useRef<HTMLDivElement>(null);
  const { popupNoteId, openPopupNote, closePopupNote } = usePopupNote();

  const [isExpanded, expand] = useState<boolean>(initialExpanded);
  const { approve, reject, request } = useCreativeWorkflow();
  const [creativeStatus, trigger] = useCreativeStatus(creative.status, {
    APPROVE: () => approve(creative.id),
    REJECT: (note: string) => reject(creative.id, note),
    REQUEST: () => request(creative.id),
  });

  const handleSubmitRejection = async (note: string) => {
    await trigger('REJECT', note);
    closePopupNote();
  };

  const handleExpandToggle = () => expand(expanded => !expanded);

  useEffect(() => {
    expand(initialExpanded);

    return () => {
      closePopupNote();
    };
  }, [initialExpanded]);

  const renderButtons = () => {
    const role = 'retailer';
    if (role === 'retailer') {
      return (
        <div className="flex items-center gap-x-4">
          {creativeStatus.can('APPROVE') && (
            <div>
              <Button
                label="Approve"
                variant="confirm"
                icon={TicksIcon}
                onClick={() => trigger('APPROVE')}
              />
            </div>
          )}

          {creativeStatus.can('REJECT') && (
            <div ref={rejectButtonRef}>
              <Button
                label="Disapprove"
                variant="decline"
                icon={CloseIcon}
                onClick={() => openPopupNote(creative.id)}
              />
            </div>
          )}

          <button className="text-purple-600" type="button">
            {EditIcon}
          </button>

          <button className="text-purple-600" type="button">
            {BinIcon}
          </button>
        </div>
      );
    }

    return (
      <div className="flex items-center gap-x-4">
        {creativeStatus.can('REQUEST') && (
          <div>
            <Button
              label="Send for approval"
              variant="secondary"
              onClick={() => trigger('REQUEST')}
            />
          </div>
        )}

        <button className="text-purple-600" type="button">
          {EditIcon}
        </button>

        <button className="text-purple-600" type="button">
          {BinIcon}
        </button>
      </div>
    );
  };

  return (
    <div
      key={creative.id}
      className={indent ? 'ml-6' : ''}
    >
      <div
        className={`
        px-4
        py-3
        h-16
        bg-white
        rounded-l-sm
        rounded-r-sm
        shadow-sm
        rounded-sm
        group-hover:bg-purple-50
      `}
      >
        <div className="flex justify-between gap-x-2">
          <div className="flex items-center gap-x-2">
            <button
              onClick={handleExpandToggle}
              type="button"
            >
              <span
                className={`
              w-8
              h-10
              flex
              items-center
              justify-center
              transition-transform
              origin-center
              ${isExpanded ? 'rotate-90' : ''}
            `}
              >
                {ChevronRightIcon}
              </span>
            </button>

            {creative.name}
          </div>

          {isExpanded ? renderButtons() : (
            <CreativeStatus status={creativeStatus.currentState} />
          )}
        </div>
      </div>

      {isExpanded && (
        <div
          className={`
            px-6
            py-3
            pt-6
            bg-white
            shadow-sm
            rounded-b-sm
            -mt-4
          `}
        >
          <div className="mb-3">
            <CreativeStatus status={creativeStatus.currentState} />
          </div>

          <div className="flex items-start space-x-4">
            {creative.previewImage && (
              <div className="rounded-xs overflow-hidden">
                <img src={creative.previewImage.url} width={180} alt={creative.previewImage.name} />
              </div>
            )}

            {creative.previewContents.length > 0 && (
              <div className="w-full">
                <DefinitionList
                  data={creative.previewContents}
                  columns={3}
                />
              </div>
            )}
          </div>
        </div>
      )}

      {popupNoteId === creative.id && (
        <PopupNote
          actionRef={rejectButtonRef}
          position="bottom"
          onClose={closePopupNote}
          onSubmit={handleSubmitRejection}
        />
      )}
    </div>
  );
};
