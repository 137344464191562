import { Control, Controller, FieldValues, Path, PathValue } from 'react-hook-form';
import { SwitchButton, SwitchProps } from './switch';

interface SwitchFieldProps<T extends FieldValues> extends SwitchProps<T> {
  control: Control<T>;
  defaultValue?: PathValue<T, Path<T>>;
};

export const SwitchField = <T extends FieldValues>({
  name,
  label,
  defaultValue,
  disabled = false,
  isFullWidth = false,
  control,
  onChange: customOnChange,
  ...rest
} : SwitchFieldProps<T>) => (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <SwitchButton
          {...field}
          {...rest}
          label={label}
          isFullWidth={isFullWidth}
          disabled={disabled}
          onChange={(e) => {
            field.onChange(e);
            if (customOnChange) {
              customOnChange(e);
            }
          }}
        />
      )}
    />
  );
