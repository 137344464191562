import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Field,
  Label,
} from '@headlessui/react';
import React, { forwardRef, useEffect, useState } from 'react';
import { ChevronUpDownIcon, TicksIcon } from '@/app/components';
import { FieldError } from './interfaces';
import { getDataOptionAttributes } from './utils';

export interface ComboboxOptions {
  name: string;
  value: string;
  description?: string;
  image?: string;
};

export interface ComboboxButtonProps {
  name: string;
  placeholder: string;
};

export type ComboboxValue = ComboboxOptions | ComboboxOptions[] | null;

export interface ComboboxProps {
  id: string;
  options: ComboboxOptions[];
  value?: ComboboxValue;
  label?: string;
  disabled?: boolean;
  inline?: boolean;
  required?: boolean | string;
  multiple?: boolean;
  error?: FieldError;
  placeholder?: string;
  displayValue?: boolean;
  immediate?: boolean;
  onChange?: (value: ComboboxValue) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onSearch?: (value: string) => void;
};

export const ComboboxSearch = forwardRef<HTMLDivElement, ComboboxProps>(({
  id,
  label,
  value,
  options,
  error,
  inline = false,
  disabled = false,
  required = false,
  multiple = false,
  placeholder = '',
  displayValue = true,
  immediate = false,
  onChange,
  onFocus,
  onBlur,
  onSearch,
  ...rest
}, ref) => {

  const [selectedOptions, setSelectedOptions] = useState<ComboboxValue>(value ?? []);

  useEffect(() => {
    if (Array.isArray(value)) {
      const selected = options.filter(option =>
        value.some(value => value.value === option.value),
      );
      setSelectedOptions([...selected]);
    } else if (value) {
      const selected = options.find(option => option.value === value.value);
      setSelectedOptions(selected !== undefined ? selected : null);
    }
  }, [value, options]);

  const handleSelectChange = (selectedOptions: ComboboxValue) => {
    if (!selectedOptions) {
      onChange?.(multiple ? [] : null);
      return;
    }

    onChange?.(selectedOptions);
    setSelectedOptions(selectedOptions);
  };

  const dataOptionAttributes = getDataOptionAttributes(value ?? null, multiple);

  const disabledClass = disabled === true
    ? 'cursor-not-allowed bg-slate-100 text-slate-500'
    : 'cursor-default bg-white';

  const errorClass = error !== undefined
    ? 'border-red-700 text-red-700'
    : 'border-slate-300 data-[focus]:border-purple-500';

  const buttonClass = `
    relative
    w-full
    rounded-md
    py-2
    pl-4
    pr-7
    h-10
    text-left
    border
    text-sm
    leading-6
    truncated
    overflow-hidden
    focus:outline-none
    data-[active]:border-purple-500
    placeholder-slate-400
    ${disabledClass}
    ${errorClass}
  `;

  const optionsClass = `
    absolute
    z-10
    mt-1
    max-h-60
    overflow-auto
    rounded-xs
    bg-white
    py-1
    text-base
    shadow-lg
    ring-1
    ring-black/5
    focus:outline-none
    sm:text-sm
    transition-[opacity]
    w-[var(--input-width)]
    data-[leave]:data-[closed]:opacity-0
  `;

  const optionClassName = `
    relative
    cursor-default
    select-none
    py-2
    pl-3
    pr-4
    group
    flex
    items-center
    gap-2
    select-none
    text-slate-800
    data-[focus]:bg-purple-200
  `;

  return (
    <Field disabled={disabled}>
      {/* Label inline position */}
      {label !== undefined && !inline && (
        <Label
          htmlFor={id}
          className="mb-1 text-sm leading-6 text-slate-800 block"
        >
          {label}
          {!required && <span className="text-slate-400 ml-1">(optional)</span>}
        </Label>
      )}

      <div
        className={`relative w-full ${
          inline === true ? 'flex items-center' : ''
        }`}
      >
        {/* Label block position */}
        {label !== undefined && inline === true && (
          <Label
            className="text-sm text-slate-800 inline-block mr-2"
            htmlFor={id}
          >
            {label}
            {!required && (
              <span className="text-slate-400 ml-1">(optional)</span>
            )}
          </Label>
        )}

        <Combobox
          {...dataOptionAttributes}
          value={selectedOptions}
          onChange={handleSelectChange}
          onFocus={onFocus}
          multiple={multiple}
          immediate={immediate}
          ref={ref}
          as="div"
        >
          <div className="relative">
            <ComboboxInput
              className={buttonClass}
              displayValue={displayValue == false ? undefined : (
                item: ComboboxOptions | ComboboxOptions[] | null,
              ) => {
                if (Array.isArray(item)) {
                  if (multiple && item.length > 0) {
                    return item.length > 0
                      ? `${item.length} selected items`
                      : '';
                  }
                  return '';
                }

                return item?.name ?? '';
              }}
              onChange={(event) => onSearch && onSearch(event.target.value)}
              placeholder={placeholder}
              autoComplete="off"
            />
            <ComboboxButton
              {...rest}
              className="group absolute inset-y-0 right-0 pr-3"
              onBlur={onBlur}
            >
              <span>{ChevronUpDownIcon}</span>
            </ComboboxButton>

            {options.length > 0 && (
              <ComboboxOptions
                anchor="bottom"
                transition
                className={optionsClass}
              >
                {options.map((option) => (
                  <ComboboxOption
                    key={option.value}
                    value={option}
                    className={`${optionClassName} ${
                      option.image !== undefined ? 'flex-row-reverse justify-between' : ''
                    }`}
                  >
                    <span
                      className={`invisible group-data-[selected]:visible ${
                        option.image !== undefined ? 'ml-auto' : ''
                      }`}
                    >
                      {TicksIcon}
                    </span>
                    <div className="flex flex-col">
                      <span>{option.name}</span>
                      {option.description && (
                        <span className="text-xs">{option.description}</span>
                      )}
                    </div>
                    {option.image !== undefined && (
                      <div className="w-16 h-16 overflow-hidden rounded-xs mr-2">
                        <img src={option.image} alt="" className="object-cover" />
                      </div>
                    )}
                  </ComboboxOption>
                ))}
              </ComboboxOptions>
            )}
          </div>
        </Combobox>
      </div>

      {error?.message !== undefined && (
        <p className="mt-1 text-xs text-red-700 leading-4">{error.message}</p>
      )}
    </Field>
  );
});
