/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiBrandItemFragment = { __typename?: 'Brand', id: string, name: string };

export type ApiSearchBrandsQueryVariables = Types.Exact<{
  limit: Types.InputMaybe<Types.Scalars['Int']['input']>;
  search: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ApiSearchBrandsQuery = { __typename?: 'Query', brands: { __typename?: 'BrandResult', items: Array<{ __typename?: 'Brand', id: string, name: string }> } };

export const BrandItemFragmentDoc = gql`
    fragment BrandItem on Brand {
  id
  name
}
    `;
export const SearchBrandsDocument = gql`
    query SearchBrands($limit: Int, $search: String) {
  brands(limit: $limit, search: $search) {
    items {
      ...BrandItem
    }
  }
}
    ${BrandItemFragmentDoc}`;

/**
 * __useSearchBrandsQuery__
 *
 * To run a query within a React component, call `useSearchBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBrandsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchBrandsQuery(baseOptions?: Apollo.QueryHookOptions<ApiSearchBrandsQuery, ApiSearchBrandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiSearchBrandsQuery, ApiSearchBrandsQueryVariables>(SearchBrandsDocument, options);
      }
export function useSearchBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiSearchBrandsQuery, ApiSearchBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiSearchBrandsQuery, ApiSearchBrandsQueryVariables>(SearchBrandsDocument, options);
        }
export function useSearchBrandsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApiSearchBrandsQuery, ApiSearchBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiSearchBrandsQuery, ApiSearchBrandsQueryVariables>(SearchBrandsDocument, options);
        }
export type SearchBrandsQueryHookResult = ReturnType<typeof useSearchBrandsQuery>;
export type SearchBrandsLazyQueryHookResult = ReturnType<typeof useSearchBrandsLazyQuery>;
export type SearchBrandsSuspenseQueryHookResult = ReturnType<typeof useSearchBrandsSuspenseQuery>;
export type SearchBrandsQueryResult = Apollo.QueryResult<ApiSearchBrandsQuery, ApiSearchBrandsQueryVariables>;