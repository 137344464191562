import { AbilityBuilder, createMongoAbility } from '@casl/ability';
import { AbilityType, DefinePermissions, resolvePermissions } from './permissions.interface';
import { CurrentUser, Role } from '../../interfaces';

export const ability = new AbilityBuilder<AbilityType>(createMongoAbility);
export const definePermissionsFor = (user?: CurrentUser): AbilityType => {
  const builder = new AbilityBuilder<AbilityType>(createMongoAbility);
  if (!user) {
    return builder.build({
      resolveAction: resolvePermissions,
    });
  }

  rolePermissions['ALL']?.(user, builder);
  rolePermissions[user.role]?.(user, builder);

  return builder.build({
    resolveAction: resolvePermissions,
  });
};

const rolePermissions: Partial<Record<Role | 'ALL', DefinePermissions>> = {
  ALL(user, { can }) {
    can('read', 'campaign');
    can('read', 'activity');
    can('read', 'creative');
  },

  SUPER_ADMIN(user, { can }) {
    can('manage', ['all']);
  },

  ORGANIZATION_ADMIN(user, { can }) {
    can('manage', [
      'campaign',
      'activity',
      'creative',
      'creativeFormat',
      'advertisingCategory',
      'advertisingTool',
      'advertisingTarget',
      'user',
      'market',
      'agency',
      'vendor',
      'brand',
    ]);
  },
};
