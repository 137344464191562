import { mapStringToLocaleEnum, mapStringToTimezoneEnum } from '@/app/common';
import { useCreateAgencyUserMutation } from './create-agency-user.query.generated';
import { AgencyUserFormData } from '../../interfaces';

type UseCreateAgencyUserHook = {
  createAgencyUser: (agencyId: string, formData: AgencyUserFormData) => Promise<void>;
  loading: boolean;
};

export const useCreateAgencyUser = (): UseCreateAgencyUserHook => {
  const [agencyUserMutation, { loading }] = useCreateAgencyUserMutation();

  const createAgencyUser = async (agencyId: string, formData: AgencyUserFormData) => {
    const doSubmit = async () => {
      const { data, errors } = await agencyUserMutation({
        variables: {
          ...formData,
          agencyId,
          locale: formData.locale && mapStringToLocaleEnum(formData.locale) || null,
          timezone: formData.timezone && mapStringToTimezoneEnum(formData.timezone) || null,
        },
      });

      // Error
      if (errors !== undefined && errors.length > 0) {
        const errorMessage = errors[0].message;
        throw new Error(errorMessage);
      }

      // No error, no data
      if (!data) {
        throw new Error('Server returned empty unexpected response.');
      }

      return data;
    };

    await doSubmit();
  };

  return {
    createAgencyUser,
    loading,
  };
};
