import React from 'react';
import { useTranslation } from 'react-i18next';

interface StatisticsListItem {
  term: string;
  description: string | number;
};

interface StatisticsListProps {
  impressions: number;
  views: number;
  clicks: number;
  visibility: number;
  ctr: number;
  osp: number;
};

export const StatisticsList: React.FC<StatisticsListProps> = ({
  impressions,
  views,
  clicks,
  visibility,
  ctr,
  osp,
}) => {
  const { i18n } = useTranslation();
  const numberFormatter = new Intl.NumberFormat(i18n.language, { style: 'percent' });
  const formatPercentage = (
    value: number,
  ) => numberFormatter.format(value / 100);

  const groups: StatisticsListItem[] = [
    { term: 'Impressions', description: impressions },
    { term: 'Views', description: views },
    { term: 'Visibility', description: formatPercentage(visibility) },
    { term: 'Clicks', description: clicks },
    { term: 'CTR', description: formatPercentage(ctr) },
    { term: 'OSP', description: formatPercentage(osp) },
  ];

  return (
    <dl className="flex flex-wrap w-full justify-around items-center">
      {groups.map((item, index) => (
        <div key={`stat-item-${index}`} className="text-center">
          <dt className="text-sm leading-6 mb-2">{item.term}</dt>
          <dd className="text-purple-600 text-2xl font-semibold">{item.description}</dd>
        </div>
      ))}
    </dl>
  );
};
