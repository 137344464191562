import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useLogin } from '@/app/common';
import { Button, InputField, Notification } from '@/app/components';

export interface LoginFormRawData {
  username: string;
  password: string;
};

const validationSchema = Joi.object({
  username: Joi
    .string()
    .required()
    .email({ tlds: { allow: false } })
    .messages({
      'string.email': 'Username has to a be a valid email',
    }),
  password: Joi.string().required(),
}).messages({
  'string.empty': 'This field is required and cannot be empty',
  'any.required': 'This field is required and cannot be empty',
});

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const { loginUser, loading } = useLogin();
  const [error, setError] = useState<string | null>(null);
  const resolver = joiResolver(validationSchema, {
    abortEarly: false,
  });
  const { register, handleSubmit, formState: { errors }} = useForm<LoginFormRawData>({
    mode: 'onChange',
    resolver,
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const onSubmit = async (formData: LoginFormRawData) => {
    try {
      setError(null);

      const { username, password } = formData;
      await loginUser(username, password);
      navigate('/');
    } catch (error) {
      setError('Login error occurred');
    }
  };

  return(
    <>
      <header className="mb-6">
        <h1 className="text-3xl font-medium">Přihlášení</h1>
      </header>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="mb-4">
          <InputField
            {...register('username')}
            label="Username"
            error={errors.username}
            placeholder="your-email@gmail.com"
            required
          />
        </div>

        <div className="mb-4">
          <InputField
            {...register('password')}
            type="password"
            label="Pasword"
            error={errors.password}
            required
          />

          {error !== null && (
            <div className="mt-3">
              <Notification
                color="red"
                label="Špatné přihlašovací údaje."
                type="error"
              />
            </div>
          )}
        </div>

        <div className="flex flex-col mt-8">
          <Button
            type="submit"
            label="Přihlásit"
            disabled={loading}
          />

          <div className="text-sm mt-8 text-center text-slate-500">
            Neznáte svoje heslo?
            {' '}
            <Link to={'/forgot-password'} className="underline">
              Vytvořit nové
            </Link>
          </div>
        </div>
      </form>
    </>
  );
};
