/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiLoginMutationVariables = Types.Exact<{
  username: Types.Scalars['String']['input'];
  password: Types.Scalars['String']['input'];
  organizationId: Types.Scalars['String']['input'];
}>;


export type ApiLoginMutation = { __typename?: 'Mutation', login: { __typename?: 'AuthToken', refreshToken: string, token: string, currentContext: { __typename?: 'AuthContext', id: string, marketId: string, marketName: string, orgId: string, role: Types.ApiRole, userId: string } } };


export const LoginDocument = gql`
    mutation Login($username: String!, $password: String!, $organizationId: String!) {
  login(username: $username, password: $password, organizationId: $organizationId) {
    refreshToken
    token
    currentContext {
      id
      marketId
      marketName
      orgId
      role
      userId
    }
  }
}
    `;
export type ApiLoginMutationFn = Apollo.MutationFunction<ApiLoginMutation, ApiLoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<ApiLoginMutation, ApiLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiLoginMutation, ApiLoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<ApiLoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<ApiLoginMutation, ApiLoginMutationVariables>;