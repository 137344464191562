import React, { InputHTMLAttributes, KeyboardEvent, cloneElement, forwardRef } from 'react';
import {
  ChangeHandler,
} from 'react-hook-form';
import { FieldError } from './interfaces';

export type InputType = (
  | 'text'
  | 'password'
  | 'number'
  | 'email'
  | 'tel'
  | 'search'
  | 'url'
  | 'date'
);

export type InputIconPosition = (
  | 'left'
  | 'right'
);

export interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  value?: string;
  label?: string;
  disabled?: boolean;
  icon?: JSX.Element;
  iconPosition?: InputIconPosition;
  placeholder?: string;
  error?: FieldError;
  type?: InputType;
  required?: boolean;
  postfix?: string;
  onEnter?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onChange?: ChangeHandler;
  onBlur?: ChangeHandler;
};

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(({
  label,
  error,
  placeholder,
  name,
  icon,
  iconPosition = 'right',
  value,
  postfix,
  required = false,
  type = 'text',
  onEnter,
  onChange,
  onBlur,
  ...rest
}, ref) => {
  const labelClass = `
    mb-1
    block
    text-slate-800
    text-sm
    font-normal
    leading-6
  `;

  const inputColorStateClass = error !== undefined
    ? 'border-red-700 text-red-700'
    : 'border-slate-300 text-slate-800';

  const iconPositionClass = iconPosition === 'right'
    ? 'pr-8'
    : 'pl-8';

  const inputClass = `
    block
    w-full
    px-3
    py-1.5
    text-sm
    leading-6
    border
    rounded-lg
    focus:border
    focus:border-purple-600
    focus-visible:outline-none
    disabled:bg-slate-100
    ${inputColorStateClass}
    ${icon !== undefined ? iconPositionClass : ''}
  `;

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && onEnter !== undefined) {
      onEnter(event);
    }
  };

  return (
    <div>
      {label !== undefined && (
        <label htmlFor={name} className={labelClass} >
          {label}
          {!required && (
            <span className="text-slate-400 ml-1">
              (optional)
            </span>
          )}
        </label>
      )}

      <div className="relative rounded-lg">
        <input
          {...rest}
          className={inputClass}
          defaultValue={value}
          id={name}
          name={name}
          placeholder={placeholder}
          required={required}
          type={type}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={handleKeyPress}
          ref={ref}
        />

        {postfix !== undefined && icon === undefined && (
          <span
            className={`
              pr-3
              text-sm
              absolute
              right-0
              top-1/2
              transform
              -translate-y-1/2
            `}
          >
            {postfix}
          </span>
        )}

        {icon !== undefined && (
          <div
            className={`
              absolute
              inset-y-0
              flex
              items-center
              pointer-events-none
              ${iconPosition === 'left' ? 'left-0 pl-3' : 'right-0 pr-3'}
            `}
          >
            <span className="text-slate-800 w-4">{cloneElement(icon, { className: 'w-4 h-4' })}</span>
          </div>
        )}
      </div>

      {error?.message !== undefined && (
        <p className="mt-1 text-xs text-red-700 leading-4">{error.message}</p>
      )}
    </div>
  );
});
