import { createFilters } from '@/app/common';

type VendorFilters = {
  search: string | null;
};

export const {
  FiltersProvider: VendorFiltersProvider,
  useFilters: useVendorFilters,
} = createFilters<VendorFilters>({
  filterId: 'vendors',
  normalizer: (filters) => ({
    search: (filters.search ?? '').length > 0 ? filters.search : null,
  }),
});
