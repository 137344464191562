import { useCallback } from 'react';
import { useRefreshTokenMutation } from './refresh-token.generated';
import { LoginPayload } from '../../hooks/auth/auth.interface';
import { useLazyCurrentUser } from '../get-current-user';

type UseRefreshTokenHook = [
  (refreshToken: string) => Promise<LoginPayload>,
];

export const useRefreshToken = (): UseRefreshTokenHook => {
  const [loginMutation] = useRefreshTokenMutation();
  const [fetchCurrentUser] = useLazyCurrentUser();

  const loginByRefreshToken = useCallback(async (
    refreshToken: string,
  ) => {
    const doLoginByRefreshToken = async () => {
      const { data, errors } = await loginMutation({
        variables: {
          refreshToken,
        },
      });

      // Error
      if (errors !== undefined && errors.length > 0) {
        const errorMessage = errors[0].message;
        throw new Error(errorMessage);
      }

      // No error, no data
      if (!data) {
        throw new Error('Login returned empty unexpected response.');
      }

      return data.refreshToken;
    };

    const loginData = await doLoginByRefreshToken();
    const { currentUser, currentWorkspace } = await fetchCurrentUser(loginData.token);
    return {
      ...loginData,
      currentUser,
      currentWorkspace,
    };
  }, []);

  return [
    loginByRefreshToken,
  ];
};
