import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainLayout } from './main-layout';
import { AuthContextsProvider, useAuth, useAuthToken } from '../common';
import { Loader } from '../components';

export const SecuredLayout: React.FC = () => {
  const navigate = useNavigate();
  const { hasCredentials } = useAuthToken();
  const { state: { currentUser, error }, loginByRefreshToken } = useAuth();

  const shouldLogin = useRef(true);
  useEffect(() => {
    // logout immediately if we dont have any tokens or user details
    if (!hasCredentials || error !== null) {
      navigate('/login');
      return;
    }

    // we have credentials but most probably invalid or expired tokens
    if (hasCredentials && !currentUser && shouldLogin.current) {
      shouldLogin.current = false;
      loginByRefreshToken();
    }
  }, [hasCredentials, currentUser, error]);

  // fallback if not redirected immediatelly to prevent rendering secured content
  if (!currentUser) {
    return <Loader />;
  }

  return (
    <AuthContextsProvider>
      <MainLayout />
    </AuthContextsProvider>
  );
};
