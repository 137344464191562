import { createContext, useContext } from 'react';

export type PaneItem = {
  id: string;
  title: string;
};

export type PaneSwitcherContextType = {
  activePane: string | null;
  panes: Record<string, PaneItem>;
  switchPane: (id: string) => void;
  register: (pane: PaneItem, active?: boolean) => void;
  unregister: (pane: PaneItem) => void;
};

export const PaneSwitcherContext = createContext<PaneSwitcherContextType | null>(null);

export const usePaneSwitcher = () => {
  const context = useContext(PaneSwitcherContext);
  if (!context) {
    throw new Error('usePaneSwitcher must be used within a PaneSwitcherProvider');
  }

  return context;
};
