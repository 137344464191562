import { useCreateVendorMutation } from './create-vendor.query.generated';
import { VendorFormData } from '../../interfaces';

type UseCreateVendorHook = {
  createVendor: (formData: VendorFormData) => Promise<void>;
  loading: boolean;
};

export const useCreateVendor = (): UseCreateVendorHook => {
  const [vendorMutation, { loading }] = useCreateVendorMutation();

  const createVendor = async (formData: VendorFormData) => {
    const doSubmit = async () => {
      const { data, errors } = await vendorMutation({
        variables: {
          ...formData,
          billingInfo: {
            businessId: formData.businessId,
            businessName: formData.businessName,
            vatNumber: formData.vatNumber,
          },
        },
      });

      // Error
      if (errors !== undefined && errors.length > 0) {
        const errorMessage = errors[0].message;
        throw new Error(errorMessage);
      }

      // No error, no data
      if (!data) {
        throw new Error('Server returned empty unexpected response.');
      }

      return data;
    };

    await doSubmit();
  };

  return {
    createVendor,
    loading,
  };
};
