/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiCreateActivityMutationVariables = Types.Exact<{
  campaignId: Types.Scalars['ID']['input'];
  toolId: Types.Scalars['ID']['input'];
  startDate: Types.Scalars['String']['input'];
  endDate: Types.InputMaybe<Types.Scalars['String']['input']>;
  orderedAmount: Types.Scalars['Int']['input'];
  customPrice: Types.Scalars['Float']['input'];
  targets: Types.InputMaybe<Array<Types.ApiCreateActivityTarget> | Types.ApiCreateActivityTarget>;
  creatives: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  note: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ApiCreateActivityMutation = { __typename?: 'Mutation', createActivity: { __typename?: 'Activity', id: string } };


export const CreateActivityDocument = gql`
    mutation CreateActivity($campaignId: ID!, $toolId: ID!, $startDate: String!, $endDate: String, $orderedAmount: Int!, $customPrice: Float!, $targets: [CreateActivityTarget!], $creatives: [String!], $note: String) {
  createActivity(
    campaignId: $campaignId
    advertisingToolId: $toolId
    startDate: $startDate
    endDate: $endDate
    orderedAmount: $orderedAmount
    customPrice: $customPrice
    targets: $targets
    creatives: $creatives
    note: $note
  ) {
    id
  }
}
    `;
export type ApiCreateActivityMutationFn = Apollo.MutationFunction<ApiCreateActivityMutation, ApiCreateActivityMutationVariables>;

/**
 * __useCreateActivityMutation__
 *
 * To run a mutation, you first call `useCreateActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActivityMutation, { data, loading, error }] = useCreateActivityMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      toolId: // value for 'toolId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      orderedAmount: // value for 'orderedAmount'
 *      customPrice: // value for 'customPrice'
 *      targets: // value for 'targets'
 *      creatives: // value for 'creatives'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useCreateActivityMutation(baseOptions?: Apollo.MutationHookOptions<ApiCreateActivityMutation, ApiCreateActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiCreateActivityMutation, ApiCreateActivityMutationVariables>(CreateActivityDocument, options);
      }
export type CreateActivityMutationHookResult = ReturnType<typeof useCreateActivityMutation>;
export type CreateActivityMutationResult = Apollo.MutationResult<ApiCreateActivityMutation>;
export type CreateActivityMutationOptions = Apollo.BaseMutationOptions<ApiCreateActivityMutation, ApiCreateActivityMutationVariables>;