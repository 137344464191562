import classNames from 'classnames';
import React from 'react';

interface ListContentBoxProps {
  children: React.ReactNode;
  fullHeight?: boolean;
};

export const ListContentBox: React.FC<ListContentBoxProps> = ({
  children,
  fullHeight = false,
}) => (
  <div className={classNames({
    'flex flex-col mt-4 space-y-2': true,
    'h-full': fullHeight,
  })}>
    {children}
  </div>
);
