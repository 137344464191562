import { ApolloError } from '@apollo/client';
import { mapCurrentUser } from './current-user.mapper';
import { mapCurrentWorkspace } from './current-workspace.mapper';
import { useCurrentUserSuspenseQuery } from './get-current-user.query.generated';
import { CurrentUser, CurrentWorkspace } from '../../interfaces';

type UseCurrentUserHook = {
  user?: CurrentUser | null;
  workspace?: CurrentWorkspace | null;
  error?: ApolloError;
};

export const useCurrentUser = (): UseCurrentUserHook => {
  const { data, error } = useCurrentUserSuspenseQuery();

  if (error) {
    return {
      error,
    };
  }

  if (data.me === null || data.workspace === null) {
    return {
      user: null,
      workspace: null,
    };
  }

  return {
    user: mapCurrentUser(data.me),
    workspace: mapCurrentWorkspace(data.workspace),
  };
};
