import { ColumnDef } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { Table, TableRow} from '@/app/components';
import { AgencyVendorItem, VendorItem } from '../../interfaces';

interface AgencyVendorListProps {
  children: React.ReactNode;
  vendors: (VendorItem | AgencyVendorItem)[];
};

export const AgencyVendorList: React.FC<AgencyVendorListProps> = ({
  children,
  vendors,
}) => {
  const columns = useMemo<ColumnDef<VendorItem | AgencyVendorItem>[]>(
    () => [
      {
        accessorKey: 'name',
        cell: (info) => info.getValue(),
        header: () => <span>Name</span>,
        size: 300,
      },
      {
        accessorKey: 'businessName',
        cell: (info) => info.getValue(),
        header: () => <span>Business name</span>,
        size: 300,
      },
      {
        accessorKey: 'vatNumber',
        header: () => <span>CID / VAT</span>,
        cell: ({ row }) => {
          return [
            row.original.businessId,
            row.original.vatNumber,
          ].filter(v => v!== null).join(' / ');
        },
      },
    ],
    [],
  );

  if (vendors.length === 0) {
    return children;
  }

  return (
    <>
      <Table<VendorItem | AgencyVendorItem> data={vendors} columns={columns}>
        {({ row }) => <TableRow key={row.id} row={row} />}
      </Table>
    </>
  );
};
