import React, { Suspense } from 'react';
import {
  Button,
  EmptySlate,
  ListContentBox,
  PageContentBox,
  PlusIcon,
} from '@/app/components';
import { CampaignFilter, CampaignsList } from './components';

export const CampaignsListPage: React.FC = () => {
  return (
    <PageContentBox fullHeight>
      <header className="flex justify-between mb-4">
        <h1 className="text-3xl">Campaigns</h1>
        <div>
          <Button
            label="Create Campaign"
            variant="primary"
            link={'create'}
            icon={PlusIcon}
            iconPosition="left"
          />
        </div>
      </header>

      <CampaignFilter />

      <ListContentBox fullHeight>
        <Suspense>
          <CampaignsList>
            <EmptySlate title="Campaigns" linkLabel="Create Campaign">
              <p>
                It seems like you haven't created any campaign yet. <br />
                Go ahead, and create one&hellip;
              </p>
            </EmptySlate>
          </CampaignsList>
        </Suspense>
      </ListContentBox>
    </PageContentBox>
  );
};
