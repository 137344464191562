import { useUpdateActivityMutation } from './update-activity.query.generated';
import { ActivityFormData } from '../../interfaces';

type UseUpdateActivityHook = {
  updateActivity: (
    id: string,
    formData: ActivityFormData,
  ) => Promise<{ id: string }>;
  loading: boolean;
};

export const useUpdateActivity = (): UseUpdateActivityHook => {
  const [activityMutation, { loading }] = useUpdateActivityMutation();

  const updateActivity = async (
    id: string,
    formData: ActivityFormData,
  ) => {
    const doSubmit = async () => {
      const { data, errors } = await activityMutation({
        variables: {
          ...formData,
          id,
        },
      });

      // Error
      if (errors !== undefined && errors.length > 0) {
        const errorMessage = errors[0].message;
        throw new Error(errorMessage);
      }

      // No error, no data
      if (!data) {
        throw new Error('Server returned empty unexpected response.');
      }

      return data;
    };

    const result = await doSubmit();
    return {
      id: result.updateActivity.id,
    };
  };

  return {
    updateActivity,
    loading,
  };
};
