import React, { ReactNode, useState } from 'react';
import { SectionHeading } from '../section-heading';

interface ToggleSectionProps {
  title: string;
  children: ReactNode;
  margin?: string;
  padding?: string;
};

export const ToggleSection: React.FC<ToggleSectionProps> = ({ title, children, padding, margin }) => {
  const [isHidden, setIsHidden] = useState(false);

  const toggleVisibility = () => {
    setIsHidden(!isHidden);
  };

  return (
    <section
      className={`
        toggle-section
        ${isHidden ? 'border-b' : ''}
        ${padding !== undefined ? padding : ''}
        ${margin !== undefined ? margin : ''}
      `}
    >
      <header className="flex justify-between items-center mb-4">
        <SectionHeading heading={title} />
        <button
          className="text-purple-600 text-sm"
          onClick={toggleVisibility}
          type="button"
        >
          {isHidden ? 'Show' : 'Hide'}
        </button>
      </header>
      {!isHidden && <div className="toggle-content">{children}</div>}
    </section>
  );
};
