import React, { Suspense } from 'react';
import { useFormContext } from 'react-hook-form';
import { Loader, SelectableListField } from '@/app/components';
import { UserFormRawData } from './user-form.interface';
import { useMarkets } from '../../api';

export const UserMarketField = () => {
  return (
    <Suspense fallback={<UserMarketFieldSkeleton />}>
      <UserMarketLoader />
    </Suspense>
  );
};

export const UserMarketLoader: React.FC = () => {
  const { control } = useFormContext<UserFormRawData>();
  const { markets } = useMarkets();

  return (
    <SelectableListField
      control={control}
      name="markets"
      label="markets"
      items={markets.map(market => ({
        id: market.id,
        title: market.name,
      }))}
      rules={{
        validate: (markets) => {
          return markets.length === 0
            ? 'You have to select at least one market'
            : true;
        },
      }}
    />
  );
};

export const UserMarketFieldSkeleton = () => (
  <Loader />
);
