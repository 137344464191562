/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiCampaignDetailFragment = { __typename?: 'Campaign', id: string, name: string, startDate: string | null, endDate: string | null, status: Types.ApiCampaignStatus, primaryContact: { __typename?: 'IdentifiedUser', id: string, name: string }, secondaryContact: { __typename?: 'IdentifiedUser', id: string, name: string } | null, vendor: { __typename?: 'IdentifiedVendor', id: string, name: string }, agency: { __typename?: 'IdentifiedAgency', id: string, name: string } | null, salesPerson: { __typename?: 'IdentifiedUser', id: string, name: string }, metrics: { __typename?: 'Metrics', impressions: number, views: number, clicks: number, visibility: number, ctr: number, osp: number }, activities: { __typename?: 'CampaignActivitiesResult', total: number, items: Array<{ __typename?: 'LeanActivity', id: string, campaignId: string, name: string, icon: Types.ApiAdvertisingToolIcon | null, status: Types.ApiActivityStatus, statusNote: string | null, startDate: string, endDate: string | null, rateType: Types.ApiAdvertisingToolRate, orderedAmount: number, unitPrice: number, totalPrice: number, metrics: { __typename?: 'Metrics', impressions: number, views: number, clicks: number, visibility: number, ctr: number, osp: number }, creatives: { __typename?: 'CreativeResult', total: number, items: Array<{ __typename?: 'Creative', id: string, name: string, status: Types.ApiCreativeStatus, statusNote: string | null, destinationType: Types.ApiCreativeDestinationTypes, destinationUrl: string, destinationId: string | null, format: { __typename?: 'IdentifiedCreativeFormat', id: string, name: string }, contents: Array<{ __typename?: 'CreativeField', fieldId: string, type: Types.ApiCreativeFieldType, slug: string, name: string, position: number, value: string | null, mediaValue: { __typename?: 'CreativeFieldMediaValue', id: string, name: string, size: number, mimeType: string, path: string, url: string } | null }> }> } }> } };

export type ApiCampaignActivityFragment = { __typename?: 'LeanActivity', id: string, campaignId: string, name: string, icon: Types.ApiAdvertisingToolIcon | null, status: Types.ApiActivityStatus, statusNote: string | null, startDate: string, endDate: string | null, rateType: Types.ApiAdvertisingToolRate, orderedAmount: number, unitPrice: number, totalPrice: number, metrics: { __typename?: 'Metrics', impressions: number, views: number, clicks: number, visibility: number, ctr: number, osp: number }, creatives: { __typename?: 'CreativeResult', total: number, items: Array<{ __typename?: 'Creative', id: string, name: string, status: Types.ApiCreativeStatus, statusNote: string | null, destinationType: Types.ApiCreativeDestinationTypes, destinationUrl: string, destinationId: string | null, format: { __typename?: 'IdentifiedCreativeFormat', id: string, name: string }, contents: Array<{ __typename?: 'CreativeField', fieldId: string, type: Types.ApiCreativeFieldType, slug: string, name: string, position: number, value: string | null, mediaValue: { __typename?: 'CreativeFieldMediaValue', id: string, name: string, size: number, mimeType: string, path: string, url: string } | null }> }> } };

export type ApiCampaignActivityCreativeFragment = { __typename?: 'Creative', id: string, name: string, status: Types.ApiCreativeStatus, statusNote: string | null, destinationType: Types.ApiCreativeDestinationTypes, destinationUrl: string, destinationId: string | null, format: { __typename?: 'IdentifiedCreativeFormat', id: string, name: string }, contents: Array<{ __typename?: 'CreativeField', fieldId: string, type: Types.ApiCreativeFieldType, slug: string, name: string, position: number, value: string | null, mediaValue: { __typename?: 'CreativeFieldMediaValue', id: string, name: string, size: number, mimeType: string, path: string, url: string } | null }> };

export type ApiCampaignActivityCreativeContentFragment = { __typename?: 'CreativeField', fieldId: string, type: Types.ApiCreativeFieldType, slug: string, name: string, position: number, value: string | null, mediaValue: { __typename?: 'CreativeFieldMediaValue', id: string, name: string, size: number, mimeType: string, path: string, url: string } | null };

export type ApiCampaignDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ApiCampaignDetailsQuery = { __typename?: 'Query', campaign: { __typename?: 'Campaign', id: string, name: string, startDate: string | null, endDate: string | null, status: Types.ApiCampaignStatus, primaryContact: { __typename?: 'IdentifiedUser', id: string, name: string }, secondaryContact: { __typename?: 'IdentifiedUser', id: string, name: string } | null, vendor: { __typename?: 'IdentifiedVendor', id: string, name: string }, agency: { __typename?: 'IdentifiedAgency', id: string, name: string } | null, salesPerson: { __typename?: 'IdentifiedUser', id: string, name: string }, metrics: { __typename?: 'Metrics', impressions: number, views: number, clicks: number, visibility: number, ctr: number, osp: number }, activities: { __typename?: 'CampaignActivitiesResult', total: number, items: Array<{ __typename?: 'LeanActivity', id: string, campaignId: string, name: string, icon: Types.ApiAdvertisingToolIcon | null, status: Types.ApiActivityStatus, statusNote: string | null, startDate: string, endDate: string | null, rateType: Types.ApiAdvertisingToolRate, orderedAmount: number, unitPrice: number, totalPrice: number, metrics: { __typename?: 'Metrics', impressions: number, views: number, clicks: number, visibility: number, ctr: number, osp: number }, creatives: { __typename?: 'CreativeResult', total: number, items: Array<{ __typename?: 'Creative', id: string, name: string, status: Types.ApiCreativeStatus, statusNote: string | null, destinationType: Types.ApiCreativeDestinationTypes, destinationUrl: string, destinationId: string | null, format: { __typename?: 'IdentifiedCreativeFormat', id: string, name: string }, contents: Array<{ __typename?: 'CreativeField', fieldId: string, type: Types.ApiCreativeFieldType, slug: string, name: string, position: number, value: string | null, mediaValue: { __typename?: 'CreativeFieldMediaValue', id: string, name: string, size: number, mimeType: string, path: string, url: string } | null }> }> } }> } } };

export const CampaignActivityCreativeContentFragmentDoc = gql`
    fragment CampaignActivityCreativeContent on CreativeField {
  fieldId
  type
  slug
  name
  position
  value
  mediaValue {
    id
    name
    size
    mimeType
    path
    url
  }
}
    `;
export const CampaignActivityCreativeFragmentDoc = gql`
    fragment CampaignActivityCreative on Creative {
  id
  name
  status
  statusNote
  format {
    id
    name
  }
  contents {
    ...CampaignActivityCreativeContent
  }
  destinationType
  destinationUrl
  destinationId
}
    ${CampaignActivityCreativeContentFragmentDoc}`;
export const CampaignActivityFragmentDoc = gql`
    fragment CampaignActivity on LeanActivity {
  id
  campaignId
  name
  icon
  status
  statusNote
  startDate
  endDate
  rateType
  orderedAmount
  unitPrice
  totalPrice
  metrics {
    impressions
    views
    clicks
    visibility
    ctr
    osp
  }
  creatives {
    total
    items {
      ...CampaignActivityCreative
    }
  }
}
    ${CampaignActivityCreativeFragmentDoc}`;
export const CampaignDetailFragmentDoc = gql`
    fragment CampaignDetail on Campaign {
  id
  name
  startDate
  endDate
  status
  primaryContact {
    id
    name
  }
  secondaryContact {
    id
    name
  }
  vendor {
    id
    name
  }
  agency {
    id
    name
  }
  salesPerson {
    id
    name
  }
  metrics {
    impressions
    views
    clicks
    visibility
    ctr
    osp
  }
  activities {
    total
    items {
      ...CampaignActivity
    }
  }
}
    ${CampaignActivityFragmentDoc}`;
export const CampaignDetailsDocument = gql`
    query CampaignDetails($id: ID!) {
  campaign(id: $id) {
    ...CampaignDetail
  }
}
    ${CampaignDetailFragmentDoc}`;

/**
 * __useCampaignDetailsQuery__
 *
 * To run a query within a React component, call `useCampaignDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCampaignDetailsQuery(baseOptions: Apollo.QueryHookOptions<ApiCampaignDetailsQuery, ApiCampaignDetailsQueryVariables> & ({ variables: ApiCampaignDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiCampaignDetailsQuery, ApiCampaignDetailsQueryVariables>(CampaignDetailsDocument, options);
      }
export function useCampaignDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiCampaignDetailsQuery, ApiCampaignDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiCampaignDetailsQuery, ApiCampaignDetailsQueryVariables>(CampaignDetailsDocument, options);
        }
export function useCampaignDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ApiCampaignDetailsQuery, ApiCampaignDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiCampaignDetailsQuery, ApiCampaignDetailsQueryVariables>(CampaignDetailsDocument, options);
        }
export type CampaignDetailsQueryHookResult = ReturnType<typeof useCampaignDetailsQuery>;
export type CampaignDetailsLazyQueryHookResult = ReturnType<typeof useCampaignDetailsLazyQuery>;
export type CampaignDetailsSuspenseQueryHookResult = ReturnType<typeof useCampaignDetailsSuspenseQuery>;
export type CampaignDetailsQueryResult = Apollo.QueryResult<ApiCampaignDetailsQuery, ApiCampaignDetailsQueryVariables>;