/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
import * as Types from '../../../../common/api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiUpdateAgencyMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  name: Types.Scalars['String']['input'];
  billingInfo: Types.ApiUpdateCompanyBillingInput;
  defaultTrafficker: Types.InputMaybe<Types.Scalars['ID']['input']>;
  defaultSalesPerson: Types.InputMaybe<Types.Scalars['ID']['input']>;
  vendors: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
}>;


export type ApiUpdateAgencyMutation = { __typename?: 'Mutation', updateAgency: { __typename?: 'Agency', id: string } };


export const UpdateAgencyDocument = gql`
    mutation UpdateAgency($id: ID!, $name: String!, $billingInfo: UpdateCompanyBillingInput!, $defaultTrafficker: ID, $defaultSalesPerson: ID, $vendors: [String!]) {
  updateAgency(
    id: $id
    name: $name
    billingInfo: $billingInfo
    defaultTrafficker: $defaultTrafficker
    defaultSalesPerson: $defaultSalesPerson
    vendors: $vendors
  ) {
    id
  }
}
    `;
export type ApiUpdateAgencyMutationFn = Apollo.MutationFunction<ApiUpdateAgencyMutation, ApiUpdateAgencyMutationVariables>;

/**
 * __useUpdateAgencyMutation__
 *
 * To run a mutation, you first call `useUpdateAgencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAgencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAgencyMutation, { data, loading, error }] = useUpdateAgencyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      billingInfo: // value for 'billingInfo'
 *      defaultTrafficker: // value for 'defaultTrafficker'
 *      defaultSalesPerson: // value for 'defaultSalesPerson'
 *      vendors: // value for 'vendors'
 *   },
 * });
 */
export function useUpdateAgencyMutation(baseOptions?: Apollo.MutationHookOptions<ApiUpdateAgencyMutation, ApiUpdateAgencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiUpdateAgencyMutation, ApiUpdateAgencyMutationVariables>(UpdateAgencyDocument, options);
      }
export type UpdateAgencyMutationHookResult = ReturnType<typeof useUpdateAgencyMutation>;
export type UpdateAgencyMutationResult = Apollo.MutationResult<ApiUpdateAgencyMutation>;
export type UpdateAgencyMutationOptions = Apollo.BaseMutationOptions<ApiUpdateAgencyMutation, ApiUpdateAgencyMutationVariables>;