import React, { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { SpinnerIcon } from '../icons';

export type ButtonCircleType = (
  | 'button'
  | 'submit'
  | 'reset'
);

export type ButtonCircleVariant = (
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'quaternary'
  | 'quinary'
);

export type ButtonCircleSize = (
  | 'xs'
  | 'md'
  | 'lg'
);

// Common properties for all buttons
interface ButtonCircleBaseProps {
  label: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  variant?: ButtonCircleVariant;
  type?: ButtonCircleType;
  loading?: boolean;
  size?: ButtonCircleSize;
};

// Properties for a button with an onClick callback
interface ButtonCircleWithOnClick extends ButtonCircleBaseProps {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  link?: never;
};

// Properties for a button with an href link
interface ButtonCircleWithHref extends ButtonCircleBaseProps {
  onClick?: never;
  link: string;
};

// Union type to define button properties
export type ButtonCircleProps = ButtonCircleWithOnClick | ButtonCircleWithHref;

export const ButtonCircle: React.FC<ButtonCircleProps> = ({
  label,
  link,
  icon,
  variant = 'primary',
  type = 'button',
  loading = false,
  disabled = loading || false,
  size = 'lg',
  onClick,
  ...rest
}) => {
  const navigate = useNavigate();

  const buttonTypeClass = {
    primary: 'btn-primary',
    secondary: 'btn-secondary',
    tertiary: 'btn-tertiary',
    quaternary: 'btn-quaternary',
    quinary: 'btn-quinary',
  }[variant];

  const buttonSizeClass =
    size === 'xs'
      ? 'w-8 h-8'
      : size === 'md'
        ? 'w-9 h-9'
        : 'w-10 h-10';

  const buttonClass = `
    circle
    inline-flex
    items-center
    justify-center
    flex-shrink-0
    text-sm
    font-medium
    rounded-lg
    leading-6
    px-2
    py-2
    focus:outline-none
    disabled:cursor-not-allowed
    transition-colors
    duration-500
    relative
    z-10
    ${buttonSizeClass}
    ${buttonTypeClass}
  `;

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (onClick !== undefined) {
      onClick(event);
    } else if (link !== undefined) {
      navigate(link);
    } else {
      throw new Error('Either onClick or link is required, but not both.');
    }
  };

  return (
    <button
      {...rest}
      type={type}
      className={buttonClass}
      disabled={disabled}
      aria-label={label}
      onClick={handleClick}
    >
      <span>{loading ? SpinnerIcon : icon}</span>
    </button>
  );
};
