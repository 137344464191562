import React from 'react';
import {
  Button,
  InfroStrip,
  PencilIcon,
  RoundedFrame,
  SectionHeading,
} from '@/app/components';
import { useAgencyDetail } from '../../api';
import { AgencyVendorList } from '../agency-vendor-list';
import { UsersList } from '../users-list';

interface AgencyDetailProps {
  agencyId: string;
};

export const AgencyDetail: React.FC<AgencyDetailProps> = ({
  agencyId,
}) => {
  const {
    agency,
    agencyUsers,
    agencyVendors,
  } = useAgencyDetail(agencyId);

  return (
    <>
      <section className="flex justify-between mb-5">
        <header>
          <h1 className="text-3xl font-medium mb-4 flex items-center gap-4">
            {agency.name}
          </h1>

          <div className="text-sm leading-6">
            <p>{agency.businessName}</p>
          </div>
        </header>

        <div className="flex space-x-4 items-start">
          <Button
            link="edit"
            label="Edit Agency"
            variant="primary"
            icon={PencilIcon}
          />
        </div>
      </section>

      <RoundedFrame margin="mb-5">
        <dl className="flex flex-col gap-y-1 text-sm leading-6 w-1/3 pr-3">
          <div className="flex space-x-2">
            <dt>Company ID:</dt>
            <dd className="font-medium">{agency.businessId}</dd>
          </div>

          <div className="flex space-x-2">
            <dt>VAT:</dt>
            <dd className="font-medium">{agency.vatNumber ?? 'none'}</dd>
          </div>
        </dl>

        <dl className="flex flex-col gap-y-1 text-sm leading-6 w-1/3 pr-3">
          <div className="flex space-x-2">
            <dt>Default Sales Person:</dt>
            <dd className="font-medium">{agency.defaultSalesPerson?.name ?? 'none'}</dd>
          </div>

          <div className="flex space-x-2">
            <dt>Default Trafficker:</dt>
            <dd className="font-medium">{agency.defaultTrafficker?.name ?? 'none'}</dd>
          </div>
        </dl>
      </RoundedFrame>

      <section className="mb-5">
        <header className="mb-4">
          <SectionHeading heading="Advertisers" />
        </header>

        <AgencyVendorList vendors={agencyVendors}>
          <div>
            <InfroStrip label="Agency does not manage any advertisers yet." type="info" />
          </div>
        </AgencyVendorList>
      </section>

      <UsersList
        headline="Users"
        users={agencyUsers}
      >
        <InfroStrip label="Agency does not have any users yet." type="info" />
      </UsersList>
    </>
  );
};
