/* eslint-disable */
/* WARNING: THIS FILE IS GENERATED, DON'T EDIT */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: string; output: string; }
  JSON: { input: unknown; output: unknown; }
};

export type ApiActivity = {
  __typename?: 'Activity';
  advertisingTool: ApiAdvertisingTool;
  campaignId: Scalars['ID']['output'];
  creatives: ApiCreativeResult;
  currencyCode: ApiCurrency;
  currencyRate: Scalars['Float']['output'];
  endDate: Maybe<Scalars['DateTime']['output']>;
  icon: Maybe<ApiAdvertisingToolIcon>;
  id: Scalars['ID']['output'];
  metrics: ApiMetrics;
  name: Scalars['String']['output'];
  orderedAmount: Scalars['Int']['output'];
  rateType: ApiAdvertisingToolRate;
  startDate: Scalars['DateTime']['output'];
  status: ApiActivityStatus;
  statusNote: Maybe<Scalars['String']['output']>;
  targets: Array<ApiActivityTarget>;
  totalPrice: Scalars['Float']['output'];
  unitPrice: Scalars['Float']['output'];
};

export type ApiActivityResult = {
  __typename?: 'ActivityResult';
  items: Array<ApiLeanActivity>;
  total: Scalars['Int']['output'];
};

export enum ApiActivityStatus {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
  Draft = 'DRAFT',
  Incomplete = 'INCOMPLETE',
  Online = 'ONLINE',
  Paused = 'PAUSED',
  PendingApproval = 'PENDING_APPROVAL',
  Ready = 'READY',
  Rejected = 'REJECTED'
}

export type ApiActivityTarget = {
  __typename?: 'ActivityTarget';
  targetId: Scalars['ID']['output'];
  valueLimit: Scalars['Int']['output'];
  values: Array<ApiActivityTargetValue>;
};

export type ApiActivityTargetValue = {
  __typename?: 'ActivityTargetValue';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

export type ApiActivityTargetValueInput = {
  id: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};

export type ApiAdTargetDto = {
  __typename?: 'AdTargetDto';
  id: Scalars['ID']['output'];
  initialValues: Array<ApiAdTargetValue>;
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  searchValues: Array<ApiAdTargetValue>;
  type: ApiAdTargetType;
  valueLimit: Scalars['Int']['output'];
  values: Array<ApiAdTargetValue>;
};


export type ApiAdTargetDtoInitialValuesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type ApiAdTargetDtoSearchValuesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  term: Scalars['String']['input'];
};

export enum ApiAdTargetType {
  Category = 'CATEGORY',
  Generic = 'GENERIC'
}

export type ApiAdTargetValue = {
  __typename?: 'AdTargetValue';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

export type ApiAdTargetValueInput = {
  id: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};

export type ApiAddress = {
  __typename?: 'Address';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  region: Scalars['String']['output'];
  street: Scalars['String']['output'];
  street2: Maybe<Scalars['String']['output']>;
};

export type ApiAdvertisingCategory = {
  __typename?: 'AdvertisingCategory';
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isInternal: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  summary: Maybe<Scalars['String']['output']>;
  tools: Array<ApiAdvertisingLeanTool>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ApiAdvertisingCategoryResult = {
  __typename?: 'AdvertisingCategoryResult';
  items: Array<ApiAdvertisingLeanCategory>;
  total: Scalars['Int']['output'];
};

export type ApiAdvertisingLeanCategory = {
  __typename?: 'AdvertisingLeanCategory';
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isInternal: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  summary: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ApiAdvertisingLeanTool = {
  __typename?: 'AdvertisingLeanTool';
  allowedMarkets: Array<ApiAdvertisingToolAllowedMarket>;
  categories: Array<ApiAdvertisingToolCategory>;
  createdAt: Scalars['DateTime']['output'];
  creativeFormats: Array<ApiAdvertisingToolCreativeFormat>;
  description: Maybe<Scalars['String']['output']>;
  icon: Maybe<ApiAdvertisingToolIcon>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  position: Maybe<Scalars['Int']['output']>;
  type: ApiAdvertisingToolType;
  updatedAt: Scalars['DateTime']['output'];
};

export type ApiAdvertisingLibraryItem = {
  __typename?: 'AdvertisingLibraryItem';
  id: Scalars['ID']['output'];
  localization: Maybe<Scalars['JSON']['output']>;
  name: Scalars['String']['output'];
  tools: Array<ApiAdvertisingLibraryTool>;
};

export type ApiAdvertisingLibraryTool = {
  __typename?: 'AdvertisingLibraryTool';
  currencyCode: ApiCurrency;
  currencyRate: Scalars['Float']['output'];
  description: Maybe<Scalars['String']['output']>;
  icon: Maybe<ApiAdvertisingToolIcon>;
  id: Scalars['ID']['output'];
  localization: Maybe<Scalars['JSON']['output']>;
  name: Scalars['String']['output'];
  rateType: ApiAdvertisingToolRate;
  targets: Array<ApiIdentifiedTarget>;
  unitPrice: Scalars['Float']['output'];
};

export type ApiAdvertisingTool = {
  __typename?: 'AdvertisingTool';
  allowedMarkets: Array<ApiAdvertisingToolAllowedMarket>;
  categories: Array<ApiAdvertisingToolCategory>;
  config: ApiToolConfigDto;
  createdAt: Scalars['DateTime']['output'];
  creativeFormats: Array<ApiAdvertisingToolCreativeFormat>;
  description: Maybe<Scalars['String']['output']>;
  features: ApiToolFeaturesDto;
  icon: Maybe<ApiAdvertisingToolIcon>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  position: Maybe<Scalars['Int']['output']>;
  previewImage: Maybe<ApiMediaFileDto>;
  targets: Array<ApiAdvertisingToolTarget>;
  type: ApiAdvertisingToolType;
  updatedAt: Scalars['DateTime']['output'];
};

export type ApiAdvertisingToolAllowedMarket = {
  __typename?: 'AdvertisingToolAllowedMarket';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ApiAdvertisingToolCategory = {
  __typename?: 'AdvertisingToolCategory';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ApiAdvertisingToolCreativeFormat = {
  __typename?: 'AdvertisingToolCreativeFormat';
  description: Maybe<Scalars['String']['output']>;
  fields: Array<ApiCreativeFormatFieldDto>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum ApiAdvertisingToolIcon {
  BannerCarouselHpGreen = 'BANNER_CAROUSEL_HP_GREEN',
  BannerCategoryGreen = 'BANNER_CATEGORY_GREEN',
  BannerSponsoredCategoryGreen = 'BANNER_SPONSORED_CATEGORY_GREEN',
  BannerUnderCarousel = 'BANNER_UNDER_CAROUSEL',
  SponsoredProductCart = 'SPONSORED_PRODUCT_CART',
  SponsoredProductCartPlus = 'SPONSORED_PRODUCT_CART_PLUS',
  SponsoredProductCategoryPosition_1Green = 'SPONSORED_PRODUCT_CATEGORY_POSITION_1_GREEN',
  SponsoredProductCategoryPosition_1Purple = 'SPONSORED_PRODUCT_CATEGORY_POSITION_1_PURPLE',
  SponsoredProductCategoryPosition_3Green = 'SPONSORED_PRODUCT_CATEGORY_POSITION_3_GREEN',
  SponsoredProductCategoryPosition_3Purple = 'SPONSORED_PRODUCT_CATEGORY_POSITION_3_PURPLE',
  SponsoredProductCategoryPosition_4Green = 'SPONSORED_PRODUCT_CATEGORY_POSITION_4_GREEN',
  SponsoredProductCategoryPosition_4Purple = 'SPONSORED_PRODUCT_CATEGORY_POSITION_4_PURPLE',
  SponsoredProductHpGreen = 'SPONSORED_PRODUCT_HP_GREEN',
  SponsoredProductHpPurple = 'SPONSORED_PRODUCT_HP_PURPLE',
  SponsoredProductProductDetailGreen = 'SPONSORED_PRODUCT_PRODUCT_DETAIL_GREEN',
  SponsoredProductProductDetailPurple = 'SPONSORED_PRODUCT_PRODUCT_DETAIL_PURPLE',
  SponsoredProductSearch = 'SPONSORED_PRODUCT_SEARCH'
}

export enum ApiAdvertisingToolRate {
  CostPerClick = 'COST_PER_CLICK',
  CostPerMeasurement = 'COST_PER_MEASUREMENT',
  FlatRate = 'FLAT_RATE'
}

export type ApiAdvertisingToolResult = {
  __typename?: 'AdvertisingToolResult';
  items: Array<ApiAdvertisingLeanTool>;
  total: Scalars['Int']['output'];
};

export type ApiAdvertisingToolTarget = {
  __typename?: 'AdvertisingToolTarget';
  id: Scalars['ID']['output'];
  initialValues: Array<ApiAdTargetValue>;
  name: Scalars['String']['output'];
  searchable: Scalars['Boolean']['output'];
  valueLimit: Scalars['Int']['output'];
  values: Array<ApiAdTargetValue>;
};


export type ApiAdvertisingToolTargetInitialValuesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export enum ApiAdvertisingToolType {
  Display = 'DISPLAY',
  SponsoredProduct = 'SPONSORED_PRODUCT'
}

export enum ApiAdvertisingToolsPaginationLimit {
  Limit_10 = 'LIMIT_10',
  Limit_20 = 'LIMIT_20',
  Limit_50 = 'LIMIT_50'
}

export type ApiAgency = {
  __typename?: 'Agency';
  billingInfo: ApiCompanyBilling;
  defaultSalesPerson: Maybe<ApiLeanUser>;
  defaultTrafficker: Maybe<ApiLeanUser>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  users: ApiAgencyUserResult;
  vendors: ApiAgencyVendorResult;
};


export type ApiAgencyUsersArgs = {
  limit?: Scalars['Int']['input'];
  page?: Scalars['Int']['input'];
  search: InputMaybe<Scalars['String']['input']>;
};


export type ApiAgencyVendorsArgs = {
  limit?: Scalars['Int']['input'];
  page?: Scalars['Int']['input'];
};

export type ApiAgencyResult = {
  __typename?: 'AgencyResult';
  items: Array<ApiLeanAgency>;
  total: Scalars['Int']['output'];
};

export type ApiAgencyUser = {
  __typename?: 'AgencyUser';
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  locale: Maybe<ApiLocale>;
  name: Scalars['String']['output'];
  timezone: Maybe<ApiTimezone>;
  username: Scalars['String']['output'];
};

export type ApiAgencyUserResult = {
  __typename?: 'AgencyUserResult';
  items: Array<ApiAgencyUser>;
  total: Scalars['Int']['output'];
};

export type ApiAgencyVendorResult = {
  __typename?: 'AgencyVendorResult';
  items: Array<ApiLeanVendor>;
  total: Scalars['Int']['output'];
};

export type ApiAppMetadata = {
  __typename?: 'AppMetadata';
  currency: ApiCurrency;
  id: Scalars['String']['output'];
  locale: ApiLocale;
  timezone: ApiTimezone;
};

export type ApiAssignUserMarketDto = {
  marketId: Scalars['ID']['input'];
  role: ApiRole;
};

export type ApiAuthContext = {
  __typename?: 'AuthContext';
  companies: Array<Scalars['String']['output']>;
  contextId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  marketId: Scalars['String']['output'];
  marketName: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
  role: ApiRole;
  userId: Scalars['String']['output'];
};

export type ApiAuthToken = {
  __typename?: 'AuthToken';
  currentContext: ApiAuthContext;
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type ApiAvertisingdToolConfigInput = {
  auction: Scalars['Boolean']['input'];
  currencyCode: ApiCurrency;
  currencyRate: Scalars['Float']['input'];
  minimalBudget: Scalars['Int']['input'];
  price: Scalars['Float']['input'];
  rateType: ApiAdvertisingToolRate;
};

export type ApiAvertisingdToolFeaturesInput = {
  autoPauseFlights: InputMaybe<Scalars['Boolean']['input']>;
  checkTargettingAvailability: InputMaybe<Scalars['Boolean']['input']>;
  minimalDaysBeforeStart: InputMaybe<Scalars['Int']['input']>;
  useDefaultPositionValue: InputMaybe<Scalars['Boolean']['input']>;
};

export type ApiBrand = {
  __typename?: 'Brand';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ApiBrandResult = {
  __typename?: 'BrandResult';
  items: Array<ApiBrand>;
  total: Scalars['Int']['output'];
};

export type ApiCampaign = {
  __typename?: 'Campaign';
  activities: ApiCampaignActivitiesResult;
  agency: Maybe<ApiIdentifiedAgency>;
  endDate: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  metrics: ApiMetrics;
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  primaryContact: ApiIdentifiedUser;
  salesPerson: ApiIdentifiedUser;
  secondaryContact: Maybe<ApiIdentifiedUser>;
  startDate: Maybe<Scalars['DateTime']['output']>;
  status: ApiCampaignStatus;
  vendor: ApiIdentifiedVendor;
};

export type ApiCampaignActivitiesResult = {
  __typename?: 'CampaignActivitiesResult';
  items: Array<ApiLeanActivity>;
  total: Scalars['Int']['output'];
};

export type ApiCampaignResult = {
  __typename?: 'CampaignResult';
  items: Array<ApiLeanCampaign>;
  total: Scalars['Int']['output'];
};

export enum ApiCampaignStatus {
  Closed = 'CLOSED',
  Draft = 'DRAFT',
  Incomplete = 'INCOMPLETE',
  Online = 'ONLINE',
  Ready = 'READY'
}

export type ApiCompanyBilling = {
  __typename?: 'CompanyBilling';
  businessId: Scalars['String']['output'];
  businessName: Scalars['String']['output'];
  vatNumber: Maybe<Scalars['String']['output']>;
};

export type ApiCompanyContact = {
  __typename?: 'CompanyContact';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  email: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  region: Scalars['String']['output'];
  street: Scalars['String']['output'];
  street2: Maybe<Scalars['String']['output']>;
};

export type ApiCreateActivityTarget = {
  targetId: Scalars['ID']['input'];
  values: Array<ApiActivityTargetValueInput>;
};

export type ApiCreateAddressInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  region: Scalars['String']['input'];
  street: Scalars['String']['input'];
  street2: InputMaybe<Scalars['String']['input']>;
};

export type ApiCreateCompanyBillingInput = {
  businessId: Scalars['String']['input'];
  businessName: Scalars['String']['input'];
  vatNumber: InputMaybe<Scalars['String']['input']>;
};

export type ApiCreateCompanyContactInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  email: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  region: Scalars['String']['input'];
  street: Scalars['String']['input'];
  street2: InputMaybe<Scalars['String']['input']>;
};

export type ApiCreateCreativeField = {
  fieldId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  position: Scalars['Int']['input'];
  slug: Scalars['String']['input'];
  type: ApiCreativeFieldType;
  value: InputMaybe<Scalars['String']['input']>;
};

export type ApiCreative = {
  __typename?: 'Creative';
  contents: Array<ApiCreativeField>;
  destination: ApiCreativeDestinationDto;
  destinationId: Maybe<Scalars['ID']['output']>;
  destinationType: ApiCreativeDestinationTypes;
  destinationUrl: Scalars['String']['output'];
  format: ApiIdentifiedCreativeFormat;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  status: ApiCreativeStatus;
  statusNote: Maybe<Scalars['String']['output']>;
  vendor: ApiIdentifiedVendor;
};

export type ApiCreativeDestination = {
  __typename?: 'CreativeDestination';
  type: ApiCreativeDestinationTypes;
  values: Array<ApiCreativeDestinationValue>;
};

export type ApiCreativeDestinationDetails = {
  product: InputMaybe<ApiCreativeDestinationProduct>;
  type: ApiCreativeDestinationTypes;
  url: Scalars['String']['input'];
};

export type ApiCreativeDestinationDto = {
  __typename?: 'CreativeDestinationDto';
  product: Maybe<ApiCreativeDestinationProductDto>;
  type: ApiCreativeDestinationTypes;
  url: Scalars['String']['output'];
};

export type ApiCreativeDestinationProduct = {
  catalog: Scalars['ID']['input'];
  externalId: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  title: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type ApiCreativeDestinationProductDto = {
  __typename?: 'CreativeDestinationProductDto';
  catalog: Scalars['ID']['output'];
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum ApiCreativeDestinationTypes {
  Custom = 'CUSTOM',
  Lp = 'LP',
  Product = 'PRODUCT'
}

export type ApiCreativeDestinationValue = {
  __typename?: 'CreativeDestinationValue';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

export type ApiCreativeField = {
  __typename?: 'CreativeField';
  fieldId: Scalars['ID']['output'];
  mediaValue: Maybe<ApiCreativeFieldMediaValue>;
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  type: ApiCreativeFieldType;
  value: Maybe<Scalars['String']['output']>;
};

export type ApiCreativeFieldMediaValue = {
  __typename?: 'CreativeFieldMediaValue';
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  size: Scalars['Int']['output'];
  url: Scalars['String']['output'];
};

export enum ApiCreativeFieldType {
  Image = 'IMAGE',
  ImageConversion = 'IMAGE_CONVERSION',
  Number = 'NUMBER',
  Product = 'PRODUCT',
  Sku = 'SKU',
  Text = 'TEXT'
}

export type ApiCreativeFormat = {
  __typename?: 'CreativeFormat';
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  fields: Array<ApiCreativeFormatFieldDto>;
  id: Scalars['ID']['output'];
  localization: Maybe<Scalars['JSON']['output']>;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  type: ApiCreativeFormatType;
  updatedAt: Scalars['DateTime']['output'];
};

export type ApiCreativeFormatFieldDto = {
  __typename?: 'CreativeFormatFieldDto';
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  localization: Maybe<Scalars['JSON']['output']>;
  localized: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  options: ApiFormatFieldOptionsDto;
  position: Scalars['Int']['output'];
  required: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
  type: ApiCreativeFieldType;
};

export enum ApiCreativeFormatFieldImageFormat {
  Jpg = 'JPG',
  Png = 'PNG'
}

export type ApiCreativeFormatFieldInput = {
  description: InputMaybe<Scalars['String']['input']>;
  localization: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  options: InputMaybe<ApiCreativeFormatFieldOptionsInput>;
  required: Scalars['Boolean']['input'];
  slug: Scalars['String']['input'];
  type: ApiCreativeFieldType;
};

export enum ApiCreativeFormatFieldNumberFormat {
  Decimal = 'DECIMAL',
  Int = 'INT'
}

export type ApiCreativeFormatFieldOptionsInput = {
  height: InputMaybe<Scalars['Int']['input']>;
  imageConversionOutputs: InputMaybe<Array<ApiCreativeFormatFieldOutputOptionInput>>;
  imageInputFormat: InputMaybe<ApiCreativeFormatFieldImageFormat>;
  imageOutputFormat: InputMaybe<ApiCreativeFormatFieldImageFormat>;
  max: InputMaybe<Scalars['Int']['input']>;
  maxFileSize: InputMaybe<Scalars['Int']['input']>;
  maxLength: InputMaybe<Scalars['Int']['input']>;
  min: InputMaybe<Scalars['Int']['input']>;
  minLength: InputMaybe<Scalars['Int']['input']>;
  numberFormat: InputMaybe<ApiCreativeFormatFieldNumberFormat>;
  width: InputMaybe<Scalars['Int']['input']>;
};

export type ApiCreativeFormatFieldOutputOptionInput = {
  height: InputMaybe<Scalars['Int']['input']>;
  operation: InputMaybe<ApiCreativeFormatImageConversionOperation>;
  outputFormat: InputMaybe<ApiCreativeFormatFieldImageFormat>;
  position: InputMaybe<ApiCreativeFormatFieldOutputPositionOptionInput>;
  width: InputMaybe<Scalars['Int']['input']>;
};

export type ApiCreativeFormatFieldOutputPositionOptionInput = {
  x: Scalars['String']['input'];
  y: Scalars['String']['input'];
};

export enum ApiCreativeFormatImageConversionOperation {
  Cover = 'COVER',
  Crop = 'CROP',
  Fill = 'FILL',
  Resize = 'RESIZE'
}

export enum ApiCreativeFormatPaginationLimit {
  Limit_10 = 'LIMIT_10',
  Limit_20 = 'LIMIT_20',
  Limit_50 = 'LIMIT_50'
}

export type ApiCreativeFormatResult = {
  __typename?: 'CreativeFormatResult';
  items: Array<ApiCreativeFormat>;
  total: Scalars['Int']['output'];
};

export enum ApiCreativeFormatType {
  LandingPage = 'LANDING_PAGE',
  Tool = 'TOOL'
}

export type ApiCreativeProductDestination = {
  __typename?: 'CreativeProductDestination';
  type: ApiCreativeDestinationTypes;
  values: Array<ApiCreativeProductDestinationValue>;
};

export type ApiCreativeProductDestinationValue = {
  __typename?: 'CreativeProductDestinationValue';
  available: Scalars['Boolean']['output'];
  catalog: Scalars['ID']['output'];
  externalId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ApiCreativeResult = {
  __typename?: 'CreativeResult';
  items: Array<ApiCreative>;
  total: Scalars['Int']['output'];
};

export enum ApiCreativeStatus {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  PendingApproval = 'PENDING_APPROVAL',
  Rejected = 'REJECTED'
}

export enum ApiCurrency {
  Czk = 'CZK',
  Eur = 'EUR'
}

export type ApiCurrentUser = {
  __typename?: 'CurrentUser';
  currentRole: ApiRole;
  id: Scalars['ID']['output'];
  locale: Maybe<ApiLocale>;
  name: Scalars['String']['output'];
  timezone: Maybe<ApiTimezone>;
  username: Scalars['String']['output'];
};

export type ApiDestinationResult = {
  __typename?: 'DestinationResult';
  custom: ApiCreativeDestination;
  landingPages: ApiCreativeDestination;
  products: ApiCreativeProductDestination;
  time: Scalars['DateTime']['output'];
};


export type ApiDestinationResultCustomArgs = {
  ids: InputMaybe<Array<Scalars['String']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  term: InputMaybe<Scalars['String']['input']>;
};


export type ApiDestinationResultLandingPagesArgs = {
  ids: InputMaybe<Array<Scalars['String']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  term: InputMaybe<Scalars['String']['input']>;
};


export type ApiDestinationResultProductsArgs = {
  ids: InputMaybe<Array<Scalars['String']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  term: InputMaybe<Scalars['String']['input']>;
};

export type ApiFormatFieldOptionsDto = {
  __typename?: 'FormatFieldOptionsDto';
  height: Maybe<Scalars['Int']['output']>;
  imageConversionOutputs: Maybe<Array<ApiFormatFieldOutputOptionDto>>;
  imageInputFormat: Maybe<ApiCreativeFormatFieldImageFormat>;
  imageOutputFormat: Maybe<ApiCreativeFormatFieldImageFormat>;
  max: Maybe<Scalars['Int']['output']>;
  maxFileSize: Maybe<Scalars['Int']['output']>;
  maxLength: Maybe<Scalars['Int']['output']>;
  min: Maybe<Scalars['Int']['output']>;
  minLength: Maybe<Scalars['Int']['output']>;
  numberFormat: Maybe<ApiCreativeFormatFieldNumberFormat>;
  width: Maybe<Scalars['Int']['output']>;
};

export type ApiFormatFieldOutputOptionDto = {
  __typename?: 'FormatFieldOutputOptionDto';
  height: Maybe<Scalars['Int']['output']>;
  operation: Maybe<ApiCreativeFormatImageConversionOperation>;
  outputFormat: Maybe<ApiCreativeFormatFieldImageFormat>;
  position: Maybe<ApiFormatFieldOutputPositionOptionDto>;
  width: Maybe<Scalars['Int']['output']>;
};

export type ApiFormatFieldOutputPositionOptionDto = {
  __typename?: 'FormatFieldOutputPositionOptionDto';
  x: Scalars['String']['output'];
  y: Scalars['String']['output'];
};

export type ApiIdentifiedAgency = {
  __typename?: 'IdentifiedAgency';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ApiIdentifiedCreativeFormat = {
  __typename?: 'IdentifiedCreativeFormat';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ApiIdentifiedTarget = {
  __typename?: 'IdentifiedTarget';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ApiIdentifiedUser = {
  __typename?: 'IdentifiedUser';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type ApiIdentifiedVendor = {
  __typename?: 'IdentifiedVendor';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ApiLeanActivity = {
  __typename?: 'LeanActivity';
  campaignId: Scalars['ID']['output'];
  creatives: ApiCreativeResult;
  currencyCode: ApiCurrency;
  currencyRate: Scalars['Float']['output'];
  endDate: Maybe<Scalars['DateTime']['output']>;
  icon: Maybe<ApiAdvertisingToolIcon>;
  id: Scalars['ID']['output'];
  metrics: ApiMetrics;
  name: Scalars['String']['output'];
  orderedAmount: Scalars['Int']['output'];
  rateType: ApiAdvertisingToolRate;
  startDate: Scalars['DateTime']['output'];
  status: ApiActivityStatus;
  statusNote: Maybe<Scalars['String']['output']>;
  totalPrice: Scalars['Float']['output'];
  unitPrice: Scalars['Float']['output'];
};

export type ApiLeanAgency = {
  __typename?: 'LeanAgency';
  billingInfo: ApiCompanyBilling;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ApiLeanCampaign = {
  __typename?: 'LeanCampaign';
  agency: Maybe<ApiIdentifiedAgency>;
  endDate: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  metrics: ApiMetrics;
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  salesPerson: ApiIdentifiedUser;
  startDate: Maybe<Scalars['DateTime']['output']>;
  status: ApiCampaignStatus;
  vendor: ApiIdentifiedVendor;
};

export type ApiLeanUser = {
  __typename?: 'LeanUser';
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  locale: Maybe<ApiLocale>;
  name: Scalars['String']['output'];
  roles: Array<ApiRole>;
  timezone: Maybe<ApiTimezone>;
  username: Scalars['String']['output'];
};

export type ApiLeanVendor = {
  __typename?: 'LeanVendor';
  billingInfo: ApiCompanyBilling;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum ApiLocale {
  CsCz = 'cs_CZ',
  EnGb = 'en_GB'
}

export type ApiMarket = {
  __typename?: 'Market';
  allowedSites: Maybe<Array<Scalars['String']['output']>>;
  currency: Maybe<ApiCurrency>;
  id: Scalars['ID']['output'];
  locale: Maybe<ApiLocale>;
  name: Scalars['String']['output'];
  skuCatalogs: Maybe<Array<Scalars['String']['output']>>;
  timezone: Maybe<ApiTimezone>;
};

export type ApiMarketResult = {
  __typename?: 'MarketResult';
  items: Array<ApiMarket>;
  total: Scalars['Int']['output'];
};

export type ApiMediaFileDto = {
  __typename?: 'MediaFileDto';
  id: Scalars['ID']['output'];
  meta: ApiMediaMetaDto;
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  size: Scalars['Int']['output'];
  url: Scalars['String']['output'];
};

export type ApiMediaMetaDto = {
  __typename?: 'MediaMetaDto';
  imageHeight: Maybe<Scalars['Int']['output']>;
  imageWidth: Maybe<Scalars['Int']['output']>;
};

export enum ApiMediaType {
  AdvertisingTool = 'ADVERTISING_TOOL',
  Creative = 'CREATIVE',
  CreativeFormat = 'CREATIVE_FORMAT'
}

export type ApiMediaUploadRequest = {
  __typename?: 'MediaUploadRequest';
  createdAt: Scalars['DateTime']['output'];
  expiresAt: Scalars['DateTime']['output'];
  requestId: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type ApiMetrics = {
  __typename?: 'Metrics';
  clicks: Scalars['Int']['output'];
  ctr: Scalars['Float']['output'];
  impressions: Scalars['Int']['output'];
  osp: Scalars['Float']['output'];
  views: Scalars['Int']['output'];
  visibility: Scalars['Float']['output'];
};

export type ApiMutation = {
  __typename?: 'Mutation';
  approveActivity: Scalars['Boolean']['output'];
  approveCreative: Scalars['Boolean']['output'];
  assignVendorBrand: Scalars['Boolean']['output'];
  cancelActivity: Scalars['Boolean']['output'];
  changeAdvertisingCategoryPosition: Scalars['Boolean']['output'];
  changeAdvertisingCategoryToolPosition: Scalars['Boolean']['output'];
  createActivity: ApiActivity;
  createAdTarget: ApiAdTargetDto;
  createAdvertisingCategory: ApiAdvertisingCategory;
  createAdvertisingTool: ApiAdvertisingTool;
  createAgency: ApiAgency;
  createAgencyUser: ApiUser;
  createCampaign: ApiCampaign;
  createCreative: ApiCreative;
  createCreativeFormat: ApiCreativeFormat;
  createMarket: ApiMarket;
  createMediaUploadRequest: ApiMediaUploadRequest;
  createOrganization: ApiOrganization;
  createSkuCatalog: ApiSkuCatalog;
  createUser: ApiUser;
  createVendor: ApiVendor;
  createVendorUser: ApiUser;
  deleteAdTarget: Scalars['Boolean']['output'];
  deleteAdvertisingCategory: Scalars['Boolean']['output'];
  deleteAdvertisingTool: Scalars['Boolean']['output'];
  deleteAgency: Scalars['Boolean']['output'];
  deleteAgencyUser: Scalars['Boolean']['output'];
  deleteCampaign: Scalars['Boolean']['output'];
  deleteCreative: Scalars['Boolean']['output'];
  deleteVendor: Scalars['Boolean']['output'];
  deleteVendorUser: Scalars['Boolean']['output'];
  login: ApiAuthToken;
  mediaFile: ApiMediaFileDto;
  pauseActivity: Scalars['Boolean']['output'];
  refreshToken: ApiAuthToken;
  rejectActivity: Scalars['Boolean']['output'];
  rejectCreative: Scalars['Boolean']['output'];
  requestActivityApproval: Scalars['Boolean']['output'];
  requestCreativeApproval: Scalars['Boolean']['output'];
  requestPasswordReset: Scalars['Boolean']['output'];
  resetPassword: Scalars['Boolean']['output'];
  resumeActivity: Scalars['Boolean']['output'];
  switchContext: ApiAuthToken;
  syncSkuCatalog: Scalars['Boolean']['output'];
  trackCurrency: Scalars['Boolean']['output'];
  unassignVendorBrand: Scalars['Boolean']['output'];
  updateActivity: ApiActivity;
  updateAdTarget: ApiAdTargetDto;
  updateAdvertisingCategory: ApiAdvertisingCategory;
  updateAdvertisingTool: ApiAdvertisingTool;
  updateAgency: ApiAgency;
  updateCampaign: ApiCampaign;
  updateCreative: ApiCreative;
  updateCreativeFormat: ApiCreativeFormat;
  updateCurrencyRates: Scalars['Boolean']['output'];
  updateMarket: ApiMarket;
  updateOrganization: ApiOrganization;
  updateSkuCatalog: ApiSkuCatalog;
  updateUser: ApiUser;
  updateVendor: ApiVendor;
  verifyPasswordResetToken: Scalars['Boolean']['output'];
};


export type ApiMutationApproveActivityArgs = {
  id: Scalars['ID']['input'];
};


export type ApiMutationApproveCreativeArgs = {
  id: Scalars['ID']['input'];
};


export type ApiMutationAssignVendorBrandArgs = {
  brandId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};


export type ApiMutationCancelActivityArgs = {
  id: Scalars['ID']['input'];
};


export type ApiMutationChangeAdvertisingCategoryPositionArgs = {
  categoryId: Scalars['ID']['input'];
  position: Scalars['Int']['input'];
};


export type ApiMutationChangeAdvertisingCategoryToolPositionArgs = {
  categoryId: Scalars['ID']['input'];
  position: Scalars['Int']['input'];
  toolId: Scalars['ID']['input'];
};


export type ApiMutationCreateActivityArgs = {
  advertisingToolId: Scalars['ID']['input'];
  campaignId: Scalars['ID']['input'];
  creatives: InputMaybe<Array<Scalars['String']['input']>>;
  customPrice: InputMaybe<Scalars['Float']['input']>;
  endDate: InputMaybe<Scalars['String']['input']>;
  note: InputMaybe<Scalars['String']['input']>;
  orderedAmount: InputMaybe<Scalars['Int']['input']>;
  startDate: Scalars['String']['input'];
  targets: InputMaybe<Array<ApiCreateActivityTarget>>;
};


export type ApiMutationCreateAdTargetArgs = {
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: ApiAdTargetType;
  valueLimit?: InputMaybe<Scalars['Int']['input']>;
  values?: InputMaybe<Array<ApiAdTargetValueInput>>;
};


export type ApiMutationCreateAdvertisingCategoryArgs = {
  description: InputMaybe<Scalars['String']['input']>;
  isActive: InputMaybe<Scalars['Boolean']['input']>;
  isInternal: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  summary: InputMaybe<Scalars['String']['input']>;
};


export type ApiMutationCreateAdvertisingToolArgs = {
  allowedMarkets: InputMaybe<Array<Scalars['String']['input']>>;
  categories: Array<Scalars['String']['input']>;
  config: InputMaybe<ApiAvertisingdToolConfigInput>;
  creativeFormats: Array<Scalars['String']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  features: InputMaybe<ApiAvertisingdToolFeaturesInput>;
  icon: InputMaybe<ApiAdvertisingToolIcon>;
  name: Scalars['String']['input'];
  previewImage: InputMaybe<Scalars['String']['input']>;
  targets: InputMaybe<Array<Scalars['String']['input']>>;
  type: ApiAdvertisingToolType;
};


export type ApiMutationCreateAgencyArgs = {
  billingInfo: ApiCreateCompanyBillingInput;
  defaultSalesPerson: InputMaybe<Scalars['ID']['input']>;
  defaultTrafficker: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  vendors: InputMaybe<Array<Scalars['String']['input']>>;
};


export type ApiMutationCreateAgencyUserArgs = {
  agencyId: Scalars['ID']['input'];
  locale: InputMaybe<ApiLocale>;
  markets: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  timezone: InputMaybe<ApiTimezone>;
  username: Scalars['String']['input'];
};


export type ApiMutationCreateCampaignArgs = {
  agency: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  primaryContact: Scalars['ID']['input'];
  secondaryContact: InputMaybe<Scalars['ID']['input']>;
  vendor: Scalars['ID']['input'];
};


export type ApiMutationCreateCreativeArgs = {
  contents: InputMaybe<Array<ApiCreateCreativeField>>;
  creativeFormatId: Scalars['ID']['input'];
  destination: ApiCreativeDestinationDetails;
  name: Scalars['String']['input'];
  vendor: Scalars['ID']['input'];
};


export type ApiMutationCreateCreativeFormatArgs = {
  description: InputMaybe<Scalars['String']['input']>;
  fields: Array<ApiCreativeFormatFieldInput>;
  localization: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  type: ApiCreativeFormatType;
};


export type ApiMutationCreateMarketArgs = {
  allowedSites: InputMaybe<Array<Scalars['String']['input']>>;
  currency: InputMaybe<ApiCurrency>;
  id: Scalars['String']['input'];
  locale: InputMaybe<ApiLocale>;
  name: Scalars['String']['input'];
  skuCatalogs: InputMaybe<Array<Scalars['String']['input']>>;
  timezone: InputMaybe<ApiTimezone>;
};


export type ApiMutationCreateMediaUploadRequestArgs = {
  imageHeight: InputMaybe<Scalars['Int']['input']>;
  imageWidth: InputMaybe<Scalars['Int']['input']>;
  mimeType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  size: Scalars['Int']['input'];
  type: ApiMediaType;
};


export type ApiMutationCreateOrganizationArgs = {
  billingAddress: InputMaybe<ApiCreateAddressInput>;
  billingInfo: ApiCreateCompanyBillingInput;
  contactAddress: ApiCreateCompanyContactInput;
  currency: ApiCurrency;
  id: Scalars['String']['input'];
  locale: ApiLocale;
  name: Scalars['String']['input'];
  timezone: ApiTimezone;
};


export type ApiMutationCreateSkuCatalogArgs = {
  config: InputMaybe<Scalars['JSON']['input']>;
  handler: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isActive: InputMaybe<Scalars['Boolean']['input']>;
  syncInterval: Scalars['Int']['input'];
};


export type ApiMutationCreateUserArgs = {
  isAdmin: InputMaybe<Scalars['Boolean']['input']>;
  locale: InputMaybe<ApiLocale>;
  markets: Array<ApiAssignUserMarketDto>;
  name: Scalars['String']['input'];
  timezone: InputMaybe<ApiTimezone>;
  username: Scalars['String']['input'];
};


export type ApiMutationCreateVendorArgs = {
  billingInfo: ApiCreateCompanyBillingInput;
  brands: InputMaybe<Array<Scalars['String']['input']>>;
  defaultSalesPerson: InputMaybe<Scalars['ID']['input']>;
  defaultTrafficker: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};


export type ApiMutationCreateVendorUserArgs = {
  locale: InputMaybe<ApiLocale>;
  markets: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  timezone: InputMaybe<ApiTimezone>;
  username: Scalars['String']['input'];
  vendorId: Scalars['ID']['input'];
};


export type ApiMutationDeleteAdTargetArgs = {
  id: Scalars['ID']['input'];
};


export type ApiMutationDeleteAdvertisingCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type ApiMutationDeleteAdvertisingToolArgs = {
  id: Scalars['ID']['input'];
};


export type ApiMutationDeleteAgencyArgs = {
  id: Scalars['String']['input'];
};


export type ApiMutationDeleteAgencyUserArgs = {
  agencyId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type ApiMutationDeleteCampaignArgs = {
  id: Scalars['ID']['input'];
};


export type ApiMutationDeleteCreativeArgs = {
  id: Scalars['ID']['input'];
};


export type ApiMutationDeleteVendorArgs = {
  id: Scalars['String']['input'];
};


export type ApiMutationDeleteVendorUserArgs = {
  userId: Scalars['ID']['input'];
  vendorId: Scalars['ID']['input'];
};


export type ApiMutationLoginArgs = {
  organizationId: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type ApiMutationMediaFileArgs = {
  id: Scalars['ID']['input'];
};


export type ApiMutationPauseActivityArgs = {
  id: Scalars['ID']['input'];
};


export type ApiMutationRefreshTokenArgs = {
  refreshToken: Scalars['String']['input'];
};


export type ApiMutationRejectActivityArgs = {
  id: Scalars['ID']['input'];
  note: Scalars['String']['input'];
};


export type ApiMutationRejectCreativeArgs = {
  id: Scalars['ID']['input'];
  note: Scalars['String']['input'];
};


export type ApiMutationRequestActivityApprovalArgs = {
  id: Scalars['ID']['input'];
};


export type ApiMutationRequestCreativeApprovalArgs = {
  id: Scalars['ID']['input'];
};


export type ApiMutationRequestPasswordResetArgs = {
  username: Scalars['String']['input'];
};


export type ApiMutationResetPasswordArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type ApiMutationResumeActivityArgs = {
  id: Scalars['ID']['input'];
};


export type ApiMutationSwitchContextArgs = {
  contextId: Scalars['String']['input'];
};


export type ApiMutationSyncSkuCatalogArgs = {
  id: Scalars['String']['input'];
};


export type ApiMutationTrackCurrencyArgs = {
  currency: Scalars['String']['input'];
};


export type ApiMutationUnassignVendorBrandArgs = {
  brandId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};


export type ApiMutationUpdateActivityArgs = {
  creatives: InputMaybe<Array<Scalars['String']['input']>>;
  customPrice: InputMaybe<Scalars['Float']['input']>;
  endDate: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  note: InputMaybe<Scalars['String']['input']>;
  orderedAmount: InputMaybe<Scalars['Int']['input']>;
  startDate: InputMaybe<Scalars['String']['input']>;
  targets: InputMaybe<Array<ApiCreateActivityTarget>>;
};


export type ApiMutationUpdateAdTargetArgs = {
  id: Scalars['ID']['input'];
  key: InputMaybe<Scalars['String']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  type: InputMaybe<ApiAdTargetType>;
  valueLimit?: InputMaybe<Scalars['Int']['input']>;
  values?: InputMaybe<Array<ApiAdTargetValueInput>>;
};


export type ApiMutationUpdateAdvertisingCategoryArgs = {
  description: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isActive: InputMaybe<Scalars['Boolean']['input']>;
  isInternal: InputMaybe<Scalars['Boolean']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  position: InputMaybe<Scalars['Int']['input']>;
  summary: InputMaybe<Scalars['String']['input']>;
};


export type ApiMutationUpdateAdvertisingToolArgs = {
  allowedMarkets: InputMaybe<Array<Scalars['String']['input']>>;
  categories: InputMaybe<Array<Scalars['String']['input']>>;
  config: InputMaybe<ApiAvertisingdToolConfigInput>;
  creativeFormats: InputMaybe<Array<Scalars['String']['input']>>;
  description: InputMaybe<Scalars['String']['input']>;
  features: InputMaybe<ApiAvertisingdToolFeaturesInput>;
  icon: InputMaybe<ApiAdvertisingToolIcon>;
  id: Scalars['ID']['input'];
  name: InputMaybe<Scalars['String']['input']>;
  previewImage: InputMaybe<Scalars['String']['input']>;
  targets: InputMaybe<Array<Scalars['String']['input']>>;
  type: InputMaybe<ApiAdvertisingToolType>;
};


export type ApiMutationUpdateAgencyArgs = {
  billingInfo: InputMaybe<ApiUpdateCompanyBillingInput>;
  defaultSalesPerson: InputMaybe<Scalars['ID']['input']>;
  defaultTrafficker: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  name: InputMaybe<Scalars['String']['input']>;
  vendors: InputMaybe<Array<Scalars['String']['input']>>;
};


export type ApiMutationUpdateCampaignArgs = {
  id: Scalars['ID']['input'];
  name: InputMaybe<Scalars['String']['input']>;
  salesPerson: InputMaybe<Scalars['ID']['input']>;
};


export type ApiMutationUpdateCreativeArgs = {
  contents: InputMaybe<Array<ApiCreateCreativeField>>;
  destination: InputMaybe<ApiCreativeDestinationDetails>;
  id: Scalars['ID']['input'];
  name: InputMaybe<Scalars['String']['input']>;
};


export type ApiMutationUpdateCreativeFormatArgs = {
  description: InputMaybe<Scalars['String']['input']>;
  fields: Array<ApiUpdateCreativeFormatFieldInput>;
  id: Scalars['String']['input'];
  localization: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};


export type ApiMutationUpdateMarketArgs = {
  allowedSites: InputMaybe<Array<Scalars['String']['input']>>;
  currency: InputMaybe<ApiCurrency>;
  id: Scalars['String']['input'];
  locale: InputMaybe<ApiLocale>;
  name: InputMaybe<Scalars['String']['input']>;
  skuCatalogs: InputMaybe<Array<Scalars['String']['input']>>;
  timezone: InputMaybe<ApiTimezone>;
};


export type ApiMutationUpdateOrganizationArgs = {
  billingAddress: InputMaybe<ApiUpdateAddressInput>;
  billingInfo: InputMaybe<ApiUpdateCompanyBillingInput>;
  contactAddress: InputMaybe<ApiUpdateCompanyContactInput>;
  currency: InputMaybe<ApiCurrency>;
  defaultSalesPerson: InputMaybe<Scalars['ID']['input']>;
  defaultTrafficker: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['String']['input'];
  locale: InputMaybe<ApiLocale>;
  name: InputMaybe<Scalars['String']['input']>;
  timezone: InputMaybe<ApiTimezone>;
};


export type ApiMutationUpdateSkuCatalogArgs = {
  config: InputMaybe<Scalars['JSON']['input']>;
  handler: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isActive: InputMaybe<Scalars['Boolean']['input']>;
  syncInterval: InputMaybe<Scalars['Int']['input']>;
};


export type ApiMutationUpdateUserArgs = {
  id: Scalars['String']['input'];
  isActive: InputMaybe<Scalars['Boolean']['input']>;
  isAdmin: InputMaybe<Scalars['Boolean']['input']>;
  locale: InputMaybe<ApiLocale>;
  markets: InputMaybe<Array<ApiAssignUserMarketDto>>;
  name: InputMaybe<Scalars['String']['input']>;
  timezone: InputMaybe<ApiTimezone>;
  username: InputMaybe<Scalars['String']['input']>;
};


export type ApiMutationUpdateVendorArgs = {
  billingInfo: InputMaybe<ApiUpdateCompanyBillingInput>;
  brands: InputMaybe<Array<Scalars['String']['input']>>;
  defaultSalesPerson: InputMaybe<Scalars['ID']['input']>;
  defaultTrafficker: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  name: InputMaybe<Scalars['String']['input']>;
};


export type ApiMutationVerifyPasswordResetTokenArgs = {
  token: Scalars['String']['input'];
};

export type ApiOrganization = {
  __typename?: 'Organization';
  billingAddress: Maybe<ApiAddress>;
  billingInfo: ApiCompanyBilling;
  contactAddress: ApiCompanyContact;
  currency: ApiCurrency;
  id: Scalars['ID']['output'];
  locale: ApiLocale;
  name: Scalars['String']['output'];
  timezone: ApiTimezone;
};

export type ApiQuery = {
  __typename?: 'Query';
  activities: ApiActivityResult;
  activity: ApiActivity;
  adTarget: ApiAdTargetDto;
  adTargets: Array<ApiAdTargetDto>;
  advertisingCategories: ApiAdvertisingCategoryResult;
  advertisingCategory: ApiAdvertisingCategory;
  advertisingLibrary: Array<ApiAdvertisingLibraryItem>;
  advertisingTool: ApiAdvertisingTool;
  advertisingTools: ApiAdvertisingToolResult;
  agencies: ApiAgencyResult;
  agency: ApiAgency;
  brands: ApiBrandResult;
  campaign: ApiCampaign;
  campaigns: ApiCampaignResult;
  contexts: Array<ApiAuthContext>;
  creative: ApiCreative;
  creativeDestinations: ApiDestinationResult;
  creativeFormat: ApiCreativeFormat;
  creativeFormats: ApiCreativeFormatResult;
  creatives: ApiCreativeResult;
  market: ApiMarket;
  markets: ApiMarketResult;
  me: Maybe<ApiCurrentUser>;
  meta: ApiAppMetadata;
  organization: ApiOrganization;
  skuCatalogs: ApiSkuCatalogResult;
  user: ApiUser;
  users: ApiUserResult;
  vendor: ApiVendor;
  vendors: ApiVendorResult;
  workspace: Maybe<ApiWorkspace>;
};


export type ApiQueryActivitiesArgs = {
  endDate: InputMaybe<Scalars['String']['input']>;
  limit?: Scalars['Int']['input'];
  page?: Scalars['Int']['input'];
  salesPerson: InputMaybe<Scalars['ID']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  startDate: InputMaybe<Scalars['String']['input']>;
  status: InputMaybe<ApiActivityStatus>;
  vendor: InputMaybe<Scalars['ID']['input']>;
};


export type ApiQueryActivityArgs = {
  id: Scalars['ID']['input'];
};


export type ApiQueryAdTargetArgs = {
  id: Scalars['ID']['input'];
};


export type ApiQueryAdTargetsArgs = {
  search: InputMaybe<Scalars['String']['input']>;
};


export type ApiQueryAdvertisingCategoriesArgs = {
  search: InputMaybe<Scalars['String']['input']>;
};


export type ApiQueryAdvertisingCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type ApiQueryAdvertisingToolArgs = {
  id: Scalars['ID']['input'];
};


export type ApiQueryAdvertisingToolsArgs = {
  limit?: InputMaybe<ApiAdvertisingToolsPaginationLimit>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
};


export type ApiQueryAgenciesArgs = {
  limit?: Scalars['Int']['input'];
  page?: Scalars['Int']['input'];
  search: InputMaybe<Scalars['String']['input']>;
  vendor: InputMaybe<Scalars['String']['input']>;
};


export type ApiQueryAgencyArgs = {
  id: Scalars['String']['input'];
};


export type ApiQueryBrandsArgs = {
  limit?: Scalars['Int']['input'];
  page?: Scalars['Int']['input'];
  search: InputMaybe<Scalars['String']['input']>;
};


export type ApiQueryCampaignArgs = {
  id: Scalars['ID']['input'];
};


export type ApiQueryCampaignsArgs = {
  endDate: InputMaybe<Scalars['String']['input']>;
  limit?: Scalars['Int']['input'];
  page?: Scalars['Int']['input'];
  salesPerson: InputMaybe<Scalars['ID']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  startDate: InputMaybe<Scalars['String']['input']>;
  status: InputMaybe<ApiCampaignStatus>;
  vendor: InputMaybe<Scalars['ID']['input']>;
};


export type ApiQueryCreativeArgs = {
  id: Scalars['ID']['input'];
};


export type ApiQueryCreativeFormatArgs = {
  id: Scalars['ID']['input'];
};


export type ApiQueryCreativeFormatsArgs = {
  limit?: InputMaybe<ApiCreativeFormatPaginationLimit>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ApiCreativeFormatType>;
};


export type ApiQueryCreativesArgs = {
  formats: InputMaybe<Array<Scalars['String']['input']>>;
  limit?: Scalars['Int']['input'];
  page?: Scalars['Int']['input'];
  search: InputMaybe<Scalars['String']['input']>;
  vendorId: InputMaybe<Scalars['String']['input']>;
};


export type ApiQueryMarketArgs = {
  id: Scalars['String']['input'];
};


export type ApiQueryMarketsArgs = {
  limit?: Scalars['Int']['input'];
  page?: Scalars['Int']['input'];
  search: InputMaybe<Scalars['String']['input']>;
};


export type ApiQueryMetaArgs = {
  slug: Scalars['String']['input'];
};


export type ApiQueryOrganizationArgs = {
  id: Scalars['String']['input'];
};


export type ApiQuerySkuCatalogsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type ApiQueryUserArgs = {
  id: Scalars['String']['input'];
};


export type ApiQueryUsersArgs = {
  limit?: Scalars['Int']['input'];
  page?: Scalars['Int']['input'];
  roles: InputMaybe<Array<ApiRole>>;
  search: InputMaybe<Scalars['String']['input']>;
};


export type ApiQueryVendorArgs = {
  id: Scalars['String']['input'];
};


export type ApiQueryVendorsArgs = {
  limit?: Scalars['Int']['input'];
  page?: Scalars['Int']['input'];
  search: InputMaybe<Scalars['String']['input']>;
};

export enum ApiRole {
  Agency = 'AGENCY',
  MarketAdmin = 'MARKET_ADMIN',
  OrganizationAdmin = 'ORGANIZATION_ADMIN',
  Retailer = 'RETAILER',
  SuperAdmin = 'SUPER_ADMIN',
  Vendor = 'VENDOR'
}

export type ApiSkuCatalog = {
  __typename?: 'SKUCatalog';
  config: Maybe<Scalars['JSON']['output']>;
  handler: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  status: ApiSkuCatalogStatus;
  syncError: Maybe<Scalars['String']['output']>;
  syncInterval: Scalars['Int']['output'];
  syncedAt: Maybe<Scalars['DateTime']['output']>;
};

export type ApiSkuCatalogResult = {
  __typename?: 'SKUCatalogResult';
  items: Array<ApiSkuCatalog>;
  total: Scalars['Int']['output'];
};

export enum ApiSkuCatalogStatus {
  OutOfSync = 'OUT_OF_SYNC',
  Running = 'RUNNING',
  Synced = 'SYNCED'
}

export enum ApiTimezone {
  EuropePrague = 'Europe_Prague'
}

export type ApiToolConfigDto = {
  __typename?: 'ToolConfigDto';
  auction: Scalars['Boolean']['output'];
  currencyCode: ApiCurrency;
  currencyRate: Scalars['Float']['output'];
  minimalBudget: Scalars['Int']['output'];
  price: Scalars['Float']['output'];
  rateType: ApiAdvertisingToolRate;
};

export type ApiToolFeaturesDto = {
  __typename?: 'ToolFeaturesDto';
  autoPauseFlights: Maybe<Scalars['Boolean']['output']>;
  checkTargettingAvailability: Maybe<Scalars['Boolean']['output']>;
  minimalDaysBeforeStart: Maybe<Scalars['Int']['output']>;
  useDefaultPositionValue: Maybe<Scalars['Boolean']['output']>;
};

export type ApiUpdateAddressInput = {
  city: InputMaybe<Scalars['String']['input']>;
  country: InputMaybe<Scalars['String']['input']>;
  postalCode: InputMaybe<Scalars['String']['input']>;
  region: InputMaybe<Scalars['String']['input']>;
  street: InputMaybe<Scalars['String']['input']>;
  street2: InputMaybe<Scalars['String']['input']>;
};

export type ApiUpdateCompanyBillingInput = {
  businessId: InputMaybe<Scalars['String']['input']>;
  businessName: InputMaybe<Scalars['String']['input']>;
  vatNumber: InputMaybe<Scalars['String']['input']>;
};

export type ApiUpdateCompanyContactInput = {
  city: InputMaybe<Scalars['String']['input']>;
  country: InputMaybe<Scalars['String']['input']>;
  email: InputMaybe<Scalars['String']['input']>;
  phone: InputMaybe<Scalars['String']['input']>;
  postalCode: InputMaybe<Scalars['String']['input']>;
  region: InputMaybe<Scalars['String']['input']>;
  street: InputMaybe<Scalars['String']['input']>;
  street2: InputMaybe<Scalars['String']['input']>;
};

export type ApiUpdateCreativeFormatFieldInput = {
  description: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['String']['input']>;
  localization: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  options: InputMaybe<ApiCreativeFormatFieldOptionsInput>;
  required: Scalars['Boolean']['input'];
  slug: Scalars['String']['input'];
  type: ApiCreativeFieldType;
};

export type ApiUser = {
  __typename?: 'User';
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isAdmin: Scalars['Boolean']['output'];
  locale: Maybe<ApiLocale>;
  markets: Array<ApiUserMarket>;
  name: Scalars['String']['output'];
  timezone: Maybe<ApiTimezone>;
  username: Scalars['String']['output'];
};

export type ApiUserMarket = {
  __typename?: 'UserMarket';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  role: ApiRole;
};

export type ApiUserResult = {
  __typename?: 'UserResult';
  items: Array<ApiLeanUser>;
  total: Scalars['Int']['output'];
};

export type ApiVendor = {
  __typename?: 'Vendor';
  billingInfo: ApiCompanyBilling;
  brands: ApiVendorBrandResult;
  defaultSalesPerson: Maybe<ApiLeanUser>;
  defaultTrafficker: Maybe<ApiLeanUser>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  users: ApiVendorUserResult;
};


export type ApiVendorBrandsArgs = {
  limit?: Scalars['Int']['input'];
  page?: Scalars['Int']['input'];
};


export type ApiVendorUsersArgs = {
  limit?: Scalars['Int']['input'];
  page?: Scalars['Int']['input'];
  search: InputMaybe<Scalars['String']['input']>;
};

export type ApiVendorBrandResult = {
  __typename?: 'VendorBrandResult';
  items: Array<ApiBrand>;
  total: Scalars['Int']['output'];
};

export type ApiVendorResult = {
  __typename?: 'VendorResult';
  items: Array<ApiLeanVendor>;
  total: Scalars['Int']['output'];
};

export type ApiVendorUser = {
  __typename?: 'VendorUser';
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  locale: Maybe<ApiLocale>;
  name: Scalars['String']['output'];
  timezone: Maybe<ApiTimezone>;
  username: Scalars['String']['output'];
};

export type ApiVendorUserResult = {
  __typename?: 'VendorUserResult';
  items: Array<ApiVendorUser>;
  total: Scalars['Int']['output'];
};

export type ApiWorkspace = {
  __typename?: 'Workspace';
  currency: ApiCurrency;
  id: Scalars['String']['output'];
  locale: ApiLocale;
  name: Scalars['String']['output'];
  timezone: ApiTimezone;
};


      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    