import { StatusLabel } from '@/app/components';
import { CampaignStatus as CampaignStatusType } from '../../interfaces';

interface CampaignStatusProps {
  status: CampaignStatusType;
};

export const CampaignStatus: React.FC<CampaignStatusProps> = ({
  status,
}) => {
  const getStatusColor = (status: CampaignStatusType) => {
    switch (status) {
      case 'online':
        return 'green';
      case 'ready':
        return 'lightGreen';
      case 'closed':
        return 'lightGray';

      case 'draft':
      case 'incomplete':
      default:
        return 'yellow';
    }
  };

  const getStatusLabel = (status: CampaignStatusType) => {
    switch (status) {
      case 'ready':
        return 'Ready';
      case 'online':
        return 'Online';
      case 'closed':
        return 'Closed';

      case 'draft':
      case 'incomplete':
      default:
        return 'Incomplete';
    }
  };

  return (
    <StatusLabel
      color={getStatusColor(status)}
      label={getStatusLabel(status)}
      hasBorder={false}
    />
  );
};
